import { Box } from "@mui/material";
import Stack from "@mui/material/Stack";
import React from "react";

const removeInvalidChars = (value: string | null | undefined) => {
  if (value == null) return value;
  return value.replace(/[^0-9]/g, "");
};
const defaultOtpInput = new Array(6).fill("");
const isNotEmpty = (value: string | null | undefined) => {
  return value != null && value !== "";
};

export const OtpInput = ({ numberDigits = 6, onFinish, onChange }) => {
  const [digits, setDigits] = React.useState<string[]>(defaultOtpInput);
  const [currentIndex, setCurrentIndex] = React.useState(0);

  React.useEffect(() => {
    onChange(digits.join(""));
  }, [digits, onChange]);

  const updateInput = React.useCallback(
    (newValue: string | null | undefined, i: number) => {
      const value = removeInvalidChars(newValue);
      if (value && value.length > 0) {
        setDigits((current) => {
          const nextDigits = [...current];
          const chars = [...value];
          for (let charIndex = 0; charIndex < chars.length && charIndex + i < 6; charIndex++) {
            const char = chars[charIndex];
            nextDigits[i + charIndex] = char;
          }
          return nextDigits;
        });
        const nextPosition = i + value.length;
        if (nextPosition < numberDigits) {
          setCurrentIndex(nextPosition);
          return;
        }
        onFinish();
      }
    },
    [onFinish, numberDigits]
  );

  const handleKeyDown = React.useCallback(
    (event: React.KeyboardEvent<HTMLDivElement | HTMLTextAreaElement>) => {
      const removeDigit = (current, at) => {
        const nextCurrent = [...current];
        nextCurrent[at] = "";
        return nextCurrent;
      };

      if (event.key === "Backspace") {
        if (isNotEmpty(digits[currentIndex]) || currentIndex === 0) {
          setDigits((current) => removeDigit(current, currentIndex));
        } else {
          setDigits((current) => removeDigit(current, currentIndex - 1));
          setCurrentIndex(currentIndex - 1);
        }
        event.stopPropagation();
        return;
      }

      if (/^[0-9]$/.test(event.key)) {
        updateInput(event.key, currentIndex);
      }
    },
    [currentIndex, digits, updateInput]
  );
  const renderInputs = () => {
    const elements = [];
    for (let i = 0; i < numberDigits; i++) {
      const isFocus = i === currentIndex;
      elements.push(
        <Box
          width={40}
          height={40}
          py={1}
          px={1.75}
          border={isFocus ? 2 : 1}
          borderRadius={1}
          sx={{ borderColor: isFocus ? "primary.main" : "grey.300" }}
          onClick={() => setCurrentIndex(i)}
          key={i}
        >
          {digits[i]}
        </Box>
      );
    }
    return <>{elements}</>;
  };

  const handlePaste = React.useCallback(
    (e: React.ClipboardEvent<unknown>) => {
      if (!e.clipboardData) return;
      const code = e.clipboardData.getData("text");
      if (!code) return;
      updateInput(code, currentIndex);
    },
    [currentIndex, updateInput]
  );

  React.useEffect(() => {
    window.addEventListener("keydown", handleKeyDown as never);
    window.addEventListener("paste", handlePaste as never);

    return () => {
      window.removeEventListener("keydown", handleKeyDown as never);
      window.removeEventListener("paste", handlePaste as never);
    };
  }, [currentIndex, handleKeyDown, handlePaste]);
  return (
    <Stack direction="row" spacing={1}>
      {renderInputs()}
    </Stack>
  );
};
