import { ADMIN_PATHS } from "@/commons/consts/system/routes";
import { loadable } from "@/utils/loadable.util";

export const userManagementRoutes = [
  {
    path: ADMIN_PATHS.ADMIN_USER_MANAGEMENT_ROUTE,
    Component: loadable(() => import("@/pages/user-management/admin-list.page")),
    exact: true
  },
  {
    path: ADMIN_PATHS.ADMIN_USER_MANAGEMENT_ADD_USER_ROUTE,
    Component: loadable(() => import("@/pages/user-management/admin-creation.page")),
    exact: true
  },
  {
    path: ADMIN_PATHS.ADMIN_USER_MANAGEMENT_EDIT_USER_ROUTE,
    Component: loadable(() => import("@/pages/user-management/admin-edition.page")),
    exact: true
  }
];
