import { COMMON_FIELDS } from "@/commons/schemas";
import { datetimeUtil } from "@/utils";
import { CREDITS } from "@/commons/consts";
import _ from "lodash";

const { sortByTime } = datetimeUtil;

export const parseBidArray = (bidArray) => {
  let newBidArray = [...bidArray];
  if (bidArray && Array.isArray(bidArray)) {
    const length = bidArray.length;
    newBidArray = bidArray.sort(sortByTime(COMMON_FIELDS.createdDate)).map((bid, index) => {
      let parsedBid = { ...bid };
      if (index !== length - 1) {
        parsedBid.status = undefined;
      }
      return parsedBid;
    });
  }

  return newBidArray;
};

export const parseCreditTerm = (credit) => {
  return CREDITS[credit] ? CREDITS[credit].label : "";
};

export const saleDetailsMapper = (saleDetails, id) => {
  const result = _.chain(saleDetails)
    .flatMap(
      ({ saleId, credit, reputation, reputationList, saleDetailResponses, paymentMethods }) =>
        _.map(saleDetailResponses, (sale) => ({
          saleId,
          credit,
          reputation,
          reputationList,
          paymentMethods,
          ...sale
        }))
    )
    .map(
      ({
        productResponse,
        saleId,
        credit,
        reputation,
        reputationList,
        quantity,
        unitPrice,
        unitPriceAfterRebate,
        minimumQuantity,
        paymentMethods
      }) => ({
        saleId,
        credit,
        reputation,
        reputationList,
        quantity,
        unitPrice: unitPriceAfterRebate === undefined ? unitPrice : unitPriceAfterRebate,
        minimumQuantity,
        paymentMethods,
        productId: productResponse.id
      })
    )
    .value();

  return result.find(({ productId }) => productId === id);
};
