import { generatePath } from "@/components/commons/navigator";

export const generatePathEnum = <T extends Record<string, string>>(
  paths: T,
  params: Record<string, string | number>
) => {
  const entries = Object.entries(paths);
  const result: T = { ...paths };
  for (const [key, value] of entries) {
    const nextValue = generatePath(value, params);
    (result as Record<string, string>)[key] = nextValue;
  }
  return result;
};

export const getPrefixUrl = (pathname) => {
  const firstUrlSegment = pathname.split("/")[1];
  return firstUrlSegment ? "/" + firstUrlSegment : "/";
};

export const removeIdPartFromProductUrl = (str) => {
  if (str) {
    const parts = str.split("--");
    return parts[0];
  }
  return str;
};

export const getIdPartFromProductUrl = (str) => {
  if (str) {
    const parts = str.split("--");
    return parts[1] ? parts[1] : str;
  }
  return str;
};
