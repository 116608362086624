import { generatePath } from "@/components/commons/navigator";
import { ROUTES } from "@/commons/consts";

export const getMenuData = (prefix: string) => [
  {
    title: "Personal Information",
    key: ROUTES.PERSIONAL_INFORMATION,
    url: generatePath(ROUTES.PERSIONAL_INFORMATION, { prefix })
  },
  {
    title: "Notifications",
    key: ROUTES.PROFILE_NOTIFICATIONS,
    url: generatePath(ROUTES.PROFILE_NOTIFICATIONS, { prefix })
  }
];
