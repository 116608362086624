import { useBrandingAssets } from "@/entities/branding-asset/libs/use-branding-asset";
import { useGetMarketplaceName } from "@/entities/branding-asset/libs/use-marketplace-name";
import { Box, Stack, Typography } from "@mui/material";
import { Link } from "react-router-dom";

const getHomepage = () => {
  return `${window.location.protocol}//${window.location.host}`;
};

export const AccessDenied = () => {
  const { brandingAssets } = useBrandingAssets();
  const marketplaceName = useGetMarketplaceName();

  return (
    <Stack
      height="100%"
      sx={{ backgroundColor: "grey.100" }}
      alignItems="center"
      justifyContent="center"
    >
      <Stack
        border={1}
        alignItems="center"
        maxWidth={480}
        px={5}
        sx={{ backgroundColor: "common.white", borderColor: "grey.300" }}
      >
        <Box my={3} width={289} height={100}>
          <img
            width="100%"
            height="100%"
            style={{ objectFit: "contain" }}
            src={brandingAssets.LOGO}
            alt="company logo"
          />
        </Box>
        <Typography textAlign="center" variant="h6" mb={2}>
          Access Denied{" "}
        </Typography>
        <Typography textAlign="center" variant="body2" mb={8}>
          You do not have permission access to the Admin Central of {marketplaceName ?? "-"}. Please
          try again with a different account.
        </Typography>
      </Stack>
      <Link to={"/"}>
        <Typography mt={2} variant="body2">
          Return to {getHomepage()}
        </Typography>
      </Link>
    </Stack>
  );
};
