import axios from "axios";
import { keycloak } from "@/services/keycloak.service";

import { getAPIEndPoint } from "./config.util";

export class HttpApi {
  constructor(config) {
    this.api = axios.create(config);
  }

  setAuthHeader = (authToken) => {
    this.api.defaults.headers.common["Authorization"] = `bearer ${authToken}`;
  };

  removeAuthHeader = () => {
    delete this.api.defaults.headers.common["Authorization"];
  };

  get = async (path, params) => {
    const result = await this.api.get(path, {
      params: params
    });
    return result.data;
  };

  post = async (path, body, params) => {
    const result = await this.api.post(path, body, {
      params: params
    });
    return result.data;
  };

  postFile = async (path, file, params) => {
    const formData = new FormData();
    formData.append("file", file);

    const result = await this.api.post(path, formData, {
      params: params,
      headers: { "Content-Type": "multipart/form-data" }
    });
    return result.data;
  };

  postFormData = async (path, formData, params) => {
    const result = await this.api.post(path, formData, {
      params: params,
      headers: { "Content-Type": "multipart/form-data" }
    });
    return result.data;
  };

  putFile = async (path, file, params) => {
    const result = await this.api.put(path, file, {
      params: params,
      headers: { "Content-Type": "multipart/form-data" }
    });
    return result.data;
  };

  put = async (path, body, params) => {
    const result = await this.api.put(path, body, {
      params: params
    });
    return result.data;
  };

  delete = async (path, params, data) => {
    const result = await this.api.delete(path, {
      params: params,
      data
    });
    return result.data;
  };

  patch = async (path, body, params) => {
    const result = await this.api.patch(path, body, {
      params: params
    });
    return result.data;
  };
}

const tokenHandler = async (config) => {
  if (keycloak.isTokenExpired()) {
    await keycloak.updateToken(30);
  }

  if (keycloak.token) {
    config.headers = {
      ...config.headers,
      Authorization: `bearer ${keycloak.token}`
    };
  }
  return config;
};

export const backendAPI = new HttpApi({
  baseURL: getAPIEndPoint(),
  timeout: 45000,
  headers: {
    "Content-Type": "application/json"
  }
});
backendAPI.api.interceptors.request.use(tokenHandler);

export const jobAPI = new HttpApi({
  baseURL: `${getAPIEndPoint()}/v2`,
  timeout: 3_600_000,
  headers: {
    "Content-Type": "application/json"
  }
});
jobAPI.api.interceptors.request.use(tokenHandler);

export const backendAPIV2 = new HttpApi({
  baseURL: `${getAPIEndPoint()}/v2`,
  timeout: 45000,
  headers: {
    "Content-Type": "application/json"
  }
});
backendAPIV2.api.interceptors.request.use(tokenHandler);
