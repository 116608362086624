import React from "react";
import { DashboardRounded, ShoppingCart } from "@mui/icons-material";

import Union from "@/components/icons/union.comp";
import Bid from "@/components/icons/bid.comp";
import Order from "@/components/icons/order.comp";
import FinancialsIcon from "@/components/icons/financials.comp";

import Box from "@mui/material/Box";
import { ROUTES } from "@/commons/consts";
import { useUserInfoHook } from "hooks/useUserInfoHook";
import SettingIcon from "@/components/icons/settings.comp";
import { ContentLayout } from "@/components/layout/content-layout.comp";
import Flags, { FlagMode } from "@/commons/consts/flags.const";

const {
  SELLER_DASHBOARD_ROUTE,
  SELLER_INVENTORY_ROUTE,
  SELLER_MARKETPLACE_ROUTE,
  SELLER_ORDER_MANAGEMENT,
  SELLER_WALLET,
  SELLER_ACCOUNT_SUMMARY,
  SELLER_ADD_FUNDS,
  SELLER_BIDDING_ARENA,
  SELLER_BULK_UPLOAD_TEMPLATES,
  SELLER_ITEM_LISTING,
  SELLER_FIND_PRODUCT,
  BANK_HOME,
  BANK_DETAIL,
  BANK_EDIT,
  NEW_BANK
} = ROUTES;

const useSellerMenuData = () => {
  const { isUserNotCompleteKYC } = useUserInfoHook();
  const walletUrl = isUserNotCompleteKYC ? `${SELLER_DASHBOARD_ROUTE}?kyc=1` : undefined;
  const walletChildren = React.useMemo(() => {
    if (isUserNotCompleteKYC) return undefined;
    return [
      {
        title: "Wallet",
        key: "wallet",
        url: SELLER_WALLET
      },
      {
        title: "Bank Accounts",
        key: "bank-accounts",
        url: [BANK_HOME, BANK_DETAIL, BANK_EDIT, NEW_BANK]
      },
      {
        title: "Account Summary",
        key: "accountSummary",
        url: SELLER_ACCOUNT_SUMMARY
      },
      {
        title: "Withdraw Fund",
        key: "withdrawFund",
        url: ROUTES.WITHDRAW_FUND
      },
      {
        title: "Add Fund",
        key: "addFund",
        url: SELLER_ADD_FUNDS
      }
    ];
  }, [isUserNotCompleteKYC]);
  return [
    {
      title: "Dashboard",
      key: "Dashboard",
      icon: DashboardRounded,
      url: SELLER_DASHBOARD_ROUTE
    },
    {
      title: "Marketplace",
      key: "Marketplace",
      icon: ShoppingCart,
      url: SELLER_MARKETPLACE_ROUTE
    },
    {
      title: "Inventory",
      key: "Inventory",
      icon: Union,
      flag: Flags.pim,
      children: [
        {
          title: "Manage Inventory",
          key: "manage-inventory",
          url: SELLER_ITEM_LISTING
        },
        {
          title: "Add Product",
          key: "add-product",
          url: SELLER_FIND_PRODUCT
        }
      ]
    },
    {
      title: "Inventory",
      key: "Inventory",
      icon: Union,
      flag: Flags.pim,
      flagMode: FlagMode.EXCLUDE,
      children: [
        {
          title: "Manage Inventory",
          key: "Manage Inventory",
          url: SELLER_INVENTORY_ROUTE
        },
        {
          title: "Item Listing",
          key: "Item Listing",
          url: SELLER_ITEM_LISTING
        },
        {
          title: "Bulk Catalog Upload",
          key: "Bulk Catalog Upload",
          url: SELLER_BULK_UPLOAD_TEMPLATES
        }
      ]
    },
    {
      title: "Bids",
      key: "Bids",
      icon: Bid,
      url: SELLER_BIDDING_ARENA
    },
    {
      title: "Orders",
      key: "Orders",
      icon: Order,
      url: SELLER_ORDER_MANAGEMENT
    },
    {
      title: "Financials",
      key: "Financials",
      icon: FinancialsIcon,
      url: walletUrl,
      children: walletChildren
    },
    {
      title: "Settings",
      key: "Settings",
      icon: SettingIcon,
      url: ROUTES.ADMIN_USER_MANAGEMENT_ROUTE
    }
  ];
};

export const SellerMainLayout = ({ children }: { children: React.ReactNode }) => {
  const menuData = useSellerMenuData();
  return (
    <ContentLayout menuData={menuData}>
      <Box height="100%">{children}</Box>
    </ContentLayout>
  );
};
