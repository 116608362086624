import React from "react";
import { Tooltip } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";

export const FormLabel = React.memo(({ label, tooltip }) => {
  return (
    <span>
      <span>{label}</span>
      {tooltip != null && (
        <span className="ml-2">
          <Tooltip title={tooltip}>
            <InfoCircleOutlined />
          </Tooltip>
        </span>
      )}
    </span>
  );
});
