import { useBrandingAssets } from "@/entities/branding-asset/libs/use-branding-asset";
import { Box } from "@mui/material";

interface Props {
  width: number;
  height: number;
}
export const Logo = ({ width, height }: Props) => {
  const { brandingAssets } = useBrandingAssets();
  return (
    <Box width={width} height={height}>
      <img
        width="100%"
        height="100%"
        style={{ objectFit: "contain" }}
        src={brandingAssets.LOGO}
        alt="company logo"
      />
    </Box>
  );
};
