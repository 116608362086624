import { FieldHookConfig, useField, useFormikContext } from "formik";

import MuiTextField, { TextFieldProps } from "@mui/material/TextField";
import React from "react";
import ReactNumberFormat from "react-number-format";
import { InputBaseComponentProps } from "@mui/material";

export type NumberFormatProps = InputBaseComponentProps & {
  onStringValueChange?: (value: string) => void;
  onNumberValueChange?: (value: number) => void;
  fixedDecimalScale?: boolean;
  decimalScale?: number;
};
export const NumberFormat = React.forwardRef<HTMLInputElement, NumberFormatProps>((props, ref) => {
  const { onStringValueChange, onNumberValueChange, defaultValue, ...other } = props;
  return (
    <ReactNumberFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values) => {
        if (onStringValueChange) onStringValueChange(values.value);
        if (onNumberValueChange) onNumberValueChange(values.floatValue);
      }}
      thousandSeparator
      isNumericString
    />
  );
});

interface BaseProps {
  name: string;
  fieldConfig?: FieldHookConfig<string>;
  required?: boolean;
  numberFormatProps?: NumberFormatProps;
  valueType?: "number" | "string";
}

type InputProps = TextFieldProps & {
  numberFormatProps?: NumberFormatProps;
};
export const NumberInput = ({ numberFormatProps, ...inputProps }: InputProps) => {
  return (
    <MuiTextField
      {...inputProps}
      InputProps={{
        ...inputProps?.InputProps,
        inputProps: numberFormatProps,
        inputComponent: NumberFormat
      }}
    />
  );
};

type Props = TextFieldProps & BaseProps;
export const NumberField = ({
  name,
  fieldConfig,
  required,
  numberFormatProps,
  valueType = "number",
  ...props
}: Props) => {
  const [field, meta] = useField({ name, type: "text", ...fieldConfig });
  const { setFieldValue } = useFormikContext();
  const handleNumberChange = (value: number) => {
    if (valueType !== "number") return;
    setFieldValue(name, value);
  };
  const handleStringValueChange = (value: string) => {
    if (valueType !== "string") return;
    setFieldValue(name, value);
  };
  const showError = Boolean(meta.touched && meta.error);
  return (
    <NumberInput
      value={field.value}
      name={field.name}
      error={showError}
      helperText={meta.touched && meta.error}
      InputLabelProps={{ required, error: showError }}
      numberFormatProps={{
        ...numberFormatProps,
        onNumberValueChange: handleNumberChange,
        onStringValueChange: handleStringValueChange
      }}
      {...props}
    />
  );
};
