import React from "react";
import { PDFDownloadLink } from "@react-pdf/renderer";
import { PDFDocument } from "components/utils/pdf/pdf-document/pdf-document.comp";

export const PdfDownloader = ({ docName, name, chartImg, onAfterDownload, isPO }) => {
  return (
    <PDFDownloadLink
      className="d-none"
      document={<PDFDocument chartImg={chartImg || ""} name={name} isPO={isPO} />}
      fileName={docName}
    >
      {({ url, loading }) => {
        if (!loading) {
          const link = document.createElement("a");
          link.download = docName;
          link.href = url;
          link.click();
          link.remove();
          onAfterDownload();
        }
      }}
    </PDFDownloadLink>
  );
};
