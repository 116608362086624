import React from "react";
import countryList from "assets/country.json";
import { Col, Row } from "antd";
import { KVList } from "components/utils";
import { ShadowCard } from "components/container";
import { LABELS } from "./company-address.schema";

const parseData = (address) => {
  const countryName = countryList.find((country) => country.alpha2Code === address.country)?.name;
  return Object.entries(address)
    .map(([key, value]) => {
      if (key === "country") return { key: LABELS.country, value: countryName };
      return { key: LABELS[key], value };
    })
    .filter(({ key }) => key != null);
};
export const CompanyAddressReadonly = ({ companyAddress }) => {
  const addresses = companyAddress
    ?.filter(({ addressLine1, city, country, id }) => {
      return addressLine1 && city && country && id;
    })
    .map((address) => parseData(address));
  if (addresses == null || addresses.length === 0) return <></>;
  return (
    <Row gutter={[24, 24]}>
      {addresses.map((address, index) => (
        <Col span={24} lg={12} key={address.id || index}>
          <ShadowCard className="mx-1">
            <KVList colProps={{ span: 24, lg: 12 }} items={address} />
          </ShadowCard>
        </Col>
      ))}
    </Row>
  );
};
