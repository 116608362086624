import React from "react";
import countryList from "assets/country.json";
import { WrappedSelect } from "../wrapped-select/wrapped-select.comp";
const dataSource = countryList.map((country) => ({
  value: country.alpha2Code,
  label: country.name
}));

export const CountrySelect = React.forwardRef((props, ref) => {
  return <WrappedSelect ref={ref} {...props} dataSource={dataSource} />;
});
