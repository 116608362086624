import React from "react";
import { DatePickerField } from "./date-picker-field.comp";
import { DocumentUploadField } from "./document-upload-field.comp";
import { SelectField } from "./select-field.comp";
import { TextField } from "./text-field.comp";
import PropTypes from "prop-types";
import { getNestedField } from "@/utils/get-nested-field.util";
import { FileUploadField } from "./file-upload-field.comp";
import { CountrySelectField } from "./country-select-field.comp";
import { PhoneField } from "./phone-field.comp";
import { HiddenField } from "./hidden-field.comp";
import { TextAreaField } from "./textarea-field.comp";
import { AddressSelectField } from "./address-select-field.comp";
export const FIELD_TYPE = {
  text: "text",
  textarea: "textarea",
  select: "select",
  country: "country",
  document: "document",
  date: "date",
  file: "file",
  phone: "phone",
  hidden: "hidden",
  selectAddress: "selectAddress"
};
const FIELDS = {
  [FIELD_TYPE.text]: TextField,
  [FIELD_TYPE.select]: SelectField,
  [FIELD_TYPE.country]: CountrySelectField,
  [FIELD_TYPE.document]: DocumentUploadField,
  [FIELD_TYPE.date]: DatePickerField,
  [FIELD_TYPE.file]: FileUploadField,
  [FIELD_TYPE.phone]: PhoneField,
  [FIELD_TYPE.hidden]: HiddenField,
  [FIELD_TYPE.textarea]: TextAreaField,
  [FIELD_TYPE.selectAddress]: AddressSelectField
};

export const Field = ({ form, initialValues, initialValue, schema, innerProps }) => {
  const props = { form, schema, innerProps };
  const { type } = schema;
  const parsedInitialValue = React.useMemo(() => {
    if (initialValue) return initialValue;
    if (initialValues) {
      return getNestedField(initialValues, schema.name);
    }
    return undefined;
  }, [initialValue, initialValues, schema]);
  const Component = type in FIELDS ? FIELDS[type] : TextField;
  return <Component {...props} initialValue={parsedInitialValue} />;
};

Field.propTypes = {
  form: PropTypes.object.isRequired,
  schema: PropTypes.object.isRequired,
  initialValue: PropTypes.any,
  initialValues: PropTypes.object,
  innerProps: PropTypes.object
};
