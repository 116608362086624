import { Helmet } from "react-helmet";
import { getAssetURL } from "@/utils/config.util";
import { useBrandingAssets } from "@/entities/branding-asset/libs/use-branding-asset";
import { Router } from "react-router-dom";
import { Routes } from "./routes.comp";

const AppRouter = ({ history, routes }) => {
  const { brandingAssets } = useBrandingAssets();
  return (
    <Router history={history}>
      <Helmet>
        <link rel="shortcut icon" href={getAssetURL("/favicon.png")} />
        <link rel="icon" type="image/x-icon" href={brandingAssets.FAVICON} />
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href={getAssetURL("/favicon/apple-touch-icon.png")}
        />
        <link
          rel="mask-icon"
          href={getAssetURL("/favicon/safari-pinned-tab.svg")}
          color="#5bbad5"
        />
        <meta name="msapplication-config" content={getAssetURL("/browserconfig.xml")} />
      </Helmet>
      <Routes routes={routes} />
    </Router>
  );
};
export default AppRouter;
