import React from "react";
import { SubLayout } from "../../components/layout/sub-layout.comp";
import { getMenuPreferencesData } from "@/processes/admin-main-layout/menu-data";

export const PreferencesLayout = ({ children }) => {
  return (
    <SubLayout menuData={getMenuPreferencesData()} header="Preferences">
      {children}
    </SubLayout>
  );
};
