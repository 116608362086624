import { useInOutAnimation } from "hooks/useInOutAnimation";
import React, { useState } from "react";

import { animated } from "react-spring";
import { submitKYC } from "@/services/kyc.service";
import { isScreensize } from "@/utils";
import { asyncErrorHandlerWrapper } from "@/utils";
import { KYCSteps } from "./kyc-steps/kyc-steps";
import { KYC1CompanyInfo } from "./kyc1-company-info/kyc1-company-info.comp";
import { KYC2OwnerInfo } from "./kyc2-owner-info/kyc2-owner-info.comp";
import { KYC5Review } from "./kyc5-review/kyc5-review.comp";
import { SkipKYCConfirmModal } from "../../skip-kyc-confirm-modal/skip-kyc-confirm-modal.comp";
import classNames from "classnames";
import { useLogout } from "@/services/keycloak.service";
import { KYC_WAITING_ROUTE } from "@/commons/consts/system/routes";

const KYC_STEPS = {
  step1: {
    title: "1. Company Information",
    content: KYC1CompanyInfo,
    disabled: false
  },
  step2: {
    title: "2. User Information",
    content: KYC2OwnerInfo,
    disabled: true
  },
  step3: {
    title: "3. Review and Submit",
    content: KYC5Review,
    disabled: true
  }
};

export const KYCProcess = ({ contentClassName, onSkip, onSubmitted, onStepChange }) => {
  const logout = useLogout();
  const [currentStep, setCurrentStep] = useState(0);
  const [steps, setSteps] = useState(KYC_STEPS);
  const [confirmModalVisible, showConfirmModal] = useState(false);
  const transitions = useInOutAnimation(1);
  const isSmallDevice = isScreensize("sm");
  const [loadingButton, setLoadingButton] = useState(false);

  React.useEffect(() => {
    onStepChange && onStepChange(currentStep);
  }, [currentStep, onStepChange]);

  const onNext = () => {
    const next = currentStep + 1;
    const currentStepName = `step${next + 1}`;
    if (next < Object.values(steps).length) {
      setCurrentStep(next);
      setSteps({
        ...steps,
        [currentStepName]: {
          ...steps[currentStepName],
          disabled: false
        }
      });
    }
  };

  const onPrev = () => {
    const prevCurrent = currentStep - 1;
    if (prevCurrent >= 0) {
      setCurrentStep(prevCurrent);
    }
  };

  const onSubmit = () => {
    setLoadingButton(!loadingButton);
    asyncErrorHandlerWrapper(async () => {
      await submitKYC();
      if (onSubmitted) {
        onSubmitted();
        return;
      }
      await logout(KYC_WAITING_ROUTE);
    });
  };

  const handleCloseModal = () => {
    showConfirmModal(false);
  };

  const stepButtonsConfig = {
    onNext: onNext,
    onPrev: onPrev,
    onSubmit: onSubmit,
    prevDisabled: currentStep === 0,
    showNext: currentStep !== Object.values(steps).length - 1,
    showSubmit: currentStep === Object.values(steps).length - 1,
    showSkip: onSkip,
    onSkip: () => showConfirmModal(true),
    currentStep
  };

  const StepContent1 = Object.values(steps)[0].content;
  const StepContent2 = Object.values(steps)[1].content;
  const StepContent3 = Object.values(steps)[2].content;

  return (
    <article className="d-flex flex-column mt-4 mb-5">
      <animated.div>
        <KYCSteps
          isSmallDevice={isSmallDevice}
          currentStep={currentStep}
          steps={steps}
          setCurrentStep={setCurrentStep}
        />
        <div
          className={classNames("dtc-br-10 mt-lg-4 d-flex align-items-stretch", contentClassName)}
        >
          {transitions.map(({ props, key }) => {
            return (
              <animated.div className="bg-white dtc-br-10 w-100 p-3" key={key} style={props}>
                {
                  <StepContent1
                    stepButtonsConfig={stepButtonsConfig}
                    className={currentStep === 0 ? "" : "d-none"}
                  />
                }
                {
                  <StepContent2
                    stepButtonsConfig={stepButtonsConfig}
                    className={currentStep === 1 ? "" : "d-none"}
                  />
                }
                {
                  <StepContent3
                    stepButtonsConfig={stepButtonsConfig}
                    className={currentStep === 2 ? "" : "d-none"}
                    loadingButton={loadingButton}
                  />
                }
              </animated.div>
            );
          })}
        </div>
        {confirmModalVisible && (
          <SkipKYCConfirmModal
            visible={confirmModalVisible}
            onClose={handleCloseModal}
            onSkip={onSkip}
          />
        )}
      </animated.div>
    </article>
  );
};
