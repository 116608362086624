import { Layout } from "./layout.comp";
import { ROUTES } from "@/commons/consts";
import { loadable } from "@/utils/loadable.util";

export const adminProfileRoutes = [
  {
    key: "settings-layout",
    Component: Layout,
    children: [
      {
        key: ROUTES.PERSIONAL_INFORMATION,
        path: ROUTES.PERSIONAL_INFORMATION,
        Component: loadable(() => import("@/pages/settings/admin-personal-information.page"))
      },
      {
        key: ROUTES.PROFILE_NOTIFICATIONS,
        path: ROUTES.PROFILE_NOTIFICATIONS,
        Component: loadable(() => import("@/pages/settings/notifications.page"))
      }
    ]
  }
];

export const traderProfileRoutes = [
  {
    key: "settings-layout",
    Component: Layout,
    children: [
      {
        key: ROUTES.PERSIONAL_INFORMATION,
        path: ROUTES.PERSIONAL_INFORMATION,
        Component: loadable(() => import("@/pages/settings/trader-personal-information.page"))
      },
      {
        key: ROUTES.PROFILE_NOTIFICATIONS,
        path: ROUTES.PROFILE_NOTIFICATIONS,
        Component: loadable(() => import("@/pages/settings/notifications.page"))
      }
    ]
  }
];
