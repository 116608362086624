import { persistentQueryOptions } from "@/commons/options/react-query.option";
import {
  FeatureFlagResponse,
  getMarketplaceFeatures,
  updateMarketplaceFeatures
} from "@/services/preference.service";
import { useMutation, useQuery, useQueryClient } from "react-query";

export const useMarketplaceSettings = (options?: {
  onSuccess: (values: FeatureFlagResponse) => void;
}) => {
  return useQuery(["feature-flag"], getMarketplaceFeatures, {
    ...persistentQueryOptions,
    ...options
  });
};

export const useUpdateMarketplaceSettings = () => {
  const queryClient = useQueryClient();
  return useMutation(updateMarketplaceFeatures, {
    onSuccess: async () => {
      await queryClient.invalidateQueries();
    }
  });
};
