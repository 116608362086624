export const HOME_ROUTE = "/";
export const SELLER_PREFIX = "/seller";
export const BUYER_PREFIX = "/buyer";
export const ADMIN_PREFIX = "/admin";

export const ONBOARDING_ROUTE = "/onboarding";
export const REGISTER_SUCCESS_ROUTE = "/register-success";
export const RESET_PASSWORD_ROUTE = "/reset-password";
export const KYC_PROCESS_ROUTE = "/complete-kyc";
export const KYC_WAITING_ROUTE = "/waiting-kyc";
export const EMAIL_CONFIRMATION_ROUTE = "/email-verification";

export const UPDATE_TIMEZONE_ROUTE = "/update-time-zone";
export const UNLOCK_USER_ROUTE = "/unlock-user";
export const EMAIL_UNLOCK_USER_ROUTE = "/email-unlock-user";

export const COOP_PREFIX = "/coop";
export const COOP_CREATE_TRADER = `${COOP_PREFIX}/create-trader`;
export const COOP_TRADERS = `${COOP_PREFIX}/traders`;

export const POLICIES = "/policies";
export const TERMS_AND_CONDITIONS = "/policies/terms-and-conditions";
export const FEE_SCHEDULE = "/policies/feeschedule";
export const PRIVACY_POLICY = "/policies/privacy-policy";
