import React from "react";
import { Form } from "@ant-design/compatible";
import "@ant-design/compatible/assets/index.css";
import { CompanyAddressFormView } from "./company-address-form.view";

export const CompanyAddressForm = React.forwardRef(
  ({ form, onSubmit, initialValues, disabled, disabledFields }, ref) => {
    React.useImperativeHandle(ref, () => ({
      form
    }));

    return (
      <Form layout="vertical" onSubmit={onSubmit}>
        <CompanyAddressFormView
          form={form}
          initialValues={initialValues}
          disabled={disabled}
          disabledFields={disabledFields}
        />
      </Form>
    );
  }
);
