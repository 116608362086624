import { ApiPathConsts } from "@/commons/consts/system/api-paths/api-paths.const";
import { backendAPI } from "@/utils/httpAPI.util";

export const updateAssetResource = async (type, formData) => {
  await backendAPI.post(ApiPathConsts.POST_MARKETPLACE_BRANDING_ASSET, formData, { type });
};
export const deleteAssetResource = async (type) => {
  await backendAPI.delete(ApiPathConsts.POST_MARKETPLACE_BRANDING_ASSET, { type });
};
export const getOrganization = async () => {
  return backendAPI.get(ApiPathConsts.GET_ORGANIZATION);
};
export const updateOrganization = async (body) => {
  return backendAPI.put(ApiPathConsts.UPDATE_ORGANIZATION, body);
};

export interface FeatureFlagResponse {
  externalPaymentServices: boolean;
  hotJarId: string;
  staggeredKYC: boolean;
  timeConstraint: boolean;
  whoCanCreateProduct: string;
  registrationSkipEmailVerification: boolean;
}

export const getMarketplaceFeatures: () => Promise<FeatureFlagResponse> = async () => {
  return backendAPI.get(ApiPathConsts.MARKETPLACE_FEATURES);
};

export const updateMarketplaceFeatures = async (payload: FeatureFlagResponse) => {
  return backendAPI.put(ApiPathConsts.ADMIN_MARKETPLACE_FEATURES, payload);
};
