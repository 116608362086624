import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { Link } from "@/components/commons/navigator";
import React from "react";
import { ROUTES } from "@/commons/consts";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { traderSettingsMenuData } from "./menu-data";
import { AppSideBar } from "@/components/layout/app-side-bar.comp";
import { ContentLayout } from "@/components/layout/content-layout.comp";

const SettingSideBar = (props) => {
  const { open } = props;
  return (
    <AppSideBar
      {...props}
      header={
        <Stack spacing={1} alignItems={open ? "flex-start" : "center"} mb={2}>
          <Box alignSelf="stretch">
            <Link to={ROUTES.DASHBOARD}>
              <Button sx={{ width: "100%" }}>
                <Stack width="100%" px={1} direction="row" spacing={1} justifyContent="start">
                  <KeyboardBackspaceIcon />
                  {open && <Box pl={0.5}>Back</Box>}
                </Stack>
              </Button>
            </Link>
          </Box>

          {open && (
            <Typography px={2} variant="h5">
              Settings
            </Typography>
          )}
        </Stack>
      }
    />
  );
};

export const TraderSettingsLayout = ({ children }) => {
  return (
    <ContentLayout menuData={traderSettingsMenuData} SideBar={SettingSideBar}>
      <Box height="100%">{children}</Box>
    </ContentLayout>
  );
};
