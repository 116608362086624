import React, { Fragment } from "react";

export const BooleanDisplayer = ({ value }) => {
  const render = () => {
    if (typeof value === "boolean") {
      return (
        <i
          className={value ? "fe fe-check-circle" : "fe fe-x-circle"}
          style={{ color: value ? "#4d824b" : "#d34423" }}
        />
      );
    }
    return value;
  };
  return <Fragment>{render()}</Fragment>;
};
