import { backendAPI } from "@/utils/httpAPI.util";
import { ApiPathConsts } from "@/commons/consts/system";

export const getAdminWalletDashboard = () => backendAPI.get("/wallet");
export const getAdminWalletTransactionDetails = () => backendAPI.get("/wallet/transactions");
export const getAdminWithdrawals = ({ page, size, sort, status }) =>
  backendAPI.get("/wallet/withdrawal", { page, size, sort, status });
export const postAdminRequestWithdrawal = (data) =>
  backendAPI.post(ApiPathConsts.WALLET_WITHDRAWAL, data);
export const getSellerWalletDashboard = async () => {
  return backendAPI.get(`/sellers/wallet/dashboard`);
};

export const getBuyerWalletDashboard = async () => {
  return backendAPI.get(`/bueyrs/wallet/dashboard`);
};

export const getWalletBlockedFund = (params) => backendAPI.get("wallet/blockedFund", params);

export const getWalletTransactionDetails = (params) =>
  backendAPI.get("wallet/transactions", params);

export const getAccountSummary = async ({ page, size, type, sort }) => {
  if (type) {
    return backendAPI.get(`/account/summary/${type}`, {
      page,
      size,
      sort
    });
  }
  return backendAPI.get(`/account/summary`, {
    page,
    size,
    sort
  });
};
