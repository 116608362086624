import SvgIcon from "@mui/material/SvgIcon";
import React from "react";

function ServicePortal(props) {
  return (
    <SvgIcon {...props}>
      <path
        d="M19.7726 11.9182L12.2316 14.3786C11.9662 14.4544 11.7769 14.7193 11.7769 15.0221V24.9023C11.7769 25.2052 11.9665 25.4323 12.2316 25.5458L19.7726 28.0062C19.8485 28.044 19.9241 28.044 19.9622 28.044C20.038 28.044 20.1136 28.044 20.1895 28.0062L27.7305 25.5458C27.9959 25.47 28.1852 25.2052 28.1852 24.9023V15.0221C28.1852 14.7193 27.9957 14.4922 27.7305 14.3786L20.1895 11.9182C20.0758 11.8802 19.9241 11.8802 19.7726 11.9179V11.9182ZM19.318 26.4924L13.1033 24.4483L13.103 15.9688L15.5283 16.7639L15.5285 22.2528C15.5285 22.6315 15.8317 22.9341 16.2105 22.9341C16.5895 22.9341 16.8925 22.6312 16.8925 22.2528V17.18L19.3177 17.9751L19.318 26.4924ZM26.859 24.4481L24.4338 25.2432V21.7605C24.4338 21.3818 24.1306 21.0792 23.7518 21.0792C23.3727 21.0792 23.0698 21.3821 23.0698 21.7605V25.6596L20.6446 26.4547L20.6443 17.9751L26.859 15.9311L26.859 24.4481ZM20.0001 16.8014L14.619 15.0222L20.0001 13.243L25.4189 15.0222L20.0001 16.8014Z"
        fillOpacity="0.87"
      />
      <path
        d="M5.63791 15.6656C6.05476 15.6656 6.43383 15.3249 6.43383 14.8705C6.43383 10.4414 9.84421 6.80736 14.2022 6.46667V8.4352C14.2022 8.70031 14.3537 8.96515 14.6191 9.11645C14.7328 9.19224 14.8845 9.23 14.9981 9.23C15.1496 9.23 15.2635 9.19224 15.415 9.11645L20.114 6.27733C20.3413 6.12602 20.4931 5.89867 20.4931 5.59607C20.4931 5.33096 20.3416 5.06611 20.114 4.91482L15.3766 2.11348C15.1493 1.96217 14.8461 1.96217 14.5807 2.11348C14.3153 2.26478 14.1639 2.5299 14.1639 2.79473V4.91455C8.97235 5.2932 4.84177 9.60863 4.84177 14.9083C4.84177 15.3247 5.18297 15.6656 5.63791 15.6656Z"
        fillOpacity="0.87"
      />
      <path
        d="M6.50951 25.7726C6.85057 30.1261 10.4885 33.5329 14.9221 33.5329C15.339 33.5329 15.7175 33.8737 15.7177 34.3279C15.7177 34.7824 15.3386 35.123 14.9218 35.123C9.61661 35.123 5.29666 30.9967 4.9176 25.8107H2.79557C2.53045 25.8107 2.26506 25.6593 2.1136 25.3942C1.96213 25.1291 1.96213 24.8262 2.1136 24.5991L4.99353 19.867C5.25892 19.4128 6.01678 19.4128 6.31997 19.867L9.16207 24.5611C9.31354 24.8262 9.31354 25.0911 9.16207 25.3562C9.01062 25.6213 8.7455 25.7726 8.48011 25.7726H6.50951Z"
        fillOpacity="0.87"
      />
      <path
        d="M34.3621 24.2964C33.9453 24.2964 33.5662 24.637 33.5662 25.0915C33.5662 29.5205 30.1558 33.1546 25.7978 33.4953V31.5268C25.7978 31.2616 25.6463 30.9968 25.3809 30.8455C25.1536 30.6942 24.8504 30.6942 24.585 30.8455L19.886 33.6846C19.6587 33.8359 19.507 34.0633 19.507 34.3659C19.507 34.6688 19.6584 34.8958 19.886 35.0471L24.585 37.8863C24.6987 37.9621 24.8504 37.9998 25.0019 37.9998C25.1533 37.9998 25.2673 37.9621 25.3809 37.8863C25.6463 37.735 25.7978 37.4699 25.7978 37.205V35.0852C31.0276 34.6688 35.1578 30.3533 35.1578 25.0536C35.1578 24.6372 34.8171 24.2964 34.3621 24.2964Z"
        fillOpacity="0.87"
      />
      <path
        d="M37.8863 14.5676C37.7348 14.3025 37.4694 14.1512 37.2043 14.1512H35.0823C34.7032 8.96486 30.3833 4.83887 25.0781 4.83887C24.6612 4.83887 24.2822 5.1795 24.2822 5.63396C24.2822 6.08814 24.6232 6.42905 25.0781 6.42905C29.5118 6.42905 33.1497 9.83586 33.4907 14.1893H31.5201C31.2547 14.1893 30.9896 14.3406 30.8381 14.6057C30.6867 14.8709 30.6867 15.1357 30.8381 15.4008L33.6802 20.0949C33.8317 20.322 34.0593 20.4736 34.3622 20.4736C34.6276 20.4736 34.8927 20.3223 35.0442 20.0949L37.8863 15.4008C38.038 15.0977 38.0377 14.7947 37.8863 14.5676Z"
        fillOpacity="0.87"
      />
    </SvgIcon>
  );
}

export default ServicePortal;
