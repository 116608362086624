import React from "react";
import { createFormErrorComp } from "@/utils";
import { ERR_MSG } from "@/commons/consts";
import { validPhoneNumber, FormLabel } from "components/utils";
export const FIELDS = {
  addressLine1: "addressLine1",
  city: "city",
  country: "country",
  state: "state",
  postalCode: "postalCode",
  email: "email",
  phone: "phone",
  phonePrefix: "phonePrefix"
};
export const LABELS = {
  [FIELDS.addressLine1]: "Address Line 1",
  [FIELDS.city]: "City",
  [FIELDS.country]: "Country",
  [FIELDS.state]: "State / Province / Territory",
  [FIELDS.postalCode]: "Postal/ZIP Code or P.O. Box",
  [FIELDS.email]: "Email",
  [FIELDS.phone]: "Telephone"
};
export const HOVER_TEXT = {
  country: "The country that the company or business is registered in.",
  addressLine1: "The first line of address of the registered company.",
  city: "The city that the company is registered in.",
  state: "The state, province, or territory that the company is registered in.",
  email: "The company or business e-mail address."
};
const Label = React.memo(({ name }) => (
  <FormLabel label={LABELS[name]} tooltip={HOVER_TEXT[name]} />
));
export const companyAddressSchema = {
  [FIELDS.addressLine1]: {
    name: FIELDS.addressLine1,
    label: <Label name={FIELDS.addressLine1} />,
    placeholder: "Company Address Line 1",
    tooltips: HOVER_TEXT[FIELDS.addressLine1],
    options: {
      rules: [
        {
          required: true,
          message: createFormErrorComp(ERR_MSG.REQUIRED_ERR(LABELS[FIELDS.addressLine1]))
        }
      ]
    }
  },
  [FIELDS.city]: {
    name: FIELDS.city,
    label: LABELS[FIELDS.city],
    placeholder: "London, Paris etc.",
    tooltips: HOVER_TEXT[FIELDS.city],
    options: {
      rules: [
        {
          required: true,
          message: createFormErrorComp(ERR_MSG.REQUIRED_ERR(LABELS[FIELDS.city]))
        }
      ]
    }
  },
  [FIELDS.country]: {
    name: FIELDS.country,
    label: LABELS[FIELDS.country],
    type: "country",
    placeholder: "Country",
    options: {
      rules: [
        {
          required: true,
          message: createFormErrorComp(ERR_MSG.REQUIRED_ERR(LABELS[FIELDS.country]))
        }
      ]
    }
  },
  [FIELDS.state]: {
    name: FIELDS.state,
    label: LABELS[FIELDS.state],
    placeholder: "Arizona, Ontario etc.",
    tooltips: HOVER_TEXT[FIELDS.state]
  },
  [FIELDS.phone]: {
    name: FIELDS.phone,
    label: LABELS[FIELDS.phone],
    type: "phone",
    options: {
      rules: [
        {
          required: true,
          message: createFormErrorComp(ERR_MSG.REQUIRED_ERR("Phone Number"))
        },
        validPhoneNumber
      ]
    }
  },
  [FIELDS.email]: {
    name: FIELDS.email,
    label: <Label name={FIELDS.email} />,
    placeholder: LABELS[FIELDS.email],
    options: {
      rules: [
        {
          required: true,
          message: createFormErrorComp(ERR_MSG.REQUIRED_ERR(LABELS[FIELDS.email]))
        },
        {
          type: "email",
          message: createFormErrorComp(ERR_MSG.EMAIL_NOT_VALID_ERR)
        }
      ]
    }
  },
  [FIELDS.postalCode]: {
    name: FIELDS.postalCode,
    label: LABELS[FIELDS.postalCode],
    placeholder: LABELS[FIELDS.postalCode]
  }
};
