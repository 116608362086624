export const cleanAndParseString = (str) => {
  if (str === undefined) {
    return;
  }
  const cleanedStr = str.replace(/\s\s+/g, " ");
  return encodeURIComponent(cleanedStr);
};

export const sensorPhone = (phone) => {
  return `*******${phone?.substring(phone.length - 4) ?? ""}`;
};

export const isValidPhone = (phone) => {
  return phone && phone.length > 5;
};
