import React from "react";
import { Form } from "@ant-design/compatible";
import "@ant-design/compatible/assets/index.css";
import { DocumentUploader } from "components/utils/form-items";
export const DocumentUploadField = ({ form, initialValue, schema, innerProps }) => {
  const { label, name, options } = schema;
  return (
    <Form.Item label={label}>
      {form.getFieldDecorator(name, {
        ...options,
        initialValue: initialValue
      })(<DocumentUploader {...innerProps} />)}
    </Form.Item>
  );
};
