import store from "store";
import { keycloak } from "@/services/keycloak.service";
export const AUTH_LOCALSTORAGE_KEY = "admin-auth";

export const getAccessToken = async () => {
  if (keycloak.isTokenExpired()) {
    await keycloak.updateToken(30);
  }
  return keycloak.token;
};

export const setAuthCredential = async (credential) => {
  store.set(AUTH_LOCALSTORAGE_KEY, credential);
};

export const removeAuthCredential = async () => {
  store.remove(AUTH_LOCALSTORAGE_KEY);
  return new Promise((r) => r(true));
};
