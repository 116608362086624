import {
  BUYER_BID_NEGOTIATION,
  BUYER_ORDER_MANAGEMENT,
  SELLER_BID_NEGOTIATION,
  SELLER_ORDER_MANAGEMENT
} from "@/commons/consts/system/routes";

const getOrderManagementSearch = ({ isPastOrders, searchText }) => {
  const params = new URLSearchParams();
  if (isPastOrders) {
    params.append("isPastOrders", "1");
  }
  if (searchText) {
    params.append("searchText", searchText);
  }
  const search = params.toString();
  if (search) return `?${search}`;
  return "";
};

export const getBuyerOrderManagementURL = ({ isPastOrders, searchText }) => {
  return `${BUYER_ORDER_MANAGEMENT}${getOrderManagementSearch({ isPastOrders, searchText })}`;
};

export const getSellerOrderManagementURL = ({ isPastOrders, searchText }) => {
  return `${SELLER_ORDER_MANAGEMENT}${getOrderManagementSearch({ isPastOrders, searchText })}`;
};

const getBidNegotiationParams = ({ saleId, focusComment }) => {
  const params = new URLSearchParams();
  params.append("sid", saleId);
  if (focusComment) params.append("focus", "comment");
  const paramString = params.toString();
  if (paramString) return `?${paramString}`;
  return "";
};

export const getBuyerBidNegotiationURL = (negotiationId, productName, { saleId, focusComment }) => {
  return `${BUYER_BID_NEGOTIATION}/${productName}--${negotiationId}${getBidNegotiationParams({
    saleId,
    focusComment
  })}`;
};
export const getSellerBidNegotiationURL = (
  negotiationId,
  productName,
  { saleId, focusComment }
) => {
  return `${SELLER_BID_NEGOTIATION}/${productName}--${negotiationId}${getBidNegotiationParams({
    saleId,
    focusComment
  })}`;
};

export const isFocusOnCommentFromURL = (search) => {
  const params = new URLSearchParams(search);
  return params.get("focus") === "comment";
};
export const removeFocusOnCommentFromURL = (search) => {
  const params = new URLSearchParams(search);
  params.delete("focus");
  return `?${params.toString()}`;
};
