export const INVENTORY = "/:prefix/inventory-management";
export const PREFIX_HOME_ROUTE = "/:prefix";
export const FORGOT_PASSWORD_ROUTE = "/:prefix/forgot-password";
export const FORGOT_PASSWORD_SUCCESS_ROUTE = "/:prefix/forgot-password-success";
export const EMAIL_VERIFICATION = "/:prefix/email-verification";
export const REGISTER_ROUTE = "/:prefix/register";
export const NOT_FOUND_ERROR_ROUTE = "/:prefix/404";
export const UNEXPECTED_ERROR_ROUTE = "/:prefix/500";
export const ORDERS = "/:prefix/orders";
export const TRADERS = "/:prefix/traders";
export const NEW_USER = "/:prefix/new-user";
export const SERVICE = "/:prefix/services";
export const WALLET = "/:prefix/wallet";
export const FEATURE_TOGGLES = "/:prefix/configuration/feature-toggles";
export const ACCOUNT_SUMMARY = "/:prefix/account-summary";
export const WITHDRAW_FUND = "/:prefix/withdraw-funds";
export const ADD_FUNDS = "/:prefix/add-funds";
export const ORDER_TRACK_AND_TRACE = "/:prefix/orders/:orderNumber/track-and-trace";
export const REBATES = "/:prefix/rebates";
export const CREATE_REBATES = "/:prefix/create-rebates";
export const TRADE_ROUTES = "/:prefix/trade-routes";
export const CREATE_TRADE_ROUTES = "/:prefix/create-trade-routes";
export const EDIT_TRADE_ROUTE = "/:prefix/edit-trade-routes";
export const ADD_DEFAULT_ROUTE = "/:prefix/add-default-routes";
export const EDIT_DEFAULT_ROUTE = "/:prefix/edit-default-routes";
export const DOCUMENT = "/:prefix/documents";
export const TRADER_DETAILS = "/:prefix/trader-details";
export const CREDIT_REQUEST = "/:prefix/credit-request";
export const CREDIT_STATUS = "/:prefix/credit-status";
export const CREDIT_USERS = "/:prefix/credit-users";
export const PURCHASE_ORDER = "/:prefix/purchase-order";
export const NOTIFICATION = "/:prefix/notifications";
export const CREATE_INTRODUCER = "/:prefix/create-introducer";
export const INTRODUCERS = "/:prefix/introducers";
export const INTRODUCER_DETAILS = "/:prefix/introducers/introducer-details";
export const INTRODUCER_EDIT = "/:prefix/introducers/introducer-edit";
export const REQUESTED_PRODUCTS = "/:prefix/requested-products";
export const PROFILE_PAGES = "/:prefix/profile/:tabName";
export const PROFILE = "/:prefix/profile";
export const ADD_ADMIN_USER = "/:prefix/settings/users/add-user";
export const EDIT_ADMIN_USER = "/:prefix/settings/users/edit-user";
export const SETTINGS = "/:prefix/settings";
export const ORGANIZATION_PROFILE = "/:prefix/settings/organization-profile";
export const PREFERENCES_PAGES = "/:prefix/settings/preferences";
export const PREFERENCES_GENERAL_PAGES = "/:prefix/preferences/general";
export const PREFERENCES_BRANDING_PAGES = "/:prefix/preferences/branding";
export const DASHBOARD = "/:prefix/dashboard";

export const ADMIN_USER_MANAGEMENT_ROUTE = `/:prefix/user-management`;
export const ADMIN_USER_MANAGEMENT_ADD_USER_ROUTE = `/:prefix/user-management/add-user`;
export const ADMIN_USER_MANAGEMENT_EDIT_USER_ROUTE = `/:prefix/user-management/edit-user`;
export const ADMIN_AUDIT_LOG_ROUTE = `/:prefix/audit-log`;

export * from "./bank.route";
export * from "./profile.routes";
export * from "./legacy-product.route";
