import React from "react";
import styles from "./styles.module.scss";

const getPathColor = (active) => (active ? "#C4C4C4" : "white");
export const ArrowLeftIcon = ({ onClick }) => {
  const [active, setActive] = React.useState(false);
  return (
    <svg
      onMouseEnter={() => setActive(true)}
      onMouseLeave={() => setActive(false)}
      onClick={onClick}
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <circle opacity={active ? 0.8 : 0.6} cx="10" cy="10" r="10" fill="#0A0A0A" />
      <path
        d="M12.8418 6.175L9.02513 10L12.8418 13.825L11.6668 15L6.6668 10L11.6668 5L12.8418 6.175Z"
        fill={getPathColor(!active)}
      />
    </svg>
  );
};
export const ArrowRightIcon = ({ onClick }) => {
  const [active, setActive] = React.useState(false);
  return (
    <svg
      onMouseEnter={() => setActive(true)}
      onMouseLeave={() => setActive(false)}
      onClick={onClick}
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        opacity={active ? 0.8 : 0.6}
        cx="10"
        cy="10"
        r="10"
        transform="rotate(-180 10 10)"
        fill="#0A0A0A"
      />
      <path
        d="M7.1582 13.825L10.9749 10L7.1582 6.175L8.3332 5L13.3332 10L8.3332 15L7.1582 13.825Z"
        fill={getPathColor(!active)}
      />
    </svg>
  );
};
export const BulletIcon = ({ active }) => (
  <span className={active ? styles.bulletActive : styles.bulletInactive}>
    <svg
      width="6"
      height="6"
      viewBox="0 0 6 6"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="3" cy="3" r="3" fill="currentColor" />
    </svg>
  </span>
);
