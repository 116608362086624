import { Button } from "antd";
import React from "react";
import { isScreensize } from "@/utils";

export const StepButtons = React.memo(
  ({ onNext, onPrev, onSubmit, prevDisabled, showNext, showSubmit, loading, showSkip, onSkip }) => {
    const isSmallDevice = isScreensize("sm");
    const containerClasses = isSmallDevice ? "navbar fixed-bottom p-0 mb-2 ml-2" : "mt-2";
    return (
      <section className={containerClasses}>
        <div
          className={`d-flex ${
            showSkip ? "justify-content-between" : "justify-content-end"
          } flex-column flex-md-row `}
        >
          <Button loading={loading} onClick={onSkip} className={showSkip ? "d-block" : "d-none"}>
            Skip For Now
          </Button>
          <span className="d-flex">
            <Button
              loading={loading}
              type="primary"
              htmlType="submit"
              onClick={onPrev}
              disabled={prevDisabled}
              className="mb-2 mb-md-0 mr-0 mr-md-2"
            >
              {isSmallDevice ? <i className="fe fe-chevron-left" /> : "Back"}
            </Button>
            <Button
              loading={loading}
              type="primary"
              onClick={onNext}
              className={showNext ? "d-block" : "d-none"}
            >
              {isSmallDevice ? <i className="fe fe-chevron-right" /> : "Next"}
            </Button>
            <Button
              loading={loading}
              type="primary"
              onClick={onSubmit}
              className={showSubmit ? "d-block" : "d-none"}
            >
              {isSmallDevice ? <i className="fe fe-check" /> : "Submit"}
            </Button>
          </span>
        </div>
      </section>
    );
  }
);
