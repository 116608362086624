import React from "react";
import { SvgIcon } from "@mui/material";

function Marketplace(props) {
  return (
    <SvgIcon {...props} viewBox="0 0 40 40">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.39131 4C4.62291 4 4 4.62291 4 5.39132C4 6.15972 4.62291 6.78263 5.39131 6.78263H9.81614L10.9732 12.5637C10.9794 12.6032 10.9872 12.6423 10.9966 12.6807L13.3209 24.2933C13.5119 25.2536 14.0345 26.1163 14.7973 26.7303C15.5565 27.3415 16.5057 27.6676 17.48 27.6524H30.9795C31.9538 27.6676 32.9029 27.3415 33.6622 26.7303C34.4252 26.116 34.948 25.2529 35.1388 24.2921L37.3669 12.6085C37.4446 12.2012 37.3366 11.7805 37.0722 11.461C36.8079 11.1415 36.4149 10.9566 36.0002 10.9566H13.4894L12.3208 5.11826C12.1907 4.46802 11.6197 4 10.9566 4H5.39131ZM16.05 23.75L14.0463 13.7392H34.3185L32.4086 23.7544C32.3442 24.0729 32.1704 24.3589 31.9173 24.5627C31.6629 24.7675 31.3446 24.8762 31.0182 24.87L30.9915 24.8697H17.4679L17.4413 24.87C17.1148 24.8762 16.7965 24.7675 16.5422 24.5627C16.2878 24.358 16.1136 24.0703 16.05 23.75ZM13.74 33.2174C13.74 31.6806 14.9858 30.4348 16.5226 30.4348C18.0594 30.4348 19.3053 31.6806 19.3053 33.2174C19.3053 34.7542 18.0594 36 16.5226 36C14.9858 36 13.74 34.7542 13.74 33.2174ZM29.0437 33.2174C29.0437 31.6806 30.2895 30.4348 31.8263 30.4348C33.3631 30.4348 34.6089 31.6806 34.6089 33.2174C34.6089 34.7542 33.3631 36 31.8263 36C30.2895 36 29.0437 34.7542 29.0437 33.2174Z"
        fillOpacity="0.87"
      />
    </SvgIcon>
  );
}

export default Marketplace;
