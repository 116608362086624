import React from "react";

import { AuthThemeLayout } from "@/processes/antd-theme-layout/ant-theme.layout";
import { UserGuard } from "@/apps/access/user-guard.comp";
import { Box, Stack } from "@mui/material";
import { MarketplaceAppBar } from "./app-bar.comp";

interface Props {
  name: string;
  children: React.ReactNode;
}
export const MarketplaceAuthLayout = ({ children, name }: Props) => {
  return (
    <UserGuard>
      <AuthThemeLayout theme={{ name }}>
        <Stack sx={{ height: "100%", color: "common.black" }}>
          <MarketplaceAppBar />
          <Box flexGrow={1} overflow="hidden">
            {children}
          </Box>
        </Stack>
      </AuthThemeLayout>
    </UserGuard>
  );
};
