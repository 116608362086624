const Flags = {
  devTools: "devTools",
  externalPaymentServices: "externalPaymentServices",
  pim: "pim"
};
export enum FlagMode {
  EXCLUDE,
  INCLUDE
}
export default Flags;
