import React from "react";
import PropTypes from "prop-types";
import { Form } from "@ant-design/compatible";
import "@ant-design/compatible/assets/index.css";
import { Row, Col } from "antd";
import { uploadDocument } from "@/services/company.service";
import { Field } from "components/utils/fields";
import { companyInfoSchema as schema, FIELDS, hasJOI } from "./company-info.schema";

export const CompanyInfoFormView = ({
  form,
  initialValues,
  disabled,
  onCountryChange,
  selectedCountry,
  onSubmit
}) => {
  const renderField = React.useCallback(
    (key, innerProps) => {
      if (schema[key] == null) return <></>;
      return (
        <Col key={key} xxl={8} lg={12} md={24}>
          <Field
            initialValues={initialValues}
            schema={schema[key]}
            form={form}
            innerProps={{
              disabled,
              ...innerProps
            }}
          />
        </Col>
      );
    },
    [form, initialValues, disabled]
  );
  const renderFields = (...keys) => keys.map((key) => renderField(key));
  const uploadFile = async (file) => await uploadDocument(file, "COMPANY_CERTIFICATION");
  const showJOI = React.useMemo(() => {
    return hasJOI(selectedCountry);
  }, [selectedCountry]);
  return (
    <Form layout="vertical" onSubmit={onSubmit}>
      <Field form={form} schema={{ name: "id", type: "hidden" }} initialValues={initialValues} />
      <Row gutter={24} type="flex" style={{ alignItems: "stretch" }}>
        {renderFields(FIELDS.name, FIELDS.registrationNumber)}
        {renderField(FIELDS.country, {
          onChange: onCountryChange,
          disabled
        })}
        <Col key={schema[FIELDS.officeNumber]} xxl={8} lg={12} md={24}>
          <Field
            initialValues={initialValues}
            schema={{
              ...schema[FIELDS.officeNumber]
            }}
            innerProps={{ disabled, country: selectedCountry }}
            form={form}
          />
        </Col>
        {renderFields(FIELDS.incorporationDate, FIELDS.registrationExpiryDate)}
        {showJOI && renderField(FIELDS.jurisdictionOfIncorporation)}
        {renderField(FIELDS.certificationDocument, {
          accept: ".pdf",
          title: "Upload",
          maxSize: 5000,
          disabled,
          uploadHandler: uploadFile
        })}
      </Row>
    </Form>
  );
};

CompanyInfoFormView.propTypes = {
  form: PropTypes.object,
  initialValues: PropTypes.object,
  disabled: PropTypes.bool,
  onCountryChange: PropTypes.func,
  selectedCountry: PropTypes.string,
  onSubmit: PropTypes.func
};

CompanyInfoFormView.defaultProps = {
  initialValue: null,
  title: null
};
