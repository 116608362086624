import { createFormErrorComp } from "@/utils";
import { ERR_MSG } from "@/commons/consts";
const EMAIL_REGEX =
  /(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))/;
const _4DIGITS_REGEX = /[0-9]{4}/;

const validateCommentContent = (content, companyName) => {
  if (EMAIL_REGEX.test(content)) return false;
  // Check if content has companyName
  const formattedContent = content.toLowerCase().replace(/[^0-9a-z]/g, "");
  const formattedCompanyName = companyName.toLowerCase().replace(/[^0-9a-z]/g, "");
  if (companyName && formattedContent.includes(formattedCompanyName)) {
    return false;
  }

  if (_4DIGITS_REGEX.test(formattedContent)) return false;
  return true;
};

const commentValidator = (companyName) => (rule, value, callback) => {
  if (value == null || value === "") {
    callback();
    return;
  }
  if (!validateCommentContent(value, companyName)) {
    callback(createFormErrorComp(ERR_MSG.COMMENT_CONTENT_MUST_BE_LEGAL));
    return;
  }
  callback();
};

export const getCommentRules = (companyName) => [
  {
    max: 4000,
    message: ERR_MSG.COMMENT_MESSAGE_IS_TOO_LONG
  },
  {
    validator: commentValidator(companyName)
  }
];
