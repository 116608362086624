import {
  createBank as createBankDetails,
  deleteBankDetails,
  getBankDetails
} from "./bank-services";
import { API_PATHS } from "@/commons/consts/system/api-paths";

import {
  updateCompanyInfo,
  updateCompanyAddresses,
  createCompanyAddresses,
  deleteCompanyAddresses
} from "@/services/company.service";

import {
  createOwnerInfo,
  uploadOwnerDocuments,
  deleteOwnerInfo,
  updateOwnerInfo
} from "./owner.service";
import { backendAPI } from "@/utils/httpAPI.util";

export const saveStep3 = async ({ bankDetails, deletedIds }) => {
  if (!bankDetails.length) {
    return;
  }
  let promises = [];
  promises.push(createBankDetails(bankDetails));
  deletedIds.forEach((id) => promises.push(deleteBankDetails(id)));
  await Promise.all(promises);
};

export const getStep3 = () => {
  return getBankDetails();
};

export const saveStep1 = async (values) => {
  const { addresses, companyInfo, deletedAddressIds } = values;
  const promises = [];
  promises.push(updateCompanyInfo(companyInfo));
  addresses.forEach((address) => {
    if (Object.values(address).filter((value) => value !== undefined).length > 0) {
      if (address.id !== undefined) {
        promises.push(updateCompanyAddresses(address.id, address));
      } else {
        promises.push(createCompanyAddresses(address));
      }
    }
  });
  deletedAddressIds.forEach((id) => promises.push(deleteCompanyAddresses(id)));
  await Promise.all(promises);
};

export const saveStep2 = async ({ ownerInfo, deletedIds }) => {
  const promises = [];
  for (const owner of ownerInfo) {
    const { documents, ...reparseValue } = owner;
    const { id } = owner;
    let newCreatedOwner = undefined;
    if (id) {
      promises.push(updateOwnerInfo(id, reparseValue));
    } else {
      newCreatedOwner = await createOwnerInfo(reparseValue);
    }
    if (documents) {
      promises.push(uploadOwnerDocuments(id ? id : newCreatedOwner.id, documents));
    }
  }
  deletedIds.forEach((id) => promises.push(deleteOwnerInfo(id)));
  await Promise.all(promises);
};

export const submitKYC = async () => {
  await backendAPI.get(API_PATHS.KYC_SUBMISSION);
};
