import React from "react";
import { Modal, Upload } from "antd";
import styles from "./styles.module.scss";

const isValidDocumentType = (documentTypes, documentType) => {
  if (documentTypes == null) return false;
  return documentTypes.some((document) => document.type === documentType);
};

const Component = ({ value, documentTypes, onChange, disabled }, ref) => {
  const [previewImage, setPreviewImage] = React.useState();
  const localFiles = React.useMemo(() => {
    if (value == null || !Array.isArray(value)) {
      return [];
    }
    return value.filter((item) => isValidDocumentType(documentTypes, item.type));
  }, [value, documentTypes]);
  const handleRemoveDocument = React.useCallback(
    (documentType) => {
      const newUploadedFiles = [];
      for (const localFile of localFiles) {
        if (localFile.type !== documentType) {
          newUploadedFiles.push(localFile);
          continue;
        }
        if (localFile.id != null) {
          newUploadedFiles.push({ ...localFile, isDeleted: true });
        }
      }
      onChange(newUploadedFiles);
    },
    [localFiles, onChange]
  );
  const handleBeforeUpload = React.useCallback(
    (documentType, file) => {
      const fileType = file?.type;
      const listValidImageType = ["image/jpg", "image/jpeg", "image/png"];
      const isValidFile = listValidImageType.includes(fileType);
      if (!isValidFile) {
        onChange([...localFiles]);
        return;
      }
      const url = URL.createObjectURL(file);
      const nextLocalFiles = [
        ...localFiles,
        {
          file,
          url,
          type: documentType
        }
      ];
      onChange(nextLocalFiles);
      return false;
    },
    [onChange, localFiles]
  );

  return (
    <div ref={ref}>
      <div className="d-flex flex-row flex-wrap">
        {documentTypes &&
          documentTypes.map(({ type, label, isRequired }) => {
            const matchedLocalFiles = localFiles.filter(
              (localFile) => localFile.type === type && !localFile.isDeleted
            );
            const fileList = matchedLocalFiles.map((localFile) => ({
              ...localFile,
              thumbUrl: localFile.url
            }));
            return (
              <div key={type} className={fileList.length === 0 ? styles["upload-item"] : undefined}>
                <Upload
                  disabled={disabled}
                  accept=".jpg,.png"
                  listType="picture-card"
                  fileList={fileList}
                  beforeUpload={(file) => handleBeforeUpload(type, file)}
                  onPreview={setPreviewImage}
                  onRemove={() => handleRemoveDocument(type)}
                >
                  {!fileList.length && !disabled && (
                    <div className="px-2">
                      {isRequired && <span className="text-red mr-1">*</span>}
                      <span>Upload {label}</span>
                    </div>
                  )}
                </Upload>
              </div>
            );
          })}
      </div>
      <Modal
        visible={previewImage != null}
        footer={null}
        onCancel={() => setPreviewImage(undefined)}
      >
        {previewImage && <img alt="example" style={{ width: "100%" }} src={previewImage.url} />}
      </Modal>
    </div>
  );
};

export const DocumentUploader = React.memo(React.forwardRef(Component));
