import { useHistory, useLocation } from "@/components/commons/navigator";
import React from "react";

const SEARCH_KEY = "searchText";
export const useSearchQuery = () => {
  const { search } = useLocation();
  const history = useHistory();
  const searchText = React.useMemo(() => {
    const params = new URLSearchParams(search);
    if (params.has(SEARCH_KEY)) {
      return params.get(SEARCH_KEY);
    }
    return "";
  }, [search]);

  const clearSearchText = React.useCallback(() => {
    const params = new URLSearchParams(search);
    params.delete(SEARCH_KEY);
    history.replace({ search: params.toString() });
  }, [search, history]);

  return { searchText, clearSearchText };
};
