import { ROUTES } from "@/commons/consts";

import GroupIcon from "@mui/icons-material/Group";
import { ListAlt } from "@mui/icons-material";

export const traderSettingsMenuData = [
  {
    title: "Users",
    icon: GroupIcon,
    key: ROUTES.ADMIN_USER_MANAGEMENT_ROUTE,
    url: ROUTES.ADMIN_USER_MANAGEMENT_ROUTE
  },
  {
    title: "Audit Log",
    icon: ListAlt,
    key: ROUTES.ADMIN_AUDIT_LOG_ROUTE,
    url: ROUTES.ADMIN_AUDIT_LOG_ROUTE
  }
];
