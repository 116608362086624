import { SvgIcon, SvgIconProps } from "@mui/material";
import React from "react";

function Bid(props: SvgIconProps) {
  return (
    <SvgIcon {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
      <path
        fill="currentColor"
        fillOpacity="0.87"
        d="M12.17 9.117L7.953 7.883a1.152 1.152 0 01.324-2.258h2.59c.476 0 .945.145 1.336.41.238.16.558.121.761-.078l1.36-1.328a.633.633 0 00-.07-.957 5.524 5.524 0 00-3.38-1.172V.625A.627.627 0 0010.25 0h-1.25a.627.627 0 00-.625.625V2.5h-.098a4.28 4.28 0 00-4.258 4.672c.164 1.8 1.54 3.266 3.274 3.773l4.004 1.172a1.152 1.152 0 01-.324 2.258h-2.59c-.477 0-.946-.145-1.337-.41a.61.61 0 00-.761.078l-1.36 1.328a.633.633 0 00.07.957 5.524 5.524 0 003.38 1.172v1.875c0 .344.28.625.625.625h1.25a.627.627 0 00.625-.625v-1.883c1.82-.035 3.527-1.117 4.128-2.84.84-2.406-.57-4.875-2.832-5.535z"
      ></path>
      <path
        stroke="currentColor"
        strokeOpacity="0.87"
        d="M12.03 9.597h0L7.813 8.363s0 0 0 0a1.652 1.652 0 01.464-3.238h2.589c.576 0 1.143.175 1.617.497l-.281.413.279-.415a.09.09 0 00.06.014.122.122 0 00.072-.034h.001l1.36-1.329h0c.044-.043.057-.138-.027-.203l-.003-.003h0A5.024 5.024 0 0010.872 3l-.498-.002V.625A.127.127 0 0010.249.5h-1.25a.127.127 0 00-.125.125V3h-.598a3.78 3.78 0 00-3.76 4.127l7.514 2.47zm0 0c1.998.583 3.242 2.765 2.5 4.89l-2.5-4.89zm-.594 2.04h.001a1.652 1.652 0 01-.466 3.237h-2.59a2.884 2.884 0 01-1.617-.496l.281-.413-.278.415a.09.09 0 00-.062-.014.122.122 0 00-.071.034h0l-1.36 1.329h-.001c-.044.044-.057.138.027.203l.003.003A5.023 5.023 0 008.375 17l.499.002v2.373c0 .068.057.125.125.125h1.25a.127.127 0 00.125-.125v-2.373l.49-.01c1.64-.031 3.143-1.006 3.666-2.504l-3.094-2.85zm0 0l-4.004-1.171s0 0 0 0m4.004 1.171l-4.004-1.172m0 0c-1.567-.458-2.773-1.771-2.916-3.338l2.916 3.338z"
      ></path>
    </SvgIcon>
  );
}

export default Bid;
