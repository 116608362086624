import { Col, Row } from "antd";
import React from "react";

const parseValue = (value) => {
  if (value == null) return "";
  if (Array.isArray(value) && value.length > 0 && value.every((item) => typeof item === "string")) {
    return value.join(", ");
  }
  if (typeof value === "object") return value;
  return value.toString();
};

const KVItem = ({ item }) => {
  return (
    <div className="d-flex flex-wrap">
      <span className="mr-2">
        <b>{item.key}: </b>
      </span>
      <span style={{ wordBreak: "break-word" }}>{parseValue(item.value)}</span>
    </div>
  );
};

const _KVList = ({ items, colProps }) => {
  return (
    <Row type="flex" gutter={[24, 8]}>
      {items.map((item, index) => (
        <Col span={24} key={index} {...colProps} {...item.props}>
          <KVItem item={item} />
        </Col>
      ))}
    </Row>
  );
};

export const KVList = React.memo(_KVList);
