import groupBy from "lodash/groupBy";
import {
  ORDER_STATUS,
  SELLER_ORDER_PROCESS_LABEL,
  ORDER_PROCESS,
  ORDER_STATUS_LABEL,
  BUYER_ORDER_PROCESS_LABEL,
  COMBO_TEXT
} from "@/commons/consts";
import { toCurrency, datetimeUtil, parseNameTitle } from "@/utils";

const { formatLocalDatetime } = datetimeUtil;

const orderProcessMapping = (orderArray, isSeller) => {
  let newArray = [...orderArray];
  if (newArray && Array.isArray(newArray)) {
    newArray = newArray.map((order) => {
      const { buyerProcess, buyerStatus, sellerStatus, sellerProcess } = order;
      let newBuyerProcess = buyerProcess;
      let newSellerProcess = sellerProcess;
      if (
        sellerProcess === buyerProcess &&
        sellerProcess === ORDER_PROCESS.UPLOAD_SHIPPING_DOCUMENTS
      ) {
        if (sellerStatus === ORDER_STATUS.IN_PROGRESS) {
          newSellerProcess = ORDER_PROCESS.WAIT_FOR_BUYER_UPLOAD_DOCUMENTS;
        }
        if (buyerStatus === ORDER_STATUS.IN_PROGRESS) {
          newBuyerProcess = ORDER_PROCESS.WAIT_FOR_SELLER_UPLOAD_DOCUMENTS;
        }
      }
      if (
        buyerProcess === ORDER_PROCESS.INSPECTION &&
        buyerStatus === ORDER_STATUS.ACTION_REQUIRED &&
        isSeller
      ) {
        return {
          ...order,
          sellerProcess: ORDER_PROCESS.SHIPMENT_REACH_BUYER_WAREHOUSE,
          processStatus: sellerStatus
        };
      }
      return {
        ...order,
        sellerProcess: newSellerProcess,
        buyerProcess: newBuyerProcess,
        processStatus: isSeller ? sellerStatus : buyerStatus
      };
    });
  }
  return newArray;
};

export const parseActiveOrders = (orderArray, isSeller) => {
  let newOrderArray = orderProcessMapping(orderArray, isSeller);
  if (newOrderArray && Array.isArray(newOrderArray)) {
    newOrderArray = newOrderArray.map((value) => {
      const {
        unitPrice,
        processStatus,
        sellerProcess,
        reason,
        buyerProcess,
        createdDate,
        combo,
        productName,
        quantity
      } = value;
      return {
        ...value,
        unitPrice: combo ? "" : unitPrice,
        quantity: combo ? "" : quantity,
        processStatus: ORDER_STATUS_LABEL[processStatus],
        sellerProcess: parseOrderProcess(sellerProcess, processStatus, reason),
        buyerProcess: BUYER_ORDER_PROCESS_LABEL[buyerProcess],
        createdDate: formatLocalDatetime(createdDate),
        productName: combo ? COMBO_TEXT : parseNameTitle(productName)
      };
    });
  }

  return newOrderArray;
};

const parseOrderProcess = (process, status, reason) => {
  if (process === ORDER_PROCESS.INSPECTION && status === ORDER_STATUS.COMPLETED && reason) {
    return SELLER_ORDER_PROCESS_LABEL[process] + " - Buyer Reject";
  } else {
    return SELLER_ORDER_PROCESS_LABEL[process];
  }
};

export const parseTrackShipmentDetails = (data, productDetails, shipmentDetails) => {
  const shipmentWeight = shipmentDetails.shippingPackageList
    .map((item) => item.numberPackage * item.weight)
    .reduce((acc, curr) => acc + curr);
  const {
    AWBNumber,
    ShipmentInfo: { Pieces, OriginServiceArea, DestinationServiceArea }
  } = data.trackingResponse.TrackingResponse.AWBInfo.ArrayOfAWBInfoItem;
  const { ArrayOfShipmentEventItem } =
    data.trackingResponse.TrackingResponse.AWBInfo.ArrayOfAWBInfoItem.ShipmentInfo.ShipmentEvent;
  const okEvent = ArrayOfShipmentEventItem.find((event) => event.ServiceEvent.EventCode === "OK");
  const shipmentEvents = groupBy(
    ArrayOfShipmentEventItem.filter((event) => event.ServiceEvent.EventCode !== " OK"),
    "Date"
  );
  return {
    info: {
      arrivedInfo: okEvent,
      basicInfo: {
        serviceName: "DHL Express",
        AWBNumber,
        originServiceArea: OriginServiceArea.Description,
        destinationServiceArea: DestinationServiceArea.Description
      }
    },
    details: {
      totalPieces: Pieces,
      shipmentWeight,
      productName: productDetails.productName
    },
    events: {
      okEvent,
      shipmentEvents
    }
  };
};

export const parseHistoryOrders = (orderArray, isSeller) => {
  let newOrderArray = orderProcessMapping(orderArray, isSeller);
  if (newOrderArray && Array.isArray(newOrderArray)) {
    newOrderArray = newOrderArray.map((value) => {
      const {
        unitPrice,
        processStatus,
        sellerProcess,
        reason,
        buyerProcess,
        createdDate,
        totalPrice
      } = value;
      return {
        ...value,
        totalPrice: toCurrency(totalPrice),
        unitPrice: toCurrency(unitPrice),
        processStatus: ORDER_STATUS_LABEL[processStatus],
        sellerProcess: parseOrderProcess(sellerProcess, processStatus, reason),
        buyerProcess: BUYER_ORDER_PROCESS_LABEL[buyerProcess],
        createdDate: formatLocalDatetime(createdDate)
      };
    });
  }

  return newOrderArray;
};
