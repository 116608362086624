import React from "react";
import { DTCModal } from "@/components/commons";
import { LoadingButton } from "@mui/lab";
import { Stack } from "@mui/material";
import { useAddAddress } from "../lib/use-mutation-address";
import { AddressForm } from "./address-form.comp";
import { Address } from "../lib/model";
import { FormikProps } from "formik";

interface Props {
  open?: boolean;
  onClose: () => void;
}

const initialValues = {
  addressLine1: "",
  country: "",
  city: "",
  state: "",
  postalCode: "",
  phone: "",
  email: ""
};
export const AddAddressFormModal = ({ open, onClose }: Props) => {
  const { mutate, isLoading } = useAddAddress();
  const ref = React.useRef<FormikProps<Address>>(null);
  const handleSubmit = (values: Address) => {
    mutate(values, {
      onSuccess: () => {
        onClose();
        ref.current?.resetForm();
      }
    });
  };
  return (
    <DTCModal
      onClose={onClose}
      open={open}
      title="Add New Address"
      size="small"
      content={
        <Stack spacing={4} mb={1} mt={2}>
          <AddressForm ref={ref} initialValues={initialValues} onSubmit={handleSubmit} />
          <LoadingButton
            loading={isLoading}
            variant="contained"
            onClick={() => ref.current?.submitForm()}
          >
            Save Address
          </LoadingButton>
        </Stack>
      }
    />
  );
};
