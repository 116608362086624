import Keycloak from "keycloak-js";
import { getKeyCloakClientId, getKeyCloakRealm, getKeyCloakURL } from "@/utils/config.util";
import { useKeycloak } from "@react-keycloak/web";

const keycloak = new Keycloak({
  url: getKeyCloakURL(),
  realm: getKeyCloakRealm(),
  clientId: getKeyCloakClientId()
});

const keycloakProviderInitConfig = {
  onLoad: "login-required"
};

const getAbsolutePath = (path) => {
  return window.location.protocol + "//" + window.location.host + path;
};

export const useLogout = () => {
  const { keycloak } = useKeycloak();
  return (redirectUri) =>
    keycloak.logout({
      redirectUri: getAbsolutePath(redirectUri ?? "")
    });
};

export { keycloak, keycloakProviderInitConfig };
