import { backendAPI } from "@/utils/httpAPI.util";
import { API_PATHS } from "@/commons/consts/system/api-paths";

export const getProductMarketplace = async ({ params, isCombo, role }) => {
  const result = await backendAPI.get(API_PATHS.PRODUCTS_MARKETPLACE, {
    ...params,
    isCombo,
    role
  });
  return result;
};

export const getProductsBidMore = async ({ saleId, sellerId, params }) => {
  const result = await backendAPI.get(
    API_PATHS.PRODUCTS_BID_MORE.replace(":sellerId", sellerId).replace(":saleId", saleId),
    { ...params }
  );
  return result;
};

export const getBrandingAsset = async (type) => {
  return backendAPI.get(API_PATHS.MARKETPLACE_BRANDING_ASSET, { type });
};

export const getMarketPlaceName = async () => {
  return backendAPI.get(API_PATHS.MARKETPLACE_NAME);
};
