import { ROUTES } from "@/commons/consts";
import { loadable } from "@/utils/loadable.util";

const { KYC_PROCESS_ROUTE, KYC_WAITING_ROUTE, EMAIL_UNLOCK_USER_ROUTE } = ROUTES;

export const publicRoutes = [
  {
    path: KYC_PROCESS_ROUTE,
    Component: loadable(() => import("pages/public/KYC-process/kyc-process.page")),
    exact: true
  },
  {
    path: KYC_WAITING_ROUTE,
    Component: loadable(() => import("pages/public/KYC-waiting/kyc-waiting.page")),
    exact: true
  },
  {
    path: EMAIL_UNLOCK_USER_ROUTE,
    Component: loadable(() => import("pages/public/email-unlock-user/email-unlock-user.page")),
    exact: true
  }
];
