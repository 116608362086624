import { PhoneOTPModal } from "@/entities/user-profile/ui/phone-otp-modal";
import { DTCModal, Loader } from "@/components/commons";
import { PhoneField } from "@/components/commons/fields";
import Button from "@mui/lab/LoadingButton";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { Form, Formik } from "formik";
import React from "react";
import * as yup from "yup";

import { useUpdateProfile, useUserProfile } from "../../libs/use-user-profile";
import { isValidPhone } from "@/utils";

const phoneRegExp = /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/;
const validationSchema = yup.object({
  phone: yup.string().required().matches(phoneRegExp, "Please enter a valid phone number")
});

export interface PhoneFormModalProps {
  open?: boolean;
  onClose?: () => void;
  onUpdated?: () => void;
  description: string;
}

export const PhoneFormModal = ({ open, onClose, onUpdated, description }: PhoneFormModalProps) => {
  const { user, isLoading } = useUserProfile();
  const { mutate, isLoading: isUpdating } = useUpdateProfile({
    onSuccess: () => {
      if (onUpdated) {
        onUpdated();
      }
    }
  });
  const submit = (values) => {
    const { phone } = values;
    const { firstName, lastName } = user;
    mutate({
      phone,
      firstName,
      lastName
    });
  };
  return (
    <DTCModal
      size="tiny"
      onClose={onClose}
      open={open}
      title={
        <Typography variant="inherit" alignItems="center">
          Phone Number
        </Typography>
      }
      content={
        <Stack spacing={2}>
          <Typography variant="body2" textAlign="center">
            {description}
          </Typography>
          {isLoading && <Loader />}
          {user && (
            <>
              <Formik
                initialValues={{ phone: user.phone }}
                validationSchema={validationSchema}
                onSubmit={submit}
                onValuesChange
              >
                <Form>
                  <Stack spacing={2}>
                    <PhoneField
                      fullWidth
                      countryFieldName={undefined}
                      prefixeDisableClearable
                      name="phone"
                      label="Phone Number"
                      placeholder="Phone Number"
                    />
                    <Button
                      sx={{ alignSelf: "center" }}
                      loading={isUpdating || isLoading}
                      variant="contained"
                      type="submit"
                    >
                      Verify
                    </Button>
                  </Stack>
                </Form>
              </Formik>
            </>
          )}
        </Stack>
      }
    />
  );
};

export interface AddPhoneModalProps {
  open?: boolean;
  onClose?: () => void;
  onReady: () => void;
  onVerify: (code: string) => void;
  phoneFormDescription: string;
  otpDescription: string;
  isSubmitting?: boolean;
}
export const AddPhoneModal = ({
  open,
  onClose,
  onReady,
  onVerify,
  phoneFormDescription,
  otpDescription,
  isSubmitting
}: AddPhoneModalProps) => {
  const { user } = useUserProfile();
  const hasPhone = isValidPhone(user.phone);
  if (!open) return <></>;
  return (
    <>
      <PhoneFormModal
        onClose={onClose}
        open={open && !hasPhone}
        onUpdated={onReady}
        description={phoneFormDescription}
      />
      <PhoneOTPModal
        description={otpDescription}
        open={open && hasPhone}
        onClose={onClose}
        phone={user.phone}
        isSubmitting={isSubmitting}
        onVerify={onVerify}
      />
    </>
  );
};
