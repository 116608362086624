const tryGetStringArray = (str) => {
  if (str == null) return [];
  try {
    return str.split(",").map((item) => item.trim());
  } catch {
    return [];
  }
};

const tryGetNumberArray = (str) => {
  return tryGetStringArray(str).map(parseInt);
};

export const getCompanyShortName = () => {
  return window?._env_?.REACT_COMPANY_SHORT_NAME ?? "";
};
export const getCompanyFullName = () => {
  return window?._env_?.REACT_APP_FULL_COMPANY_NAME ?? "";
};
export const getAPIEndPoint = () => window?._env_?.REACT_APP_API_ENDPOINT;

export const getMarketplaceUrl = () =>
  window?._env_?.REACT_APP_MARKETPLACE_URL || "https://dev.distichain.com";
export const getServicePortalUrl = () =>
  window?._env_?.REACT_APP_SERVICE_PORTAL_URL || "https://services.distichain.dev";

export const getBadgeNumbers = () => tryGetNumberArray(window?._env_?.REACT_APP_NUMBER_BADGES);
export const getBadgeValues = () => tryGetNumberArray(window?._env_?.REACT_APP_VALUE_BADGES);
export const getDefaultCurrency = () => {
  return window?._env_?.REACT_APP_DEFAULT_CURRENCY_TYPE || "USD";
};
export const getSupportEmail = () => {
  return window?._env_?.REACT_APP_SUPPORT_EMAIL ?? "";
};
export const getCompanyName = () => {
  return window?._env_?.REACT_APP_COMPANY_NAME ?? "";
};

export const getAssetURL = (path) => {
  return (window?._env_?.ASSET_URL ?? "/admin") + (path ?? "");
};
export const getAppVersion = () => {
  return window?._env_?.APPLICATION_VERSION ?? "v0.0.0";
};

export const getHomePage = () => {
  return window?._env_?.REACT_APP_HOME_PAGE ?? "";
};

export const getPrivacyPolicyLink = () => {
  return window?._env_?.REACT_APP_PRIVACY_POLICY ?? "";
};

export const getFlags = () => {
  return tryGetStringArray(window?._env_?.REACT_APP_FLAGS);
};

export const getKeyCloakURL = () => {
  return window?._env_?.REACT_APP_KEYCLOAK_URL ?? "";
};

export const getKeyCloakRealm = () => {
  return window?._env_?.REACT_APP_KEYCLOAK_REALM ?? "";
};

export const getKeyCloakClientId = () => {
  return window?._env_?.REACT_APP_KEYCLOAK_CLIENT_ID ?? "";
};

const getNumberArray = (stringValue) => {
  if (!stringValue) return [];
  return stringValue
    .split(",")
    .filter((value) => !isNaN(value))
    .map((stringNumber) => Number.parseInt(stringNumber.trim()));
};

export const getBadgeValueLevels = () => {
  return getNumberArray(window?._env_?.REACT_APP_VALUE_BADGES);
};

export const getBadgeNumberLevels = () => {
  return getNumberArray(window?._env_?.REACT_APP_NUMBER_BADGES);
};

export const getDateTimeFormat = () => {
  return window?._env_?.DATETIME_FORMAT ?? "DD-MM-YYYY HH:mm A";
};

export const getInventoryTemplateFile = () => {
  return window?._env_?.REACT_APP_INVENTORY_TEMPLATE ?? "";
};

export const getCurrency = () => {
  return window?._env_?.REACT_APP_DEFAULT_CURRENCY_TYPE ?? "";
};

export const getDateFormat = () => {
  return window?._env_?.DATE_FORMAT ?? "DD-MM-YYYY";
};
