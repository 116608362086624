import Box from "@mui/material/Box";

import { getCompanyName } from "@/utils/config.util";
import { useBrandingAssets } from "@/entities/branding-asset/libs/use-branding-asset";
import { PortalSwitcher } from "../commons/portal-switcher.comp";

import { portalMap } from "../commons/portals.map";
import { AppBarLayout } from "../commons/app-bar.layout";
import { UserMenu } from "../commons/user-menu.comp";

export const AdminAppBar = () => {
  const { brandingAssets } = useBrandingAssets();

  return (
    <AppBarLayout
      leftElement={
        <Box display="flex" alignItems="center" justifyContent="center" height={40}>
          <img
            style={{ height: "100%", objectFit: "contain" }}
            src={brandingAssets.LOGO}
            alt={`${getCompanyName()}`}
          />
          <PortalSwitcher portals={[portalMap.marketplace, portalMap.logistics]} />
        </Box>
      }
      rightElement={<UserMenu />}
    />
  );
};
