import { FEE_SCHEDULE, PRIVACY_POLICY, TERMS_AND_CONDITIONS } from "@/commons/consts/system/routes";
import { loadable } from "@/utils/loadable.util";
import PoliciesPage from "@legacy/pages/public/policies";

export const policiesRoutes = [
  {
    exact: false,
    key: "policies",
    Component: PoliciesPage,
    children: [
      {
        path: TERMS_AND_CONDITIONS,
        Component: loadable(() => import("pages/public/policies/terms-and-conditions")),
        exact: true
      },
      {
        path: FEE_SCHEDULE,
        Component: loadable(() => import("pages/public/policies/fee-schedule")),
        exact: true
      },
      {
        path: PRIVACY_POLICY,
        Component: loadable(() => import("pages/public/policies/privacy-policy.page")),
        exact: true
      }
    ]
  }
];
