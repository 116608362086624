import React from "react";
import { Form } from "@ant-design/compatible";
import "@ant-design/compatible/assets/index.css";
import { PhoneInput } from "../form-items/phone-input/phone-input.comp";
export const PhoneField = ({ form, initialValue, schema, innerProps }) => {
  const { label, name, options, prefixDataSource, placeholder, prefixPlaceholder } = schema;
  const fieldError = form.getFieldError(name);
  const validateTrigger = fieldError ? "onChange" : "onInputChange";
  return (
    <Form.Item label={label}>
      {form.getFieldDecorator(name, {
        ...options,
        validateTrigger,
        initialValue
      })(
        <PhoneInput
          dataSource={prefixDataSource}
          placeholder={placeholder}
          prefixPlaceholder={prefixPlaceholder}
          {...innerProps}
        />
      )}
    </Form.Item>
  );
};
