import { useQuery, useQueryClient } from "react-query";
import { getOwnerInfo } from "@/services/owner.service";

export const useGetOwnerInfo = () => {
  const { data, ...others } = useQuery(["owner-info"], getOwnerInfo);
  return { owners: data ?? [], ...others };
};

export const useInvalidateOwnerInfo = () => {
  const queryClient = useQueryClient();
  return () => queryClient.invalidateQueries("owner-info");
};
