import { BuyerMainLayout } from "@/processes/marketplace/buyer/main-layout.comp";
import { BuyerAuthLayout } from "@/processes/marketplace/buyer/auth-layout.comp";

import { EmptyLayout } from "@/processes/empty/empty.layout";
import HomePage from "@/pages/home/home.page";
import { buyerRoutes } from "./buyer.route";
import { publicRoutes } from "./public.route";
import { sellerRoutes } from "./seller.route";

import { ROUTES } from "@/commons/consts";
import { MainContainer } from "@/processes/container/main-container.comp";

import { LegacyContainer } from "@/processes/legacy-container";
import { SellerMainLayout } from "@/processes/marketplace/seller/main-layout.comp";
import { SellerAuthLayout } from "@/processes/marketplace/seller/auth-layout.comp";
import { marketplaceSettingRoutes } from "../settings/marketplace-setting.routes";
import { bankRoutes } from "@/apps/bank/bank.route";
import { loadable } from "@/utils/loadable.util";
import { policiesRoutes } from "./policies.route";
import { traderProfileRoutes } from "../user-profile/user-profile.routes";
import { inventoryRoutes } from "@/pages/inventory/routes";

const { BUYER_PREFIX, SELLER_PREFIX } = ROUTES;
export const marketRoutes = [
  {
    key: "home",
    path: "/",
    Component: HomePage,
    exact: true
  },
  ...policiesRoutes,
  {
    key: "empty-layout",
    Component: EmptyLayout,
    exact: false,
    children: [
      {
        key: "lagecy",
        Component: LegacyContainer,
        children: publicRoutes
      }
    ]
  },
  {
    key: "onboarding",
    path: ROUTES.ONBOARDING_ROUTE,
    Component: loadable(() => import("@/pages/onboarding/onboarding.page")),
    exact: true
  },
  {
    key: "seller-auth-layout",
    Component: SellerAuthLayout,
    path: SELLER_PREFIX,
    exact: false,
    children: [
      {
        key: "seller-main-layout",
        Component: SellerMainLayout,
        children: [
          {
            key: "main-container",
            Component: MainContainer,
            children: [
              {
                key: "legacy-container",
                Component: LegacyContainer,
                children: sellerRoutes
              },
              {
                path: ROUTES.WITHDRAW_FUND,
                Component: loadable(() => import("@/pages/withdraw-fund/new-withdraw-fund.page")),
                exact: true
              }
            ]
          },
          ...inventoryRoutes,
          ...bankRoutes,
          ...traderProfileRoutes
        ]
      },
      ...marketplaceSettingRoutes
    ]
  },
  {
    key: "buyer-auth-layout",
    Component: BuyerAuthLayout,
    path: BUYER_PREFIX,
    exact: false,
    children: [
      {
        key: "buyer-main-layout",
        Component: BuyerMainLayout,
        children: [
          {
            key: "main-container",
            Component: MainContainer,
            children: [
              {
                key: "legacy-container",
                Component: LegacyContainer,
                children: buyerRoutes
              },
              {
                path: ROUTES.WITHDRAW_FUND,
                Component: loadable(() => import("@/pages/withdraw-fund/new-withdraw-fund.page")),
                exact: true
              }
            ]
          },
          ...bankRoutes,
          ...traderProfileRoutes
        ]
      },
      ...marketplaceSettingRoutes
    ]
  }
];
