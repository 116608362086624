import { backendAPI } from "@/utils/httpAPI.util";
import { calBidTimer } from "@/utils";
import { API_PATHS } from "@/commons/consts/system/api-paths";
import { BID_TYPE } from "@/commons/consts/general.const";

export const saleProduct = async (values) => {
  await backendAPI.post(API_PATHS.SALES_PRODUCT, values);
};

/** @typedef {Object} SaleDetails
 * @property {String} addressLine1
 * @property {Boolean} anonymous
 * @property {String} city
 * @property {Boolean} combo
 * @property {String} companyID
 * @property {String} country
 * @property {String} createdBy
 * @property {String} credit
 * @property {Boolean} custom
 * @property {Array} paymentMethods
 * @property {String} postalCode
 * @property {Number} rating
 * @property {Number} reputation
 * @property {Array} reputationList
 * @property {Array} saleDetailResponses
 * @property {String} saleId
 * @property {Array} saleProductImageResponse
 * @property {String} sellerId
 * @property {String} timezone
 * @property {String} username
 */

/**
 *
 * @param {String} saleId
 * @returns {SaleDetails}
 */
export const getSaleDetails = async (saleId) => {
  const saleDetails = await backendAPI.get(`${API_PATHS.SALES_PRODUCT}/${saleId}`);
  return saleDetails;
};

export const bidProduct = async (values) => {
  await backendAPI.post(`${API_PATHS.BID_PRODUCT}`, values);
};

export const getBidderList = async ({ saleId, params }) => {
  const bidderList = await backendAPI.get(
    `${API_PATHS.SELLER_SALES}/${saleId}/negotiations`,
    params
  );
  return bidderList;
};

export const getFullsizeBidderList = async (params) => {
  const { page, size, saleId } = params;

  const res = await backendAPI.get(`${API_PATHS.SELLER_SALES}/${saleId}/negotiations`, {
    page,
    size
  });
  return res;
};

export const getSellerBidDetails = async (negotiationId) => {
  const bids = await backendAPI.get(`${API_PATHS.SELLER_BID_NEGOTIATION}/${negotiationId}`);
  return bids;
};

export const getBuyerBidDetails = async (negotiationId) => {
  const bids = await backendAPI.get(`${API_PATHS.BUYER_BID_NEGOTIATION}/${negotiationId}`);
  return bids;
};

export const sellerAcceptBid = async (bidId, responseTime) => {
  await backendAPI.patch(`${API_PATHS.SELLER_BID_NEGOTIATION_BID}/${bidId}/accept/${responseTime}`);
};

export const sellerCounterBid = async (values, responseTime) => {
  await backendAPI.post(`${API_PATHS.SELLER_BID_NEGOTIATION_BID}/counter/${responseTime}`, values);
};

export const sellerCancelCounter = async (bidId) => {
  await backendAPI.delete(`${API_PATHS.SELLER_BID_NEGOTIATION_BID}/${bidId}`);
};

export const sellerRejectBid = async (bidId, responseTime) => {
  await backendAPI.patch(`${API_PATHS.SELLER_BID_NEGOTIATION_BID}/${bidId}/reject/${responseTime}`);
};

export const buyerFinalBid = async (values, responseTime) => {
  await backendAPI.post(`${API_PATHS.BUYER_BID_NEGOTIATION_BID}/final/${responseTime}`, values);
};

export const buyerAcceptBid = async (bidId, responseTime) => {
  await backendAPI.patch(`${API_PATHS.BUYER_BID_NEGOTIATION_BID}/${bidId}/accept/${responseTime}`);
};

export const buyerRejectBid = async (bidId, responseTime) => {
  await backendAPI.patch(`${API_PATHS.BUYER_BID_NEGOTIATION_BID}/${bidId}/reject/${responseTime}`);
};

export const buyerCancelBid = async (bidId) => {
  await backendAPI.delete(`${API_PATHS.BUYER_BID_NEGOTIATION_BID}/${bidId}`);
};

export const getSellerSales = async (params) => {
  const { size } = params;

  const res = await backendAPI.get(`${API_PATHS.SELLER_SALES}`, { size });
  return res;
};

export const getBuyerBids = async (params) => {
  const { size } = params;

  const res = await backendAPI.get(`${API_PATHS.BUYER_BIDS}`, { size });
  return res;
};

export const getOfferDetail = async ({ saleId }) => {
  const bidderList = await backendAPI.get(`${API_PATHS.SALES_PRODUCT}/${saleId}`);
  return bidderList;
};

export const deleteSale = async (saleId) => {
  await backendAPI.delete(`${API_PATHS.SELLER_SALES}/${saleId}`);
};

export const deleteNegotiation = async (negotiationId) => {
  await backendAPI.delete(`${API_PATHS.BUYER_BID_NEGOTIATION}/${negotiationId}`);
};

export const sellerExpireBid = async (bidId) => {
  await backendAPI.patch(`${API_PATHS.SELLER_BID}/${bidId}/expire`);
};

export const buyerExpireBid = async (bidId) => {
  await backendAPI.patch(`${API_PATHS.BUYER_BID}/${bidId}/expire`);
};

export const getPendingSales = async () => {
  const res = await backendAPI.get(`${API_PATHS.SELLER_SALES}/pending`);
  return res;
};

export const sellerGetApprovalHistory = async () => {
  const res = await backendAPI.get(`${API_PATHS.SELLER_SALES}/approval`);
  return res;
};
export const sellerApproveSalePending = async (saleId) => {
  const res = await backendAPI.post(`${API_PATHS.SELLER_SALES}/${saleId}/approve`);
  return res;
};

export const sellerRejectSalePending = async (saleId) => {
  const res = await backendAPI.post(`${API_PATHS.SELLER_SALES}/${saleId}/reject`);
  return res;
};

export const sellerDeleteSalePending = async (saleId) => {
  await backendAPI.delete(`${API_PATHS.SELLER_SALES}/${saleId}/pending`);
};

export const calculatorResponseTime = async (negotiationId, isSeller) => {
  const getCountDownTimeOfBid = (bids) => {
    let init = bids.find((bid) => bid.type === BID_TYPE.FIRST);
    let counter = bids.find((bid) => bid.type === BID_TYPE.COUNTER);
    let counterApprove = bids.find((bid) => bid.type === BID_TYPE.COUNTER_APPROVING);
    let final = bids.find((bid) => bid.type === BID_TYPE.FINAL);

    const isInitial = init && [counter, counterApprove, final].every((bid) => bid === undefined);
    const isCountered = [init, counter].every((bid) => bid !== undefined) && final === undefined;
    const isCounteredApprove =
      [init, counterApprove].every((bid) => bid !== undefined) && final === undefined;
    const isFinal = [init, counter, final].every((bid) => bid !== undefined);

    if (isInitial) {
      return calBidTimer({ ...init, isSeller: true });
    }
    if (isCountered) {
      return calBidTimer({ ...counter, isSeller: false });
    }
    if (isCounteredApprove) {
      return calBidTimer({ ...counterApprove, isSeller: false });
    }
    if (isFinal) {
      return calBidTimer({ ...final, isSeller: true });
    }
  };

  if (isSeller) {
    const bids = await getSellerBidDetails(negotiationId);
    const countDownTime = getCountDownTimeOfBid(bids);
    return countDownTime.totalMinutes - countDownTime.minutesRemaining;
  } else {
    const bids = await getBuyerBidDetails(negotiationId);
    const countDownTime = getCountDownTimeOfBid(bids);
    return countDownTime.totalMinutes - countDownTime.minutesRemaining;
  }
};
