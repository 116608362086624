import React from "react";
import { companyAddressSchema as schema, FIELDS } from "./company-address.schema";
import { Row, Col } from "antd";
import { Field } from "components/utils";

export const Cell = ({ form, name, disabled, disabledFields, initialValues, innerProps }) => {
  const isDisabled = React.useCallback(() => {
    if (disabled) return true;
    if (disabledFields && disabledFields.includes(name)) return true;
    return false;
  }, [disabled, disabledFields, name]);
  return (
    <Col xxl={8} lg={12} md={24}>
      <Field
        form={form}
        schema={schema[name]}
        innerProps={{
          disabled: isDisabled(name),
          ...innerProps
        }}
        initialValues={initialValues}
      />
    </Col>
  );
};

export const CompanyAddressFormView = React.memo(
  ({ form, initialValues, disabledFields, disabled }) => {
    const props = { form, initialValues, disabled, disabledFields };
    const selectedCountry = React.useMemo(() => {
      return form.getFieldValue(FIELDS.country) ?? initialValues[FIELDS.country];
    }, [form, initialValues]);
    return (
      <>
        <Field form={form} schema={{ name: "id", type: "hidden" }} initialValues={initialValues} />
        <Row type="flex" gutter={24}>
          <Cell {...props} name={FIELDS.addressLine1} />
          <Cell {...props} name={FIELDS.city} />
          <Cell {...props} name={FIELDS.country} />
          <Cell {...props} name={FIELDS.state} />
          <Cell
            {...props}
            name={FIELDS.phone}
            innerProps={{
              disabled,
              country: selectedCountry
            }}
          />
          <Cell {...props} name={FIELDS.email} />
          <Cell {...props} name={FIELDS.postalCode} />
        </Row>
      </>
    );
  }
);
