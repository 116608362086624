import React from "react";
import { Form } from "@ant-design/compatible";
import "@ant-design/compatible/assets/index.css";
import { WrappedSelect } from "../form-items/wrapped-select/wrapped-select.comp";
export const SelectField = ({ form, initialValue, schema, innerProps }) => {
  const { label, options, name, dataSource, placeholder } = schema;
  return (
    <Form.Item label={label}>
      {form.getFieldDecorator(name, {
        ...options,
        initialValue
      })(<WrappedSelect dataSource={dataSource} placeholder={placeholder} {...innerProps} />)}
    </Form.Item>
  );
};
