import { loadable } from "@/utils/loadable.util";
import { ROUTES } from "@/commons/consts";
import { Layout } from "../../entities/bank-details/components/layout.comp";

export const bankRoutes = [
  {
    key: "bank-accounts",
    Component: Layout,
    children: [
      {
        key: ROUTES.NEW_BANK,
        path: ROUTES.NEW_BANK,
        Component: loadable(() => import("../../entities/bank-details/pages/addition.page"))
      },
      {
        key: ROUTES.BANK_HOME,
        path: ROUTES.BANK_HOME,
        Component: loadable(() => import("../../entities/bank-details/pages/banks.page"))
      },
      {
        key: ROUTES.BANK_DETAIL,
        path: ROUTES.BANK_DETAIL,
        Component: loadable(() => import("../../entities/bank-details/pages/detail.page"))
      },
      {
        key: ROUTES.BANK_EDIT,
        path: ROUTES.BANK_EDIT,
        Component: loadable(() => import("../../entities/bank-details/pages/edition.page"))
      }
    ]
  }
];
