import { getDefaultCurrency } from "./config.util";
import numeral from "numeral";

export const formatCurrency = (value: number) => {
  return new Intl.NumberFormat("en-US", { style: "currency", currency: "USD" }).format(value);
};

export const toCurrency = (value, defaultCurrency = "USD") => {
  const currency = getDefaultCurrency() === "USD" ? defaultCurrency : getDefaultCurrency();

  return value !== undefined && !isNaN(value)
    ? currency + " " + numeral(value).format("0,0.00")
    : value;
};

export const convertToUtc = (date) => {
  return new Date(date).toISOString();
};

export const percentage = (value, total, numberFixed) => {
  return ((value / total) * 100).toFixed(numberFixed);
};
