import React from "react";
import classnames from "classnames";
export const ShadowCard = ({ children, className }) => (
  <div className={classnames("air__utils__shadow p-3 dtc-br-10", className)}>{children}</div>
);

const Header = ({ children }) => {
  return <h5 className="text-capitalize font-weight-bold">{children}</h5>;
};

ShadowCard.Header = Header;
