import { isNotCompletedKYC } from "components/kyc/util";

import { useHistory, useLocation } from "@/components/commons/navigator";
import { useUserProfile } from "@/entities/user-profile/libs/use-user-profile";
import { Modal } from "antd";
import { openKYCProcessModal } from "@legacy/components/kyc/kyc-modal/kyc-modal.comp";

export const useUserInfoHook = () => {
  const [modal, contextHolder] = Modal.useModal();
  const { user } = useUserProfile();
  const isUserNotCompleteKYC = isNotCompletedKYC(user);
  const location = useLocation();
  const history = useHistory();
  const _openKYCProcessModal = () => {
    const onClose = () => {
      const params = new URLSearchParams(location.search);
      params.delete("kyc");
      history.push({ pathname: location.pathname, search: params.toString() });
    };
    openKYCProcessModal(modal, onClose);
  };
  const triggerKYCProcess = () => {
    const params = new URLSearchParams(location.search);
    params.set("kyc", "1");
    history.push({ search: params.toString() });
  };
  return {
    isUserNotCompleteKYC,
    openKYCProcessModal: _openKYCProcessModal,
    triggerKYCProcess,
    contextHolder
  };
};
