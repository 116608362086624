import { backendAPI } from "@/utils/httpAPI.util";
import { ApiPathConsts } from "@/commons/consts/system/api-paths/api-paths.const";
import isString from "lodash/isString";

export const getCompanyInfo = () => {
  return backendAPI.get(ApiPathConsts.GET_COMPANY_ME);
};

export const updateCompanyInfo = async (values) => {
  await backendAPI.put(ApiPathConsts.COMPANY_INFO, values);
};

/*
 * COMPANY ADDRESSES
 */

export const getCompanyAddresses = async () => {
  return backendAPI.get(ApiPathConsts.COMPANY_ADDRESSES);
};

export const createCompanyAddresses = async (values) => {
  await backendAPI.post(ApiPathConsts.COMPANY_ADDRESSES, values);
};

export const updateCompanyAddresses = async (id, values) => {
  await backendAPI.put(`${ApiPathConsts.COMPANY_ADDRESSES}/${id}`, values);
};

export const deleteCompanyAddresses = async (id) => {
  await backendAPI.delete(`${ApiPathConsts.COMPANY_ADDRESSES}/${id}`);
};

export const getCompanyMe = () => {
  return backendAPI.get(`${ApiPathConsts.GET_COMPANY_ME}`);
};

/*
 * BANK DETAILS
 */

export const getBankDetails = () => {
  return backendAPI.get(ApiPathConsts.BANK_DETAILS);
};

export const createBank = (values) => {
  return backendAPI.post(ApiPathConsts.BANK_DETAILS, values);
};

export const updateBankDetails = async (id, values) => {
  await backendAPI.put(`${ApiPathConsts.BANK_DETAILS}/${id}`, values);
};

export const deleteBankDetails = async (id) => {
  await backendAPI.delete(`${ApiPathConsts.BANK_DETAILS}/${id}`);
};

export const submitBankDetails = async (data) => {
  await backendAPI.post(`${ApiPathConsts.BANK_DETAILS}/submit`, data);
};

export const sendEmailConfirmBank = async () => {
  await backendAPI.get(ApiPathConsts.COMPANY_SEND_VERIFICATION_CODE);
};

export const verifyBankConfirmCode = async (code) => {
  await backendAPI.post(`${ApiPathConsts.COMPANY_SEND_VERIFICATION_CODE}?code=${code}`);
};

/*
 * DOCUMENTS
 */

export const getDocuments = async () => {
  return backendAPI.get(ApiPathConsts.DOCUMENTS);
};

export const uploadDocuments = async (fileList) => {
  const uploadPromises = fileList.map(({ file, type, url }) => {
    if (isString(file)) {
      return null;
    }
    return backendAPI.postFile(`${url ? url : ApiPathConsts.DOCUMENTS}`, file, {
      type
    });
  });
  return Promise.all(uploadPromises);
};

export const deleteDocuments = async (id) => {
  await backendAPI.delete(`${ApiPathConsts.DOCUMENTS}/${id}`);
};

export const getAuditLogs = async (params) => {
  const { page, size } = params;

  return backendAPI.get(`${ApiPathConsts.GET_AUDIT_LOGS}`, {
    page,
    size
  });
};
