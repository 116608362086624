import { createCompanyAddresses } from "@/services/bank-services";
import { useMutation } from "react-query";
import { useInvalidateAddress } from "./use-query-address";

export const useAddAddress = () => {
  const invalidate = useInvalidateAddress();
  return useMutation(createCompanyAddresses, {
    onSuccess: async () => {
      await invalidate();
    }
  });
};
