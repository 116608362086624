import React from "react";
import { Button, Steps } from "antd";
import { EditOutlined } from "@ant-design/icons";

const { Step } = Steps;

export const KYCSteps = ({ isSmallDevice, currentStep, steps, setCurrentStep }) => {
  return (
    <Steps
      current={currentStep}
      onChange={(value) => setCurrentStep(value)}
      labelPlacement="vertical"
    >
      {Object.values(steps).map((step, index) => (
        <Step
          title
          key={step.title}
          disabled={step.disabled}
          description={isSmallDevice ? null : <span>{step.title}</span>}
          icon={
            currentStep === index ? (
              <span className="stepIcons">
                <Button shape="circle-outline" icon={<EditOutlined />}></Button>
              </span>
            ) : null
          }
        />
      ))}
    </Steps>
  );
};
