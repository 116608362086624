import React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Box, Checkbox, FormControlLabel, Stack } from "@mui/material";
import { useSettingsContext } from "./settings-provider.comp";
import Flags from "@/commons/consts/flags.const";

export const SettingContent = () => {
  const { flags, updateFlags } = useSettingsContext();
  const [accordionOpen, setAccordionOpen] = React.useState<Map<string, boolean>>(() => {
    const map = new Map<string, boolean>();
    map.set("flags", true);
    return map;
  });
  const handleOpen = (value: string) => {
    setAccordionOpen((current) => {
      const newMap = new Map(current);
      const nextOpen = newMap.get(value);
      newMap.set(value, !Boolean(nextOpen));
      return newMap;
    });
  };
  const toggleFlag = (flag: string) => {
    const hasFlag = flags.has(flag);
    const newFlags = new Set(flags);
    if (hasFlag) {
      newFlags.delete(flag);
    } else {
      newFlags.add(flag);
    }
    updateFlags(newFlags);
  };
  return (
    <Box height="80vh" sx={{ overflowY: "scroll" }}>
      <AccordionSummary aria-controls="panel1bh-content" id="panel1bh-header">
        <Typography sx={{ width: "33%", flexShrink: 0 }}>VERSION</Typography>
        <Typography sx={{ width: "33%", flexShrink: 0 }}>
          {(window as unknown as { _env_: Record<string, string> })?._env_?.BUILD_VERSION} at{" "}
          {(window as unknown as { _env_: Record<string, string> })?._env_?.BUILD_AT}
        </Typography>
      </AccordionSummary>
      <Accordion expanded={accordionOpen.get("flags")} onChange={() => handleOpen("flags")}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <Typography sx={{ width: "33%", flexShrink: 0 }}>Feature Flags</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Stack>
            {Object.keys(Flags).map((flag) => (
              <FormControlLabel
                key={flag}
                label={flag}
                control={
                  <Checkbox
                    disabled={flag === Flags.devTools}
                    checked={flags.has(flag)}
                    onChange={() => toggleFlag(flag)}
                  />
                }
              />
            ))}
          </Stack>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={accordionOpen.get("panel1")} onChange={() => handleOpen("panel1")}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <Typography sx={{ width: "33%", flexShrink: 0 }}>Environments</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Stack spacing={1} sx={{ overflowX: "scroll" }}>
            {Object.entries(
              (window as unknown as { _env_: Record<string, string> })?._env_ ?? {}
            ).map(([key, value]) => (
              <Stack key={key}>
                <Typography variant="body2" fontWeight="bold">
                  {key}
                </Typography>
                <Typography variant="body2">{value}</Typography>
              </Stack>
            ))}
          </Stack>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};
