import { WRONG_VERIFICATION_CODE } from "@/commons/consts";
import { ApiPathConsts } from "@/commons/consts/system/api-paths/api-paths.const";
import { backendAPI } from "@/utils/httpAPI.util";
import { API_PATHS } from "@/commons/consts/system/api-paths";

export interface UserProfile {
  id: string;
  email: string;
  firstName: string;
  lastName: string;
  country: string;
  phone: string;
  createdDate: string;
  updatedDate: string;
  byEmail: boolean;
  byWeb: boolean;
  byWhatsapp: boolean;
  bySms: boolean;
  phoneVerified: boolean;
  status: string;
  companyStatus: string;
  messageRead: boolean;
  walletStatus: string;
  walletName: string;
  companyType: string;
  kycStatus: string;
  logoUrl: string;
}
export const getUserProfile: () => Promise<UserProfile> = async () =>
  backendAPI.get(ApiPathConsts.UPDATE_USER_INFO);

export const updateProfile = async (values) => {
  await backendAPI.put(ApiPathConsts.UPDATE_USER_INFO, values);
};

// export const requestPhoneCode = async () => {
//   await backendAPI.get(ApiPathConsts.PHONE_VERIFICATION);
// };

// export const verifyPhoneCode = async (code) => {
//   await backendAPI.post(ApiPathConsts.PHONE_VERIFICATION, undefined, { code });
//   return true;
// };

export const updateNotificationChannel = async (values) => {
  await backendAPI.put(ApiPathConsts.NOTIFICATION_SETTING, values);
};

export const validateOTP = async (code) => {
  const data = await backendAPI.post(ApiPathConsts.THREE_STEPS_OTP, { code });
  if (data.status === "INVALID") {
    throw new Error(WRONG_VERIFICATION_CODE);
  }
  return data;
};

export const onboardingAccount = async (data) => {
  return await backendAPI.post(API_PATHS.GET_CURRENT_USER, data);
};

export const createOTP = async () => {
  await backendAPI.put(API_PATHS.THREE_STEPS_OTP);
};
