import { PreferencesLayout } from "@/processes/preference-layout/preference-layout.comp";

import { SettingsLayout } from "@/processes/setting-layout/settings-layout.comp";

import { bankRoutes } from "@/apps/bank/bank.route";
import { tradeRouteRoutes } from "@/components/trade-route/trade-route.routes";

import { MainContainer } from "@/processes/container/main-container.comp";
import { AdminMainLayout } from "@/processes/admin-main-layout/admin-main-layout.comp";
import { loadable } from "@/utils/loadable.util";
import { pimRoutes } from "@/pages/pim/routes";
import { userDetailsRoutes } from "@/components/trader-details/trader-details.routes";
import { ROUTES } from "@/commons/consts";
import { legacyProductRoutes } from "./legacy-product.route";
import { AdminAuthLayout } from "@/processes/marketplace/admin/admin-auth-layout.comp";
import { userManagementRoutes } from "./user-management.route";
import { adminProfileRoutes } from "../user-profile/user-profile.routes";

const { ADMIN_PATHS } = ROUTES;

export const adminRoutes = [
  {
    key: "auth-layout",
    Component: AdminAuthLayout,
    path: ROUTES.ADMIN_PREFIX,
    exact: false,
    children: [
      {
        key: "settings-layout",
        Component: SettingsLayout,
        children: [
          {
            path: ADMIN_PATHS.ORGANIZATION_PROFILE,
            Component: loadable(
              () => import("@/pages/organization-profile/organization-profile.page")
            )
          },
          {
            key: "preference-layout",
            Component: PreferencesLayout,
            children: [
              {
                path: ADMIN_PATHS.PREFERENCES_GENERAL_PAGES,
                Component: loadable(() => import("@/pages/preferences/preferences-general.page"))
              },
              {
                path: ADMIN_PATHS.PREFERENCES_BRANDING_PAGES,
                Component: loadable(() => import("@/pages/preferences/preferences-branding.page"))
              }
            ]
          },
          ...userManagementRoutes,
          ...bankRoutes
        ]
      },
      {
        key: "main-auth-layout",
        Component: AdminMainLayout,
        children: [
          {
            key: "admin-home",
            path: ADMIN_PATHS.PREFIX_HOME_ROUTE,
            Component: loadable(() => import("@/pages/admin-home/admin-home.page"))
          },
          {
            key: "main-auth-container",
            Component: MainContainer,
            children: [
              {
                path: ROUTES.PREFIX_HOME_ROUTE,
                Component: loadable(() => import("@/pages/home/home.page")),
                exact: true
              },
              {
                path: ADMIN_PATHS.DASHBOARD,
                Component: loadable(() => import("@/pages/dashboard/dashboard.page")),
                exact: true
              },
              {
                path: ADMIN_PATHS.TRADERS,
                Component: loadable(() => import("@/pages/traders/traders.page")),
                exact: true
              },
              {
                path: ADMIN_PATHS.ORDERS,
                Component: loadable(() => import("@/pages/orders/orders.page")),
                exact: true
              },
              {
                path: ADMIN_PATHS.ACCOUNT_SUMMARY,
                Component: loadable(() => import("@/pages/account-summary/account-summary.page")),
                exact: true
              },
              {
                path: ADMIN_PATHS.WITHDRAW_FUND,
                Component: loadable(() => import("@/pages/withdraw-fund/new-withdraw-fund.page")),
                exact: true
              },
              {
                path: ADMIN_PATHS.WALLET,
                Component: loadable(() => import("@/pages/wallet/wallet.page")),
                exact: true
              },
              {
                path: ADMIN_PATHS.ADD_FUNDS,
                Component: loadable(() => import("@/pages/add-funds/add-funds.page")),
                exact: true
              },
              {
                path: ADMIN_PATHS.NOTIFICATION,
                Component: loadable(() => import("@/pages/notification/notification.page")),
                exact: true
              },
              ...legacyProductRoutes,
              ...tradeRouteRoutes,
              ...pimRoutes
            ]
          },
          ...userDetailsRoutes,
          ...adminProfileRoutes
        ]
      }
    ]
  }
];
