import { ERR_MSG } from "@/commons/consts";
import { createFormErrorComp } from "@/utils";

export const validPhoneNumber = {
  validator: (_, value, callback) => {
    if (value == null || value === "") {
      callback();
      return;
    }
    const parts = value.split(" ");
    if (parts.length === 1) {
      callback(createFormErrorComp(ERR_MSG.REQUIRED_ERR("Phone Prefix")));
      return;
    }
    const [, ...phoneParts] = parts;
    const phone = phoneParts.join(" ");

    if (phone === "") {
      callback(createFormErrorComp(ERR_MSG.REQUIRED_ERR("Phone Number")));
      return;
    }

    if (!/^([0-9 ]+)$/.test(phone)) {
      callback(createFormErrorComp(ERR_MSG.PHONE_ONLY_NUMBER_ERR));
      return;
    }
    callback();
  }
};
