import { ROUTES } from "@/commons/consts";
import { loadable } from "@/utils/loadable.util";
import { TraderSettingsLayout } from "@/processes/setting-layout/trader-settings-layout.comp";

export const marketplaceSettingRoutes = [
  {
    key: "trader-settings-layout",
    Component: TraderSettingsLayout,
    children: [
      {
        key: "users",
        path: ROUTES.ADMIN_USER_MANAGEMENT_ROUTE,
        Component: loadable(() => import("@/pages/user-management/marketplace-list.page"))
      },
      {
        key: "edit-user",
        path: ROUTES.ADMIN_USER_MANAGEMENT_EDIT_USER_ROUTE,
        Component: loadable(() => import("@/pages/user-management/marketplace-edition.page"))
      },
      {
        key: "add-user",
        path: ROUTES.ADMIN_USER_MANAGEMENT_ADD_USER_ROUTE,
        Component: loadable(() => import("@/pages/user-management/marketplace-creation.page"))
      },
      {
        key: "audit-log",
        path: ROUTES.ADMIN_AUDIT_LOG_ROUTE,
        Component: loadable(() => import("@/pages/user-management/audit-log.page"))
      }
    ]
  }
];
