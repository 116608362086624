import { Tooltip } from "antd";
import { StepButtons } from "components/utils";
import { useInOutAnimation } from "hooks/useInOutAnimation";
import { animated } from "react-spring";
import { InfoCircleOutlined } from "@ant-design/icons";
import {
  useOwnerInfo,
  OwnerInfoForm,
  useOwnerInfoController
} from "@/entities/owner/ui/owner-info";

import { MultiForm } from "components/utils/multi-form/multi-form.comp";
import { PartnerHeader } from "@/entities/owner/ui/owner-info/partner-header";
import { TEXTS } from "@/commons/consts/texts.const";
import { Loader } from "@/components/commons";
import { useUpdateOwner } from "@/entities/owner/libs/use-update-owner";

export const KYC2OwnerInfo = (props) => {
  const { owner, partners, isLoading } = useOwnerInfo();
  if (isLoading) return <Loader />;
  return <KYC2OwnerInfoContent {...props} owner={owner} partners={partners} />;
};

const KYC2OwnerInfoContent = ({ stepButtonsConfig, className, owner, partners }) => {
  const { mutate, isLoading: loading } = useUpdateOwner();
  const { formsRef: ownerFormsRef, composeValues: ownerComposeValues } = useOwnerInfoController([
    owner
  ]);
  const {
    formsRef: partnerFormsRef,
    handleDeleteOwner,
    composeValues: partnerComposeValues
  } = useOwnerInfoController(partners);
  const transitions = useInOutAnimation(1);

  const handleNextStep = async () => {
    const { onNext } = stepButtonsConfig;
    const [hasOwnerError, ownerValues, , ownerDeletedDocuments] = await ownerComposeValues();
    const [hasPartnerError, partnerValues, partnerDeletedIds, partnerDeletedDocuments] =
      await partnerComposeValues();
    if (hasPartnerError || hasOwnerError) {
      return;
    }
    const deletedDocuments = [...ownerDeletedDocuments, ...partnerDeletedDocuments];
    ownerValues.forEach((value) => {
      value.owner = true;
    });
    const values = [...ownerValues, ...partnerValues];
    const deletedFiles = [];
    if (deletedDocuments.length > 0) {
      for (const deletedDocument of deletedDocuments) {
        deletedFiles.push({
          fileIds: [deletedDocument.id],
          ownerId: deletedDocument.ownerId
        });
      }
    }
    mutate(
      {
        ownerInfo: values,
        deletedIds: partnerDeletedIds,
        deletedFiles
      },
      {
        onSuccess: async () => {
          if (onNext) onNext();
        }
      }
    );
  };

  return (
    <div className={className}>
      {loading && (
        <div className="d-flex justify-content-center align-items-center w-100 dtc-h-fix-380">
          <Loader />
        </div>
      )}
      {!loading &&
        transitions.map(({ key, props }) => (
          <animated.div key={key} style={props}>
            <div className="d-flex">
              <h5 className="text-uppercase mr-2">Authorized Representative Information</h5>
              <Tooltip title={TEXTS.userInformattionHoverText}>
                <InfoCircleOutlined />
              </Tooltip>
            </div>
            <OwnerInfoForm
              name="owner"
              initialValues={owner}
              ref={(form) => (ownerFormsRef.current = [form])}
            />
            <MultiForm initialValues={partners}>
              <PartnerHeader />
              <MultiForm.Forms
                onDeleteForm={handleDeleteOwner}
                Form={OwnerInfoForm}
                ref={partnerFormsRef}
              />
              <MultiForm.AddButton>Add Partner</MultiForm.AddButton>
            </MultiForm>
          </animated.div>
        ))}
      <hr />
      <section>
        <StepButtons {...stepButtonsConfig} onNext={handleNextStep} loading={loading} />
      </section>
    </div>
  );
};
