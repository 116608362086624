import React from "react";
import { CompanyInfoFormView } from "./company-info-form.view";
import { FIELDS } from "./company-info.schema";

export const CompanyInfoForm = React.forwardRef(({ form, initialValues, disabled }, ref) => {
  const [selectedCountry, setSelectedCountry] = React.useState(
    initialValues ? initialValues[FIELDS.country] : undefined
  );
  React.useImperativeHandle(ref, () => ({
    form
  }));

  return (
    <CompanyInfoFormView
      selectedCountry={selectedCountry}
      onCountryChange={setSelectedCountry}
      disabled={disabled}
      form={form}
      initialValues={initialValues}
    />
  );
});
