import { backendAPI } from "@/utils/httpAPI.util";
import { API_PATHS } from "@/commons/consts/system/api-paths";

export interface ReputationListItem {
  type: string;
  value: number;
}

export interface CertificationDocument {
  id: string;
  url: string;
  fileName: string;
  originalFileName: string;
  type: string;
  createdDate: string;
  status: string;
}
export interface Badge {
  url: string;
  name: string;
}
export interface CompanyInfoResponse {
  name: string;
  registrationNumber: string;
  reputationList: ReputationListItem[];
  id: string;
  workingStartTime: number;
  workingEndTime: number;
  workingDays: string;
  timezone: string;
  totalNumberOfTransaction: number;
  transactionValue: number;
  officeNumber: string;
  reputation: number;
  badgeList: Badge[];
  certificationDocument: CertificationDocument;
  registrationExpiryDate: string;
  incorporationDate: string;
  country: string;
  logoUrl: string;
}
export const getCompanyInfo: () => Promise<CompanyInfoResponse> = async () => {
  return await backendAPI.get(API_PATHS.COMPANY_INFO);
};

export const updateCompanyInfo = async (values) => {
  await backendAPI.put(API_PATHS.COMPANY_INFO, values);
};

export const uploadCompanyLogo = async (imgFile) => {
  await backendAPI.postFile(API_PATHS.COMPANY_LOGO, imgFile);
};

/*
 * COMPANY ADDRESSES
 */

export const getCompanyAddresses = async () => {
  return await backendAPI.get(API_PATHS.COMPANY_ADDRESSES);
};

export const createCompanyAddresses = async (values) => {
  return await backendAPI.post(API_PATHS.COMPANY_ADDRESSES, values);
};

export const updateCompanyAddresses = async (id, values) => {
  return await backendAPI.put(`${API_PATHS.COMPANY_ADDRESSES}/${id}`, values);
};

export const deleteCompanyAddresses = async (id) => {
  return await backendAPI.delete(`${API_PATHS.COMPANY_ADDRESSES}/${id}`);
};

/*
 * DOCUMENTS
 */

export const getDocuments = async () => {
  return await backendAPI.get(API_PATHS.DOCUMENTS);
};

export const uploadDocument = async (file, type) => {
  return await backendAPI.postFile(API_PATHS.DOCUMENTS, file, {
    type
  });
};

export const uploadDocuments = async (fileList) => {
  const uploadPromises = fileList.map(({ file, type, url }) => {
    const promise = backendAPI.postFile(`${url ? url : API_PATHS.DOCUMENTS}`, file, {
      type
    });
    return promise;
  });
  const result = await Promise.all(uploadPromises);
  return result;
};

export const deleteDocuments = async (id) => {
  await backendAPI.delete(`${API_PATHS.DOCUMENTS}/${id}`);
};

export const getAuditLogs = async (params) => {
  const { page, size } = params;
  const result = await backendAPI.get(`${API_PATHS.GET_AUDIT_LOGS}`, {
    page,
    size
  });
  return result;
};

export const sendEmailConfirmBank = async () => {
  await backendAPI.get(API_PATHS.COMPANY_SEND_VERIFICATION_CODE);
};

export const verifyBankConfirmCode = async (code) => {
  await backendAPI.post(`${API_PATHS.COMPANY_SEND_VERIFICATION_CODE}?code=${code}`);
};
