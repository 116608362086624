import React from "react";
import styles from "./chart-container-comp.module.scss";

export const ChartContainer = ({ children, classname, style }) => {
  return (
    <div
      className={`air__utils__shadow bg-white dtc-br-10 pt-2 pb-3 ${styles["padding"]} ${classname}`}
      style={style}
    >
      {children}
    </div>
  );
};
