import { Divider, IconButton, Link, Popover, Typography } from "@mui/material";

import Badge from "@mui/material/Badge";
import { Box } from "@mui/system";

import NotificationIcon from "@/components/icons/notification.comp";
import { NotificationList } from "@/components/commons/notification-list/notification-list.comp";
import React from "react";
import { NOTIFICATION } from "@/commons/consts/system/routes/shared-paths.const";
import { useHistory } from "@/components/commons/navigator";
import { useNotification, useReadNotification } from "@/entities/notification/lib/use-notification";

export const NotificationDropdown = () => {
  const history = useHistory();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const { notifications, isLoading, messageRead } = useNotification({ size: 5 });
  const { mutate: readMessage } = useReadNotification();
  const open = Boolean(anchorEl);
  const id = open ? "notification-list" : undefined;

  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleViewAll = () => {
    history.push(NOTIFICATION);
    handleClose();
  };
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    readMessage();
  };
  return (
    <>
      <IconButton color="inherit" onClick={handleClick} aria-describedby={id}>
        <Badge variant="dot" color="error" invisible={messageRead}>
          <NotificationIcon />
        </Badge>
      </IconButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right"
        }}
      >
        <Box sx={{ width: "335px", height: "360px", display: "flex", flexDirection: "column" }}>
          <Typography
            variant="h5"
            sx={{ margin: "6px 16px", fontWeight: "bold", fontSize: "18px" }}
          >
            Notifications
          </Typography>
          <Divider />
          <Box sx={{ flex: "1 1 0", height: 0, overflowX: "auto" }}>
            <Box sx={{ padding: "16px 0" }}>
              <NotificationList listData={notifications} isLoading={isLoading} size="small" />
            </Box>
          </Box>
          <Divider />
          <Link underline="none" onClick={handleViewAll}>
            <Typography sx={{ textAlign: "center", padding: "16px 0" }}>View All</Typography>
          </Link>
        </Box>
      </Popover>
    </>
  );
};
