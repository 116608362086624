import { DATE_FORMAT, HH_MM_FORMAT } from "@/commons/consts";
import React from "react";
import styles from "./styles.module.scss";
import moment from "moment-timezone";

export const TimePoint = ({ dateTime, children, type }) => {
  const indicatorClass =
    type === "major"
      ? "air__utils__donut air__utils__donut--danger mr-3"
      : `${styles["balloon-child"]} mr-3`;
  return (
    <li className={styles.item}>
      <time className={`${type === "major" ? styles.itemTime : styles.itemTimeChild} mr-3`}>
        <div className="text-nowrap">{moment(new Date(dateTime)).format(DATE_FORMAT)}</div>
        <div>{moment(new Date(dateTime)).format(HH_MM_FORMAT)}</div>
      </time>
      <div className={styles.itemSeparator}>
        <div className={indicatorClass} />
      </div>
      <div className={styles.content}>{children}</div>
    </li>
  );
};
