import React from "react";
import { Modal, Button } from "antd";
import { CheckCircleFilled } from "@ant-design/icons";
import clsx from "classnames";
import PropTypes from "prop-types";
import { getSupportEmail } from "@/utils/config.util";
import classes from "./complete-kyc-modal.module.scss";

export const CompleteKYCModalContent = ({ onConfirm }) => {
  const supportEmail = getSupportEmail();
  return (
    <div className={clsx(classes.content, "text-center")}>
      <span className={classes.iconChecked}>
        <CheckCircleFilled className={classes.iconChecked} />
      </span>
      <h5>Thank you for completing the KYC verification process</h5>

      <p className={classes.modalInfo}>
        We will inform you by email once we have successfully processed your KYC verification
        details.
        <br />
        This will typically be within 10-15 minutes. Sometime it may take up to 24 hours.
        <br />
        If you have not received any email confirmation by then please reach out to
        <a rel="noopener noreferrer" target="_blank" href={`mailto:${supportEmail}`}>
          {" "}
          {supportEmail}.
        </a>
      </p>
      <div>
        <Button style={{ marginTop: "27.5px" }} type="primary" onClick={onConfirm}>
          OK
        </Button>
      </div>
    </div>
  );
};
export const CompleteKYCModal = (modalVisible, onConfirm) => {
  return (
    <Modal
      visible={modalVisible}
      closable={false}
      bodyStyle={{ height: 350 }}
      centered
      footer={null}
      width={960}
    >
      <CompleteKYCModalContent onConfirm={onConfirm} />
    </Modal>
  );
};

export const openCompleteKYCModal = (modal, onClose) => {
  const instance = modal.info({
    maskClosable: false,
    keyboard: false,
    footer: null,
    icon: <></>,
    width: "80%",
    okButtonProps: { style: { display: "none" } },
    content: (
      <CompleteKYCModalContent
        onConfirm={() => {
          instance && instance.destroy();
          onClose();
        }}
      />
    )
  });
};

CompleteKYCModal.propTypes = {
  modalVisible: PropTypes.bool.isRequired,
  onConfirm: PropTypes.func.isRequired
};
