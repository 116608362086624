export const roundToHalfDecimal = (value) => {
  // ex: value: 1.4 => 1.5, 1.2 => 1.0
  if (value) {
    return Math.round(value * 2) / 2;
  }
  return 0;
};

export const roundToDecimalByNumber = (value, numberDecimal) => {
  // ex: value: 1.456; numberDecimal: 1 => 1.4
  if (value) {
    return value.toFixed(numberDecimal);
  }
  return 0;
};
