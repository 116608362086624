import React, { useCallback } from "react";
import { SmallAmountCard } from "./small-amount-card/small-amount-card.comp";
import { toCurrency } from "@/utils";

export const CardContainer = ({ className, cards, lastItemClassName = className }) => {
  const isLastItem = useCallback(
    (index) => {
      return index === Object.values(cards).length - 1;
    },
    [cards]
  );

  return Object.values(cards).map(
    ({ name, title, icon, valueIcon, value, func, description }, index) => (
      <div
        key={name}
        className={`${isLastItem(index) ? lastItemClassName : className} ${
          func && "cursor-pointer"
        }`}
        onClick={func}
        title={description}
      >
        <SmallAmountCard
          title={title}
          amount={
            <span>
              {toCurrency(value)} {valueIcon}
            </span>
          }
          icon={icon}
        />
      </div>
    )
  );
};
