import React from "react";
import { Form } from "@ant-design/compatible";
import "@ant-design/compatible/assets/index.css";
import { Input } from "antd";

class _InlineEditor extends React.PureComponent {
  constructor() {
    super();
    this.state = {
      isEdit: false,
      innerValue: undefined
    };
    this.node = React.createRef();
  }

  componentDidMount = () => {
    document.addEventListener("mousedown", this.handleClick);
  };

  componentWillUnmount = () => {
    document.removeEventListener("mousedown", this.handleClick);
  };

  handleClick = (e) => {
    const { innerValue, isEdit } = this.state;
    const { form } = this.props;

    if (this.node.current.contains(e.target)) {
      if (!isEdit) {
        this.setState({
          ...this.state,
          isEdit: true
        });
      }
      return;
    }
    if (isEdit) {
      form.validateFieldsAndScroll((error, values) => {
        const { onEdit } = this.props;
        const value = values["editable"];
        if (!error) {
          if (innerValue !== value && Number(value) !== 0) {
            onEdit(value);
          }
          this.setState({
            ...this.state,
            isEdit: false
          });
        }
      });
    }
  };

  handleEnterPress = (e) => {
    const { onEdit, form } = this.props;
    const { innerValue } = this.state;
    if (e.key === "Enter") {
      form.validateFieldsAndScroll((error, values) => {
        if (!error) {
          const value = values["editable"];
          if (innerValue !== value) {
            onEdit(value);
          }
          this.setState({
            ...this.state,
            isEdit: false
          });
        }
      });
    }
  };

  render = () => {
    const { children, rules, form } = this.props;
    const { isEdit, innerValue } = this.state;
    return (
      <div ref={this.node}>
        {isEdit ? (
          <Form onSubmit={this.handleSubmit}>
            <Form.Item>
              {form.getFieldDecorator("editable", {
                initialValue: innerValue,
                rules: rules
              })(<Input onKeyPress={this.handleEnterPress} />)}
            </Form.Item>
          </Form>
        ) : (
          <div
            onClick={() => {
              this.setState({
                ...this.state,
                isEdit: !isEdit
              });
            }}
          >
            {children}
          </div>
        )}
      </div>
    );
  };
}

export const InlineEditor = Form.create()(_InlineEditor);
