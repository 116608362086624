const FIELDS = {
  week: "7",
  month: "30",
  year: "365",
  id: "id",
  color: "color",
  name: "productName",
  quantity: "quantity",
  totalPrice: "totalPrice",
  percent: "percent",
  type: "type",
  category: "category"
};

const COLOR_FIELDS = {
  lime: "lime",
  aqua: "aqua",
  orange: "orange",
  red: "red",
  black: "black",
  yellow: "yellow",
  green: "green",
  blue: "blue",
  pink: "pink",
  gray: "gray"
};

const LABELS = {
  [FIELDS.week]: "Week",
  [FIELDS.month]: "Month",
  [FIELDS.year]: "Year",
  [FIELDS.quantity]: "Unit",
  [FIELDS.totalPrice]: "Value",
  [FIELDS.type]: "Product Type",
  [FIELDS.category]: "Product Category",
  [FIELDS.name]: "Product Name"
};

const MONTHS = {
  fullText: {
    Jan: "Jan",
    Feb: "Feb",
    Mar: "Mar",
    Apr: "Apr",
    May: "May",
    Jun: "Jun",
    Jul: "Jul",
    Aug: "Aug",
    Sep: "Sep",
    Oct: "Oct",
    Nov: "Nov",
    Dec: "Dec"
  },
  number: {
    Jan: "1",
    Feb: "2",
    Mar: "3",
    Apr: "4",
    May: "5",
    Jun: "6",
    Jul: "7",
    Aug: "8",
    Sep: "9",
    Oct: "10",
    Nov: "11",
    Dec: "12"
  }
};

const CHART_SCHEMA = {
  analysisSection: "Analysis Action",
  actionList: "Action List",
  numberOfProductTimeLine: "Number of products purchase Vs timeline",
  top10Items: "Top 10 purchase Items",
  wallet: "Wallet",
  overall: "Overall purchase",
  biddingArena: "Bidding arena",
  last30DaysByTop10Items: "Last 30 days purchase by top 10 items",
  processStatuses: "Process statuses"
};

export const DASHBOARD_SCHEMA = Object.freeze({
  FIELDS,
  LABELS,
  MONTHS,
  COLOR_FIELDS,
  CHART_SCHEMA
});
