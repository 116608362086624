import React from "react";
import { Input } from "antd";
import { useDebouncedCallback } from "hooks/useDebounceHook";

export const SearchBar = React.memo(
  ({ onSubmit, onTyping, classname = "w-50", renderRightAddon }) => {
    const [debouncedSearchStrCallBack] = useDebouncedCallback((value) => {
      onSubmit && onSubmit(value);
    }, 500);

    return (
      <div className="d-flex justify-content-center align-items-center w-100">
        <div className={classname}>
          <Input.Search
            onChange={(e) => {
              onTyping && onTyping();
              debouncedSearchStrCallBack(e.target.value);
            }}
            allowClear
            className="w-100"
            placeholder="Search for a product"
            addonAfter={renderRightAddon && renderRightAddon()}
          />
        </div>
      </div>
    );
  }
);
