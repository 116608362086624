import React from "react";
import { CountryField, PhoneField, TextField } from "@legacy/mui/components/commons/fields";
import Grid, { GridSize } from "@mui/material/Grid";
import { Form, Formik, FormikProps } from "formik";
import { Address } from "../lib/model";
import { addressValidationSchema } from "../lib/validation.schema";

interface Props {
  initialValues: Address;
  onSubmit: (value: Address) => void;
  itemGridSize?: { xs?: GridSize; sm?: GridSize; md?: GridSize; lg?: GridSize; xl?: GridSize };
}
export const AddressForm = React.forwardRef(
  ({ initialValues, onSubmit, itemGridSize }: Props, ref: React.Ref<FormikProps<Address>>) => {
    return (
      <Formik
        innerRef={ref}
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={addressValidationSchema}
      >
        <Form>
          <Grid container rowSpacing={4} columnSpacing={4}>
            <Grid item xs={12} {...itemGridSize}>
              <TextField
                fullWidth
                required
                name="addressLine1"
                label="Address Line 1"
                placeholder="Company Address Line 1"
              />
            </Grid>
            <Grid item xs={12} {...itemGridSize}>
              <CountryField required name="country" label="Country" placeholder="Country" />
            </Grid>
            <Grid item xs={12} {...itemGridSize}>
              <TextField fullWidth required name="city" label="City" placeholder="City" />
            </Grid>
            <Grid item xs={12} {...itemGridSize}>
              <TextField
                fullWidth
                name="state"
                label="State/Province/Territory"
                placeholder="State/Province/Territory"
              />
            </Grid>
            <Grid item xs={12} {...itemGridSize}>
              <TextField
                fullWidth
                name="postalCode"
                label="Postal/Zip Code or PO Box"
                placeholder="Postal/Zip Code or PO Box"
              />
            </Grid>
            <Grid item xs={12} {...itemGridSize}>
              <PhoneField
                countryFieldName="country"
                prefixeDisableClearable
                fullWidth
                name="phone"
                label="Phone Number"
                placeholder="Phone Number"
                required
              />
            </Grid>
            <Grid item xs={12} {...itemGridSize}>
              <TextField fullWidth required name="email" label="Email" placeholder="Email" />
            </Grid>
          </Grid>
        </Form>
      </Formik>
    );
  }
);
