import domtoimage from "dom-to-image";

const optionsDownload = (elm, scale) => {
  return {
    bgcolor: "#ffffff",
    height: elm.offsetHeight * scale,
    width: elm.offsetWidth * scale,
    style: {
      transform: "scale(" + scale + ")",
      transformOrigin: "top left",
      width: elm.offsetWidth + "px",
      height: elm.offsetHeight + "px"
    }
  };
};

export const getElementImage = (elm) => {
  const scale = 2;
  return domtoimage.toPng(elm, optionsDownload(elm, scale));
};

export const downloadElementImage = (elm, name) => {
  return getElementImage(elm).then((url) => {
    const link = document.createElement("a");
    link.download = name;
    link.href = url;
    link.click();
    link.remove();
    return new Promise((resolve: (value?: unknown) => void) => {
      resolve();
    });
  });
};
