export const DOCUMENT_KEYS = {
  NATIONAL_ID: "NATIONAL_ID",
  PASSPORT: "PASSPORT",
  DRIVER_LICENSE: "DRIVER_LICENSE"
};

export const DOCUMENT_TYPES = {
  NATIONAL_ID: "National ID",
  PASSPORT: "Passport",
  DRIVER_LICENSE: "Driving License"
};

const createDocumentSchema = (type, label, isRequired = false) => ({ type, label, isRequired });
export const DOCUMENT_SCHEMA = {
  [DOCUMENT_KEYS.NATIONAL_ID]: [
    createDocumentSchema("NATIONAL_ID_MAIN", "Front Image", true),
    createDocumentSchema("NATIONAL_ID_OPTIONAL", "Back Image", true)
  ],
  [DOCUMENT_KEYS.PASSPORT]: [
    createDocumentSchema("PASSPORT_MAIN", "Main Information Page", true),
    createDocumentSchema("PASSPORT_OPTIONAL", "Second Page", false)
  ],
  [DOCUMENT_KEYS.DRIVER_LICENSE]: [
    createDocumentSchema("DRIVER_LICENSE_MAIN", "Front Image", true),
    createDocumentSchema("DRIVER_LICENSE_OPTIONAL", "Back Image", true)
  ]
};
