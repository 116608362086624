import { Tooltip, Row, Col, Grid, Form, Input } from "antd";

import React from "react";
import { CheckOutlined, CloseOutlined, InfoCircleOutlined } from "@ant-design/icons";
import { SCHEMA, hasNationalIdNumber, getDocumentRules } from "./owner-info-form.schema";
import { DocumentUploader } from "components/utils";
import { CountrySelect } from "components/utils/form-items/country-select/country-select.comp";
import { WrappedSelect } from "components/utils/form-items/wrapped-select/wrapped-select.comp";
import { PhoneInput } from "components/utils/form-items/phone-input/phone-input.comp";
import { WrappedDatePicker } from "components/utils/form-items/wrapped-date-picker/wrapped-date-picker.comp";
import { TEXTS } from "@/commons/consts/texts.const";
import { DOCUMENT_KEYS, DOCUMENT_TYPES, DOCUMENT_SCHEMA } from "@/commons/consts/document.const";

const { useBreakpoint } = Grid;
const ItemLayout = ({ icon, children }) => {
  return (
    <div className="d-flex">
      <div>{icon}</div>
      <div className="flex-grow text-justify">{children}</div>
    </div>
  );
};
const ValidItem = ({ children }) => {
  return (
    <ItemLayout
      icon={<CheckOutlined style={{ color: "var(--success-color)", marginRight: "8px" }} />}
    >
      {children}
    </ItemLayout>
  );
};
const InvalidItem = ({ children }) => {
  return (
    <ItemLayout
      icon={<CloseOutlined style={{ color: "var(--error-color)", marginRight: "8px" }} />}
    >
      {children}
    </ItemLayout>
  );
};

const Tips = () => (
  <>
    <div style={{ marginBottom: "22px" }}>
      <strong>For a smoother experience, please use the Document Verification tips below :</strong>
    </div>
    <Row gutter={24}>
      <Col span={12}>
        <ValidItem>
          Use a clear picture of your ID document, where every single detail is readable
        </ValidItem>
        <InvalidItem>Black and White images are not acceptable</InvalidItem>
        <InvalidItem>Avoid flashes, glare, blur, and handwritten information</InvalidItem>
        <ValidItem>Use an upright image, no rotation</ValidItem>
      </Col>
      <Col span={12}>
        <ValidItem>ID image should cover at least 80% of the overall image</ValidItem>
        <ValidItem>The quality of the image scanned to be around 300 dpi</ValidItem>
        <ValidItem>Ensure all edges of the ID are visible</ValidItem>
        <ValidItem>The uploaded images must be in JPG/PNG format</ValidItem>
      </Col>
    </Row>
  </>
);

const SampleImage = () => {
  return (
    <img
      style={{ maxWidth: "218px", maxHeight: "236px", width: "100%", height: "100%" }}
      width="100%"
      height="100%"
      src={`${process.env.PUBLIC_URL}/images/kyc/document_example.png`}
      alt="example"
    />
  );
};

const {
  firstName: firstNameSchema,
  middleName: middleNameSchema,
  lastName: lastNameSchema,
  birthDate: birthDateSchema,
  gender: genderSchema,
  nationalIdNumber: nationalIdNumberSchema,
  nationality: nationalitySchema,
  issueDate: issueDateSchema,
  expiryDate: expiryDateSchema,
  issueCountry: issueCountrySchema,
  documentType: documentTypeSchema,
  documentNumber: documentNumberSchema,
  documents: documentsSchema,
  addressLine1: addressLine1Schema,
  city: citySchema,
  country: countrySchema,
  email: emailSchema,
  phone: phoneSchema,
  secondaryPhone: secondaryPhoneSchema,
  postalCode: postalCodeSchema,
  state: stateSchema
} = SCHEMA;

const DocumentSection = React.memo(({ uploadElement, disabled }) => {
  const screen = useBreakpoint();
  if (disabled) return <>{uploadElement}</>;
  if (!screen.md) {
    return (
      <>
        <Row>
          <Col span={12}>{uploadElement}</Col>
          <Col span={12}>
            <div className="mt-4">
              <SampleImage />
            </div>
          </Col>
        </Row>
        <div className="mt-3">
          <Tips />
        </div>
      </>
    );
  }
  return (
    <Row>
      <Col span={15}>
        {uploadElement}
        <Tips />
      </Col>
      <Col span={9}>
        <div className="pl-2 mt-4">
          <SampleImage />
        </div>
      </Col>
    </Row>
  );
});

const WrappedItem = (props) => {
  return (
    <Col md={12} span={24}>
      <Form.Item {...props} />
    </Col>
  );
};

const formatName = (name) => {
  if (!name) return [];
  if (Array.isArray(name)) {
    return name;
  }
  return [name];
};
export const OwnerInfoFormView = ({
  disabled,
  onDocumentTypeChange,
  onDocumentNumberChange,
  onNationalityChange
}) => {
  const getFieldName = React.useCallback((name) => {
    return [...formatName(name)];
  }, []);
  const parseSchema = (schema) => {
    const name = getFieldName(schema.name);
    const { label, rules } = schema;
    return { label, rules, name };
  };

  return (
    <>
      <Form.Item name="id" hidden>
        <Input />
      </Form.Item>
      <Row type="flex" gutter={24}>
        <WrappedItem {...parseSchema(firstNameSchema)}>
          <Input disabled={disabled} placeholder={firstNameSchema.placeholder} />
        </WrappedItem>
        <WrappedItem {...parseSchema(middleNameSchema)}>
          <Input disabled={disabled} placeholder={middleNameSchema.placeholder} />
        </WrappedItem>
        <WrappedItem {...parseSchema(lastNameSchema)}>
          <Input disabled={disabled} placeholder={lastNameSchema.placeholder} />
        </WrappedItem>
        <WrappedItem {...parseSchema(birthDateSchema)}>
          <WrappedDatePicker disabled={disabled} placeholder={birthDateSchema.placeholder} />
        </WrappedItem>
        <WrappedItem {...parseSchema(genderSchema)}>
          <WrappedSelect
            dataSource={genderSchema.dataSource}
            disabled={disabled}
            placeholder={genderSchema.placeholder}
          />
        </WrappedItem>
        <WrappedItem {...parseSchema(addressLine1Schema)}>
          <Input disabled={disabled} placeholder={addressLine1Schema.placeholder} />
        </WrappedItem>
        <WrappedItem {...parseSchema(citySchema)}>
          <Input disabled={disabled} placeholder={citySchema.placeholder} />
        </WrappedItem>
        <WrappedItem {...parseSchema(stateSchema)}>
          <Input disabled={disabled} placeholder={stateSchema.placeholder} />
        </WrappedItem>
        <WrappedItem {...parseSchema(countrySchema)}>
          <CountrySelect disabled={disabled} placeholder={countrySchema.placeholder} />
        </WrappedItem>
        <WrappedItem {...parseSchema(postalCodeSchema)}>
          <Input disabled={disabled} placeholder={postalCodeSchema.placeholder} />
        </WrappedItem>
        <WrappedItem {...parseSchema(nationalitySchema)}>
          <CountrySelect
            onChange={onNationalityChange}
            disabled={disabled}
            placeholder={nationalitySchema.placeholder}
          />
        </WrappedItem>
        <WrappedItem {...parseSchema(emailSchema)}>
          <Input disabled={disabled} placeholder={emailSchema.placeholder} />
        </WrappedItem>
        <WrappedItem dependencies={[countrySchema.name]} noStyle>
          {({ getFieldValue, getFieldError }) => {
            const selectedResidential = getFieldValue(countrySchema.name);
            const fieldErrors = getFieldError(phoneSchema.name);
            const validateTrigger =
              fieldErrors && fieldErrors.length > 0 ? "onChange" : "onInputChange";
            return (
              <Form.Item {...parseSchema(phoneSchema)} validateTrigger={validateTrigger}>
                <PhoneInput
                  country={selectedResidential}
                  dataSource={phoneSchema.prefixDataSource}
                  disabled={disabled}
                />
              </Form.Item>
            );
          }}
        </WrappedItem>
        <WrappedItem {...parseSchema(secondaryPhoneSchema)} validateTrigger="onInputChange">
          <PhoneInput disabled={disabled} />
        </WrappedItem>
        <Form.Item dependencies={[nationalitySchema.name, documentTypeSchema.name]} noStyle>
          {({ getFieldValue }) => {
            const currentNationality = getFieldValue(nationalitySchema.name);
            const documentType = getFieldValue(documentTypeSchema.name);
            const isVisible = hasNationalIdNumber(currentNationality);
            if (!isVisible) return <></>;
            return (
              <WrappedItem {...parseSchema(nationalIdNumberSchema)}>
                <Input
                  disabled={disabled || documentType === DOCUMENT_KEYS.NATIONAL_ID}
                  placeholder={nationalIdNumberSchema.placeholder}
                />
              </WrappedItem>
            );
          }}
        </Form.Item>
      </Row>
      <hr />
      <Row>
        <Col span={24}>
          <div className="d-flex">
            <h5 className="mr-2">ID VERIFICATION</h5>
            <Tooltip title={TEXTS.ownerIdVerificationHoverText}>
              <InfoCircleOutlined />
            </Tooltip>
          </div>
          <div className="sub__text">{TEXTS.ownerIdVerificationSubtitle}</div>
        </Col>
      </Row>
      <Row type="flex" className="mt-4" gutter={24}>
        <WrappedItem {...parseSchema(documentTypeSchema)}>
          <WrappedSelect
            dataSource={documentTypeSchema.dataSource}
            onChange={onDocumentTypeChange}
            disabled={disabled}
            placeholder={documentTypeSchema.placeholder}
          />
        </WrappedItem>
        <WrappedItem {...parseSchema(issueCountrySchema)}>
          <CountrySelect disabled={disabled} placeholder={issueCountrySchema.placeholder} />
        </WrappedItem>
        <WrappedItem shouldUpdate noStyle>
          {({ getFieldValue }) => {
            const documentType = getFieldValue(documentTypeSchema.name);
            const label = `${DOCUMENT_TYPES[documentType] ?? "Document"} Number`;
            const placeholder = `${DOCUMENT_TYPES[documentType] ?? "Document"} Number`;
            return (
              <Form.Item {...parseSchema(documentNumberSchema)} label={label} shouldUpdate>
                <Input
                  onChange={onDocumentNumberChange}
                  disabled={disabled}
                  placeholder={placeholder}
                />
              </Form.Item>
            );
          }}
        </WrappedItem>
        <WrappedItem {...parseSchema(issueDateSchema)}>
          <WrappedDatePicker disabled={disabled} placeholder={issueDateSchema.placeholder} />
        </WrappedItem>
        <WrappedItem {...parseSchema(expiryDateSchema)}>
          <WrappedDatePicker disabled={disabled} placeholder={expiryDateSchema.placeholder} />
        </WrappedItem>
      </Row>
      <div style={{ marginBottom: "64px" }}>
        <DocumentSection
          disabled={disabled}
          uploadElement={
            <Form.Item noStyle dependencies={[documentTypeSchema.name]}>
              {({ getFieldValue }) => {
                const documentType = getFieldValue(documentTypeSchema.name);
                const documentTypes = DOCUMENT_SCHEMA[documentType] ?? [];
                const documentRules = getDocumentRules(documentType);
                return (
                  <Form.Item {...parseSchema(documentsSchema)} rules={documentRules}>
                    <DocumentUploader disabled={disabled} documentTypes={documentTypes} />
                  </Form.Item>
                );
              }}
            </Form.Item>
          }
        />
      </div>
    </>
  );
};
