import { loadable } from "@/utils/loadable.util";
import { ROUTES } from "@/commons/consts";

const { ADMIN_PATHS } = ROUTES;

export const legacyProductRoutes = [
  {
    key: "legacy-product-route",
    path: ADMIN_PATHS.LEGACY_PRODUCT_ROUTE,
    Component: loadable(() => import("@/pages/product-database/product-database.page"))
  },
  {
    key: "legacy-add-product",
    path: ADMIN_PATHS.LEGACY_ADD_PRODUCT_ROUTE,
    Component: loadable(() => import("@/pages/add-product/add-product.page"))
  },
  {
    key: "legacy-edit-product",
    path: ADMIN_PATHS.LEGACY_EDIT_PRODUCT_ROUTE,
    Component: loadable(() => import("@/pages/edit-product/edit-product.page"))
  }
];
