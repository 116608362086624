import React from "react";
import { OutLinedIcon } from "@/components/commons";

function Order(props) {
  return (
    <OutLinedIcon {...props}>
      <path
        d="M12.0002 2L2.00017 7L12.0002 12L22.0002 7L12.0002 2Z"
        stroke="currentColor"
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.00017 17L12.0002 22L22.0002 17"
        stroke="currentColor"
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.00017 12L12.0002 17L22.0002 12"
        stroke="currentColor"
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </OutLinedIcon>
  );
}

export default Order;
