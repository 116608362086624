import { Loader } from "@/components/commons";
import { useUserProfile } from "@/entities/user-profile/libs/use-user-profile";
import { ONBOARDING_ROUTE } from "@/commons/consts/system/routes";
import { Redirect } from "@/components/commons/navigator";
import { COMPANY_TYPE, ROUTES } from "@/commons/consts";

const preferedPathMap = {
  [COMPANY_TYPE.CCC_BUYER]: ROUTES.BUYER_PREFIX,
  [COMPANY_TYPE.CCC_SELLER]: ROUTES.SELLER_PREFIX,
  [COMPANY_TYPE.CCC_DUAL]: ROUTES.BUYER_PREFIX,
  [COMPANY_TYPE.PCC_DUAL]: ROUTES.ADMIN_PREFIX
};

const HomePage = () => {
  const { user, isLoading } = useUserProfile();

  if (isLoading) return <Loader />;
  const preferPath = preferedPathMap[user?.companyType];
  if (preferPath) return <Redirect to={preferPath} />;
  if (!user?.companyType) return <Redirect to={ONBOARDING_ROUTE} />;
  return <></>;
};

export default HomePage;
