import { persistentQueryOptions } from "@/commons/options/react-query.option";
import { getCompanyAddresses } from "@/services/bank-services";

import { useQuery, useQueryClient } from "react-query";

const QUERY_KEY = "company-address";
export const useGetCompanyAddresses = () => {
  const { data, ...others } = useQuery([QUERY_KEY], getCompanyAddresses, {
    ...persistentQueryOptions
  });
  const addresses = data ?? [];
  return {
    addresses,
    ...others
  };
};

export const useInvalidateAddress = () => {
  const queryClient = useQueryClient();
  return () => queryClient.invalidateQueries(QUERY_KEY);
};
