import { persistentQueryOptions } from "@/commons/options/react-query.option";
import { useMutation, useQuery, useQueryClient } from "react-query";

import { parseFormValues } from "./mapper";
import { getUserProfile, updateProfile, UserProfile } from "@/services/user-profile.service";

const tryGetUserProfile = async () => {
  try {
    return await getUserProfile();
  } catch (e) {
    if (e?.response.status === 400) return {};
    throw e;
  }
};
export const useUserProfile = (
  options = {
    enabled: true
  }
) => {
  const { data, ...others } = useQuery(["me"], tryGetUserProfile, {
    enabled: false,
    ...options,
    ...persistentQueryOptions
  });
  const user: Partial<UserProfile> = data || {};
  return { data, user, ...others };
};

export const useInvalidateUserProfiles = () => {
  const queryClient = useQueryClient();
  return () => queryClient.invalidateQueries(["me"]);
};

const updatePhone = (values) => updateProfile(parseFormValues(values));
export const useUpdateProfile = ({ onSuccess }) => {
  const invalidate = useInvalidateUserProfiles();
  const { mutate, isLoading } = useMutation(updatePhone, {
    onSuccess: () => {
      invalidate();
      if (onSuccess) onSuccess();
    }
  });
  return { mutate, isLoading };
};
