import { INVENTORY } from "./shared-paths.const";
import { cleanAndParseString } from "@/utils/string.util";
import { BUYER_PREFIX } from "./common.route";

// BUYER ROUTES
export const BUYER_DASHBOARD_ROUTE = `${BUYER_PREFIX}/dashboard`;
export const BUYER_INVENTORY_ROUTE = `${BUYER_PREFIX}${INVENTORY}`;
export const BUYER_INVENTORY_EXPLORE = `${BUYER_PREFIX}/explore`;
export const BUYER_ITEM_SEARCH_ROUTE = `${BUYER_PREFIX}/item-search`;
export const BUYER_PRODUCT_CREATION_ROUTE = `${BUYER_PREFIX}/product-creation`;
export const BUYER_BIDDING_ARENA = `${BUYER_PREFIX}/bidding-arena`;
export const BUYER_BID_NEGOTIATION = `${BUYER_PREFIX}/bid-negotiation`;

export const BUYER_SUB_LIST = `${BUYER_PREFIX}/subscriptions-list`;
export const BUYER_MARKETPLACE_ROUTE = `${BUYER_PREFIX}/marketplace`;

export const BUYER_ORDER_MANAGEMENT = `${BUYER_PREFIX}/order-management`;
export const BUYER_CANCELLED_ORDER = `${BUYER_PREFIX}/cancelled-orders`;
export const BUYER_OFFER_APPROVED = `${BUYER_ORDER_MANAGEMENT}/offer-approved`;
export const BUYER_SERIAL_NUMBER = `${BUYER_ORDER_MANAGEMENT}/:orderNumber/serial-number`;
// export const BUYER_REVIEW_ORDER = `${BUYER_ORDER_MANAGEMENT}/:orderNumber/review`;
export const BUYER_ORDER_TRACK_AND_TRACE = `${BUYER_ORDER_MANAGEMENT}/:orderNumber/track-and-trace`;
export const BUYER_CHOOSE_LOGISTICS_PROVIDER = `${BUYER_ORDER_MANAGEMENT}/:orderNumber/choose-logistics-provider`;
export const BUYER_SHIPPING_DETAILS = `${BUYER_ORDER_MANAGEMENT}/shipping-details`;

export const BUYER_ACCOUNT_SUMMARY = `${BUYER_PREFIX}/account-summary`;
export const BUYER_DOCUMENT_MANAGEMENT = `${BUYER_ORDER_MANAGEMENT}/:orderNumber/document-management`;
export const BUYER_DOCUMENT_MANAGEMENT_DETAILS = `${BUYER_DOCUMENT_MANAGEMENT}/:docId`;
export const BUYER_PENDING_APPROVAL_POST_PRODUCT = `${BUYER_PREFIX}/pending-approval/post-product`;
export const BUYER_APPROVAL_ROUTE = `${BUYER_PREFIX}/approval`;
export const BUYER_APPROVAL_HISTORY_POST_PRODUCT = `${BUYER_PREFIX}/approval-history/post-product`;
export const BUYER_ORDER_TRACK_SHIPMENT = `${BUYER_ORDER_MANAGEMENT}/:orderNumber/track-shipment`;
export const BUYER_TRACK_ORDERS = `${BUYER_PREFIX}/track-orders`;
export const BUYER_TRACK_ORDERS_DETAILS = `${BUYER_PREFIX}/track-orders/:orderNumber/details`;
export const BUYER_TRACK_SHIPMENT = `${BUYER_PREFIX}/track-shipment`;
export const BUYER_TRACK_SHIPMENT_DETAILS = `${BUYER_PREFIX}/track-shipment/:orderNumber/details`;
export const BUYER_WALLET = `${BUYER_PREFIX}/wallet`;
export const BUYER_ADD_FUNDS = `${BUYER_PREFIX}/add-funds`;
export const BUYER_BID_ON_MORE_PRODUCT = `${BUYER_PREFIX}/explore/bid-on-more-product`;

export const buyerRouteBuilder = Object.freeze({
  buildFirstBidRoute: (productName, productId, saleId) => {
    const parsedProductName = cleanAndParseString(productName);
    return `${BUYER_INVENTORY_EXPLORE}/${parsedProductName}--${productId}/bid?sid=${saleId}`;
  },
  buildExploreRoute: (productName, productId) => {
    const parsedProductName = cleanAndParseString(productName);
    return `${BUYER_INVENTORY_EXPLORE}/${parsedProductName}--${productId}`;
  }
});
