import React from "react";
import { Form } from "@ant-design/compatible";
import "@ant-design/compatible/assets/index.css";
import { getDateFormat } from "@/utils/config.util";
import { WrappedDatePicker } from "../form-items/wrapped-date-picker/wrapped-date-picker.comp";

export const DatePickerField = ({ form, initialValue, schema, innerProps }) => {
  const { name, label, options } = schema;
  return (
    <Form.Item label={label}>
      {form.getFieldDecorator(name, {
        ...options,
        initialValue
      })(<WrappedDatePicker format={getDateFormat()} {...innerProps} />)}
    </Form.Item>
  );
};
