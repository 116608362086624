import { WRONG_VERIFICATION_CODE } from "@/commons/consts/system/errors.const";
import { useMessage } from "@/hooks/use-message";
import { useInvalidateUserProfiles, useUserProfile } from "../../libs/use-user-profile";
import { createOTP, validateOTP } from "@/services/user-profile.service";
import { usePhoneVerifier } from "../../libs/use-phone-verifier";
import React from "react";

interface Parameter {
  onSuccess?: () => void;
}
export const useNewPhoneVerifier = (props?: Parameter) => {
  const [open, setOpen] = React.useState(false);
  const { user } = useUserProfile();
  const invalidate = useInvalidateUserProfiles();
  const message = useMessage();
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  const onReady = () => {};
  const onError = () => {
    message.error(WRONG_VERIFICATION_CODE);
  };
  const verifier = usePhoneVerifier({
    onReady,
    onError,
    validateFn: validateOTP,
    requestVerifyFn: createOTP,
    onSuccess: () => {
      invalidate();
      if (props?.onSuccess) props?.onSuccess();
    }
  });

  const startVerifyingPhone = () => {
    setOpen(true);
    if (user.phone) {
      verifier.startVerify();
    }
  };

  const close = () => {
    verifier.reset();
    setOpen(false);
  };

  return {
    open,
    close,

    isVerifyingPhone: verifier.isVerifying,
    isSubmitting: verifier.isSubmitting,

    startVerifyingPhone,
    verifyPhone: verifier.verify
  };
};
