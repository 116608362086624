import { ROUTES } from "@/commons/consts";
import CorporateFareRoundedIcon from "@mui/icons-material/CorporateFareRounded";
import OrderIcon from "@/components/icons/order.comp";
import TradersIcon from "@/components/icons/traders.comp";
import TradeRouteIcon from "@/components/icons/trade-route.comp";
import FinancialsIcon from "@/components/icons/financials.comp";
import DashboardIcon from "@/components/icons/dashboard.comp";
import PreferencesIcon from "@/components/icons/preferences.comp";
import SettingIcon from "@/components/icons/setting.comp";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import GroupIcon from "@mui/icons-material/Group";
import ProductIcon from "@/components/icons/product.comp";
import { pimMenu } from "@/pages/pim/routes";
import Flags, { FlagMode } from "@/commons/consts/flags.const";

const menuData = [
  {
    title: "Dashboard",
    key: "Dashboard",
    icon: DashboardIcon,
    url: ROUTES.DASHBOARD
  },
  {
    title: "Orders",
    key: "Orders",
    icon: OrderIcon,
    url: ROUTES.ORDERS
  },
  {
    title: "Traders",
    key: "Traders",
    icon: TradersIcon,
    url: ROUTES.TRADERS
  },
  {
    title: "Trade Routes",
    key: "RoutesMain",
    icon: TradeRouteIcon,
    children: [
      {
        title: "Trade Routes",
        key: "TradeRoutes",
        url: ROUTES.TRADE_ROUTES
      },
      {
        title: "Trade Routes Creation",
        key: "RouteCreation",
        url: ROUTES.CREATE_TRADE_ROUTES
      },
      {
        title: "Documents",
        key: "Document",
        url: ROUTES.DOCUMENT
      }
    ]
  },
  {
    title: "Product Template",
    key: "legacy-product-template",
    icon: ProductIcon,
    flag: Flags.pim,
    flagMode: FlagMode.EXCLUDE,
    children: [
      {
        title: "Product Template Database",
        key: "product-database",
        url: ROUTES.LEGACY_PRODUCT_ROUTE
      },
      {
        title: "Product Template Creation",
        key: "add-product-database",
        url: ROUTES.LEGACY_ADD_PRODUCT_ROUTE
      }
    ]
  },
  pimMenu,
  {
    title: "Financials",
    key: "Financials",
    icon: FinancialsIcon,
    children: [
      {
        title: "Wallet",
        key: "Wallet",
        url: ROUTES.WALLET
      },
      {
        title: "Account Summary",
        key: "accountSummary",
        url: ROUTES.ACCOUNT_SUMMARY
      },
      {
        title: "Withdraw Fund",
        key: "withdrawFund",
        url: ROUTES.WITHDRAW_FUND
      },
      {
        title: "Add Fund",
        key: "Add Fund",
        url: ROUTES.ADD_FUNDS
      }
    ]
  },
  {
    title: "Settings",
    key: "Settings",
    icon: SettingIcon,
    url: ROUTES.ORGANIZATION_PROFILE
  }
];

const menuSettingsData = [
  {
    title: "Organization Profile",
    key: "Organization Profile",
    icon: CorporateFareRoundedIcon,
    url: ROUTES.ORGANIZATION_PROFILE
  },
  {
    title: "Bank Accounts",
    icon: MonetizationOnIcon,
    key: ROUTES.BANK_HOME,
    url: ROUTES.BANK_HOME
  },
  {
    title: "Users",
    icon: GroupIcon,
    key: ROUTES.ADMIN_USER_MANAGEMENT_ROUTE,
    url: ROUTES.ADMIN_USER_MANAGEMENT_ROUTE
  },
  {
    title: "Preferences",
    key: "Preferences",
    icon: PreferencesIcon,
    url: ROUTES.PREFERENCES_GENERAL_PAGES
  }
];
const menuPreferencesData = [
  {
    title: "General",
    key: ROUTES.PREFERENCES_GENERAL_PAGES,
    url: ROUTES.PREFERENCES_GENERAL_PAGES
  },
  {
    title: "Branding",
    key: ROUTES.PREFERENCES_BRANDING_PAGES,
    url: ROUTES.PREFERENCES_BRANDING_PAGES
  }
];

export const getMenuData = () => menuData;

export const getMenuSettingsData = () => menuSettingsData;

export const getMenuPreferencesData = () => menuPreferencesData;
