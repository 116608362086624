import React from "react";
import { Tooltip } from "antd";
import { MultiFormContext } from "components/utils";
import { InfoCircleOutlined } from "@ant-design/icons";
import { TEXTS } from "@/commons/consts/texts.const";

export const PartnerHeader = () => {
  const { formArray } = React.useContext(MultiFormContext);
  if (formArray.length === 0) return <></>;
  return (
    <div className="d-flex">
      <h5 className="text-uppercase mr-2">Partner</h5>
      <Tooltip title={TEXTS.partnerInformationHoverText}>
        <InfoCircleOutlined />
      </Tooltip>
    </div>
  );
};
