import { useMessage } from "@/hooks/use-message";
import { getOrganization, updateOrganization } from "@/services/preference.service";
import { useMutation, useQuery, useQueryClient } from "react-query";

const QUERY_KEY = "organizations";

export const useGetOrganization = () => {
  const { data, ...rest } = useQuery([QUERY_KEY], getOrganization);
  return {
    organization: data ?? {},
    ...rest
  };
};

export const useInvalidateOrganization = () => {
  const client = useQueryClient();
  return () => client.invalidateQueries(QUERY_KEY);
};

export const useUpdateOrganization = () => {
  const message = useMessage();
  const invalidate = useInvalidateOrganization();
  return useMutation(updateOrganization, {
    onSuccess: async () => {
      await invalidate();
    },
    onError: () => {
      message.error("Your branding is failed.");
    }
  });
};
