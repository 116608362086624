import dayjs from "dayjs";
import isBetween from "dayjs/plugin/isBetween";
import relativeTime from "dayjs/plugin/relativeTime";
import utc from "dayjs/plugin/utc";
import { DATETIME_FORMAT, DATE_FORMAT } from "@/commons/consts/general.const";
import moment from "moment-timezone";
import { getDateFormat, getDateTimeFormat } from "./config.util";

dayjs.extend(isBetween);
dayjs.extend(relativeTime);
dayjs.extend(utc);

export class DatetimeUtils {
  static isBetweenTwoDate = (currentDate, firstDate, lastDate) => {
    return dayjs(currentDate).isBetween(firstDate, lastDate);
  };

  static subtractDateTime = (currentDate, number, type) => {
    return dayjs(currentDate).subtract(number, type).format();
  };

  static formatDate = (value) => {
    return dayjs(value).format(DATE_FORMAT);
  };

  static formatDateTime = (value, format = DATETIME_FORMAT) => {
    if (dayjs(value, DATETIME_FORMAT).isValid()) {
      return value;
    }

    return dayjs(value).format(format);
  };

  static fromNow = (value) => {
    return dayjs.utc(value).fromNow();
  };

  static minutesTotime = (minutes = 0, getTimeString = false) => {
    let h = (minutes / 60) | 0,
      m = minutes % 60 | 0;
    if (getTimeString) {
      const hString = h < 10 ? `0${h}` : h;
      const mString = m < 10 ? `0${m}` : m;
      return `${hString}:${mString}`;
    }
    return {
      h: h,
      m: m
    };
  };
}

export const datetimeUtil = {
  formatLocalDatetime: (value, timezone, format = DATETIME_FORMAT) => {
    return moment.tz(moment.utc(value, format), moment.tz.guess()).format(format);
  },
  formatDate: (value) => {
    return moment(value, DATETIME_FORMAT).format(getDateFormat());
  },
  formatDatetime: (value) => {
    return moment(value, DATETIME_FORMAT).format(getDateTimeFormat());
  },
  formatServerDateTime: (value) => {
    return moment(value, getDateFormat()).format("YYYY-MM-DDTHH:mm:ss");
  },
  getTimezonesForCountry: (alpha2Code) => {
    if (alpha2Code === undefined) {
      return [];
    }
    return moment.tz.zonesForCountry(alpha2Code);
  },

  minutesToTime: (minutes = 0, getTimeString = false) => {
    let h = (minutes / 60) | 0,
      m = minutes % 60 | 0;
    if (getTimeString) {
      const hString = h < 10 ? `0${h}` : h;
      const mString = m < 10 ? `0${m}` : m;
      return `${hString}:${mString}`;
    }
    return {
      h: h,
      m: m
    };
  },

  isBetweenTwoDate: (current, start, end) => {
    if (moment(start).isSameOrBefore(current) && moment(current).isBefore(end)) {
      return true;
    }
    return false;
  },

  sortByTime:
    (fieldName = undefined) =>
    (value1, value2) => {
      if (fieldName === undefined) {
        return moment(value1) - moment(value2);
      } else {
        return moment(value1[fieldName]) - moment(value2[fieldName]);
      }
    },
  sortByTimeAscending:
    (fieldName = undefined) =>
    (value1, value2) => {
      if (fieldName === undefined) {
        return moment(value2) - moment(value1);
      } else {
        return moment(value2[fieldName]) - moment(value1[fieldName]);
      }
    },
  fromNow: (value) => {
    return moment.utc(value).fromNow();
  }
};

const { minutesToTime } = datetimeUtil;

export const setTimeForDayByMinute = (mins, day) => {
  let obj = minutesToTime(mins);
  return moment(day).hours(obj.h).minutes(obj.m);
};
