import React from "react";
import { SelectField } from "./select-field.comp";
import countryList from "assets/country.json";
const dataSource = countryList.map((country) => ({
  value: country.alpha2Code,
  label: country.name
}));
export const CountrySelectField = ({ form, initialValue, schema, innerProps }) => {
  return (
    <SelectField
      form={form}
      initialValue={initialValue}
      innerProps={innerProps}
      schema={{ ...schema, dataSource }}
    />
  );
};
