import { Box, ListItemButton, ListItemIcon, ListItemText, Typography } from "@mui/material";

import { DatetimeUtils } from "@/utils/date-time.util";
import React from "react";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";

const Content = ({ message, createdDate }) => {
  return (
    <Box sx={{ wordBreak: "break-word" }}>
      <Typography variant="body2">{message}</Typography>
      <Typography variant="caption">{DatetimeUtils.fromNow(createdDate)}</Typography>
    </Box>
  );
};

export const NotificationItem = React.memo(({ data, onClick, size }) => {
  return (
    <ListItemButton onClick={onClick} sx={{ alignItems: "flex-start" }}>
      <ListItemIcon sx={{ paddingTop: "8px", color: (theme) => theme.palette.primary.main }}>
        <ShoppingCartIcon />
      </ListItemIcon>
      <ListItemText primary={<Content {...data} size={size} />} />
    </ListItemButton>
  );
});
