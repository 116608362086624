import logoPlaceholder from "@/assets/images/logo-placeholder.png";
import faviconPlaceholder from "@/assets/images/favicon-placeholder.png";
import { getBrandingAsset } from "@/services/marketplace.service";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { persistentQueryOptions } from "@/commons/options/react-query.option";
import { deleteAssetResource, updateAssetResource } from "@/services/preference.service";
import { useMessage } from "@/hooks/use-message";

const tryGetBrandingAsset = async (type: string) => {
  try {
    return await getBrandingAsset(type);
  } catch (err) {
    if (err.response.status === 400) {
      return {
        url: undefined
      };
    }
    throw err;
  }
};
const getAllAssets = async () => {
  const getLogo = tryGetBrandingAsset("LOGO");
  const getFavicon = tryGetBrandingAsset("FAVICON");
  const [logoRes, faviconRes] = await Promise.all([getLogo, getFavicon]);
  return {
    LOGO: logoRes.url,
    FAVICON: faviconRes.url
  };
};

export const useBrandingAssets = () => {
  const { data, ...others } = useQuery(["assets"], getAllAssets, {
    ...persistentQueryOptions
  });
  const brandingAssets = {
    LOGO: data?.LOGO ?? logoPlaceholder,
    FAVICON: data?.FAVICON ?? faviconPlaceholder
  };
  const isUnset = {
    LOGO: Boolean(!data?.LOGO),
    FAVICON: Boolean(!data?.FAVICON)
  };
  return {
    brandingAssets,
    isUnset,
    ...others
  };
};

export const useInvalidateBrandingAsset = () => {
  const client = useQueryClient();
  return () => client.invalidateQueries(["assets"]);
};

export const useDeleteBrandingAsset = ({ successMessage }: { successMessage: string }) => {
  const message = useMessage();
  const invalidate = useInvalidateBrandingAsset();
  return useMutation(deleteAssetResource, {
    onSuccess: async () => {
      await invalidate();
      message.success(successMessage);
    },
    onError: () => {
      message.error("Your branding is failed.");
    }
  });
};

export const useUpdateBrandingAsset = ({ successMessage }: { successMessage: string }) => {
  const message = useMessage();
  const invalidate = useInvalidateBrandingAsset();
  return useMutation(
    ({ type, formData }: { type: string; formData: FormData }) =>
      updateAssetResource(type, formData),
    {
      onSuccess: async () => {
        await invalidate();
        message.success(successMessage);
      },
      onError: () => {
        message.error("Your branding is failed.");
      }
    }
  );
};
