import { UserGuard } from "@/apps/access/user-guard.comp";
import { AuthThemeLayout } from "@/processes/antd-theme-layout/ant-theme.layout";
import { Box, Stack } from "@mui/material";

import { AdminAppBar } from "./admin-app-bar.comp";

export const AdminAuthLayout = ({ children }) => {
  return (
    <UserGuard>
      <AuthThemeLayout theme={{ name: "admin" }}>
        <Stack sx={{ height: "100%", color: "common.black" }}>
          <AdminAppBar />
          <Box flexGrow={1} overflow="hidden">
            {children}
          </Box>
        </Stack>
      </AuthThemeLayout>
    </UserGuard>
  );
};
