import { ROUTES } from "@/commons/consts";
import { loadable } from "@/utils/loadable.util";
import { SELLER_PREFIX } from "@/commons/consts/system/routes";

export const sellerRoutes = [
  {
    key: "seller-dashboard",
    path: ROUTES.SELLER_DASHBOARD_ROUTE,
    Component: loadable(() => import("pages/seller/seller-dashboard/seller-dashboard.page")),
    exact: true
  },
  {
    key: "seller-inventory",
    path: ROUTES.SELLER_INVENTORY_ROUTE,
    Component: loadable(() => import("pages/seller/seller-inventory/seller-inventory.page")),
    exact: true
  },
  {
    key: "seller-notification",
    path: ROUTES.NOTIFICATION,
    Component: loadable(() => import("@/pages/notification/notification.page")),
    exact: true
  },
  {
    key: "seller-review-and-sell",
    path: `${ROUTES.SELLER_INVENTORY_REVIEW_AND_SELL}/:id`,
    Component: loadable(
      () => import("pages/seller/seller-review-and-sell/seller-review-and-sell.page")
    ),
    exact: true
  },
  {
    key: "seller-item-listing",
    path: `${ROUTES.LEGACY_SELLER_ITEM_LISTING}`,
    Component: loadable(() => import("pages/seller/seller-item-listing/seller-item-listing.page")),
    exact: true
  },
  {
    key: "seller-order-management",
    path: `${ROUTES.SELLER_ORDER_MANAGEMENT}`,
    Component: loadable(
      () => import("pages/seller/seller-order-management/seller-order-management.page")
    ),
    exact: true
  },
  {
    key: "seller-cancelled-order",
    path: `${ROUTES.SELLER_CANCELLED_ORDER}`,
    Component: loadable(
      () => import("pages/seller/seller-cancelled-order/seller-cancelled-order.page")
    ),
    exact: true
  },
  {
    key: "seller-bidding-arena",
    path: `${ROUTES.SELLER_BIDDING_ARENA}`,
    Component: loadable(
      () => import("pages/seller/seller-bidding-arena/seller-bidding-arena.page")
    ),
    exact: true
  },
  {
    key: "seller-bid-details",
    path: `${ROUTES.SELLER_BID_DETAILS}/:id`,
    Component: loadable(() => import("pages/seller/seller-bid-details/seller-bid-details.page")),
    exact: true
  },
  {
    key: "seller-bid-negotiation",
    path: `${ROUTES.SELLER_BID_NEGOTIATION}/:id`,
    Component: loadable(
      () => import("pages/seller/seller-bid-negotiation/seller-bid-negotiation.page")
    ),
    exact: true
  },
  {
    key: "seller-offer-approved",
    path: `${ROUTES.SELLER_OFFER_APPROVED}/:id`,
    Component: loadable(
      () => import("pages/seller/seller-offer-approved/seller-offer-approved.page")
    ),
    exact: true
  },
  {
    key: "seller-account-summary",
    path: `${ROUTES.SELLER_ACCOUNT_SUMMARY}`,
    Component: loadable(
      () => import("pages/seller/seller-account-summary/seller-account-summary.page")
    ),
    exact: true
  },
  {
    key: "seller-marketplace-route",
    path: `${ROUTES.SELLER_MARKETPLACE_ROUTE}`,
    Component: loadable(() => import("pages/seller/seller-marketplace/seller-marketplace.page")),
    exact: true
  },
  {
    key: "seller-marketplace-expore",
    path: `${ROUTES.SELLER_MARKETPLACE_EXPLORE_ROUTE}/:id`,
    Component: loadable(() => import("pages/seller/seller-explore/seller-explore.page")),
    exact: true
  },
  {
    key: "seller-document-mangement-details",
    path: `${ROUTES.SELLER_DOCUMENT_MANAGEMENT_DETAILS}`,
    Component: loadable(
      () => import("pages/seller/seller-doc-mgt-details/seller-doc-mgt-details.page")
    ),
    exact: true
  },
  {
    path: `${ROUTES.SELLER_DOCUMENT_MANAGEMENT}`,
    Component: loadable(
      () => import("pages/seller/seller-document-management/seller-document-management.page")
    ),
    exact: true
  },
  {
    path: `${ROUTES.SELLER_PENDING_APPROVAL_POST_PRODUCT}/:id`,
    Component: loadable(
      () =>
        import(
          "pages/seller/seller-pending-approval-post-product/seller-pending-approval-post-product.page"
        )
    ),
    exact: true
  },
  {
    path: `${ROUTES.SELLER_APPROVAL_ROUTE}`,
    Component: loadable(() => import("pages/seller/seller-approval/seller-approval.page")),
    exact: true
  },
  {
    path: `${ROUTES.SELLER_APPROVAL_HISTORY_POST_PRODUCT}/:id`,
    Component: loadable(
      () =>
        import(
          "pages/seller/seller-approval-history-post-product/seller-approval-history-post-product.page"
        )
    ),
    exact: true
  },
  {
    path: `${ROUTES.SELLER_ORDER_TRACK_AND_TRACE}`,
    Component: loadable(
      () => import("pages/seller/seller-order-track-and-trace/seller-order-track-and-trace.page")
    ),
    exact: true
  },
  {
    path: `${ROUTES.SELLER_PROVIDE_SHIPPING_DETAILS}`,
    Component: loadable(
      () => import("pages/seller/seller-shipping-details/seller-shipping-details.page")
    ),
    exact: true
  },
  {
    path: `${ROUTES.SELLER_ORDER_TRACK_SHIPMENT}`,
    Component: loadable(
      () => import("pages/seller/seller-order-track-shipment/seller-order-track-shipment.page")
    ),
    exact: true
  },
  {
    path: `${ROUTES.SELLER_TRACK_ORDERS}`,
    Component: loadable(() => import("pages/seller/seller-track-orders/seller-track-orders.page")),
    exact: true
  },
  {
    path: `${ROUTES.SELLER_TRACK_ORDER_DETAILS}`,
    Component: loadable(
      () => import("pages/seller/seller-track-order-details/seller-track-order-details.page")
    ),
    exact: true
  },
  {
    path: `${ROUTES.SELLER_TRACK_SHIPMENT}`,
    Component: loadable(
      () => import("pages/seller/seller-track-shipment/seller-track-shipment.page")
    ),
    exact: true
  },
  {
    path: `${ROUTES.SELLER_TRACK_SHIPMENT_DETAILS}`,
    Component: loadable(
      () => import("pages/seller/seller-track-shipment-details/seller-track-shipment-details.page")
    ),
    exact: true
  },
  {
    path: `${ROUTES.SELLER_WALLET}`,
    Component: loadable(() => import("pages/seller/seller-wallet/seller-wallet.page")),
    exact: true
  },
  {
    path: `${ROUTES.SELLER_ADD_FUNDS}`,
    Component: loadable(() => import("pages/seller/seller-add-funds/seller-add-funds.page")),
    exact: true
  },
  {
    path: `${ROUTES.SELLER_CONFIRM_ADDITIONAL_DOCUMENT}/:id`,
    Component: loadable(
      () => import("pages/seller/seller-confirm-additional-doc/seller-confirm-additional-doc.page")
    ),
    exact: true
  },
  {
    path: `${ROUTES.SELLER_BULK_UPLOAD_TEMPLATES}`,
    Component: loadable(
      () => import("pages/seller/seller-bulk-catalog-upload/seller-bulk-catalog-upload.page")
    ),
    exact: true
  },
  {
    path: SELLER_PREFIX,
    Component: loadable(() => import("pages/seller/seller-home/seller-home.page")),
    exact: true
  }
];
