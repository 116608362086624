import { generatePath } from "@/components/commons/navigator";

import { ROUTES } from "@/commons/consts";

interface PathParams {
  id: string;
  prefix: string;
}

export const getBankDetailPath = ({ id, prefix }: PathParams) => {
  return generatePath(ROUTES.BANK_DETAIL, { id, prefix });
};
export const getBankEditPath = ({ id, prefix }) => {
  return generatePath(ROUTES.BANK_EDIT, { id, prefix });
};
