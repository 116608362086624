import Alert, { AlertColor } from "@mui/material/Alert";
import Box from "@mui/material/Box";
import { OptionsObject, useSnackbar } from "notistack";
import React from "react";

interface MessageContentProps {
  content: React.ReactNode;
  onClose: () => void;
  variant: AlertColor;
  width?: number;
}

export const MessageContent = ({ content, onClose, variant, width = 400 }: MessageContentProps) => {
  return (
    <Alert onClose={onClose} severity={variant}>
      <Box
        width={width}
        maxWidth="100vw"
        display="flex"
        alignItems="center"
        justifyContent="space-between"
      >
        {content}
      </Box>
    </Alert>
  );
};

export const useMessage = () => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const popMessage = React.useCallback(
    (variant: AlertColor) =>
      (content: React.ReactNode, options?: OptionsObject & { width?: number }) => {
        const key = enqueueSnackbar(
          <MessageContent
            width={options?.width}
            variant={variant}
            onClose={() => closeSnackbar(key)}
            content={content}
          />,
          { ...options, variant }
        );
      },
    [closeSnackbar, enqueueSnackbar]
  );

  return React.useMemo(() => {
    return {
      error: popMessage("error"),
      info: popMessage("info"),
      success: popMessage("success"),
      warning: popMessage("warning")
    };
  }, [popMessage]);
};
