import { usePrefixPath } from "@/utils/location.util";
import { Link as RLink, LinkProps } from "react-router-dom";

export const Link = ({ to, ...others }: LinkProps) => {
  const { generatePath } = usePrefixPath();
  const getPath = () => {
    if (typeof to === "string") return generatePath(to);
    return to;
  };
  return <RLink to={getPath()} {...others} />;
};
