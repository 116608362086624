import React from "react";
import { Statistic } from "antd";

const { Countdown } = Statistic;

const CountdownTimer = ({ value }) => {
  return (
    <span className="d-flex align-items-center">
      <span className="pr-2">DUE IN</span>
      <Countdown
        value={value}
        format="H [hr(s)] mm [min(s)]"
        valueStyle={{ color: "white", fontSize: "12px" }}
      />
    </span>
  );
};

export default CountdownTimer;
