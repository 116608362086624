import { InfoCircleOutlined } from "@ant-design/icons";
import { Form } from "@ant-design/compatible";
import "@ant-design/compatible/assets/index.css";
import { Tooltip } from "antd";
import { StepButtons } from "components/utils";
import { CompanyInfoForm, useCompanyInfoController } from "@/entities/company/ui/company-info";
import {
  CompanyAddressForm,
  useCompanyAddressController
} from "@/entities/company/ui/company-address";
import { withMultiForm } from "HOCs/withMultiForm";

import { useInOutAnimation } from "hooks/useInOutAnimation";
import React, { useRef } from "react";
import { animated } from "react-spring";
import { FIELDS } from "@/entities/company/ui/company-info/company-info.schema";
import { TEXTS } from "@/commons/consts/texts.const";
import { useGetCompany } from "@/entities/company/libs/use-get-company";
import { Loader } from "@/components/commons";
import { useMutation } from "react-query";
import { saveStep1 } from "@/services/kyc.service";
import { useGetCompanyAddresses } from "@/entities/address/lib/use-query-address";

const ECompanyInfoForm = Form.create({
  name: "company-info",
  onFieldsChange(props, changedFields) {
    props.onChange && props.onChange(changedFields);
  }
})(CompanyInfoForm);

export const KYC1CompanyInfo = (props) => {
  const { company, isLoading: isCompanyLoading } = useGetCompany();
  const { addresses, isLoading: isAddressesLoading } = useGetCompanyAddresses();
  const companyAddress = React.useMemo(() => {
    if (addresses.length === 0) {
      return [{}];
    }
    return addresses;
  }, [addresses]);
  if (isCompanyLoading || isAddressesLoading) return <Loader />;
  return (
    <KYC1CompanyInfoContent {...props} companyInfo={company} companyAddress={companyAddress} />
  );
};

const KYC1CompanyInfoContent = ({ stepButtonsConfig, className, companyInfo, companyAddress }) => {
  const { mutate, isLoading: loading } = useMutation(saveStep1);

  const {
    selectedCountry,
    handleCountryChange,
    refCompanyAddressFromArr,
    handleDeleteCompanyAddress,
    composeValues: composeAddressValues
  } = useCompanyAddressController(companyAddress);
  const { refCompanyInfoForm, composeValues: composeCompanyValues } = useCompanyInfoController();

  const handleCompanyChange = (changedField) => {
    const country = FIELDS.country;
    if (changedField[country]) {
      const value = changedField[country].value;
      handleCountryChange(value);
    }
  };
  const transitions = useInOutAnimation();
  const ECompanyAddressFormArr = useRef(
    withMultiForm({
      name: "company-address",
      cantRemoveSectionFieldName: "main",
      onRemoveForm: handleDeleteCompanyAddress,
      isShowButtonAdd: false
    })(Form.create()(CompanyAddressForm))
  ).current;

  const onNextStep = () => {
    const { onNext } = stepButtonsConfig;
    const [hasAddressError, addressValues, deletedIds] = composeAddressValues();
    const [hasCompanyError, companyValues] = composeCompanyValues();
    if (hasCompanyError || hasAddressError) return;
    const composedValues = {
      companyInfo: companyValues,
      addresses: addressValues,
      deletedAddressIds: deletedIds
    };
    mutate(composedValues, { onSuccess: onNext });
  };

  const renderForms = () => {
    if (loading) {
      return (
        <div className="d-flex justify-content-center align-items-center w-100 dtc-h-fix-380">
          <Loader />
        </div>
      );
    }
    return transitions.map(({ key, props }) => (
      <animated.div key={key} style={props}>
        <section>
          <div className="d-flex">
            <h5 className="text-uppercase">COMPANY INFORMATION</h5>
            <Tooltip title={TEXTS.companyInfoHoverText}>
              <InfoCircleOutlined className="ml-2" />
            </Tooltip>
          </div>
          <ECompanyInfoForm
            title={"COMPANY INFORMATION"}
            initialValues={companyInfo}
            wrappedComponentRef={refCompanyInfoForm}
            onChange={handleCompanyChange}
          />
        </section>
        <hr />
        <section>
          <ECompanyAddressFormArr
            title={"COMPANY ADDRESS"}
            initialValues={companyAddress}
            country={selectedCountry}
            disabledFields={selectedCountry ? [FIELDS.country] : undefined}
            wrappedRef={(formArr) => (refCompanyAddressFromArr.current = formArr)}
          />
        </section>
        <hr />
      </animated.div>
    ));
  };

  return (
    <div className={className}>
      {renderForms()}
      <section>
        <StepButtons {...stepButtonsConfig} onNext={onNextStep} loading={loading} />
      </section>
    </div>
  );
};
