import React from "react";
import { Client } from "@stomp/stompjs";
import { WSClient } from "@/utils";

const WSContext = React.createContext<{
  client: Client;
  connected: boolean;
}>({} as never);

export const WSProvider = ({ children }: { children: React.ReactElement }) => {
  const [wsClient, setWsClient] = React.useState<Client>();
  const [connected, setConnected] = React.useState(false);

  React.useEffect(() => {
    let unmount = false;
    WSClient.create().then((client) => {
      if (!unmount) {
        client.onConnect = () => setConnected(true);
        client.activate();
        setWsClient(client);
      }
    });
    return () => {
      unmount = true;
    };
  }, []);

  return (
    <WSContext.Provider value={{ client: wsClient, connected }}>{children}</WSContext.Provider>
  );
};

export const useWSContext = () => {
  return React.useContext(WSContext);
};
