export const downloadPdfFromBase64 = (url, name) => {
  const link = document.createElement("a");
  link.download = `${name}.pdf`;
  link.href = url;
  link.click();
  link.remove();
};

export const downloadFromUrl = (url, name) => {
  fetch(url).then(function (fileValue) {
    return fileValue.blob().then((value) => {
      const a = document.createElement("a");
      a.href = URL.createObjectURL(value);
      a.setAttribute("download", name);
      a.click();
    });
  });
};
