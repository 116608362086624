import { useHistory } from "@/components/commons/navigator";
import { IconButton, Stack } from "@mui/material";
import { NotificationDropdown } from "./notification-dropdown.comp";
import { UserDropdownMenu } from "./user-dropdown-menu.comp";
import WalletIcon from "@/components/icons/wallet.comp";
import { useUserInfoHook } from "@legacy/hooks/useUserInfoHook";
import { ROUTES } from "@/commons/consts";

export const UserMenu = () => {
  const { isUserNotCompleteKYC, triggerKYCProcess } = useUserInfoHook();
  const history = useHistory();

  const handleClick = (path: string) => {
    if (isUserNotCompleteKYC) {
      triggerKYCProcess();
      return;
    }
    history.push(path);
  };

  return (
    <Stack direction="row" alignItems="center" justifyContent="flex-start" spacing={1}>
      <NotificationDropdown />
      <IconButton color="inherit" onClick={() => handleClick(ROUTES.WALLET)}>
        <WalletIcon sx={{ color: "common.black" }} />
      </IconButton>
      <UserDropdownMenu />
    </Stack>
  );
};
