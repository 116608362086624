import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";

import { useHistory } from "@/components/commons/navigator";
import { NEW_BANK } from "@/commons/consts/system/routes";
import { AddPhoneModal } from "@/entities/user-profile/ui/add-phone-modal/add-phone-modal.comp";
import { useUserProfile } from "@/entities/user-profile/libs/use-user-profile";
import React from "react";
import { useNewPhoneVerifier } from "@/entities/user-profile/ui/personal-information-form/use-new-phone-verifier";
import { isValidPhone, sensorPhone } from "@/utils";

export const BankEmpty = () => {
  const history = useHistory();
  const { user } = useUserProfile();

  const goNext = () => {
    history.push(NEW_BANK);
  };
  const handleClick = () => {
    if (!isValidPhone(user.phone) || !user.phoneVerified) {
      verifier.startVerifyingPhone();
      return;
    }
    goNext();
  };

  const verifier = useNewPhoneVerifier({
    onSuccess: goNext
  });
  return (
    <>
      <Box p={3}>
        <Typography variant="h5">Banking</Typography>
        <Box pt={5}>
          <Stack alignItems="center" spacing={3}>
            <Typography variant="body2">You have not saved any bank accounts</Typography>
            <Button onClick={handleClick} variant="contained">
              Add bank account
            </Button>
          </Stack>
        </Box>
      </Box>
      <AddPhoneModal
        phoneFormDescription=" Please add your phone number for verification to add your Bank Details"
        otpDescription={`Please enter your OTP sent to your verified mobile number ${sensorPhone(
          user.phone
        )}`}
        open={verifier.open}
        onClose={verifier.close}
        onVerify={verifier.verifyPhone}
        onReady={verifier.startVerifyingPhone}
      />
    </>
  );
};
