import React from "react";
import {
  useInvalidateUserProfiles,
  useUserProfile
} from "../../user-profile/libs/use-user-profile";
import { getNotificationList, setNotificationsRead } from "@/services/notification.service";
import { QueryFunctionContext, useInfiniteQuery, useMutation, useQueryClient } from "react-query";

import { messageCallbackType, StompSubscription } from "@stomp/stompjs";
import { useWSContext } from "@/providers/ws-provider";

const fetchNotification = (context: QueryFunctionContext, size: number) =>
  getNotificationList(context.pageParam, size);

const useQueryNotifiaction = ({ size }: { size: number }) => {
  const { data, ...others } = useInfiniteQuery(
    ["notification", size],
    (context) => fetchNotification(context, size),
    {
      getNextPageParam: (_, pages) => {
        const lastPageLength = pages[pages.length]?.content?.length;
        if (!lastPageLength) return undefined;
        return pages.length;
      }
    }
  );
  const notifications = React.useMemo(() => {
    if (!data?.pages) return [];
    const result = [];
    for (const page of data.pages) {
      if (page.content) result.push(...page.content);
    }
    return result;
  }, [data]);
  return { notifications, ...others };
};

const useInvalidateNotification = () => {
  const client = useQueryClient();
  return React.useCallback(async () => {
    await client.invalidateQueries(["notifications"]);
  }, [client]);
};

export const useWebSocket = ({ url, callback }: { url: string; callback: messageCallbackType }) => {
  const { client, connected } = useWSContext();
  const subscribeRef = React.useRef(false);
  React.useEffect(() => {
    if (!connected || subscribeRef.current) {
      return;
    }

    let subscription: StompSubscription;
    client.subscribe(url, callback);
    subscribeRef.current = true;

    return () => {
      if (subscription) {
        subscription.unsubscribe();
        subscribeRef.current = false;
      }
    };
  }, [client, connected, url, callback]);
};

export const useReadNotification = () => {
  const invalidate = useInvalidateUserProfiles();
  return useMutation(setNotificationsRead, {
    onSuccess: async () => {
      await invalidate();
    }
  });
};

export const useNotification = ({ size = 5 }: { size?: number }) => {
  const { notifications, isLoading, fetchNextPage, hasNextPage, isFetchingNextPage } =
    useQueryNotifiaction({ size });
  const invalidate = useInvalidateNotification();
  const { user } = useUserProfile();
  const callback: messageCallbackType = (m) => {
    invalidate();
  };
  useWebSocket({ url: `/notification/${user.id}`, callback });

  return {
    messageRead: Boolean(user.messageRead),
    notifications,
    isLoading,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage
  };
};
