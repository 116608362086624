import { StepButtons } from "components/utils";
import { CompanyAddressReadonly } from "@/entities/company/ui/company-address";

import { OwnerInfoReadonly, useOwnerInfo } from "@/entities/owner/ui/owner-info";
import { useGetCompany } from "@/entities/company/libs/use-get-company";
import { Loader } from "@/components/commons";
import { CompanyInfoReadonly } from "@/entities/company/ui/company-info";
import { useGetCompanyAddresses } from "@/entities/address/lib/use-query-address";

export const KYC5Review = (props) => {
  const { company, isLoading: isLoadingCompany } = useGetCompany();
  const { addresses, isLoading: isLoadingAddresses } = useGetCompanyAddresses();
  const { owner, partners, isLoading: isOwnerLoading } = useOwnerInfo();
  if (isLoadingCompany || isLoadingAddresses || isOwnerLoading) {
    return <Loader />;
  }
  return (
    <KYC5ReviewContent
      {...props}
      companyInfo={company}
      companyAddress={addresses}
      ownerInfo={[owner, ...partners]}
    />
  );
};

const KYC5ReviewContent = ({
  stepButtonsConfig,
  companyInfo,
  companyAddress,
  ownerInfo,
  className,
  loadingButton
}) => {
  return (
    <div className={className}>
      <section className="mb-5">
        <CompanyInfoReadonly
          companyInfo={companyInfo}
          className="col-lg-4 col-md-6 col-sm-12 mb-1"
        />
        <div className="mb-4 mt-2">
          <div className="mb-1">
            <strong>COMPANY ADDRESS</strong>
          </div>
          <CompanyAddressReadonly companyAddress={companyAddress} />
        </div>
        {/* <CompanyTradeLicenseReadonly tradeLicenseDetails={tradeLicenseDetails} /> */}
      </section>
      {/* OWNER INFORMATION */}
      <section className="mb-5">
        <OwnerInfoReadonly ownerInfo={ownerInfo} />
      </section>

      {/* <section>
        {stepButtonsConfig.currentStep === 4 && <BusinessDetailsReadonly showHeader={false} />}
      </section> */}
      {/* BANK DETAILS */}
      {/* <section className="mb-5">
        <BankDetailsReadonly bankDetails={bankDetails} />
      </section> hide BankDetail because field change */}

      {/* UPLOAD VALID DOCUMENTS */}
      {/* <section className="mb-5">
        <UploadDocumentsReadonly documentList={documentList} />
      </section> */}
      <hr />
      <section>
        <StepButtons {...stepButtonsConfig} loading={loadingButton} />
      </section>
    </div>
  );
};
