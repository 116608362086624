import React from "react";
import { Typography } from "antd";
import { FIELDS, LABELS } from "./owner-info-form.schema";
import { KVList } from "components/utils/kv-list/kv-list.comp";

import { Country } from "components/utils/country/country.comp";
import { datetimeUtil } from "@/utils";
import { DOCUMENT_SCHEMA, DOCUMENT_TYPES } from "@/commons/consts/document.const";
const { Text } = Typography;
const GENDERS = {
  F: "Female",
  M: "Male"
};
export const DOCUMENT_LABELS = Object.values(DOCUMENT_SCHEMA).reduce(
  (acc, cur) => ({ ...acc, [cur[0].type]: cur[0], [cur[1].type]: cur[1] }),
  {}
);
const parseOwnerInfo = (ownerInfo) => {
  const array = [];
  const pushField = (field, formatFn) => {
    let value = ownerInfo[field];
    let label = LABELS[field];
    if (value == null || label == null) return;
    if (formatFn) {
      value = formatFn(value);
    }
    array.push({
      key: label,
      value
    });
  };
  const countryFormat = (code) => <Country code={code} />;
  pushField(FIELDS.firstName);
  pushField(FIELDS.middleName);
  pushField(FIELDS.lastName);
  pushField(FIELDS.birthDate, datetimeUtil.formatDate);
  pushField(FIELDS.gender, (value) => GENDERS[value]);
  pushField(FIELDS.nationality, countryFormat);
  if (ownerInfo.documentType !== DOCUMENT_TYPES.NATIONAL_ID) {
    pushField(FIELDS.nationalIdNumber);
  }
  const documentNumberLabel = DOCUMENT_TYPES[ownerInfo.documentType];
  array.push({ key: documentNumberLabel, value: ownerInfo.documentNumber });
  pushField(FIELDS.issueDate, datetimeUtil.formatDate);
  pushField(FIELDS.expiryDate, datetimeUtil.formatDate);
  pushField(FIELDS.issueCountry, countryFormat);
  return array;
};

const UserFields = ({ owner }) => {
  const arrayData = parseOwnerInfo(owner);
  return <KVList items={arrayData} colProps={{ col: 24, md: 12, lg: 8 }} />;
};

const DocumentInfo = ({ owner }) => {
  return (
    <div>
      {owner.documents &&
        owner.documents.map((doc) => (
          <div key={doc.fileName} className="row">
            <div className="col-lg-4 col-md-6 col-sm-12 mb-1">
              <b>Type: </b>
              {DOCUMENT_LABELS[doc.type].label}
            </div>
            <div className="col-lg-8 col-md-6 col-sm-12 mb-1">
              <b>Document Name: </b>
              <a target="_blank" rel="noopener noreferrer" href={doc.url}>
                {doc.fileName}
              </a>
            </div>
          </div>
        ))}
      {(owner.documents == null || owner.documents.length === 0) && (
        <Text type="secondary">You do not have any documents.</Text>
      )}
    </div>
  );
};

const UserInfoList = ({ companies }) => {
  return (
    <div className="mb-5">
      {companies
        .map((preParsed) => ({ ...preParsed, ...preParsed.address }))
        .map((owner, index) => {
          return (
            <React.Fragment key={owner.id ?? index}>
              {index !== 0 && <hr />}
              <CompanyInfo owner={owner} />
            </React.Fragment>
          );
        })}
    </div>
  );
};

const CompanyInfo = ({ owner }) => {
  return (
    <div>
      <UserFields owner={owner} />
      <div className="mt-4 mb-1 text-uppercase">
        <strong>Owner documents</strong>
      </div>
      <DocumentInfo owner={owner} />
    </div>
  );
};

export const OwnerInfoReadonly = ({ ownerInfo = [], showHeader = true }) => {
  const partners = React.useMemo(() => ownerInfo.filter((item) => !item.owner), [ownerInfo]);
  const owners = React.useMemo(() => ownerInfo.filter((item) => item.owner), [ownerInfo]);

  return (
    <section className="mb-5">
      {showHeader ? (
        <div>
          <h5 className="font-weight-bold">OWNER INFORMATION</h5>
          <hr />
        </div>
      ) : null}
      <UserInfoList companies={owners} />
      {partners.length > 0 && (
        <>
          <h5 className="font-weight-bold">PARTNER INFORMATION</h5>
          <hr />
          <UserInfoList companies={partners} />
        </>
      )}
    </section>
  );
};
