import { ERR_MSG } from "@/commons/consts";
import { createFormErrorComp } from "@/utils";
import { ERRORS as UPLOAD_ERRORS } from "./upload-file-input.comp";

const UPLOAD_ERROR_MESSAGES = {
  [UPLOAD_ERRORS.INVALID_FILE_TYPE]: ERR_MSG.INVALID_DOCUMENT_FILE,
  [UPLOAD_ERRORS.FILE_LIMIT_EXCEED]: ERR_MSG.FILE_LIMIT_EXCEED,
  [UPLOAD_ERRORS.UNEXPECTED]: ERR_MSG.UNEXPECTED_ERROR
};
export const pdfUploadRules = [
  {
    validator: (_, value, callback) => {
      if (value == null || Object.values(value).length === 0) {
        callback(createFormErrorComp(ERR_MSG.REQUIRE_DOCUMENT_ERROR));
        return;
      }
      callback();
    }
  },
  {
    validator: (_, value, callback) => {
      if (!value || !value.error) return callback();
      if (value.error in UPLOAD_ERROR_MESSAGES) {
        return callback(createFormErrorComp(UPLOAD_ERROR_MESSAGES[value.error]));
      }
      return callback(createFormErrorComp(UPLOAD_ERROR_MESSAGES.UNEXPECTED));
    }
  }
];
