import { ROUTES } from "@/commons/consts";
import { MainContainer } from "@/processes/container/main-container.comp";
import { loadable } from "@/utils/loadable.util";
import { Layout } from "./layout.comp";

export const userDetailsRoutes = [
  {
    key: "trader-details",
    Component: Layout,
    children: [
      {
        key: "container",
        Component: MainContainer,
        children: [
          {
            path: `${ROUTES.TRADER_DETAILS}/:id`,
            Component: loadable(() => import("@/pages/trader-details/trader-details.page")),
            exact: true
          }
        ]
      }
    ]
  }
];
