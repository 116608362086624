import { generatePath } from "@/components/commons/navigator";
import { SELLER_PREFIX } from "./common.route";

export const SELLER_ADMIN_USER_MANAGEMENT_ROUTE = `${SELLER_PREFIX}/user-management`;
export const SELLER_ADMIN_USER_MANAGEMENT_ADD_USER_ROUTE = `${SELLER_ADMIN_USER_MANAGEMENT_ROUTE}/add-user`;
export const SELLER_ADMIN_AUDIT_LOG_ROUTE = `${SELLER_PREFIX}/audit-log`;

// SELLER ROUTES
export const SELLER_DASHBOARD_ROUTE = `${SELLER_PREFIX}/dashboard`;
export const SELLER_INVENTORY_ROUTE = `${SELLER_PREFIX}/inventory-management`;
export const SELLER_INVENTORY_REVIEW_AND_SELL = `${SELLER_PREFIX}/review-and-sell`;
export const SELLER_PRODUCT_CREATION_ROUTE = `${SELLER_PREFIX}/product-creation`;
export const SELLER_BIDDING_ARENA = `${SELLER_PREFIX}/bidding-arena`;
export const SELLER_BID_DETAILS = `${SELLER_PREFIX}/bid-details`;
export const SELLER_BID_NEGOTIATION = `${SELLER_PREFIX}/bid-negotiation`;
export const SELLER_MARKETPLACE_ROUTE = `${SELLER_PREFIX}/marketplace`;
export const SELLER_MARKETPLACE_EXPLORE_ROUTE = `${SELLER_MARKETPLACE_ROUTE}/explore`;

export const SELLER_APPROVAL_ROUTE = `${SELLER_PREFIX}/approval`;
export const SELLER_CANCELLED_ORDER = `${SELLER_PREFIX}/cancelled-orders`;

export const SELLER_PENDING_APPROVAL_POST_PRODUCT = `${SELLER_PREFIX}/pending-approval/post-product`;
export const SELLER_APPROVAL_HISTORY_POST_PRODUCT = `${SELLER_PREFIX}/approval-history/post-product`;

export const SELLER_ORDER_MANAGEMENT = `${SELLER_PREFIX}/order-management`;
export const SELLER_ORDER_TRACK_AND_TRACE = `${SELLER_ORDER_MANAGEMENT}/:orderNumber/track-and-trace`;
export const SELLER_OFFER_APPROVED = `${SELLER_ORDER_MANAGEMENT}/offer-approved`;
export const SELLER_DOCUMENT_MANAGEMENT = `${SELLER_ORDER_MANAGEMENT}/:orderNumber/document-management`;
export const SELLER_PROVIDE_SHIPPING_DETAILS = `${SELLER_ORDER_MANAGEMENT}/:orderNumber/provide-shipping-details`;
export const SELLER_DOCUMENT_MANAGEMENT_DETAILS = `${SELLER_DOCUMENT_MANAGEMENT}/:docId`;
export const SELLER_UPLOAD_SERIAL_NUMBER = `${SELLER_ORDER_MANAGEMENT}/:orderNumber/upload-serial-number`;
export const SELLER_ORDER_TRACK_SHIPMENT = `${SELLER_ORDER_MANAGEMENT}/:orderNumber/track-shipment`;
export const SELLER_TRACK_ORDERS = `${SELLER_PREFIX}/track-orders`;
export const SELLER_TRACK_ORDER_DETAILS = `${SELLER_PREFIX}/track-orders/:orderNumber/details`;
export const SELLER_TRACK_SHIPMENT = `${SELLER_PREFIX}/track-shipment`;
export const SELLER_TRACK_SHIPMENT_DETAILS = `${SELLER_PREFIX}/track-shipment/:orderNumber/details`;
export const SELLER_CONFIRM_ADDITIONAL_DOCUMENT = `${SELLER_ORDER_MANAGEMENT}/confirm-additional-document`;

export const SELLER_WALLET = `${SELLER_PREFIX}/wallet`;
export const SELLER_ADD_FUNDS = `${SELLER_PREFIX}/add-funds`;
export const SELLER_ACCOUNT_SUMMARY = `${SELLER_PREFIX}/account-summary`;
export const SELLER_BULK_UPLOAD_TEMPLATES = `${SELLER_PREFIX}/bulk-catalog-upload`;

// Products
export const SELLER_PRODUCT_CATEGORIES = `${SELLER_PREFIX}/product-categories`;
export const SELLER_ADD_PRODUCT_BY_PRODUCT_TYPE = `${SELLER_PREFIX}/add-product/:code`;
export const SELLER_FIND_PRODUCT = `${SELLER_PREFIX}/find-product`;

// Legacy Item Listing
export const LEGACY_SELLER_ITEM_LISTING = `${SELLER_PREFIX}/legacy/item-listing`;

// Item listing
export const SELLER_ITEM_LISTING = `${SELLER_PREFIX}/item-listing`;
export const SELLER_ITEM_LISTING_EDIT = `${SELLER_PREFIX}/item-listing/edit/:id`;
export const SELLER_ITEM_LISTING_ADD = `${SELLER_PREFIX}/item-listing/add/:productID`;
export const SELLER_ITEM_LISTING_ROUTE = `${SELLER_PREFIX}/item-listing`;

export const getSellerAddProductByProductTypePath = (code: string) => {
  return generatePath(SELLER_ADD_PRODUCT_BY_PRODUCT_TYPE, { code });
};
export const getItemListingEditPath = (id?: string) => {
  if (!id) return SELLER_ITEM_LISTING;
  return generatePath(SELLER_ITEM_LISTING_EDIT, { id });
};
export const getItemListingAddPath = (productID?: string) => {
  if (!productID) return SELLER_ITEM_LISTING;
  return generatePath(SELLER_ITEM_LISTING_ADD, { productID });
};
