import React from "react";
import countryList from "assets/country.json";
import { ERR_MSG } from "@/commons/consts";
import { createFormErrorComp } from "@/utils";
import { FormLabel } from "components/utils/form-label/form-label.comp";
import { validPhoneNumber } from "components/utils/form-rules/phone.rules";
import { TEXTS } from "@/commons/consts/texts.const";
import { DOCUMENT_SCHEMA, DOCUMENT_TYPES } from "@/commons/consts/document.const";
const prefixes = countryList.reduce((codes, country) => {
  return [...codes, ...country.callingCodes.map((code) => `+${code}`)];
}, []);

export const FIELDS = {
  firstName: "firstName",
  middleName: "middleName",
  lastName: "lastName",
  birthDate: "birthDate",
  gender: "gender",
  nationalHealthNumber: "nationalHealthNumber",
  nationalIdNumber: "nationalIdNumber",
  nationality: "nationality",
  socialServiceNumber: "socialServiceNumber",
  issueDate: "issueDate",
  expiryDate: "expiryDate",
  issueCountry: "issueCountry",
  documentType: "documentType",
  documentNumber: "documentNumber",
  documents: "documents",
  addressLine1: "addressLine1",
  city: "city",
  country: "country",
  email: "email",
  phone: "phone",
  secondaryPhone: "secondaryPhone",
  postalCode: "postalCode",
  state: "state"
};
export const LABELS = {
  [FIELDS.addressLine1]: "Residential Address Line 1",
  [FIELDS.city]: "Residential City",
  [FIELDS.country]: "Residential Country",
  [FIELDS.email]: "Email",
  [FIELDS.phone]: "Primary Mobile Number",
  [FIELDS.secondaryPhone]: "Secondary Mobile Number",
  [FIELDS.postalCode]: "Postal/ZIP Code or P.O. Box",
  [FIELDS.state]: "Residential State / Province/ Territory",
  [FIELDS.birthDate]: "Date of Birth ",
  [FIELDS.gender]: "Gender",
  [FIELDS.firstName]: "First Name",
  [FIELDS.lastName]: "Last Name",
  [FIELDS.middleName]: "Middle Name",
  [FIELDS.nationalIdNumber]: "National ID/ Social Security/ TaxID Number",
  [FIELDS.nationality]: "Nationality",
  [FIELDS.documentNumber]: "Number",
  [FIELDS.issueCountry]: "Country of Issuance",
  [FIELDS.issueDate]: "Date of Issuance",
  [FIELDS.expiryDate]: "Date of Expiry",
  [FIELDS.documentType]: "Document Type",
  [FIELDS.documents]: "Documents"
};

const VISIBLE_FIELDS_BY_COUNTRY = Object.freeze({
  Lebanon: [FIELDS.nationalIdNumber],
  Qatar: [FIELDS.nationalIdNumber],
  Oman: [FIELDS.nationalIdNumber],
  Bahrain: [FIELDS.nationalIdNumber],
  Jordan: [FIELDS.nationalIdNumber],
  France: [FIELDS.nationalIdNumber],
  China: [FIELDS.nationalIdNumber],
  "Hong Kong": [FIELDS.nationalIdNumber],
  Netherlands: [FIELDS.nationalIdNumber],
  Singapore: [FIELDS.nationalIdNumber],
  "United Arab Emirates": [FIELDS.nationalIdNumber],
  "Saudi Arabia": [FIELDS.nationalIdNumber]
});

const HOVER_TEXT = {
  userInformation: TEXTS.userInformattionHoverText,
  partnerInformation: TEXTS.partnerInformationHoverText,
  addressLine1: TEXTS.ownerAddressLine1HoverText,
  city: TEXTS.ownerCityHoverText,
  country: TEXTS.ownerCountryHoverText,
  state: TEXTS.ownerStateHoverText,
  email: TEXTS.ownerEmailHoverText,
  nationality: TEXTS.ownerNationalityHoverText,
  idVerification: TEXTS.ownerIdVerificationHoverText
};

export const HIDDEN_FIELDS_BY_COUNTRY = countryList
  .filter((c) => !(c.name in VISIBLE_FIELDS_BY_COUNTRY))
  .reduce(
    (ob, current) => ({
      ...ob,
      [current.name]: [FIELDS.nationalIdNumber]
    }),
    {}
  );

const countryDataSource = countryList.map((country) => ({
  value: country.alpha2Code,
  label: country.name
}));
const genderDataSource = [
  { value: "M", label: "Male" },
  { value: "F", label: "Female" }
];
const documentTypeDataSource = Object.entries(DOCUMENT_TYPES).map(([value, label]) => ({
  value,
  label
}));
const Label = ({ name }) => <FormLabel label={LABELS[name]} tooltip={HOVER_TEXT[name]} />;
export const SCHEMA = {
  [FIELDS.firstName]: {
    name: FIELDS.firstName,
    label: LABELS[FIELDS.firstName],
    placeholder: LABELS[FIELDS.firstName],
    rules: [
      {
        required: true,
        message: createFormErrorComp(ERR_MSG.REQUIRED_ERR(LABELS[FIELDS.firstName]))
      }
    ]
  },
  [FIELDS.middleName]: {
    name: FIELDS.middleName,
    label: LABELS[FIELDS.middleName],
    placeholder: LABELS[FIELDS.middleName]
  },
  [FIELDS.lastName]: {
    name: FIELDS.lastName,
    label: LABELS[FIELDS.lastName],
    placeholder: LABELS[FIELDS.lastName],
    rules: [
      {
        required: true,
        message: createFormErrorComp(ERR_MSG.REQUIRED_ERR(LABELS[FIELDS.lastName]))
      }
    ]
  },
  [FIELDS.birthDate]: {
    name: FIELDS.birthDate,
    label: LABELS[FIELDS.birthDate],
    placeholder: LABELS[FIELDS.birthDate],
    rules: [
      {
        required: true,
        message: createFormErrorComp(ERR_MSG.REQUIRED_ERR(LABELS[FIELDS.birthDate]))
      }
    ]
  },
  [FIELDS.gender]: {
    name: FIELDS.gender,
    label: LABELS[FIELDS.gender],
    placeholder: LABELS[FIELDS.gender],
    dataSource: genderDataSource,
    rules: [
      {
        required: true,
        message: createFormErrorComp(ERR_MSG.REQUIRED_ERR(LABELS[FIELDS.gender]))
      }
    ]
  },
  [FIELDS.addressLine1]: {
    name: ["address", "addressLine1"],
    label: <Label name={FIELDS.addressLine1} />,
    placeholder: LABELS[FIELDS.addressLine1],
    rules: [
      {
        required: true,
        message: createFormErrorComp(ERR_MSG.REQUIRED_ERR(LABELS[FIELDS.addressLine1]))
      }
    ]
  },
  [FIELDS.city]: {
    name: ["address", "city"],
    label: <Label name={FIELDS.city} />,
    placeholder: "London, Paris etc.",
    tooltips: HOVER_TEXT[FIELDS.city],
    rules: [
      {
        required: true,
        message: createFormErrorComp(ERR_MSG.REQUIRED_ERR(LABELS[FIELDS.city]))
      }
    ]
  },
  [FIELDS.state]: {
    name: ["address", "state"],
    label: <Label name={FIELDS.state} />,
    placeholder: "Arizona, Ontario etc.",
    rules: [
      {
        message: createFormErrorComp(ERR_MSG.REQUIRED_ERR(LABELS[FIELDS.state]))
      }
    ]
  },
  [FIELDS.country]: {
    name: ["address", "country"],
    label: <Label name={FIELDS.country} />,
    placeholder: LABELS[FIELDS.country],
    dataSource: countryDataSource,
    rules: [
      {
        required: true,
        message: createFormErrorComp(ERR_MSG.REQUIRED_ERR(LABELS[FIELDS.country]))
      }
    ]
  },
  [FIELDS.postalCode]: {
    name: ["address", "postalCode"],
    label: LABELS[FIELDS.postalCode],
    placeholder: LABELS[FIELDS.postalCode]
  },
  [FIELDS.nationality]: {
    name: FIELDS.nationality,
    label: <Label name={FIELDS.nationality} />,
    placeholder: LABELS[FIELDS.nationality],
    type: "select",
    dataSource: countryDataSource,
    rules: [
      {
        required: true,
        message: createFormErrorComp(ERR_MSG.REQUIRED_ERR(LABELS[FIELDS.nationality]))
      }
    ]
  },
  [FIELDS.email]: {
    name: ["address", "email"],
    label: <Label name={FIELDS.email} />,
    placeholder: LABELS[FIELDS.email],
    rules: [
      {
        required: true,
        message: createFormErrorComp(ERR_MSG.REQUIRED_ERR(LABELS[FIELDS.email]))
      }
    ]
  },
  [FIELDS.phone]: {
    name: ["address", "phone"],
    label: <Label name={FIELDS.phone} />,
    type: "phone",
    prefixDataSource: prefixes,
    rules: [
      {
        required: true,
        message: createFormErrorComp(ERR_MSG.REQUIRED_ERR(LABELS[FIELDS.phone]))
      },
      validPhoneNumber
    ]
  },
  [FIELDS.secondaryPhone]: {
    name: ["address", "secondaryPhone"],
    label: <Label name={FIELDS.secondaryPhone} />,
    prefixDataSource: prefixes,
    rules: [validPhoneNumber]
  },
  [FIELDS.nationalIdNumber]: {
    name: FIELDS.nationalIdNumber,
    label: <Label name={FIELDS.nationalIdNumber} />,
    placeholder: LABELS[FIELDS.nationalIdNumber]
  },
  [FIELDS.documentType]: {
    name: FIELDS.documentType,
    label: <Label name={FIELDS.documentType} />,
    placeholder: LABELS[FIELDS.documentType],
    dataSource: documentTypeDataSource,
    rules: [
      {
        required: true,
        message: createFormErrorComp(ERR_MSG.REQUIRED_ERR(LABELS[FIELDS.documentType]))
      }
    ]
  },
  [FIELDS.issueCountry]: {
    name: FIELDS.issueCountry,
    label: <Label name={FIELDS.issueCountry} />,
    placeholder: LABELS[FIELDS.issueCountry],
    type: "select",
    dataSource: countryDataSource,
    rules: [
      {
        required: true,
        message: createFormErrorComp(ERR_MSG.REQUIRED_ERR(LABELS[FIELDS.issueCountry]))
      }
    ]
  },
  [FIELDS.documentNumber]: {
    name: FIELDS.documentNumber,
    label: <Label name={FIELDS.documentNumber} />,
    placeholder: LABELS[FIELDS.documentNumber],
    rules: [
      {
        required: true,
        message: createFormErrorComp(ERR_MSG.REQUIRED_ERR(LABELS[FIELDS.documentNumber]))
      }
    ]
  },
  [FIELDS.issueDate]: {
    name: FIELDS.issueDate,
    label: LABELS[FIELDS.issueDate],
    placeholder: LABELS[FIELDS.issueDate],
    rules: [
      {
        required: true,
        message: createFormErrorComp(ERR_MSG.REQUIRED_ERR(LABELS[FIELDS.issueDate]))
      }
    ]
  },
  [FIELDS.expiryDate]: {
    name: FIELDS.expiryDate,
    label: LABELS[FIELDS.expiryDate],
    placeholder: LABELS[FIELDS.expiryDate],
    type: "date",
    rules: [
      {
        required: true,
        message: createFormErrorComp(ERR_MSG.REQUIRED_ERR(LABELS[FIELDS.expiryDate]))
      }
    ]
  },
  [FIELDS.documents]: {
    name: FIELDS.documents,
    label: "Documents"
  }
};

export const getDocumentRules = (selectedDocumentType) => {
  return [
    {
      message: createFormErrorComp("Please upload at least one image in JPG/JPEG/PNG format."),
      validator: async (_, value) => {
        if (value == null || !Array.isArray(value)) {
          throw new Error();
        }
        const documentSchema = DOCUMENT_SCHEMA[selectedDocumentType];
        const isMissingRequiredDocument = documentSchema.some(({ type, isRequired }) => {
          if (!isRequired) {
            return false;
          }
          return !value.some((document) => !document.isDeleted && document.type === type);
        });
        if (isMissingRequiredDocument) {
          throw new Error();
        }
      }
    }
  ];
};

export const hasNationalIdNumber = (countryCode) => {
  if (!countryCode) return false;
  const countryName = countryList.find((country) => country.alpha2Code === countryCode)?.name;
  return (
    HIDDEN_FIELDS_BY_COUNTRY[countryName] == null ||
    !HIDDEN_FIELDS_BY_COUNTRY[countryName].includes(FIELDS.nationalIdNumber)
  );
};
