import { KYC_STATUSES, USER_STATUSES } from "@/commons/consts";

export const isNotCompletedKYC = (user) => {
  const isUserActive = user && user.status === USER_STATUSES.ACTIVE;
  return isUserActive && user.kycStatus !== KYC_STATUSES.PASSED;
};

export const isWaitingKYC = (user) => {
  const isUserActive = user && user.status === USER_STATUSES.ACTIVE;
  return isUserActive && user.kycStatus === KYC_STATUSES.IN_PROGRESS;
};

export const isProcessingKYC = (user) => {
  const isUserActive = user && user.status === USER_STATUSES.ACTIVE;
  return (
    user.authorized &&
    isUserActive &&
    (user.kycStatus === KYC_STATUSES.FAILED || user.kycStatus === KYC_STATUSES.NOT_COMPLETED)
  );
};
