import React from "react";
import { Select } from "antd";

export const WrappedSelect = React.forwardRef(({ dataSource, ...rest }, ref) => {
  return (
    <Select
      ref={ref}
      showSearch
      autoComplete="off"
      filterOption={(inputValue, option) =>
        option.props?.children?.toLowerCase().includes(inputValue.toLowerCase())
      }
      {...rest}
    >
      {dataSource.map((item) => (
        <Select.Option key={item.value} value={item.value}>
          {item.label}
        </Select.Option>
      ))}
    </Select>
  );
});
