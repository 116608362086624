import { backendAPI } from "@/utils/httpAPI.util";
import { ApiPathConsts } from "@/commons/consts/system";
import { API_PATHS } from "@/commons/consts/system/api-paths";

export class ProductService {
  static PRODUCT_CATEGORY = "/products/category";
  static HS_CODE = "/hsCode";

  static addProduct = (data) => {
    const { fileName, productName, typeId, variantList, detail } = data;
    return backendAPI.post(ApiPathConsts.ADD_PRODUCT, {
      detail,
      fileName,
      productName,
      typeId,
      variantList
    });
  };

  static checkDuplicate = (data) => {
    return backendAPI.post(ApiPathConsts.CHECK_DUPLICATE_PRODUCT, data);
  };

  static editProduct = (data, id) => {
    return backendAPI.put(ApiPathConsts.EDIT_PRODUCT.replace(":id", id), data);
  };

  static getProducts = ({ params }) => {
    return backendAPI.get(ApiPathConsts.GET_PRODUCTS, params);
  };

  static getAllProducts = ({ params }) => {
    return backendAPI.get(ApiPathConsts.GET_ALL_PRODUCTS, params);
  };

  static getTotalOfferProducts = () => {
    return backendAPI.get(ApiPathConsts.GET_TOTAL_OFFER_PRODUCTS);
  };
  static getProductTypes = (category) => {
    return backendAPI.get(`${ApiPathConsts.GET_PRODUCT_CATEGORIES}/${category}`);
  };

  static getProductCategories = () => {
    return backendAPI.get(ApiPathConsts.GET_PRODUCT_CATEGORIES);
  };

  static getProductSaleChannels = () => {
    return backendAPI.get(ApiPathConsts.GET_PRODUCT_SALE_CHANNEL);
  };

  static deleteProduct = (id) => {
    return backendAPI.delete(ApiPathConsts.DELETE_PRODUCT.replace(":id", id));
  };

  static getProductDetails = (id) => {
    return backendAPI.get(ApiPathConsts.GET_PRODUCT_DETAILS.replace(":id", id));
  };

  static getProductNameByTypeId = (typeId) => {
    return backendAPI.get(ApiPathConsts.GET_PRODUCT_NAMES_BY_TYPE_ID.replace(":id", typeId));
  };

  static addAvailableProduct = (sourceId, targetId) => {
    return backendAPI.put(
      ApiPathConsts.ADD_AVAILABLE_PRODUCT.replace(":sourceId", sourceId).replace(
        ":targetId",
        targetId
      )
    );
  };

  static getProductTypeByCategory = (catId) => {
    return backendAPI.get(`${this.PRODUCT_CATEGORY}/${catId}`);
  };

  static getAllHsCode = (search = null, page = 0, size = 100) => {
    return backendAPI.get(
      // eslint-disable-next-line sonarjs/no-nested-template-literals
      `${this.HS_CODE}?page=${page}&size=${size}${search !== null ? `&searchText=${search}` : ""}`
    );
  };

  static getHsCodeDetails = (code) => {
    return backendAPI.get(`/hsCode/${code}`);
  };
}

export const getProductDetails = async ({ productId }) => {
  const productDetails = await backendAPI.get(`${API_PATHS.PRODUCTS}/${productId}`);
  return productDetails;
};

export const getProducts = async ({ params }) => {
  const { page, size, searchText } = params;

  const products = await backendAPI.get(API_PATHS.PRODUCTS_ITEM_LISTING, {
    page,
    size,
    searchText
  });
  return products;
};

export const getSellersOfProduct = async ({ productId, params }) => {
  const sellers = await backendAPI.get(`${API_PATHS.PRODUCTS}/${productId}/sellers`, params);
  return sellers;
};

export const subscribeToProduct = async (productId) => {
  await backendAPI.post(`${API_PATHS.PRODUCTS}/${productId}/subscribe`);
};

export const unsubscribeToProduct = async (productId) => {
  await backendAPI.post(`${API_PATHS.PRODUCTS}/${productId}/unsubscribe`);
};

export const getSubscriptionList = async (page = 0, size = 2000) => {
  const result = await backendAPI.get(API_PATHS.SUBSCRIPTION, {
    page,
    size,
    topicName: "PRODUCT_SUBSCRIPTION"
  });
  return result;
};

export const unsubscribeBulk = async (ids) => {
  if (!Array.isArray(ids) || ids.length === 0) {
    return true;
  }
  let promises = [];
  ids.forEach((id) => {
    promises.push(unsubscribeToProduct(id));
  });
  await Promise.all(promises);
};

export const isProductSuscribed = async (productId) => {
  //400 = not subscribe / 204 = subscribed
  await backendAPI.get(`${API_PATHS.SUBSCRIPTION}/check`, {
    subjectId: productId,
    topicName: "PRODUCT_SUBSCRIPTION"
  });
};

export const getProductCategories = async () => {
  const res = await backendAPI.get(API_PATHS.PRODUCTS_CATEGORIES);
  return res;
};

export const getProductTypeByCategory = async (category) => {
  const res = await backendAPI.get(`${API_PATHS.PRODUCTS_CATEGORIES}/${category}`);
  return res;
};

export const uploadProductImage = async (file) => {
  const formData = new FormData();
  formData.append("file", file);
  const result = backendAPI.postFormData(API_PATHS.PRODUCTS_IMAGE, formData);
  return result;
};

export const deleteProductImage = async (fileName) => {
  const res = await backendAPI.delete(`${API_PATHS.PRODUCTS_IMAGE}/${fileName}`);
  return res;
};

export const uploadSaleImage = async (file) => {
  const formData = new FormData();
  formData.append("file", file);
  const res = await backendAPI.post(API_PATHS.SALES_IMAGE, formData);
  return res;
};

export const deleteSaleImage = async (id) => {
  await backendAPI.delete(`${API_PATHS.SALES_IMAGE}/${id}`);
};
