import Box from "@mui/material/Box";
import MuiAppBar from "@mui/material/AppBar";
import React from "react";
import Toolbar from "@mui/material/Toolbar";
import { styled } from "@mui/material/styles";

const StyledAppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open"
})(({ theme }) => ({
  backgroundColor: theme.palette.grey[100],
  boxShadow: theme.shadows[0],
  borderBottom: "1px solid",
  borderBottomColor: theme.palette.divider,
  zIndex: theme.zIndex.drawer - 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen
  }),
  height: 50
}));

interface Props {
  leftElement: React.ReactNode;
  rightElement: React.ReactNode;
}

export const AppBarLayout = ({ leftElement, rightElement }: Props) => {
  return (
    <StyledAppBar position="relative" color="inherit">
      <Toolbar
        variant="dense"
        disableGutters
        sx={{
          paddingLeft: 2,
          paddingRight: 1,
          justifyContent: "space-between",
          alignItems: "center"
        }}
      >
        <Box display="flex" alignItems="center" justifyContent="center" height={40}>
          {leftElement}
        </Box>
        <Box>{rightElement}</Box>
      </Toolbar>
    </StyledAppBar>
  );
};
