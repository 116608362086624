import React from "react";
import { Document, Page, Image, StyleSheet, Text, Font, View } from "@react-pdf/renderer";

Font.register({
  family: "Roboto",
  src: "https://fonts.gstatic.com/s/roboto/v16/zN7GBFwfMP4uA6AR0HCoLQ.ttf"
});

const styles = StyleSheet.create({
  page: {
    padding: 12
  },
  text: {
    fontSize: 28,
    fontWeight: "bold",
    textAlign: "center",
    fontFamily: "Roboto"
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1
  }
});

export const PDFDocument = ({ chartImg = "", name = "", size = "A4", isPO }) => {
  return (
    <Document>
      <Page size={size} style={isPO ? null : styles.page}>
        <View style={isPO ? { paddingTop: 20 } : styles.section}>
          {!isPO && <Text style={styles.text}>{name}</Text>}
          <Image style={styles.image} src={chartImg} />
        </View>
      </Page>
    </Document>
  );
};
