import { createOTP, validateOTP } from "@/services/user-profile.service";
import React from "react";

import { useMutation } from "react-query";

interface Props {
  requestVerifyFn?: () => Promise<unknown>;
  validateFn?: (code: string) => Promise<unknown>;
  onReady?: () => void;
  onSuccess?: () => void;
  onError?: () => void;
}
export const usePhoneVerifier = ({
  requestVerifyFn = createOTP,
  validateFn = validateOTP,
  onReady,
  onError,
  onSuccess
}: Props) => {
  const [isVerifying, setIsVerifying] = React.useState(false);

  const requestMutation = useMutation(requestVerifyFn, {
    onError,
    onSuccess: () => {
      setIsVerifying(true);
      if (onReady) onReady();
    }
  });

  const verifyMutation = useMutation(validateFn, {
    onSuccess: () => {
      setIsVerifying(false);
      if (onSuccess) onSuccess();
    },
    onError
  });

  const startVerify = () => {
    requestMutation.mutate();
  };

  const verify = (code: string) => {
    verifyMutation.mutate(code);
  };

  const reset = () => {
    setIsVerifying(false);
  };

  return {
    isSubmitting: requestMutation.isLoading || verifyMutation.isLoading,
    isVerifying,
    startVerify,
    verify,
    reset
  };
};
