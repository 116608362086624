import * as ROUTES from "@/commons/consts/system/routes";
import { loadable } from "@/utils/loadable.util";

export const buyerRoutes = [
  {
    path: ROUTES.BUYER_DASHBOARD_ROUTE,
    Component: loadable(() => import("pages/buyer/buyer-dashboard/buyer-dashboard.page")),
    exact: true
  },
  {
    path: ROUTES.BUYER_MARKETPLACE_ROUTE,
    Component: loadable(() => import("pages/buyer/buyer-marketplace/buyer-marketplace.page")),
    exact: true
  },
  {
    path: ROUTES.BUYER_INVENTORY_ROUTE,
    Component: loadable(() => import("pages/buyer/buyer-inventory/buyer-inventory.page")),
    exact: true
  },
  {
    path: ROUTES.NOTIFICATION,
    Component: loadable(() => import("@/pages/notification/notification.page")),
    exact: true
  },
  {
    path: `${ROUTES.BUYER_BID_ON_MORE_PRODUCT}`,
    Component: loadable(
      () => import("pages/buyer/buyer-bid-on-more-product/buyer-bid-on-more-product.page")
    ),
    exact: true
  },
  {
    path: `${ROUTES.BUYER_INVENTORY_EXPLORE}/:id`,
    Component: loadable(() => import("pages/buyer/buyer-explore/buyer-explore.page")),
    exact: true
  },
  {
    path: `${ROUTES.BUYER_INVENTORY_EXPLORE}/:id/bid`,
    Component: loadable(() => import("pages/buyer/buyer-bid/buyer-bid.page")),
    exact: true
  },
  {
    path: `${ROUTES.BUYER_ITEM_SEARCH_ROUTE}`,
    Component: loadable(() => import("pages/buyer/buyer-item-search/buyer-item-search.page")),
    exact: true
  },
  {
    path: `${ROUTES.BUYER_BIDDING_ARENA}`,
    Component: loadable(() => import("pages/buyer/buyer-bidding-arena/buyer-bidding-arena.page")),
    exact: true
  },
  {
    path: `${ROUTES.BUYER_BID_NEGOTIATION}/:id`,
    Component: loadable(
      () => import("pages/buyer/buyer-bid-negotiation/buyer-bid-negotiation.page")
    ),
    exact: true
  },
  {
    path: `${ROUTES.BUYER_SUB_LIST}`,
    Component: loadable(() => import("pages/buyer/buyer-sub-list/buyer-sub-list.page")),
    exact: true
  },
  {
    path: `${ROUTES.BUYER_ORDER_MANAGEMENT}`,
    Component: loadable(
      () => import("pages/buyer/buyer-order-management/buyer-order-management.page")
    ),
    exact: true
  },
  {
    path: `${ROUTES.BUYER_CANCELLED_ORDER}`,
    Component: loadable(
      () => import("pages/buyer/buyer-cancelled-order/buyer-cancelled-order.page")
    ),
    exact: true
  },
  {
    path: `${ROUTES.BUYER_OFFER_APPROVED}/:id`,
    Component: loadable(() => import("pages/buyer/buyer-offer-approved/buyer-offer-approved.page")),
    exact: true
  },
  {
    path: `${ROUTES.BUYER_ACCOUNT_SUMMARY}`,
    Component: loadable(
      () => import("pages/buyer/buyer-account-summary/buyer-account-summary.page")
    ),
    exact: true
  },
  {
    path: `${ROUTES.BUYER_SERIAL_NUMBER}`,
    Component: loadable(() => import("pages/buyer/buyer-serial-number/buyer-serial-number.page")),
    exact: true
  },
  {
    path: `${ROUTES.BUYER_DOCUMENT_MANAGEMENT_DETAILS}`,
    Component: loadable(
      () => import("pages/buyer/buyer-doc-mgt-details/buyer-doc-mgt-details.page")
    ),
    exact: true
  },
  {
    path: `${ROUTES.BUYER_DOCUMENT_MANAGEMENT}`,
    Component: loadable(
      () => import("pages/buyer/buyer-document-management/buyer-document-management.page")
    ),
    exact: true
  },
  {
    path: [
      `${ROUTES.BUYER_PENDING_APPROVAL_POST_PRODUCT}/:id`,
      `${ROUTES.BUYER_APPROVAL_HISTORY_POST_PRODUCT}/:id`
    ],
    Component: loadable(
      () =>
        import(
          "pages/buyer/buyer-pending-approval-post-product/buyer-pending-approval-post-product.page"
        )
    ),
    exact: true
  },
  {
    path: `${ROUTES.BUYER_APPROVAL_ROUTE}`,
    Component: loadable(() => import("pages/buyer/buyer-approval/buyer-approval.page")),
    exact: true
  },
  {
    path: `${ROUTES.BUYER_ORDER_TRACK_AND_TRACE}`,
    Component: loadable(
      () => import("pages/buyer/buyer-order-track-and-trace/buyer-order-track-and-trace.page")
    ),
    exact: true
  },
  {
    path: `${ROUTES.BUYER_CHOOSE_LOGISTICS_PROVIDER}`,
    Component: loadable(
      () =>
        import("pages/buyer/buyer-choose-logistics-provider/buyer-choose-logistics-provider.page")
    ),
    exact: true
  },
  {
    path: `${ROUTES.BUYER_SHIPPING_DETAILS}/:id`,
    Component: loadable(
      () => import("pages/buyer/buyer-view-shipping-details/buyer-view-shipping-details.page")
    ),
    exact: true
  },
  {
    path: `${ROUTES.BUYER_ORDER_TRACK_SHIPMENT}`,
    Component: loadable(
      () => import("pages/buyer/buyer-order-track-shipment/buyer-order-track-shipment.page")
    ),
    exact: true
  },
  {
    path: `${ROUTES.BUYER_TRACK_ORDERS}`,
    Component: loadable(() => import("pages/buyer/buyer-track-orders/buyer-track-orders.page")),
    exact: true
  },
  {
    path: `${ROUTES.BUYER_TRACK_ORDERS_DETAILS}`,
    Component: loadable(
      () => import("pages/buyer/buyer-track-order-details/buyer-track-order-details.page")
    ),
    exact: true
  },
  {
    path: `${ROUTES.BUYER_TRACK_SHIPMENT}`,
    Component: loadable(() => import("pages/buyer/buyer-track-shipment/buyer-track-shipment.page")),
    exact: true
  },
  {
    path: `${ROUTES.BUYER_TRACK_SHIPMENT_DETAILS}`,
    Component: loadable(
      () => import("pages/buyer/buyer-track-shipment-details/buyer-track-shipment-details.page")
    ),
    exact: true
  },
  {
    path: `${ROUTES.BUYER_WALLET}`,
    Component: loadable(() => import("pages/buyer/buyer-wallet/buyer-wallet.page")),
    exact: true
  },
  {
    path: `${ROUTES.BUYER_ADD_FUNDS}`,
    Component: loadable(() => import("pages/buyer/buyer-add-funds/buyer-add-funds.page")),
    exact: true
  },
  {
    path: ROUTES.BUYER_PREFIX,
    Component: loadable(() => import("pages/buyer/buyer-home/buyer-home.page")),
    exact: true
  }
];
