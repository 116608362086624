import React from "react";
import SvgIcon from "@mui/material/SvgIcon";

function Notification(props) {
  return (
    <SvgIcon {...props} viewBox="0 0 24 24" data-testid="NotificationsIcon">
      <path
        d="M12 22C13.1 22 14 21.1 14 20H10C10 21.1 10.9 22 12 22ZM18 16V11C18 7.93 16.37 5.36 13.5 4.68V4C13.5 3.17 12.83 2.5 12 2.5C11.17 2.5 10.5 3.17 10.5 4V4.68C7.64 5.36 6 7.92 6 11V16L4 18V19H20V18L18 16ZM16 17H8V11C8 8.52 9.51 6.5 12 6.5C14.49 6.5 16 8.52 16 11V17Z"
        fill="black"
        fillOpacity="0.54"
      />
      <path
        d="M19.5 18.2071V18.5H4.5V18.2071L6.35355 16.3536L6.5 16.2071V16V11C6.5 8.0953 8.03148 5.78086 10.6157 5.16644L11 5.07506V4.68V4C11 3.44614 11.4461 3 12 3C12.5539 3 13 3.44614 13 4V4.68V5.07538L13.3847 5.16653C15.9774 5.78083 17.5 8.10389 17.5 11V16V16.2071L17.6464 16.3536L19.5 18.2071ZM16 17.5H16.5V17V11C16.5 9.6619 16.0927 8.41673 15.3156 7.49727C14.5318 6.56974 13.392 6 12 6C10.608 6 9.4682 6.56974 8.68436 7.49727C7.90733 8.41673 7.5 9.6619 7.5 11V17V17.5H8H16ZM13.4135 20.5C13.2061 21.0806 12.6488 21.5 12 21.5C11.3512 21.5 10.7939 21.0806 10.5865 20.5H13.4135Z"
        stroke="black"
        strokeOpacity="0.87"
      />
    </SvgIcon>
  );
}

export default Notification;
