import { ROUTES } from "@/commons/consts";
import { loadable } from "@/utils/loadable.util";
import { Layout } from "./layout.comp";

export const inventoryRoutes = [
  {
    key: "inventory-layout",
    Component: Layout,
    children: [
      {
        path: ROUTES.SELLER_PRODUCT_CATEGORIES,
        Component: loadable(() => import("@/pages/inventory/product-category.page")),
        exact: true
      },
      {
        path: ROUTES.SELLER_ADD_PRODUCT_BY_PRODUCT_TYPE,
        Component: loadable(() => import("@/pages/inventory/add-product-detail.page")),
        exact: true
      },
      {
        path: ROUTES.SELLER_FIND_PRODUCT,
        Component: loadable(() => import("@/pages/inventory/find-product.page")),
        exact: true
      },
      {
        path: ROUTES.SELLER_ITEM_LISTING,
        Component: loadable(() => import("@/pages/inventory/item-listing.page")),
        exact: true
      },
      {
        path: ROUTES.SELLER_ITEM_LISTING_EDIT,
        Component: loadable(() => import("@/pages/inventory/item-listing-edit.page")),
        exact: true
      },
      {
        path: ROUTES.SELLER_ITEM_LISTING_ADD,
        Component: loadable(() => import("@/pages/inventory/item-listing-add.page")),
        exact: true
      }
    ]
  }
];
