import {
  ADD_FUNDS,
  ADMIN_USER_MANAGEMENT_ROUTE,
  CREATE_TRADE_ROUTES,
  CREDIT_REQUEST,
  ORDERS
} from "@/commons/consts/system/routes";
import { OrderService, UserService } from "@/services";
import { getAllRecordsFromAPI, getPrefixUrl, useAsyncErrorHandler } from "@/utils";
import { NOTIFICATION_TYPE, ROUTES, ORDER_PROCESS, COMBO_TEXT } from "@/commons/consts";
import {
  getBuyerOrderDetail,
  getSellerOrderDetail,
  checkChangePaymentMethod
} from "@/services/order.service";
import { getProductDetails } from "@/services/product.service";
import {
  getBuyerBidDetails,
  getOfferDetail,
  getSellerBidDetails
} from "@/services/sale-and-bid.service";
import { cleanAndParseString, datetimeUtil } from "@/utils";
import { parseBidArray } from "@/commons/mappers";
import { COMMON_FIELDS } from "@/commons/schemas";
import {
  getBuyerBidNegotiationURL,
  getBuyerOrderManagementURL,
  getSellerBidNegotiationURL,
  getSellerOrderManagementURL
} from "@/utils/routes.util";
import { globalSnackbarRef } from "@/components/snackbar-provider/snackbar-provider.comp";
import { useHistory } from "@/components/commons/navigator";
import { useMessage } from "@/hooks/use-message";

const { sortByTimeAscending } = datetimeUtil;

const getAdminRelevantUser = async (companyId) => {
  const data = await getAllRecordsFromAPI(UserService.getAllUsers);
  return data.find((user) => user.id === companyId);
};

const getAdminTradeRoutePath = async (subjectId) => {
  const order = await OrderService.getOrderById(subjectId);
  return `${CREATE_TRADE_ROUTES}?productType=${order.productType}&productCategory=${order.productCategory}&from=${order.sellerCountry}&to=${order.buyerCountry}`;
};

const getAdminNavigateRoute = async (notificationType, subjectId) => {
  let targetRoute = "";

  switch (notificationType) {
    case NOTIFICATION_TYPE.ADMIN_PROMPT_PCC_PAYMENT_FOR_BUYER: {
      targetRoute = ADD_FUNDS;
      break;
    }
    case NOTIFICATION_TYPE.ADMIN_MARKETPLACE_CREDIT_REQUEST_PCC: {
      targetRoute = CREDIT_REQUEST;
      break;
    }
    case NOTIFICATION_TYPE.ADMIN_ROUTE_ADD_NEW_ADDRESS:
    case NOTIFICATION_TYPE.ADMIN_ROUTE_ADD_NEW_USER: {
      const res = await getAdminRelevantUser(subjectId);
      if (!res) break;
      targetRoute = `${ADMIN_USER_MANAGEMENT_ROUTE}?username=${res.username}&companyId=${res.id}`;
      break;
    }
    case NOTIFICATION_TYPE.ADMIN_ROUTE_ADD_ORDER: {
      return await getAdminTradeRoutePath(subjectId);
    }
    case NOTIFICATION_TYPE.ADMIN_CONFIRM_EXTERNAL_ORDER: {
      const params = new URLSearchParams();
      params.append("isPastOrders", "1");
      targetRoute = `${ORDERS}?${params.toString()}`;
      break;
    }
    default: {
      break;
    }
  }
  return targetRoute;
};

const notificationHandlers = {
  [NOTIFICATION_TYPE.BUYER_NEGOTIATION_COMMENT]: async (subjectId) => {
    const bids = await getBuyerBidDetails(subjectId);
    const parsedBids = parseBidArray(bids);
    const { saleId, negotiationId, bidDetail } = parsedBids.sort(
      sortByTimeAscending(COMMON_FIELDS.createdDate)
    )[0];
    const parsedProductName = cleanAndParseString(bidDetail[0].productName);
    const productName = bidDetail.length > 1 ? COMBO_TEXT : parsedProductName;
    return getBuyerBidNegotiationURL(negotiationId, productName, { saleId, focusComment: true });
  },
  [NOTIFICATION_TYPE.SELLER_NEGOTIATION_COMMENT]: async (subjectId) => {
    const result = await getSellerBidDetails(subjectId);
    const parsedBids = parseBidArray(result);
    const { bidDetail, saleId, negotiationId } = parsedBids.sort(
      sortByTimeAscending(COMMON_FIELDS.createdDate)
    )[0];
    const name = bidDetail.length > 1 ? COMBO_TEXT : bidDetail[0].productName;
    const parsedName = cleanAndParseString(name);
    const productName = `${parsedName}--${negotiationId}`;
    return getSellerBidNegotiationURL(negotiationId, productName, { saleId, focusComment: true });
  }
};

const getNavigateRoute = async (notificationType, subjectId, callback, isSeller) => {
  if (notificationType in notificationHandlers) {
    return notificationHandlers[notificationType](subjectId);
  }
  if (SUB_PROD_NOTI_TYPES.includes(notificationType)) {
    return getRouteForSubProdType(notificationType, subjectId);
  }
  if (SALE_NOTI_TYPES.includes(notificationType)) {
    return getRouteForSaleType(notificationType, subjectId);
  }
  if (BID_NOTI_TYPES.includes(notificationType)) {
    return getRouteForBidType(notificationType, subjectId, callback);
  }
  if (ORDER_SELLER_NOTI_TYPES.includes(notificationType)) {
    return getRouteForOrderSellerType(notificationType, subjectId);
  }
  if (ORDER_BUYER_NOTI_TYPES.includes(notificationType)) {
    return getRouteForOrderBuyerType(notificationType, subjectId);
  }
  if (BUYER_GMEX_NOTI_TYPE.includes(notificationType)) {
    return getRouteForGMEXBuyerType(notificationType);
  }
  if (SELLER_GMEX_NOTI_TYPE.includes(notificationType)) {
    return getRouteForGMEXSellerType(notificationType);
  }
  if (BUYER_MARKETPLACE_CREDIT.includes(notificationType)) {
    return getRouteForMarketplaceCredit(notificationType, subjectId);
  }
  if (CREATE_PROD_TYPES.includes(notificationType)) {
    return getRouteForProductCreation(notificationType, isSeller);
  }
  return "";
};

const CREATE_PROD_TYPES = [
  NOTIFICATION_TYPE.CCC_ENABLE_CREATE_PRODUCT,
  NOTIFICATION_TYPE.CCC_DISABLE_CREATE_PRODUCT
];

const SUB_PROD_NOTI_TYPES = [NOTIFICATION_TYPE.BUYER_SUBSCRIBE];

const SALE_NOTI_TYPES = [
  NOTIFICATION_TYPE.BUYER_SALE,
  NOTIFICATION_TYPE.SELLER_SALE,
  NOTIFICATION_TYPE.SELLER_SALE_CREATED_COMBO
];

const BID_NOTI_TYPES = [
  NOTIFICATION_TYPE.BUYER_BID_COUNTER,
  NOTIFICATION_TYPE.BUYER_BID_COUNTER_COMBO,
  NOTIFICATION_TYPE.BUYER_BID_ACCEPTED,
  NOTIFICATION_TYPE.BUYER_BID_ACCEPTED_COMBO,
  NOTIFICATION_TYPE.BUYER_BID_REJECTED,
  NOTIFICATION_TYPE.BUYER_BID_REJECTED_COMBO,
  NOTIFICATION_TYPE.BUYER_BID_DELETED,
  NOTIFICATION_TYPE.BUYER_BID_DELETED_COMBO,
  NOTIFICATION_TYPE.SELLER_BID_FIRST,
  NOTIFICATION_TYPE.SELLER_BID_FIRST_COMBO,
  NOTIFICATION_TYPE.SELLER_BID_ACCEPTED,
  NOTIFICATION_TYPE.SELLER_BID_ACCEPTED_COMBO,
  NOTIFICATION_TYPE.SELLER_BID_COUNTER,
  NOTIFICATION_TYPE.SELLER_BID_COUNTER_COMBO,
  NOTIFICATION_TYPE.SELLER_BID_REJECTED,
  NOTIFICATION_TYPE.SELLER_BID_REJECTED_COMBO,
  NOTIFICATION_TYPE.SELLER_BID_DELETED,
  NOTIFICATION_TYPE.SELLER_BID_DELETED_COMBO
];

const ORDER_SELLER_NOTI_TYPES = [
  NOTIFICATION_TYPE.SELLER_SERIAL_NUMBER,
  NOTIFICATION_TYPE.SELLER_CONFIRM_ADDITIONAL_DOCUMENT,
  NOTIFICATION_TYPE.SELLER_REVIEW,
  NOTIFICATION_TYPE.SELLER_SALE_APPROVED,
  NOTIFICATION_TYPE.SELLER_SALE_REJECTED,
  NOTIFICATION_TYPE.SELLER_SALE_REVIEW,
  NOTIFICATION_TYPE.SELLER_SHIPPING_DETAILS,
  NOTIFICATION_TYPE.SELLER_PICKUP_SUCCESS,
  NOTIFICATION_TYPE.SELLER_DELIVERY_SUCCESS,
  NOTIFICATION_TYPE.SELLER_INSPECTION,
  NOTIFICATION_TYPE.SELLER_INSPECTION_PASS,
  NOTIFICATION_TYPE.SELLER_INSPECTION_FAIL,
  NOTIFICATION_TYPE.SELLER_PREPARE_PICKUP,
  NOTIFICATION_TYPE.SELLER_FIRST_REMINDER_PICKUP,
  NOTIFICATION_TYPE.SELLER_SECOND_REMINDER_PICKUP,
  NOTIFICATION_TYPE.SELLER_CANCEL_SHIPMENT,
  NOTIFICATION_TYPE.SELLER_PROVIDE_PICKUP_DATE,
  NOTIFICATION_TYPE.SELLER_DOCUMENT_COMBO,
  NOTIFICATION_TYPE.SELLER_DOCUMENT,
  NOTIFICATION_TYPE.COOP_PROVIDE_SHIPPING_DETAILS,
  NOTIFICATION_TYPE.COOP_UPLOAD_DOCUMENT,
  NOTIFICATION_TYPE.SELLER_CONFIRM_PROFORMA_INVOICE,
  NOTIFICATION_TYPE.SELLER_PROFORMA_INVOICE_ACCEPTED,
  NOTIFICATION_TYPE.SELLER_PROFORMA_INVOICE_REJECTED,
  NOTIFICATION_TYPE.VIEW_PROFORMA_INVOICE_ACCEPTED_AS_SELLER,
  NOTIFICATION_TYPE.SELLER_VIEW_PROFORMA_INVOICE,
  NOTIFICATION_TYPE.SELLER_REJECT_PROFORMA_INVOICE,
  NOTIFICATION_TYPE.SELLER_BUYER_NOT_CONFIRM_PROFORMA_INVOICE,
  NOTIFICATION_TYPE.SELLER_NOT_CONFIRM_PROFORMA_INVOICE,
  NOTIFICATION_TYPE.SELLER_CONFIRM_EXTERNAL_ORDER
];

const ORDER_BUYER_NOTI_TYPES = [
  NOTIFICATION_TYPE.BUYER_SERIAL_NUMBER,
  NOTIFICATION_TYPE.BUYER_REVIEW,
  NOTIFICATION_TYPE.BUYER_PICKUP_SUCCESS,
  NOTIFICATION_TYPE.BUYER_SHIPMENT,
  NOTIFICATION_TYPE.BUYER_SHIPMENT_DOCUMENT,
  NOTIFICATION_TYPE.BUYER_LOGISTIC_PROVIDER,
  NOTIFICATION_TYPE.BUYER_INSPECTION,
  NOTIFICATION_TYPE.BUYER_PREPARE_PICKUP,
  NOTIFICATION_TYPE.BUYER_FIRST_LOAD_FUND,
  NOTIFICATION_TYPE.BUYER_SECOND_LOAD_FUND,
  NOTIFICATION_TYPE.BUYER_BLOCK_FUND_IVS,
  NOTIFICATION_TYPE.BUYER_BLOCK_FUND_TSF,
  NOTIFICATION_TYPE.BUYER_CANCEL_SHIPMENT,
  NOTIFICATION_TYPE.BUYER_DOCUMENT_COMBO,
  NOTIFICATION_TYPE.BUYER_DOCUMENT,
  NOTIFICATION_TYPE.COOP_LOAD_FUND,
  NOTIFICATION_TYPE.BUYER_VIEW_PROFORMA_INVOICE,
  NOTIFICATION_TYPE.BUYER_CONFIRM_PROFORMA_INVOICE,
  NOTIFICATION_TYPE.BUYER_PROFORMA_INVOICE_REJECTED,
  NOTIFICATION_TYPE.BUYER_SELLER_NOT_CONFIRM_PROFORMA_INVOICE,
  NOTIFICATION_TYPE.BUYER_NOT_CONFIRM_PROFORMA_INVOICE,
  NOTIFICATION_TYPE.PROMPT_PCC_CREATE_ROUTE,
  NOTIFICATION_TYPE.BUYER_CONFIRM_EXTERNAL_ORDER
];

const SELLER_ORDER_NOTI_TYPE_MAPPING = {
  [NOTIFICATION_TYPE.SELLER_SERIAL_NUMBER]: undefined,
  [NOTIFICATION_TYPE.SELLER_REVIEW]: undefined,
  [NOTIFICATION_TYPE.SELLER_SALE_APPROVED]: undefined,
  [NOTIFICATION_TYPE.SELLER_SALE_REJECTED]: undefined,
  [NOTIFICATION_TYPE.SELLER_SALE_REVIEW]: undefined,
  [NOTIFICATION_TYPE.SELLER_SHIPPING_DETAILS]: undefined,
  [NOTIFICATION_TYPE.SELLER_PICKUP_SUCCESS]: undefined,
  [NOTIFICATION_TYPE.SELLER_DELIVERY_SUCCESS]: undefined,
  [NOTIFICATION_TYPE.SELLER_INSPECTION]: undefined,
  [NOTIFICATION_TYPE.SELLER_INSPECTION_PASS]: undefined,
  [NOTIFICATION_TYPE.SELLER_INSPECTION_FAIL]: undefined,
  [NOTIFICATION_TYPE.SELLER_PREPARE_PICKUP]: undefined,
  [NOTIFICATION_TYPE.SELLER_FIRST_REMINDER_PICKUP]: undefined,
  [NOTIFICATION_TYPE.SELLER_SECOND_REMINDER_PICKUP]: undefined,
  [NOTIFICATION_TYPE.SELLER_CANCEL_SHIPMENT]: undefined,
  [NOTIFICATION_TYPE.SELLER_PROVIDE_PICKUP_DATE]: undefined,
  [NOTIFICATION_TYPE.COOP_PROVIDE_SHIPPING_DETAILS]: undefined,
  [NOTIFICATION_TYPE.COOP_UPLOAD_DOCUMENT]: undefined,
  [NOTIFICATION_TYPE.SELLER_DOCUMENT]: undefined
};

const BUYER_ORDER_NOTI_TYPE_MAPPING = {
  [NOTIFICATION_TYPE.BUYER_SERIAL_NUMBER]: undefined,
  [NOTIFICATION_TYPE.BUYER_REVIEW]: undefined,
  [NOTIFICATION_TYPE.BUYER_PICKUP_SUCCESS]: undefined,
  [NOTIFICATION_TYPE.BUYER_SHIPMENT]: undefined,
  [NOTIFICATION_TYPE.BUYER_DOCUMENT]: undefined,
  [NOTIFICATION_TYPE.BUYER_SHIPMENT_DOCUMENT]: undefined,
  [NOTIFICATION_TYPE.BUYER_LOGISTIC_PROVIDER]: [ORDER_PROCESS.CHOOSE_LOGISTICS_PROVIDER],
  [NOTIFICATION_TYPE.BUYER_INSPECTION]: undefined,
  [NOTIFICATION_TYPE.BUYER_PREPARE_PICKUP]: undefined,
  [NOTIFICATION_TYPE.BUYER_FIRST_LOAD_FUND]: undefined,
  [NOTIFICATION_TYPE.BUYER_SECOND_LOAD_FUND]: undefined,
  [NOTIFICATION_TYPE.BUYER_BLOCK_FUND_IVS]: undefined,
  [NOTIFICATION_TYPE.BUYER_BLOCK_FUND_TSF]: undefined,
  [NOTIFICATION_TYPE.BUYER_CANCEL_SHIPMENT]: undefined,
  [NOTIFICATION_TYPE.COOP_LOAD_FUND]: undefined
};

const BUYER_GMEX_NOTI_TYPE = [
  NOTIFICATION_TYPE.SELLER_RECEIPT_WITHDRAWAL,
  NOTIFICATION_TYPE.COMPLETION_WITHDRAWAL,
  NOTIFICATION_TYPE.SELLER_BALANCE_CREDIT,
  NOTIFICATION_TYPE.SELLER_BALANCE_DEBIT,
  NOTIFICATION_TYPE.SELLER_BALANCE_CREDIT_IVS
];

const BUYER_MARKETPLACE_CREDIT = [
  NOTIFICATION_TYPE.MARKETPLACE_CREDIT_ACCEPTED,
  NOTIFICATION_TYPE.MARKETPLACE_CREDIT_REJECTED,
  NOTIFICATION_TYPE.BUYER_CREDIT_LOAD_FUND
];

const SELLER_GMEX_NOTI_TYPE = [
  NOTIFICATION_TYPE.BUYER_RECEIPT_WITHDRAWAL,
  NOTIFICATION_TYPE.COMPLETION_WITHDRAWAL,
  NOTIFICATION_TYPE.BUYER_BALANCE_UPDATE_CREDIT,
  NOTIFICATION_TYPE.BUYER_BALANCE_UPDATE_DEBIT_IVS,
  NOTIFICATION_TYPE.BUYER_BALANCE_UPDATE_DEBIT_TSF
];

const getRouteForProductCreation = async (notificationType, isSeller) => {
  let targetRoute = "";
  switch (notificationType) {
    case NOTIFICATION_TYPE.CCC_ENABLE_CREATE_PRODUCT: {
      targetRoute = isSeller
        ? ROUTES.SELLER_PRODUCT_CREATION_ROUTE
        : ROUTES.BUYER_PRODUCT_CREATION_ROUTE;
      break;
    }
    default:
      break;
  }
  return targetRoute;
};

const getRouteForMarketplaceCredit = async (notificationType, subjectId) => {
  let targetRoute = "";
  switch (notificationType) {
    case NOTIFICATION_TYPE.MARKETPLACE_CREDIT_ACCEPTED: {
      targetRoute = ROUTES.BUYER_ORDER_MANAGEMENT;
      break;
    }
    case NOTIFICATION_TYPE.MARKETPLACE_CREDIT_REJECTED: {
      const res = await checkChangePaymentMethod(subjectId);
      if (res.isUpdated === true) {
        targetRoute = "";
        globalSnackbarRef.current?.warning(
          `${res.error ? res.error : "You have already updated the payment method"}`
        );
      } else {
        targetRoute = `${ROUTES.BUYER_ORDER_MANAGEMENT}?showChoosePaymentMethods=true&oid=${subjectId}`;
      }
      break;
    }
    case NOTIFICATION_TYPE.PROMPT_USER_ADD_FUND:
    case NOTIFICATION_TYPE.BUYER_CREDIT_LOAD_FUND: {
      targetRoute = ROUTES.BUYER_ADD_FUNDS;
      break;
    }
    default: {
      break;
    }
  }
  return targetRoute;
};

const getRouteForSubProdType = async (notificationType, subjectId) => {
  let targetRoute = "";
  const { buildExploreRoute } = ROUTES.buyerRouteBuilder;
  switch (notificationType) {
    // Subscribe a Product
    case NOTIFICATION_TYPE.BUYER_SUBSCRIBE: {
      const { id, name } = await getProductDetails({ productId: subjectId });
      targetRoute = buildExploreRoute(name, id);
      break;
    }
    default: {
      break;
    }
  }
  return targetRoute;
};

const getRouteForSaleType = async (notificationType, subjectId) => {
  let targetRoute = "";
  const { buildExploreRoute } = ROUTES.buyerRouteBuilder;
  switch (notificationType) {
    // Sale
    case NOTIFICATION_TYPE.BUYER_SALE: {
      const { saleDetailResponses } = await getOfferDetail({ saleId: subjectId });
      const { name, id } = saleDetailResponses[0].productResponse;
      targetRoute = buildExploreRoute(name, id);
      break;
    }
    case NOTIFICATION_TYPE.SELLER_SALE_CREATED_COMBO:
    case NOTIFICATION_TYPE.SELLER_SALE: {
      const { saleDetailResponses } = await getOfferDetail({ saleId: subjectId });
      const productName =
        saleDetailResponses.length > 1 ? COMBO_TEXT : saleDetailResponses[0].productResponse.name;
      const parsedProductName = cleanAndParseString(productName);
      targetRoute = `${ROUTES.SELLER_BID_DETAILS}/${parsedProductName}--${subjectId}`;
      break;
    }

    default: {
      break;
    }
  }
  return targetRoute;
};

const getRouteForBidType = async (notificationType, subjectId, callback) => {
  let targetRoute = "";
  const { buildExploreRoute } = ROUTES.buyerRouteBuilder;
  switch (notificationType) {
    // Bid
    case NOTIFICATION_TYPE.BUYER_BID_COUNTER_COMBO:
    case NOTIFICATION_TYPE.BUYER_BID_COUNTER: {
      const bids = await getBuyerBidDetails(subjectId);
      const parsedBids = parseBidArray(bids);
      const { saleId, negotiationId, status, bidDetail } = parsedBids.sort(
        sortByTimeAscending(COMMON_FIELDS.createdDate)
      )[0];
      if (status === "CANCELLED") {
        targetRoute = "";
        callback && callback();
      } else {
        const parsedProductName = cleanAndParseString(bidDetail[0].productName);
        targetRoute = `${ROUTES.BUYER_BID_NEGOTIATION}/${
          bidDetail.length > 1 ? COMBO_TEXT : parsedProductName
        }--${negotiationId}?sid=${saleId}`;
      }
      break;
    }
    case NOTIFICATION_TYPE.BUYER_BID_ACCEPTED_COMBO:
    case NOTIFICATION_TYPE.BUYER_BID_ACCEPTED: {
      const { productOrderResponses, combo } = await getBuyerOrderDetail(subjectId);
      const parsedProductName = cleanAndParseString(
        combo ? COMBO_TEXT : productOrderResponses[0].productName
      );
      targetRoute = `${ROUTES.BUYER_ORDER_MANAGEMENT}/offer-approved/${parsedProductName}--${subjectId}`;
      break;
    }
    case NOTIFICATION_TYPE.BUYER_BID_REJECTED_COMBO:
    case NOTIFICATION_TYPE.BUYER_BID_DELETED_COMBO: {
      targetRoute = ROUTES.BUYER_MARKETPLACE_ROUTE;
      break;
    }
    case NOTIFICATION_TYPE.BUYER_BID_REJECTED:
    case NOTIFICATION_TYPE.BUYER_BID_DELETED: {
      const { name, id } = await getProductDetails({ productId: subjectId });
      targetRoute = buildExploreRoute(name, id);
      break;
    }
    case NOTIFICATION_TYPE.SELLER_BID_FIRST_COMBO:
    case NOTIFICATION_TYPE.SELLER_BID_FIRST:
    case NOTIFICATION_TYPE.SELLER_BID_ACCEPTED_COMBO:
    case NOTIFICATION_TYPE.SELLER_BID_ACCEPTED:
    case NOTIFICATION_TYPE.SELLER_BID_COUNTER_COMBO:
    case NOTIFICATION_TYPE.SELLER_BID_COUNTER: {
      const result = await getSellerBidDetails(subjectId);
      const parsedBids = parseBidArray(result);
      const { bidDetail, saleId, negotiationId, status } = parsedBids.sort(
        sortByTimeAscending(COMMON_FIELDS.createdDate)
      )[0];
      const name = bidDetail.length > 1 ? COMBO_TEXT : bidDetail[0].productName;
      if (status === "CANCELLED") {
        targetRoute = window.location.pathname;
        callback && callback();
      } else {
        const parsedName = cleanAndParseString(name);
        targetRoute = `${ROUTES.SELLER_BID_NEGOTIATION}/${parsedName}--${negotiationId}?sid=${saleId}`;
      }
      break;
    }
    case NOTIFICATION_TYPE.SELLER_BID_REJECTED_COMBO:
    case NOTIFICATION_TYPE.SELLER_BID_REJECTED:
    case NOTIFICATION_TYPE.SELLER_BID_DELETED_COMBO:
    case NOTIFICATION_TYPE.SELLER_BID_DELETED: {
      const result = await getSellerBidDetails(subjectId);
      const parsedBids = parseBidArray(result);
      const { productName, saleId, negotiationId } = parsedBids[0];
      const parsedProductName = cleanAndParseString(productName);
      targetRoute = `${ROUTES.SELLER_BID_NEGOTIATION}/${parsedProductName}--${negotiationId}?sid=${saleId}`;
      break;
    }

    default: {
      break;
    }
  }
  return targetRoute;
};

const getRouteForOrderSellerType = async (notificationType, subjectId) => {
  let targetRoute = "";
  if (
    notificationType === NOTIFICATION_TYPE.SELLER_VIEW_PROFORMA_INVOICE ||
    notificationType === NOTIFICATION_TYPE.SELLER_PROFORMA_INVOICE_ACCEPTED ||
    notificationType === NOTIFICATION_TYPE.SELLER_PROFORMA_INVOICE_REJECTED
  ) {
    notificationType = "PROFORMA_INVOICE_ROUTE";
  }

  switch (notificationType) {
    case NOTIFICATION_TYPE.SELLER_SERIAL_NUMBER: {
      const { number, id } = await getSellerOrderDetail(subjectId);
      targetRoute = `${ROUTES.SELLER_DOCUMENT_MANAGEMENT.replace(
        ":orderNumber",
        number
      )}?oid=${id}&process=Upload%20Serial%20Number`;
      break;
    }

    case NOTIFICATION_TYPE.SELLER_CONFIRM_ADDITIONAL_DOCUMENT: {
      const { productOrderResponses, quoteId, serviceProviderId, serviceName, logisticType } =
        await getSellerOrderDetail(subjectId);
      const parseProductName = cleanAndParseString(productOrderResponses[0].productName);
      targetRoute = `${ROUTES.SELLER_CONFIRM_ADDITIONAL_DOCUMENT}/${parseProductName}--${subjectId}?quoteId=${quoteId}&serviceProviderId=${serviceProviderId}&serviceName=${serviceName}&logisticType=${logisticType}`;
      break;
    }

    case NOTIFICATION_TYPE.SELLER_REVIEW: {
      targetRoute = `${ROUTES.SELLER_ORDER_MANAGEMENT}?showReviewNotice=true&oid=${subjectId}`;
      break;
    }

    case NOTIFICATION_TYPE.SELLER_SALE_APPROVED: {
      const { productId, productName } = await getOfferDetail({ saleId: subjectId });
      const parsedProductName = cleanAndParseString(productName);
      targetRoute = `${ROUTES.SELLER_MARKETPLACE_EXPLORE_ROUTE}/${parsedProductName}--${productId}`;
      break;
    }

    case NOTIFICATION_TYPE.SELLER_SALE_REJECTED: {
      targetRoute = ROUTES.SELLER_ITEM_LISTING_ROUTE;
      break;
    }

    case NOTIFICATION_TYPE.SELLER_SALE_REVIEW: {
      targetRoute = ROUTES.SELLER_APPROVAL_ROUTE;
      break;
    }

    case NOTIFICATION_TYPE.SELLER_SHIPPING_DETAILS: {
      const { number } = await getSellerOrderDetail(subjectId);
      targetRoute = `${ROUTES.SELLER_PROVIDE_SHIPPING_DETAILS.replace(
        ":orderNumber",
        number
      )}?oid=${subjectId}`;
      break;
    }

    case NOTIFICATION_TYPE.SELLER_PICKUP_SUCCESS: {
      const { number } = await getSellerOrderDetail(subjectId);
      targetRoute = `${ROUTES.SELLER_ORDER_TRACK_SHIPMENT.replace(
        ":orderNumber",
        number
      )}?oid=${subjectId}`;
      break;
    }

    case NOTIFICATION_TYPE.SELLER_DELIVERY_SUCCESS:
    case NOTIFICATION_TYPE.SELLER_INSPECTION:
    case NOTIFICATION_TYPE.SELLER_INSPECTION_PASS:
    case NOTIFICATION_TYPE.SELLER_INSPECTION_FAIL: {
      targetRoute = `${ROUTES.SELLER_ORDER_MANAGEMENT}?showInspection=true&oid=${subjectId}`;
      break;
    }

    case NOTIFICATION_TYPE.SELLER_PREPARE_PICKUP:
    case NOTIFICATION_TYPE.SELLER_FIRST_REMINDER_PICKUP:
    case NOTIFICATION_TYPE.SELLER_SECOND_REMINDER_PICKUP: {
      break;
    }

    case NOTIFICATION_TYPE.SELLER_CANCEL_SHIPMENT: {
      const { id, name } = await getProductDetails({ productId: subjectId });
      const parsedProductName = cleanAndParseString(name);
      targetRoute = `${ROUTES.BUYER_INVENTORY_EXPLORE}/${parsedProductName}--${id}`;
      break;
    }

    case NOTIFICATION_TYPE.SELLER_PROVIDE_PICKUP_DATE: {
      const { number } = await getSellerOrderDetail(subjectId);
      targetRoute = `${ROUTES.SELLER_PROVIDE_SHIPPING_DETAILS.replace(
        ":orderNumber",
        number
      )}?oid=${subjectId}`;
      break;
    }

    case NOTIFICATION_TYPE.SELLER_DOCUMENT:
    case NOTIFICATION_TYPE.SELLER_DOCUMENT_COMBO: {
      const { number } = await getSellerOrderDetail(subjectId);
      targetRoute = `${ROUTES.SELLER_DOCUMENT_MANAGEMENT.replace(
        ":orderNumber",
        number
      )}?oid=${subjectId}`;
      break;
    }

    case NOTIFICATION_TYPE.BUYER_CONFIRM_PROFORMA_INVOICE:
    case NOTIFICATION_TYPE.SELLER_VIEW_PROFORMA_INVOICE: {
      const { number } = await getBuyerOrderDetail(subjectId);
      targetRoute = `${ROUTES.BUYER_DOCUMENT_MANAGEMENT_DETAILS.replace(
        ":orderNumber",
        number
      ).replace(":docId", "pro-forma-invoice")}?oid=${subjectId}`;
      break;
    }

    case NOTIFICATION_TYPE.COOP_PROVIDE_SHIPPING_DETAILS:
    case NOTIFICATION_TYPE.COOP_UPLOAD_DOCUMENT:
    case NOTIFICATION_TYPE.SELLER_CONFIRM_PROFORMA_INVOICE:
    case "PROFORMA_INVOICE_ROUTE": {
      const { number } = await getSellerOrderDetail(subjectId);
      targetRoute = `${ROUTES.SELLER_DOCUMENT_MANAGEMENT_DETAILS.replace(
        ":orderNumber",
        number
      ).replace(":docId", "pro-forma-invoice")}?oid=${subjectId}`;
      break;
    }

    case NOTIFICATION_TYPE.SELLER_BUYER_NOT_CONFIRM_PROFORMA_INVOICE:
    case NOTIFICATION_TYPE.SELLER_NOT_CONFIRM_PROFORMA_INVOICE:
    case NOTIFICATION_TYPE.SELLER_REJECT_PROFORMA_INVOICE: {
      targetRoute = `${ROUTES.SELLER_CANCELLED_ORDER}`;
      break;
    }

    case NOTIFICATION_TYPE.SELLER_CONFIRM_EXTERNAL_ORDER: {
      //Get order number and adding to URL query
      const { number } = await getSellerOrderDetail(subjectId);
      targetRoute = getSellerOrderManagementURL({ isPastOrders: "1", searchText: number });
      break;
    }

    default: {
      break;
    }
  }
  return targetRoute;
};

const getRouteForOrderBuyerType = async (notificationType, subjectId) => {
  let targetRoute = "";
  if (
    notificationType === NOTIFICATION_TYPE.BUYER_VIEW_PROFORMA_INVOICE ||
    notificationType === NOTIFICATION_TYPE.BUYER_CONFIRM_PROFORMA_INVOICE ||
    notificationType === NOTIFICATION_TYPE.SELLER_VIEW_PROFORMA_INVOICE
  ) {
    notificationType = "PROFORMA_INVOICE_ROUTE";
  }

  switch (notificationType) {
    // Buyer
    case NOTIFICATION_TYPE.BUYER_SERIAL_NUMBER: {
      const { number, id } = await getBuyerOrderDetail(subjectId);
      targetRoute = `${ROUTES.BUYER_ORDER_MANAGEMENT}/${number}/serial-number?oid=${id}`;
      break;
    }

    case NOTIFICATION_TYPE.BUYER_REVIEW: {
      targetRoute = `${ROUTES.BUYER_ORDER_MANAGEMENT}?showReviewNotice=true&oid=${subjectId}`;
      break;
    }

    case NOTIFICATION_TYPE.BUYER_PICKUP_SUCCESS: {
      const { number } = await getBuyerOrderDetail(subjectId);
      targetRoute = `${ROUTES.BUYER_ORDER_TRACK_SHIPMENT.replace(
        ":orderNumber",
        number
      )}?oid=${subjectId}`;
      break;
    }

    case NOTIFICATION_TYPE.BUYER_DOCUMENT:
    case NOTIFICATION_TYPE.BUYER_SHIPMENT:
    case NOTIFICATION_TYPE.BUYER_SHIPMENT_DOCUMENT:
    case NOTIFICATION_TYPE.PROMPT_PCC_CREATE_ROUTE: {
      const { number } = await getBuyerOrderDetail(subjectId);
      targetRoute = `${ROUTES.BUYER_DOCUMENT_MANAGEMENT.replace(
        ":orderNumber",
        number
      )}?oid=${subjectId}`;
      break;
    }

    case NOTIFICATION_TYPE.BUYER_LOGISTIC_PROVIDER: {
      const { number } = await getBuyerOrderDetail(subjectId);
      targetRoute = `${ROUTES.BUYER_CHOOSE_LOGISTICS_PROVIDER.replace(
        ":orderNumber",
        number
      )}?oid=${subjectId}`;
      break;
    }

    case NOTIFICATION_TYPE.BUYER_INSPECTION: {
      targetRoute = `${ROUTES.BUYER_ORDER_MANAGEMENT}?showInspection=true&oid=${subjectId}`;
      break;
    }

    case NOTIFICATION_TYPE.BUYER_PREPARE_PICKUP: {
      break;
    }

    case NOTIFICATION_TYPE.COOP_LOAD_FUND:
    case NOTIFICATION_TYPE.BUYER_FIRST_LOAD_FUND:
    case NOTIFICATION_TYPE.BUYER_SECOND_LOAD_FUND: {
      targetRoute = ROUTES.BUYER_ADD_FUNDS;
      break;
    }

    case NOTIFICATION_TYPE.BUYER_BLOCK_FUND_IVS:
    case NOTIFICATION_TYPE.BUYER_BLOCK_FUND_TSF: {
      targetRoute = ROUTES.BUYER_WALLET;
      break;
    }

    case NOTIFICATION_TYPE.BUYER_CANCEL_SHIPMENT: {
      const { id, name } = await getProductDetails({ productId: subjectId });
      const parsedProductName = cleanAndParseString(name);
      targetRoute = `${ROUTES.SELLER_MARKETPLACE_EXPLORE_ROUTE}/${parsedProductName}--${id}`;
      break;
    }

    case NOTIFICATION_TYPE.BUYER_DOCUMENT_COMBO: {
      const { number } = await getBuyerOrderDetail(subjectId);
      targetRoute = `${ROUTES.BUYER_DOCUMENT_MANAGEMENT.replace(
        ":orderNumber",
        number
      )}?oid=${subjectId}`;
      break;
    }
    case "PROFORMA_INVOICE_ROUTE": {
      const { number } = await getBuyerOrderDetail(subjectId);
      targetRoute = `${ROUTES.BUYER_DOCUMENT_MANAGEMENT_DETAILS.replace(
        ":orderNumber",
        number
      ).replace(":docId", "pro-forma-invoice")}?oid=${subjectId}`;
      break;
    }

    case NOTIFICATION_TYPE.BUYER_PROFORMA_INVOICE_REJECTED:
    case NOTIFICATION_TYPE.BUYER_NOT_CONFIRM_PROFORMA_INVOICE:
    case NOTIFICATION_TYPE.BUYER_SELLER_NOT_CONFIRM_PROFORMA_INVOICE: {
      targetRoute = `${ROUTES.BUYER_CANCELLED_ORDER}`;
      break;
    }

    case NOTIFICATION_TYPE.BUYER_CONFIRM_EXTERNAL_ORDER: {
      //Get order number and adding to URL query
      const { number } = await getBuyerOrderDetail(subjectId);
      targetRoute = getBuyerOrderManagementURL({ isPastOrders: "1", searchText: number });
      break;
    }

    default: {
      break;
    }
  }
  return targetRoute;
};

const getRouteForGMEXSellerType = async (notificationType) => {
  switch (notificationType) {
    case NOTIFICATION_TYPE.SELLER_RECEIPT_WITHDRAWAL:
    case NOTIFICATION_TYPE.COMPLETION_WITHDRAWAL:
    case NOTIFICATION_TYPE.SELLER_BALANCE_CREDIT:
    case NOTIFICATION_TYPE.SELLER_BALANCE_DEBIT:
    case NOTIFICATION_TYPE.SELLER_BALANCE_CREDIT_IVS:
  }
  return "";
};

const getRouteForGMEXBuyerType = async (notificationType) => {
  let targetRoute = "";
  switch (notificationType) {
    case NOTIFICATION_TYPE.SELLER_RECEIPT_WITHDRAWAL:
    case NOTIFICATION_TYPE.COMPLETION_WITHDRAWAL:
    case NOTIFICATION_TYPE.SELLER_BALANCE_CREDIT:
    case NOTIFICATION_TYPE.SELLER_BALANCE_DEBIT:
    case NOTIFICATION_TYPE.SELLER_BALANCE_CREDIT_IVS:
    default: {
      targetRoute = "";
      break;
    }
  }
  return targetRoute;
};

const isValidNotification = async (notiType, subjectId) => {
  //check seller type
  if (SELLER_ORDER_NOTI_TYPE_MAPPING[notiType] !== undefined) {
    const { sellerProcess } = await getSellerOrderDetail(subjectId);
    return SELLER_ORDER_NOTI_TYPE_MAPPING[notiType].includes(sellerProcess);
  } else if (BUYER_ORDER_NOTI_TYPE_MAPPING[notiType] !== undefined) {
    const { buyerProcess } = await getBuyerOrderDetail(subjectId);
    return BUYER_ORDER_NOTI_TYPE_MAPPING[notiType].includes(buyerProcess);
  } else {
    return true;
  }
};

export const useNotificationHandler = () => {
  const asyncErrorHandlerWrapper = useAsyncErrorHandler();
  const history = useHistory();
  const messageResult = useMessage();
  const isSeller = getPrefixUrl(history.location.pathname) === ROUTES.SELLER_PREFIX;

  const handleClick = (data) => {
    const { notificationType, subjectId } = data;

    const redirectWithNewState = (targetRoute) => {
      if (targetRoute.length) {
        history.push(targetRoute, { value: new Date().getUTCMilliseconds() });
      }
    };

    const openNotiWhenBidDeleted = () => {
      messageResult.error("This Sale Offer has been deleted!");
    };

    const handleNavigateToAction = () => {
      asyncErrorHandlerWrapper(async () => {
        const isValid = await isValidNotification(notificationType, subjectId);
        if (isValid === false) {
          messageResult.error("Your link has expired!");
          return;
        }

        const targetRoute = await getNavigateRoute(
          notificationType,
          subjectId,
          openNotiWhenBidDeleted,
          isSeller
        );

        if (targetRoute) {
          redirectWithNewState(targetRoute);
          return;
        }
        const adminTargetRoute = await getAdminNavigateRoute(notificationType, subjectId);
        redirectWithNewState(adminTargetRoute);
      });
    };

    handleNavigateToAction();
  };
  return { handleClick };
};
