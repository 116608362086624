import React, { useRef, useEffect, useState } from "react";
export const useCompanyAddressController = (companyAddress) => {
  const [selectedCountry, setSelectedCountry] = useState();
  const refCompanyAddressFromArr = React.useRef([]);
  const deletedIds = useRef([]);
  useEffect(() => {
    if (companyAddress && Array.isArray(companyAddress)) {
      companyAddress[0] && setSelectedCountry(companyAddress[0].country);
    }
  }, [companyAddress]);
  const handleDeleteCompanyAddress = (id) => {
    deletedIds.current = [...deletedIds.current, id];
  };
  const handleCountryChange = (value) => {
    setSelectedCountry(value);
    refCompanyAddressFromArr.current &&
      refCompanyAddressFromArr.current.forEach((instance) => {
        instance.form.setFieldsValue({ country: value });
      });
  };
  const getDeletedIds = () => {
    const ids = deletedIds?.current ?? [];
    return ids.filter((id) => companyAddress.find((address) => address.id === id));
  };
  const composeValues = () => {
    const addressForms = refCompanyAddressFromArr.current.map((item) => item.form);
    let addresses = [];
    let hasError = false;
    addressForms &&
      addressForms.forEach((form) =>
        form.validateFields((error, values) => {
          if (error) {
            hasError = true;
          } else {
            addresses.push(values);
          }
        })
      );
    const originIds = companyAddress?.map((address) => address.id) ?? [];

    // Remove deleteIds in array after added id props
    if (deletedIds && deletedIds.current && deletedIds.current.length > 0) {
      deletedIds.current = deletedIds.current.filter((id) => originIds.includes(id));
    }

    // Replace new id by undefined
    addresses = addresses.map((address) => {
      if (originIds.includes(address.id)) {
        return address;
      }
      return { ...address, id: undefined };
    });

    return [hasError, addresses, deletedIds.current];
  };
  return {
    composeValues,
    refCompanyAddressFromArr,
    getDeletedIds,
    handleCountryChange,
    handleDeleteCompanyAddress,
    selectedCountry
  };
};
