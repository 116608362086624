export const getAllFromAPI = async <P, T>(
  fn: (params: P) => Promise<T>,
  {
    initialParams,
    hasNext,
    nextParams,
    maxLoop: _maxLoop = 100
  }: {
    initialParams: P;
    hasNext: (result: T) => boolean;
    nextParams: (params: P) => P;
    maxLoop?: number;
  }
) => {
  let params = initialParams;
  const results: T[] = [];
  let maxLoop = _maxLoop;
  do {
    const result = await fn(params);
    results.push(result);
    if (!hasNext(result)) {
      break;
    }
    params = nextParams(params);
    maxLoop--;
  } while (maxLoop > 0);
  return results;
};

export const getAllRecordsFromAPI = async (
  serviceFn,
  options = {
    outerParams: null,
    sortTerm: null,
    sortOrder: null
  }
) => {
  const { outerParams, sortTerm, sortOrder } = options;
  const maxSize = 2000;
  let page = 0;

  const res = await serviceFn({
    page: page,
    size: maxSize,
    sort: `${sortTerm},${sortOrder}`,
    ...outerParams
  });

  let allDataRes = { ...res };

  if (res) {
    const { totalElements } = res;
    if (totalElements > maxSize) {
      let remainElements = totalElements - maxSize;
      while (true) {
        page = page + 1;
        const remainRes = await serviceFn({
          size: maxSize,
          page,
          sort: `${sortTerm},${sortOrder}`,
          ...outerParams
        });
        allDataRes = { ...allDataRes, content: [...allDataRes.content, ...remainRes.content] };
        remainElements = remainElements - maxSize;
        if (remainElements <= 0) {
          break;
        }
      }
    }
  }

  return allDataRes.content;
};
