import React from "react";
import { Box, Button, Grid, Popover, Stack, Typography } from "@mui/material";
import { useHistory } from "react-router-dom";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

interface PortalItemProps {
  icon: React.ReactNode;
  label: string;
  link: string;
  isExternalLink?: boolean;
}

const PortalItem = ({ icon, link, label, isExternalLink }: PortalItemProps) => {
  const history = useHistory();
  const handleClick = () => {
    if (isExternalLink) {
      window && window.open(link, "_blank");
      return;
    }
    history.push(link);
  };

  return (
    <Grid
      item
      xs={4}
      md={4}
      minWidth="150px"
      sx={{
        color: "common.black",
        textAlign: "center",
        "&:hover": {
          color: "primary.main"
        }
      }}
    >
      <Stack role="button" onClick={handleClick}>
        <Box>{icon}</Box>
        <Typography variant="body2">{label}</Typography>
      </Stack>
    </Grid>
  );
};

const styles = {
  paper: {
    overflowX: "unset",
    overflowY: "unset",
    boxShadow:
      "0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12)",
    borderRadius: "10px",
    padding: "28px",
    left: "10px !important",
    marginTop: "26px",
    "&::before": {
      content: '""',
      position: "absolute",
      marginRight: "-0.71em",
      top: -4,
      left: 47,
      width: 24,
      height: 15,
      background: "#FFFFFF",
      transform: "rotate(-45deg)",
      clipPath: "polygon(-5px -5px, calc(100% + 5px) -5px, calc(100% + 5px) calc(100% + 5px))"
    }
  }
};

interface PopoverMenuProps {
  portals: PortalItemProps[];
  anchorEl: Element | null;
  open?: boolean;
  onClose: () => void;
}
const PopoverMenu = ({ anchorEl, open, onClose, portals }: PopoverMenuProps) => {
  return (
    <Popover
      open={open}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left"
      }}
      PaperProps={{ sx: styles.paper }}
    >
      <Grid container rowSpacing={3} columnSpacing={3}>
        {portals.map((portal) => (
          <PortalItem key={portal.label} {...portal} />
        ))}
      </Grid>
    </Popover>
  );
};

interface Props {
  portals?: PortalItemProps[];
  label?: string;
}
export const PortalSwitcher = ({ portals, label }: Props) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const openPopover = Boolean(anchorEl);

  const onHandleOpenMenu = (event) => {
    if (!portals || portals.length === 0) return;
    setAnchorEl(event.currentTarget);
  };

  const onHandleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Button onClick={onHandleOpenMenu} variant="text" sx={{ color: "common.black" }}>
        <Stack spacing={1} direction="row" alignItems="center">
          {label && (
            <Typography variant="subtitle1" fontWeight="bold">
              {label}
            </Typography>
          )}
          {portals && <KeyboardArrowDownIcon />}
        </Stack>
      </Button>
      {portals && (
        <PopoverMenu
          portals={portals}
          onClose={onHandleClose}
          open={openPopover}
          anchorEl={anchorEl}
        />
      )}
    </>
  );
};
