import React from "react";
import { DatePicker } from "antd";
import { DATE_FORMAT } from "@/commons/consts";
import moment from "moment";
import { getDateFormat } from "@/utils/config.util";

export const WrappedDatePicker = React.forwardRef((props, ref) => {
  const handleChange = (value) => {
    try {
      const formatDate = value.format(DATE_FORMAT);
      props.onChange && props.onChange(formatDate);
    } catch {
      props.onChange && props.onChange(undefined);
    }
  };
  return (
    <DatePicker
      ref={ref}
      style={{ width: "100%" }}
      format={getDateFormat()}
      {...props}
      value={props.value ? moment(props.value) : undefined}
      onChange={handleChange}
    />
  );
});
