import React from "react";
import { ExclamationCircleFilled } from "@ant-design/icons";
import { Modal, Button } from "antd";
import PropTypes from "prop-types";
import clsx from "classnames";

import classes from "./skip-kyc-confirm-modal.module.scss";
export const SkipKYCConfirmModal = ({ visible, onClose, onSkip }) => {
  return (
    <Modal
      onCancel={onClose}
      maskClosable={false}
      mask={false}
      centered
      visible={visible}
      footer={null}
      width={400}
      bodyStyle={{ height: 300, textAlign: "center", zIndex: 9999 }}
    >
      <div className={classes.content}>
        <span className={clsx("text-danger", classes.iconError)}>
          <ExclamationCircleFilled />
        </span>
        <h5>Are you sure ?</h5>
        <p>Your access to the Marketplace will be limited until you complete the KYC steps.</p>
        <div className={classes.btnGroup}>
          <Button type="link" onClick={onClose}>
            Cancel
          </Button>
          <Button type="danger" onClick={onSkip}>
            Skip For Now
          </Button>
        </div>
      </div>
    </Modal>
  );
};

SkipKYCConfirmModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSkip: PropTypes.func.isRequired
};
