import React from "react";
import { hotjar } from "@/hotjar/hotjar";

import { Loader } from "@/components/commons";
import Flags from "@/commons/consts/flags.const";
import { getFlags } from "@/utils/config.util";
import { GlobalHotKeys } from "react-hotkeys";
import { DTCModal } from "@/components/commons/dtc-modal/dtc-modal.comp";
import { SettingContent } from "./setting-content.comp";

import { FeatureFlagResponse } from "@/services/preference.service";
import { useMarketplaceSettings } from "@/entities/marketplace-settings/libs/use-marketplace-settings";

interface ISettingsContext {
  hotJarId: string;
  whoCanCreateProduct: string;
  flags: Set<string>;
  updateFlags: (flags: Set<string>) => void;
}
const SettingsContext = React.createContext<ISettingsContext>({} as never);

const keyMap = {
  SETTINGS: ["command+shift+k", "alt+shift+k"]
};

export const SettingsProvider = ({ children }: { children: React.ReactNode }) => {
  const { data, isLoading } = useMarketplaceSettings();
  if (isLoading || data == null) return <Loader />;
  return <SettingsProviderContent data={data}>{children}</SettingsProviderContent>;
};

const SettingsProviderContent = React.memo(
  ({ data, children }: { data: FeatureFlagResponse; children: React.ReactNode }) => {
    const [open, setOpen] = React.useState(false);

    const [flags, setFlags] = React.useState(() => {
      const set = new Set<string>();
      if (data?.externalPaymentServices) {
        set.add(Flags.externalPaymentServices);
      }
      const envFlags = getFlags();
      if (Array.isArray(envFlags)) {
        for (const flag of envFlags) {
          if (typeof flag === "string") set.add(flag);
        }
      }
      return set;
    });

    React.useEffect(() => {
      if (data?.hotJarId) {
        hotjar(data.hotJarId);
      }
    }, [data?.hotJarId]);

    const handlers = {
      SETTINGS: () => {
        setOpen(true);
      }
    };

    const updateFlags = (newFlags: Set<string>) => {
      setFlags(newFlags);
    };

    return (
      <SettingsContext.Provider
        value={{
          updateFlags,
          hotJarId: data.hotJarId,
          whoCanCreateProduct: data.whoCanCreateProduct,
          flags
        }}
      >
        {children}
        {flags.has(Flags.devTools) && <GlobalHotKeys keyMap={keyMap} handlers={handlers} />}
        {flags.has(Flags.devTools) && (
          <DTCModal
            size="large"
            title="App settings"
            open={open}
            onClose={() => setOpen(false)}
            content={<SettingContent />}
          />
        )}
      </SettingsContext.Provider>
    );
  }
);

export const useSettingsContext = () => {
  return React.useContext(SettingsContext);
};

export const useFlag = (flag: string) => {
  const { flags } = useSettingsContext();
  return flags.has(flag);
};
