import React from "react";
import CssBaseline from "@mui/material/CssBaseline";
import { ErrorBoundary } from "@/components/error-boundary/error-boundary.comp";
import { SnackbarProvider } from "@/components/snackbar-provider/snackbar-provider.comp";
import { ThemeProvider } from "@mui/material/styles";
import { adminTheme } from "@/theme/admin-theme";
import ModalProvider from "mui-modal-provider";
import AppRouter from "../app-router";
import { marketRoutes } from "./market.route";
import { createBrowserHistory } from "history";
import { adminRoutes } from "../admin/admin.route";

export const history = createBrowserHistory();
export const MarketApp = () => {
  return (
    <ThemeProvider theme={adminTheme}>
      <ModalProvider>
        <ErrorBoundary>
          <SnackbarProvider>
            <CssBaseline />
            <AppRouter history={history} routes={[...marketRoutes, ...adminRoutes]} />
          </SnackbarProvider>
        </ErrorBoundary>
      </ModalProvider>
    </ThemeProvider>
  );
};
