import { backendAPI } from "@/utils/httpAPI.util";
import { ApiPathConsts } from "@/commons/consts/system";
import dayjs from "dayjs";
import { API_PATHS } from "@/commons/consts/system/api-paths";
import { LOGISTIC_TYPE } from "@/commons/consts/general.const";

const DATETIME_FORMAT = "YYYY-MM-DDTHH:mm:ss";
export class OrderService {
  static getAllActiveOrders = async (options) => {
    const { createdDate } = options;
    const formattedCreatedDate = dayjs(createdDate).utc().format(DATETIME_FORMAT);
    return backendAPI.post(ApiPathConsts.GET_ALL_ACTIVE_ORDERS, undefined, {
      ...options,
      createdDate: formattedCreatedDate
    });
  };

  static getAllOrderHistory = async (options) => {
    const { createdDate } = options;
    const formattedCreatedDate = dayjs(createdDate).utc().format(DATETIME_FORMAT);
    return backendAPI.post(ApiPathConsts.GET_ALL_ORDERS_HISTORY, undefined, {
      ...options,
      createdDate: formattedCreatedDate
    });
  };

  static getOrderById = (orderId) => {
    return backendAPI.get(`${ApiPathConsts.GET_ORDER_PREFIX}/${orderId}`);
  };

  static getOrderCompleted = async (options) => {
    return backendAPI.get(`${ApiPathConsts.GET_COMPLETE_ORDERS}`, {
      ...options
    });
  };

  static getAllOrdersMarket = async (options) => {
    return backendAPI.get(`${ApiPathConsts.GET_ALL_ORDERS}`, {
      ...options
    });
  };
}

export const getOrdersByStatusAsSeller = async (params) => {
  const { page, size, status, sort } = params;

  const res = await backendAPI.get(`${API_PATHS.ORDER_SELLER}/status/${status}`, {
    page,
    size,
    sort
  });

  return res;
};

export const getOrdersByStatusAsBuyer = async (params) => {
  const { page, size, status, sort } = params;

  const res = await backendAPI.get(`${API_PATHS.ORDER_BUYER}/status/${status}`, {
    page,
    size,
    sort
  });

  return res;
};

export const getSellerOrderDetail = async (id) => {
  const orderDetail = await backendAPI.get(`${API_PATHS.ORDER_SELLER}/${id}`);
  return orderDetail;
};

export const getBuyerOrderDetail = async (id) => {
  const orderDetail = await backendAPI.get(`${API_PATHS.ORDER_BUYER}/${id}`);
  return orderDetail;
};

export const getBuyerInspectionReport = async (orderId) => {
  const inspection = await backendAPI.get(`${API_PATHS.ORDER_BUYER}/${orderId}/inspection`);
  return inspection;
};

export const acceptInspection = async (orderId) => {
  await backendAPI.post(`${API_PATHS.ORDER_BUYER}/${orderId}/inspection/accept`);
};

export const rejectInspection = async (orderId, value, fileList) => {
  if (fileList && Array.isArray(fileList)) {
    const formData = new FormData();
    fileList.forEach((f) => {
      formData.append("files", f.originFileObj);
    });
    formData.append("request", JSON.stringify(value));
    await backendAPI.postFormData(
      `${API_PATHS.ORDER_BUYER}/${orderId}/inspection/reject`,
      formData
    );
  }
};

export const uploadInspectionImages = async (orderId, fileList, type) => {
  if (fileList && Array.isArray(fileList)) {
    let promises = [];
    for (const file of fileList) {
      const formData = new FormData();
      formData.append("file", file.originFileObj);
      promises.push(
        backendAPI.postFormData(`inspection/documents/${orderId}`, formData, {
          type: type
        })
      );
    }
    await Promise.all(promises);
  }
};

export const deleteInspectionImage = async (id) => {
  await backendAPI.delete(`inspection/documents/${id}`);
};

export const getSellerInspectionReport = async (orderId) => {
  const inspection = await backendAPI.get(`${API_PATHS.ORDER_SELLER}/${orderId}/inspection`);
  return inspection;
};

export const rateBySeller = async (orderId, value, logisticValue, alreadyRated) => {
  const apiMethod = alreadyRated ? backendAPI.put : backendAPI.post;
  await apiMethod(`${API_PATHS.ORDER_SELLER}/${orderId}/rating/${value}`);
  await apiMethod(
    `${API_PATHS.ORDER_SELLER}/${orderId}/rating/service-provider/${logisticValue}?serviceProviderType=LOGISTIC_PROVIDER`
  );
};

export const rateByBuyer = async (orderId, value, logisticValue, alreadyRated) => {
  const apiMethod = alreadyRated ? backendAPI.put : backendAPI.post;
  await apiMethod(`${API_PATHS.ORDER_BUYER}/${orderId}/rating/${value}`);
  await apiMethod(
    `${API_PATHS.ORDER_BUYER}/${orderId}/rating/service-provider/${logisticValue}?serviceProviderType=LOGISTIC_PROVIDER`
  );
};

export const getSellerOrderRating = async (orderId) => {
  const rating = await backendAPI.get(`${API_PATHS.ORDER_SELLER}/${orderId}/rating`);
  return rating;
};

export const getBuyerOrderRating = async (orderId) => {
  const rating = await backendAPI.get(`${API_PATHS.ORDER_BUYER}/${orderId}/rating`);
  return rating;
};

export const getLogisticRatingAsSeller = async (orderId) => {
  const rating = await backendAPI.get(`${API_PATHS.ORDER_SELLER}/${orderId}/logistic/rating`);
  return rating;
};

export const getLogisticRatingAsBuyer = async (orderId) => {
  const rating = await backendAPI.get(`${API_PATHS.ORDER_BUYER}/${orderId}/logistic/rating`);
  return rating;
};

export const getPurchaseOrderAsSeller = async (orderId) => {
  const rating = await backendAPI.get(`${API_PATHS.ORDER_SELLER}/${orderId}/purchase`);
  return rating;
};

export const getPurchaseOrderAsBuyer = async (orderId) => {
  const rating = await backendAPI.get(`${API_PATHS.ORDER_BUYER}/${orderId}/purchase`);
  return rating;
};

export const getLogisticsProvider = async (orderId) => {
  const res = await backendAPI.get(`${API_PATHS.ORDER_BUYER}/${orderId}/logistic`);
  return res;
};

export const acceptLogisticsProvider = async (orderId, item) => {
  const logisticType = item.logisticType;
  if (logisticType !== LOGISTIC_TYPE.SHIPCHAIN) {
    const res = await backendAPI.post(`${API_PATHS.ORDER_BUYER}/${orderId}/logistic/accept`, null, {
      serviceName: item.name,
      type: item.logisticType
    });
    return res;
  } else {
    const res = await backendAPI.post(`${API_PATHS.ORDER_BUYER}/${orderId}/logistic/shipchain`, {
      serviceName: item.name,
      id: item.quoteId,
      serviceProviderId: item.logisticProviderId
    });
    return res;
  }
};

export const getDetailLogisticProviderAsBuyer = async (
  orderId,
  name,
  quoteId,
  logisticProviderId,
  type
) => {
  let path = `${API_PATHS.ORDER_BUYER}/${orderId}/logistic/details?serviceName=${name}&type=${type}`;
  if (quoteId && logisticProviderId)
    path = `${API_PATHS.ORDER_BUYER}/${orderId}/logistic/details?quoteId=${quoteId}&serviceProviderId=${logisticProviderId}&type=${type}`;
  const res = await backendAPI.get(path);
  return res;
};

export const getDetailLogisticProviderAsSeller = async (orderId, data) => {
  const res = await backendAPI.get(`${API_PATHS.ORDER_SELLER}/${orderId}/logistic/details`, data);
  return res;
};

export const agreeAdditionalDoc = async (orderId) => {
  await backendAPI.post(`${API_PATHS.ORDER_SELLER}/${orderId}/logistic/shipchain/accept`);
};

export const rejectAdditionalDoc = async (orderId) => {
  await backendAPI.post(`${API_PATHS.ORDER_SELLER}/${orderId}/logistic/shipchain/reject`);
};

export const postShippingDetails = async (orderId, data) => {
  const res = await backendAPI.post(`${API_PATHS.ORDER_SELLER}/${orderId}/shippingDetails`, data);
  return res;
};

export const putShippingDetails = async (orderId, data) => {
  const res = await backendAPI.put(`${API_PATHS.ORDER_SELLER}/${orderId}/shippingDetails`, data);
  return res;
};

export const getSellerShippingDetails = async (orderId) => {
  const res = await backendAPI.get(`${API_PATHS.ORDER_SELLER}/${orderId}/shippingDetails`);
  return res;
};

export const getBuyerShippingDetails = async (orderId) => {
  const res = await backendAPI.get(`${API_PATHS.ORDER_BUYER}/${orderId}/shippingDetails`);
  return res;
};

export const getSellerShipmentDocument = async (orderId) => {
  const res = await backendAPI.get(`${API_PATHS.ORDER_SELLER}/${orderId}/shipmentDocuments`);
  return res;
};

export const getBuyerShipmentDocument = async (orderId) => {
  const res = await backendAPI.get(`${API_PATHS.ORDER_BUYER}/${orderId}/shipmentDocuments`);
  return res;
};

export const getSellerShipmentDetails = async (orderId) => {
  const res = await backendAPI.get(`${API_PATHS.ORDER_SELLER}/${orderId}/shipmentDetails`);
  return res;
};

export const getBuyerShipmentDetails = async (orderId) => {
  const res = await backendAPI.get(`${API_PATHS.ORDER_BUYER}/${orderId}/shipmentDetails`);
  return res;
};

export const getTrackOrdersAsSeller = async (params) => {
  const { page, size, sort } = params;

  const res = await backendAPI.get(`${API_PATHS.ORDER_SELLER}/tracking`, {
    page,
    size,
    sort
  });

  return res;
};

export const getTrackOrdersAsBuyer = async (params) => {
  const { page, size, sort } = params;

  const res = await backendAPI.get(`${API_PATHS.ORDER_BUYER}/tracking`, {
    page,
    size,
    sort
  });

  return res;
};

export const getTrackShipmentAsSeller = async (params) => {
  const { page, size, sort } = params;

  const res = await backendAPI.get(`${API_PATHS.ORDER_SELLER}/trackingShipment`, {
    page,
    size,
    sort
  });

  return res;
};

export const getTrackShipmentAsBuyer = async (params) => {
  const { page, size, sort } = params;

  const res = await backendAPI.get(`${API_PATHS.ORDER_BUYER}/trackingShipment`, {
    page,
    size,
    sort
  });

  return res;
};

export const getOrderDocTypesAsSeller = async (orderId) => {
  const res = await backendAPI.get(`${API_PATHS.ORDER_SELLER}/${orderId}/document/type`);
  return res;
};

export const getOrderDocTypesAsBuyer = async (orderId) => {
  const res = await backendAPI.get(`${API_PATHS.ORDER_BUYER}/${orderId}/document/type`);
  return res;
};

export const getTrackShipmentDetails = async () => {
  const res = await backendAPI.get()("https://www.mocky.io/v2/5eb932582f00005e003c2ed8");
  return res;
};

export const uploadAllDocsAsSeller = async (orderId) => {
  const result = await backendAPI.post(`${API_PATHS.ORDER_SELLER}/${orderId}/document`);
  return result;
};

export const uploadTempDocsAsSeller = async (orderId, file, type, name) => {
  const result = backendAPI.postFile(`${API_PATHS.ORDER_SELLER}/${orderId}/document/temp`, file, {
    name,
    type
  });
  return result;
};

export const getTempDocsAsSeller = async (orderId) => {
  const result = backendAPI.get(`${API_PATHS.ORDER_SELLER}/${orderId}/document/temp`);
  return result;
};

export const uploadAllDocsAsBuyer = async (orderId) => {
  const result = await backendAPI.post(`${API_PATHS.ORDER_BUYER}/${orderId}/document`);
  return result;
};

export const uploadTempDocsAsBuyer = async (orderId, file, type, name) => {
  const formData = new FormData();
  formData.append("file", file);
  const result = backendAPI.postFormData(
    `${API_PATHS.ORDER_BUYER}/${orderId}/document/temp`,
    formData,
    {
      name,
      type
    }
  );
  return result;
};

export const getTempDocsAsBuyer = async (orderId) => {
  const result = backendAPI.get(`${API_PATHS.ORDER_BUYER}/${orderId}/document/temp`);
  return result;
};

export const deleteDocAsSeller = async (orderId, name) => {
  const result = await backendAPI.delete(`${API_PATHS.ORDER_SELLER}/${orderId}/document`, {
    name
  });
  return result;
};

export const deleteDocAsBuyer = async (orderId, name) => {
  const result = await backendAPI.delete(`${API_PATHS.ORDER_BUYER}/${orderId}/document`, {
    name
  });
  return result;
};

export const cancelOrder = async (orderId) => {
  await backendAPI.post(`buyers/order/cancel-order/${orderId}`);
};

export const changePaymentMethod = async (data) => {
  await backendAPI.post("buyers/order/re-update/payment-method", data);
};

export const checkChangePaymentMethod = async (id) => {
  const res = await backendAPI.get(`buyers/order/check-update/payment-method/${id}`);
  return res;
};

// ADDITIONAL DOCUMENTS
export const getOrderAdditionalDocumentTypesAsSeller = async (orderId) => {
  const res = await backendAPI.get(`${API_PATHS.ORDER_SELLER}/${orderId}/document/type/quote`);
  return res;
};

export const getOrderAdditionalDocumentTypesAsBuyer = async (orderId) => {
  const res = await backendAPI.get(`${API_PATHS.ORDER_BUYER}/${orderId}/document/type/quote`);
  return res;
};

export const getTempAdditionalDocumentsAsSeller = async (orderId) => {
  const result = backendAPI.get(`${API_PATHS.ORDER_SELLER}/${orderId}/document/temp/quote`);
  return result;
};

export const getTempAdditionalDocumentsAsBuyer = async (orderId) => {
  const result = backendAPI.get(`${API_PATHS.ORDER_BUYER}/${orderId}/document/temp/quote`);
  return result;
};

export const uploadAllAdditionalDocsAsSeller = async (orderId) => {
  const result = await backendAPI.post(`${API_PATHS.ORDER_SELLER}/${orderId}/quote/document`);
  return result;
};

export const uploadAllAdditionalDocsAsBuyer = async (orderId) => {
  const result = await backendAPI.post(`${API_PATHS.ORDER_BUYER}/${orderId}/quote/document`);
  return result;
};

export const uploadTempAdditionalDocsAsSeller = async (quoteDocumentId, file) => {
  const formData = new FormData();
  formData.append("file", file);
  const result = backendAPI.postFormData(
    `${API_PATHS.SHIPCHAIN_ORDER_SELLER}/document/${quoteDocumentId}`,
    formData
  );
  return result;
};

export const uploadTempAdditionalDocsAsBuyer = async (quoteDocumentId, file) => {
  const formData = new FormData();
  formData.append("file", file);
  const result = backendAPI.postFormData(
    `${API_PATHS.SHIPCHAIN_ORDER_BUYER}/document/${quoteDocumentId}`,
    formData
  );
  return result;
};

export const deleteAdditionDocAsSeller = async (quoteDocumentId) => {
  const result = await backendAPI.delete(
    `${API_PATHS.ORDER_SELLER}/quote/document/${quoteDocumentId}`
  );
  return result;
};
export const deleteAdditionDocAsBuyer = async (quoteDocumentId) => {
  const result = await backendAPI.delete(
    `${API_PATHS.ORDER_BUYER}/quote/document/${quoteDocumentId}`
  );
  return result;
};

export const getProformaInvoiceAsSeller = async (orderId) => {
  return backendAPI.get(`${API_PATHS.ORDER_SELLER}/${orderId}/proforma`);
};

export const getProformaInvoiceAsBuyer = async (orderId) => {
  const rating = await backendAPI.get(`${API_PATHS.ORDER_BUYER}/${orderId}/proforma`);
  return rating;
};

export const postInvoiceAcceptAsBuyer = async (orderId) => {
  await backendAPI.post(`${API_PATHS.ORDER_BUYER}/${orderId}/invoice/accept`);
  return true;
};

export const postInvoiceRejectAsBuyer = async (orderId) => {
  await backendAPI.post(`${API_PATHS.ORDER_BUYER}/${orderId}/invoice/reject`);
  return true;
};

export const postInvoiceAcceptAsSeller = async (orderId) => {
  await backendAPI.post(`${API_PATHS.ORDER_SELLER}/${orderId}/invoice/accept`);
  return true;
};

export const postInvoiceRejectAsSeller = async (orderId) => {
  await backendAPI.post(`${API_PATHS.ORDER_SELLER}/${orderId}/invoice/reject`);
  return true;
};

export const updateInvoice = async (orderId, values) => {
  return backendAPI.put(`${API_PATHS.ORDER_SELLER}/${orderId}`, values);
};
