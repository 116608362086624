import { Form } from "@ant-design/compatible";
import "@ant-design/compatible/assets/index.css";
import { Button, Select } from "antd";
import React from "react";
import { AddAddressFormModal } from "@/entities/address/ui/add-address-form-modal.comp";
import { useGetCompanyAddresses } from "@/entities/address/lib/use-query-address";

const AddressSelectLabel = ({ label, showAddAddress, onAddAddressClick }) => {
  return (
    <div className="d-flex justify-content-between align-items-baseline ant-form-item-label">
      <label className="ant-form-item-required ant-form-item-no-colon">{label}</label>
      {showAddAddress && (
        <a type="link" className="text-primary font-weight-bold" onClick={onAddAddressClick}>
          <div className="d-flex align-items-center">
            <i className="fa fa-plus mr-2" style={{ fontSize: "10px" }} aria-hidden="true"></i>{" "}
            <span>Add new address</span>
          </div>
        </a>
      )}
    </div>
  );
};
const AddressSelect = React.forwardRef(
  ({ value, onChange, loading, dataSource, label, ...others }, ref) => {
    const [open, setOpen] = React.useState(false);

    const handleNavigate = () => {
      setOpen(true);
    };
    return (
      <div ref={ref}>
        {!loading && dataSource.length === 0 ? (
          <div>
            <AddressSelectLabel
              label={label}
              onAddAddressClick={handleNavigate}
              showAddAddress={false}
            />
            <div>
              Please add a new address in your{" "}
              <Button className="font-weight-bold" onClick={handleNavigate}>
                Company Information
              </Button>
            </div>
          </div>
        ) : (
          <div>
            <AddressSelectLabel
              label={label}
              onAddAddressClick={handleNavigate}
              showAddAddress={true}
            />
            <Select
              value={value}
              onChange={onChange}
              showSearch
              autoComplete="off"
              filterOption={(inputValue, option) =>
                option.props?.children?.toLowerCase().includes(inputValue.toLowerCase())
              }
              {...others}
            >
              {dataSource.map((item) => (
                <Select.Option key={item.value} value={item.value}>
                  {item.label}
                </Select.Option>
              ))}
            </Select>
          </div>
        )}
        <AddAddressFormModal open={open} onClose={() => setOpen(false)} />
      </div>
    );
  }
);

export const getAddressString = ({ addressLine1, city, country, postalCode }) => {
  let str = `${addressLine1} ${city} ${country}`;
  if (postalCode) {
    str += " " + postalCode;
  }
  return str;
};

export const AddressSelectField = ({ form, initialValue, schema, innerProps }) => {
  const { addresses, isLoading: loading } = useGetCompanyAddresses();

  const { label, options, name, placeholder, getValue } = schema;
  const dataSource = React.useMemo(() => {
    const addressDataSource =
      addresses
        ?.filter(({ addressLine1, city, country, id }) => {
          return addressLine1 && city && country && id;
        })
        .map((address) => ({
          value: getValue ? getValue(address) : address.id,
          label: getAddressString(address)
        })) ?? [];
    return addressDataSource;
  }, [addresses, getValue]);

  return (
    <>
      <Form.Item>
        {form.getFieldDecorator(name, {
          ...options,
          initialValue
        })(
          <AddressSelect
            placeholder={placeholder}
            loading={loading}
            dataSource={dataSource}
            label={label}
            {...innerProps}
          />
        )}
      </Form.Item>
    </>
  );
};
