import * as React from "react";

import { useHistory, useLocation } from "@/components/commons/navigator";

import Collapse from "@mui/material/Collapse";
import MuiList from "@mui/material/List";
import MuiListItemButton from "@mui/material/ListItemButton";
import MuiListItemIcon from "@mui/material/ListItemIcon";
import MuiListItemText from "@mui/material/ListItemText";
import Typography from "@mui/material/Typography";
import { matchPath } from "@/components/commons/navigator";
import { styled } from "@mui/material/styles";
import ExpandMoreOutlinedIcon from "@mui/icons-material/ExpandMoreOutlined";
import ExpandLessOutlinedIcon from "@mui/icons-material/ExpandLessOutlined";
import { SvgIconProps } from "@mui/material";
import { FlagMode } from "@/commons/consts/flags.const";
import { useSettingsContext } from "../commons/settings/settings-provider.comp";

const List = styled(MuiList)({
  paddingTop: 0,
  paddingBottom: 0
});
const ListItemIcon = styled(MuiListItemIcon)({
  color: "inherit",
  width: 20
});
const ListItemText = styled(MuiListItemText)({
  color: "inherit"
});
const ListItemButton = styled(MuiListItemButton)(({ theme }) => ({
  justifyContent: "center",
  padding: "16px",
  height: 52,
  "&.Mui-selected": {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    ":hover": {
      backgroundColor: theme.palette.primary.light,
      color: theme.palette.common.white
    }
  }
}));

const isChildrenMatchPath = (data, location) => {
  return data.children && data.children.some((child) => matchPath(location.pathname, child.url));
};

export interface IMenuItem {
  url?: string | string[];
  icon: React.ComponentClass<SvgIconProps>;
  children?: IMenuItem[];
  title: string;
  key: string;
  flag?: string;
  flagMode?: FlagMode;
}

interface MenuItemProps {
  collapsed?: boolean;
  onExpand: () => void;
  data: IMenuItem;
}

const MenuItem = ({ data, collapsed, onExpand }: MenuItemProps) => {
  const location = useLocation();
  const [open, setOpen] = React.useState(() => {
    if (matchPath(location.pathname, { path: data.url })) return true;
    return isChildrenMatchPath(data, location);
  });
  const history = useHistory();
  const canCollapse = !collapsed && data.children != null && data.children.length > 0;
  const selected = React.useMemo(() => {
    if (matchPath(location.pathname, data.url)) return true;
    return (collapsed || !open) && isChildrenMatchPath(data, location);
  }, [location, data, collapsed, open]);

  const handleClick = () => {
    if (data.url && typeof data.url === "string") {
      history.push(data.url);
      return;
    }
    if (data.url && data.url.length > 0) {
      history.push(data.url[0]);
      return;
    }
    if (collapsed) {
      onExpand();
      setOpen(true);
      return;
    }
    if (canCollapse) {
      setOpen((currentOpen) => !currentOpen);
    }
  };
  const Icon = data.icon;

  return (
    <div>
      <ListItemButton onClick={handleClick} selected={selected}>
        <ListItemIcon sx={{ minWidth: "0" }}>{Icon && <Icon fontSize="small" />}</ListItemIcon>
        {!collapsed && (
          <ListItemText
            sx={{ paddingLeft: 1 }}
            primary={<Typography variant="body2">{data.title}</Typography>}
          />
        )}
        {canCollapse &&
          !collapsed &&
          (open ? (
            <ExpandLessOutlinedIcon sx={{ fontSize: "18px" }} />
          ) : (
            <ExpandMoreOutlinedIcon sx={{ fontSize: "18px" }} />
          ))}
      </ListItemButton>
      {canCollapse && !collapsed && (
        <Collapse in={open} timeout="auto" unmountOnExit>
          <MuiList component="div" disablePadding>
            {data.children.map((childData) => (
              <MenuItem key={childData.key} data={childData} onExpand={onExpand} />
            ))}
          </MuiList>
        </Collapse>
      )}
    </div>
  );
};

interface Props {
  collapsed?: boolean;
  onExpand: () => void;
  menuData: IMenuItem[];
}

export const AppLeftMenu = ({ collapsed, onExpand, menuData }: Props) => {
  const { flags } = useSettingsContext();
  const renderedMenu = menuData.filter((item) => {
    if (!item.flag) return true;
    const result = flags.has(item.flag);
    if (item.flagMode === FlagMode.EXCLUDE) return !result;
    return result;
  });
  return (
    <List>
      {renderedMenu.map((item) => {
        return (
          <List key={item.key}>
            <MenuItem data={item} collapsed={collapsed} onExpand={onExpand} />
          </List>
        );
      })}
    </List>
  );
};
