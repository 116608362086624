import React from "react";
import { Form } from "@ant-design/compatible";
import "@ant-design/compatible/assets/index.css";
import { Input } from "antd";
export const TextField = ({ form, initialValue, schema, innerProps }) => {
  const { label, name, options, placeholder } = schema;
  return (
    <Form.Item label={label}>
      {form.getFieldDecorator(name, {
        ...options,
        initialValue
      })(<Input placeholder={placeholder} {...innerProps} />)}
    </Form.Item>
  );
};
