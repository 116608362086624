import React, { useState, useCallback } from "react";
import { Upload, Button } from "antd";
import {
  DeleteOutlined,
  FileImageOutlined,
  FileOutlined,
  FilePdfOutlined,
  UploadOutlined
} from "@ant-design/icons";
import PropTypes from "prop-types";

export const ERRORS = {
  FILE_LIMIT_EXCEED: "FILE_LIMIT_EXCEED",
  INVALID_FILE_TYPE: "INVALID_FILE_TYPE",
  UNEXPECTED: "UNEXPECTED"
};
const iconStyle = { fontSize: "24px" };
const ICON_MAP = {
  "image/png": <FileImageOutlined style={iconStyle} />,
  png: <FileImageOutlined style={iconStyle} />,
  "application/pdf": <FilePdfOutlined style={iconStyle} />,
  pdf: <FilePdfOutlined style={iconStyle} />
};
const getIcon = (file) => {
  const icon = ICON_MAP[file?.type || file?.name];
  if (icon != null) return icon;
  return <FileOutlined style={iconStyle} />;
};

const UploadedFile = ({ file, disabled, onRemove }) => {
  if (!file) return <></>;
  return (
    <div className="d-flex align-items-baseline mt-2">
      <div>
        <span className="mr-2">{getIcon(file)}</span>
        {file.originalFileName}
      </div>
      <Button
        className="ml-4 align-self-center"
        disabled={disabled}
        type="danger"
        icon={<DeleteOutlined />}
        size="small"
        onClick={onRemove}
      />
    </div>
  );
};

export const UploadFile = React.forwardRef(
  ({ title, value, onChange, uploadHandler, accept, maxSize, disabled, uploadProps }, ref) => {
    const [loading, setLoading] = useState(false);
    const beforeUpload = useCallback(
      (file) => {
        if (maxSize != null && file.size / 1024 > maxSize) {
          onChange({ error: ERRORS.FILE_LIMIT_EXCEED });
          return false;
        }
        if (accept && file.name.toLowerCase().match(accept) == null) {
          onChange({ error: ERRORS.INVALID_FILE_TYPE });
          return false;
        }
        return true;
      },
      [accept, maxSize, onChange]
    );

    const handleUpload = useCallback(
      async ({ file, onError, onSuccess }) => {
        try {
          setLoading(true);
          const uploadedFile = await uploadHandler(file);
          onSuccess(uploadedFile, file);
          onChange && onChange(uploadedFile);
        } catch (error) {
          onError(new Error(ERRORS.UNEXPECTED));
          onChange({ error: ERRORS.UNEXPECTED });
        } finally {
          setLoading(false);
        }
      },
      [onChange, uploadHandler]
    );

    const removeUploadFile = useCallback(() => {
      onChange(undefined);
    }, [onChange]);

    const showUploadButton = React.useMemo(() => {
      if (!value) return true;
      if (value.error) return true;
      return false;
    }, [value]);

    return (
      <div ref={ref}>
        {showUploadButton && (
          <Upload
            disabled={disabled}
            beforeUpload={beforeUpload}
            showUploadList={false}
            multiple={false}
            customRequest={handleUpload}
            {...uploadProps}
          >
            <Button loading={loading}>
              {!loading && <UploadOutlined />} {title}
            </Button>
          </Upload>
        )}
        {!showUploadButton && (
          <UploadedFile
            file={value}
            loading={loading}
            disabled={disabled}
            onRemove={removeUploadFile}
          />
        )}
      </div>
    );
  }
);

UploadFile.propTypes = {
  title: PropTypes.string.isRequired,
  value: PropTypes.shape({
    error: PropTypes.oneOf(Object.keys(ERRORS)),
    id: PropTypes.string,
    originalFileName: PropTypes.string,
    type: PropTypes.string,
    url: PropTypes.string
  }),
  uploadHandler: PropTypes.func.isRequired,
  onChange: PropTypes.func,
  accept: PropTypes.string,
  maxSize: PropTypes.number,
  disabled: PropTypes.bool,
  uploadProps: PropTypes.object
};
