import Stack from "@mui/material/Stack";
import { getCompanyName } from "@/utils/config.util";
import { useBrandingAssets } from "@/entities/branding-asset/libs/use-branding-asset";

import { PortalSwitcher } from "./portal-switcher.comp";

import { portalMap } from "./portals.map";
import { AppBarLayout } from "./app-bar.layout";
import { UserMenu } from "./user-menu.comp";
import { COMPANY_TYPE } from "@/commons/consts";
import { useUserProfile } from "@/entities/user-profile/libs/use-user-profile";
import { usePrefixPath } from "@/utils/location.util";

const centralNameMap = {
  buyer: "Buyer Central",
  seller: "Seller Central"
};

export const MarketplaceAppBar = () => {
  const { prefix } = usePrefixPath();
  const { user } = useUserProfile();
  const portalKeys = [COMPANY_TYPE.CCC_DUAL, COMPANY_TYPE.PCC_DUAL].includes(user.companyType)
    ? ["buyer", "seller"]
    : undefined;
  const portals = portalKeys
    ?.filter((key) => key in portalMap && key !== prefix)
    ?.map((key) => portalMap[key]);
  const { brandingAssets } = useBrandingAssets();

  const centralName = centralNameMap[prefix];

  return (
    <AppBarLayout
      leftElement={
        <Stack direction="row" alignItems="center" justifyContent="center" height={40} spacing={2}>
          <img
            style={{ height: "100%", objectFit: "contain" }}
            src={brandingAssets.LOGO}
            alt={`${getCompanyName()}`}
          />
          <Stack direction="row" alignItems="center">
            <PortalSwitcher label={centralName} portals={portals} />
          </Stack>
        </Stack>
      }
      rightElement={<UserMenu />}
    />
  );
};
