import { Button, Tooltip } from "antd";
import { useInOutAnimation } from "hooks/useInOutAnimation";
import React, { useEffect, useState } from "react";
import { animated } from "react-spring";

import { InfoCircleOutlined } from "@ant-design/icons";

export const withMultiForm = ({
  name,
  onRemoveForm,
  formLimit = null,
  readonlySectionFieldName,
  cantRemoveSectionFieldName,
  isShowButtonAdd = true,
  canRemoveAll = false
}) => (FormComponent) => {
  const idPrefix = `form-${name}-`;
  let initId = 0;
  const generateId = () => `${idPrefix}${initId++}`;

  return React.memo(
    ({
      title,
      initialValues,
      addBtnText,
      disabledAddBtn = false,
      wrappedRef,
      wrapperClass,
      deleteIcon,
      deleteBtnWrapperClass,
      ...restProps
    }) => {
      const [formArr, setFormArr] = useState([]);
      const transitions = useInOutAnimation(formArr, (form) => form.id);

      // eslint-disable-next-line
      let formRefArr = [];

      useEffect(() => {
        if (initialValues && initialValues.length) {
          setFormArr(
            initialValues.map((values) => ({
              id: values.id || generateId(),
              Form: FormComponent,
              initialValues: values,
              cantRemove: values[cantRemoveSectionFieldName] || false,
              cantEdit: values[readonlySectionFieldName] || false
            }))
          );
        }
      }, [initialValues]);

      useEffect(() => {
        wrappedRef && wrappedRef(formRefArr);
      }, [wrappedRef, formRefArr]);

      const addForm = () => {
        if (formLimit !== null && formArr.length === formLimit) {
          return;
        }
        setFormArr([
          ...formArr,
          {
            id: generateId(),
            Form: FormComponent,
            initialValues: {},
            cantRemove: false
          }
        ]);
      };

      const removeForm = (id, index) => {
        const target = formArr.find((form) => form.id === id);
        if (target && target.cantRemove === false) {
          setFormArr(formArr.filter((form) => form.id !== id));
          onRemoveForm && onRemoveForm(id, index);
        }
      };

      const renderAddBtn = () => {
        if (formLimit !== null && formArr.length === formLimit) {
          return;
        }
        return addBtnText ? (
          <Button type="primary" onClick={addForm} disabled={disabledAddBtn}>
            {addBtnText}
          </Button>
        ) : (
          <Button
            className="p-0 font-size-18 font-weight-bolder"
            onClick={addForm}
            disabled={disabledAddBtn}
          >
            <i className="fe fe-plus pl-2 pr-2 mt-1" />
          </Button>
        );
      };

      const renderRemoveBtn = (id, index) => {
        return (
          <div className={`text-right ${deleteBtnWrapperClass}`}>
            <Button
              type="danger"
              className="p-0 font-size-18 font-weight-bolder"
              onClick={() => removeForm(id, index)}
            >
              {deleteIcon || <i className="fe fe-minus pl-2 pr-2 mt-1" />}
            </Button>
          </div>
        );
      };

      const renderTooltips = (title) => {
        switch (title) {
          case "Authorized Representative Information":
            return (
              <Tooltip
                title={
                  "This section is to collect the information of the primary User that is the authorized representative signing up to the Marketplace. We use this information only to verify the User via our Know Your Customer (KYC) process. This is required for compliance with our Bank's due diligence requirements as well as securing the Marketplace for all as only vetted Users are allowed access for Trading."
                }
              >
                <InfoCircleOutlined />
              </Tooltip>
            );
          case "Add Partner":
            return (
              <Tooltip
                title={
                  "In the instance where your KYC (Know Your Customer) process comes back as declined by our Bank, you will be required to add the details of another business partner, shareholder, or authorized representative of the company. You can do this by clicking this 'Add Partner' button to submit the details of the new partner and we will run the KYC process again with our bank. Once this is successfully completed, your company will be registered with full access for trading on the Marketplace."
                }
              >
                <InfoCircleOutlined />
              </Tooltip>
            );
          default:
            return null;
        }
      };

      return (
        <React.Fragment>
          <div className="flex row">
            <h5 className="mb-3 mx-3 text-uppercase">{title}</h5>
            {renderTooltips(title)}
          </div>
          {transitions.map(({ item, props, key }, index) => {
            const { Form, initialValues, cantRemove, cantEdit, id } = item;
            return (
              <animated.div key={key} style={props}>
                <div className={wrapperClass}>
                  {!canRemoveAll && (canRemoveAll || formArr.length === 1 || cantRemove)
                    ? null
                    : renderRemoveBtn(id, index)}
                  <Form
                    {...restProps}
                    index={index}
                    editable={!cantEdit}
                    wrappedComponentRef={(form) => (formRefArr = [...formRefArr, form])}
                    initialValues={initialValues}
                  />
                </div>
              </animated.div>
            );
          })}

          {isShowButtonAdd ? renderAddBtn() : null}
        </React.Fragment>
      );
    }
  );
};
