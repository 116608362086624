import { ROUTES } from "@/commons/consts";
import MarketplaceIcon from "@/components/icons/marketplace.comp";
import ServicePortalIcon from "@/components/icons/service-portal.comp";
import { getMarketplaceUrl, getServicePortalUrl } from "@/utils/config.util";
import { StoreMallDirectoryRounded } from "@mui/icons-material";

export const portalMap = {
  marketplace: {
    icon: (
      <MarketplaceIcon
        sx={{
          fontSize: "40px"
        }}
      />
    ),
    label: "Marketplace",
    link: `${getMarketplaceUrl()}/buyer`,
    isExternalLink: true
  },
  logistics: {
    icon: <ServicePortalIcon viewBox="0 0 40 40" sx={{ fontSize: "40px" }} />,
    label: "Logistics",
    link: getServicePortalUrl(),
    isExternalLink: true
  },
  seller: {
    icon: <StoreMallDirectoryRounded sx={{ fontSize: "40px" }} />,
    label: "Seller Central",
    link: ROUTES.SELLER_DASHBOARD_ROUTE,
    isExternalLink: false
  },
  buyer: {
    icon: <MarketplaceIcon sx={{ fontSize: "40px" }} />,
    label: "Buyer Central",
    link: ROUTES.BUYER_DASHBOARD_ROUTE,
    isExternalLink: false
  }
};
