import React from "react";
import { DashboardRounded, ShoppingCart } from "@mui/icons-material";
import {
  BANK_HOME,
  BANK_DETAIL,
  BANK_EDIT,
  NEW_BANK,
  BUYER_DASHBOARD_ROUTE,
  BUYER_MARKETPLACE_ROUTE,
  BUYER_ORDER_MANAGEMENT,
  BUYER_WALLET
} from "@/commons/consts/system/routes";
import Bid from "@/components/icons/bid.comp";
import Order from "@/components/icons/order.comp";
import FinancialsIcon from "@/components/icons/financials.comp";
import Box from "@mui/material/Box";
import {
  BUYER_ACCOUNT_SUMMARY,
  BUYER_ADD_FUNDS,
  BUYER_BIDDING_ARENA
} from "@/commons/consts/system/routes";
import { useUserInfoHook } from "hooks/useUserInfoHook";
import { ROUTES } from "@/commons/consts";
import SettingIcon from "@/components/icons/settings.comp";
import { ContentLayout } from "@/components/layout/content-layout.comp";

const useBuyerMenuData = () => {
  const { isUserNotCompleteKYC } = useUserInfoHook();
  const walletUrl = isUserNotCompleteKYC ? `${BUYER_DASHBOARD_ROUTE}?kyc=1` : undefined;
  const walletChildren = React.useMemo(() => {
    if (isUserNotCompleteKYC) return undefined;
    return [
      {
        title: "Wallet",
        key: "wallet",
        url: BUYER_WALLET
      },
      {
        title: "Bank Accounts",
        key: "bank-accounts",
        url: [BANK_HOME, BANK_DETAIL, BANK_EDIT, NEW_BANK]
      },
      {
        title: "Account Summary",
        key: "accountSummary",
        url: BUYER_ACCOUNT_SUMMARY
      },
      {
        title: "Withdraw Fund",
        key: "withdrawFund",
        url: ROUTES.WITHDRAW_FUND
      },
      {
        title: "Add Fund",
        key: "addFund",
        url: BUYER_ADD_FUNDS
      }
    ];
  }, [isUserNotCompleteKYC]);
  return [
    {
      title: "Dashboard",
      key: "Dashboard",
      icon: DashboardRounded,
      url: BUYER_DASHBOARD_ROUTE
    },
    {
      title: "Marketplace",
      key: "Marketplace",
      icon: ShoppingCart,
      url: BUYER_MARKETPLACE_ROUTE
    },
    {
      title: "Bids",
      key: "Bids",
      icon: Bid,
      url: BUYER_BIDDING_ARENA
    },
    {
      title: "Orders",
      key: "Orders",
      icon: Order,
      url: BUYER_ORDER_MANAGEMENT
    },
    {
      title: "Financials",
      key: "Financials",
      icon: FinancialsIcon,
      url: walletUrl,
      children: walletChildren
    },
    {
      title: "Settings",
      key: "Settings",
      icon: SettingIcon,
      url: ROUTES.ADMIN_USER_MANAGEMENT_ROUTE
    }
  ];
};

export const BuyerMainLayout = ({ children }: { children: React.ReactNode }) => {
  const menuData = useBuyerMenuData();
  return (
    <ContentLayout menuData={menuData}>
      <Box height="100%">{children}</Box>
    </ContentLayout>
  );
};
