export const TEXTS = {
  // company info
  companyInfoHoverText:
    "This section is to collect the information of the company or business signing up to trade in this B2B Marketplace. We use this information only to verify the company via our Know Your Business (KYB) process. This is required for compliance with our Bank's due diligence requirements as well as securing the Marketplace for all as only vetted companies are allowed access for Trading.",
  companyNameHoverText: "The registered company name as per the Commercial or Trade License.",
  companyRegistrationNumberHoverText:
    "The company or business registration number or trade license number as per the Commercial or Trade License.",
  companyCountryHoverText: "The country that the company or business is registered in.",
  companyOfficeNumberHoverText: "The company or business contact telephone number.",
  companyIncorporationDateHoverText:
    "The date that the company or business was incorporated or registered.",
  companyRegistrationExpiryDateHoverText:
    "The date that the company or business registration is valid till.",

  // owner-info
  userInformattionHoverText:
    "This section is to collect the information of the primary User that is the authorized representative signing up to the Marketplace. This should be someone of signatory authority within company such as Senior Manager, Director, or the Business Owner. We use this information only to verify the User as part of our Know Your Customer (KYC) process. This is required for compliance with our Bank's due diligence policy as well as ensuring the safety and security for all Users of the Marketplace whereby only verified Users are allowed access.",
  partnerInformationHoverText:
    "In the instance where your KYC (Know Your Customer) process comes back as declined by our Bank, you will be required to add the details of another business partner, shareholder, or authorized representative of the company. You can do this by clicking this 'Add Partner' button to submit the details of the new partner and we will run the KYC process again with our bank. Once this is successfully completed, your company will be registered with full access for trading on the Marketplace.",
  ownerAddressLine1HoverText:
    "The first line of residential address of the authorized representative.",
  ownerCityHoverText: "The residential  city of the authorized representative.",
  ownerCountryHoverText: "The residential country of the authorized representative.",
  ownerStateHoverText:
    "The residential  state, province, or territory of the authorized representative.",
  ownerEmailHoverText: "The e-mail address of the authorized representative.",
  ownerNationalityHoverText: "The nationality of the authorized representative",
  ownerIdVerificationHoverText:
    "This section is to collect a copy of a valid government-issued photo ID document to allow us to easily verify your identity.",
  ownerIdVerificationSubtitle:
    "Please select one of the below government-issued photo ID document for verification of your identity."
};
