import imageCompression from "browser-image-compression";
import XLSX from "xlsx";
import { popError } from "./logger.util";

export const fileToBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

export const getImageDimension = (url) =>
  new Promise((resolve, reject) => {
    const img = new Image();

    img.onload = function () {
      resolve({
        width: img.width,
        height: img.height
      });
    };

    img.onerror = (error) => reject(error);

    img.src = url;
  });

export const createImage = (url) =>
  new Promise((resolve, reject) => {
    const image = new Image();
    image.addEventListener("load", () => resolve(image));
    image.addEventListener("error", (error) => reject(error));
    image.setAttribute("crossOrigin", "anonymous"); // needed to avoid cross-origin issues on CodeSandbox
    image.src = url;
  });

export function getRadianAngle(degreeValue) {
  return (degreeValue * Math.PI) / 180;
}

/**
 * Returns the new bounding area of a rotated rectangle.
 */
export function rotateSize(width, height, rotation) {
  const rotRad = getRadianAngle(rotation);

  return {
    width: Math.abs(Math.cos(rotRad) * width) + Math.abs(Math.sin(rotRad) * height),
    height: Math.abs(Math.sin(rotRad) * width) + Math.abs(Math.cos(rotRad) * height)
  };
}

/**
 * This function was adapted from the one in the ReadMe of https://github.com/DominicTobias/react-image-crop
 */
export async function getCroppedImg(imageSrc, pixelCrop, rotation = 0, fileName = null) {
  const flip = { horizontal: false, vertical: false };
  const image = await createImage(imageSrc);
  const canvas = document.createElement("canvas");
  const ctx = canvas.getContext("2d");

  if (!ctx) {
    return null;
  }

  const rotRad = getRadianAngle(rotation);

  // calculate bounding box of the rotated image
  const { width: bBoxWidth, height: bBoxHeight } = rotateSize(image.width, image.height, rotation);

  // set canvas size to match the bounding box
  canvas.width = bBoxWidth;
  canvas.height = bBoxHeight;

  // translate canvas context to a central location to allow rotating and flipping around the center
  ctx.translate(bBoxWidth / 2, bBoxHeight / 2);
  ctx.rotate(rotRad);
  ctx.scale(flip.horizontal ? -1 : 1, flip.vertical ? -1 : 1);
  ctx.translate(-image.width / 2, -image.height / 2);

  // draw rotated image
  ctx.drawImage(image, 0, 0);

  // croppedAreaPixels values are bounding box relative
  // extract the cropped image using these values
  const data = ctx.getImageData(pixelCrop.x, pixelCrop.y, pixelCrop.width, pixelCrop.height);

  // set canvas width to final desired crop size - this will clear existing context
  canvas.width = pixelCrop.width;
  canvas.height = pixelCrop.height;

  // paste generated rotate image at the top left corner
  ctx.putImageData(data, 0, 0);

  const imageType = "image/png";

  // As Base64 string
  if (!fileName) {
    return canvas.toDataURL(imageType);
  }

  // As a blob
  const { file, base64 } = await new Promise((resolve) => {
    canvas.toBlob((blob) => {
      let file = new File([blob], fileName, { type: imageType });
      resolve({
        file,
        base64: canvas.toDataURL(imageType)
      });
    }, imageType);
  });
  const compressedFile = await imageCompression(file, {
    maxSizeMB: 1
  });
  return {
    file: compressedFile,
    base64
  };
}

/**
 * This function was adapted from the one in the ReadMe of https://github.com/DominicTobias/react-image-crop
 */
export async function getCroppedAndCompressedImg(
  imageSrc,
  { pixelCrop, rotation = 0, fileName },
  { maxSizeMB = 5 }
) {
  const flip = { horizontal: false, vertical: false };
  const image = await createImage(imageSrc);
  const canvas = document.createElement("canvas");
  const ctx = canvas.getContext("2d");

  if (!ctx) {
    return null;
  }

  const rotRad = getRadianAngle(rotation);

  // calculate bounding box of the rotated image
  const { width: bBoxWidth, height: bBoxHeight } = rotateSize(image.width, image.height, rotation);

  // set canvas size to match the bounding box
  canvas.width = bBoxWidth;
  canvas.height = bBoxHeight;

  // translate canvas context to a central location to allow rotating and flipping around the center
  ctx.translate(bBoxWidth / 2, bBoxHeight / 2);
  ctx.rotate(rotRad);
  ctx.scale(flip.horizontal ? -1 : 1, flip.vertical ? -1 : 1);
  ctx.translate(-image.width / 2, -image.height / 2);

  // draw rotated image
  ctx.drawImage(image, 0, 0);

  // croppedAreaPixels values are bounding box relative
  // extract the cropped image using these values
  const data = ctx.getImageData(pixelCrop.x, pixelCrop.y, pixelCrop.width, pixelCrop.height);

  // set canvas width to final desired crop size - this will clear existing context
  canvas.width = pixelCrop.width;
  canvas.height = pixelCrop.height;

  // paste generated rotate image at the top left corner
  ctx.putImageData(data, 0, 0);

  const imageType = "image/png";

  // As Base64 string
  if (!fileName) {
    return canvas.toDataURL(imageType);
  }

  // As a blob
  const { file, base64 } = await new Promise((resolve) => {
    canvas.toBlob((blob) => {
      let file = new File([blob], fileName, { type: imageType });
      resolve({
        file,
        base64: canvas.toDataURL(imageType)
      });
    }, imageType);
  });
  if (file.size <= maxSizeMB * 1000_000) {
    return {
      file,
      base64
    };
  }
  const compressedFile = await imageCompression(file, {
    maxSizeMB
  });

  return {
    file: compressedFile,
    base64
  };
}

export const getFileExtension = (filename) => {
  return filename.split(".").pop();
};

export const validateSizeFile = (currentSize, conditionSize) => {
  if (currentSize && currentSize > conditionSize) {
    return "Your file exceeds the maximum upload size for this page (5mb)";
  }
  return "";
};

export const validateExtFile = (currentExt, conditionExt) => {
  if (currentExt && conditionExt.toLowerCase() !== currentExt.toLowerCase()) {
    return "The file format is not supported";
  }
  return "";
};

export const handleDownloadExcel = (data, filename, fileSheet) => {
  if (data.length) {
    const sheet = XLSX.utils.json_to_sheet(data, {
      skipHeader: true
    });
    const excelBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(excelBook, sheet, fileSheet);
    XLSX.writeFile(excelBook, filename, {
      bookType: "xlsx",
      type: "file",
      sheet: fileSheet
    });
  } else {
    popError("There is no data");
  }
};
