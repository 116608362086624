import React from "react";
import countries from "assets/country.json";

const _Country = ({ code }) => {
  const country = countries.find((current) => current.alpha2Code === code);
  if (!country) {
    return <></>;
  }
  return <span>{country.name}</span>;
};

export const Country = React.memo(_Country);
