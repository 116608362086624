import React from "react";

import { ConfigProvider } from "antd";
import { ThemeProvider } from "@mui/material";

import { QueryClientProvider } from "@/providers/query-client-provider";
import { keycloak } from "@/services/keycloak.service";

import { Loader } from "@/components/commons";
import { ReactKeycloakProvider } from "@react-keycloak/web";
import { SnackbarProvider } from "@/components/snackbar-provider/snackbar-provider.comp";
import { adminTheme } from "@/theme/admin-theme";
import { ReactQueryDevtools } from "react-query/devtools";
import { ErrorBoundary } from "../components/error-boundary/error-boundary.comp";
import { WSProvider } from "./ws-provider";
import { SettingsProvider } from "@/components/commons/settings/settings-provider.comp";

interface Props {
  children: React.ReactNode;
  initOptions?: unknown;
}

const PageLoader = () => {
  return (
    <div style={{ position: "fixed", top: 0, right: 0, bottom: 0, left: 0 }}>
      <Loader />
    </div>
  );
};
export const AppProviders = ({ children, initOptions }: Props) => {
  const ref = React.useRef<HTMLDivElement>(null);
  return (
    <ReactKeycloakProvider
      authClient={keycloak}
      initOptions={initOptions}
      LoadingComponent={<PageLoader />}
    >
      <WSProvider>
        <ConfigProvider getPopupContainer={() => ref.current as HTMLElement}>
          <ThemeProvider theme={adminTheme}>
            <ErrorBoundary>
              <SnackbarProvider>
                <QueryClientProvider>
                  <SettingsProvider>
                    <div style={{ height: "100%" }}>{children}</div>
                    <div className="legacy legacy-visible" style={{ height: "0" }}>
                      <div ref={ref} id="global-modal-container" />
                    </div>
                  </SettingsProvider>
                  {process.env.NODE_ENV === "development" && (
                    <ReactQueryDevtools initialIsOpen={false} />
                  )}
                </QueryClientProvider>
              </SnackbarProvider>
            </ErrorBoundary>
          </ThemeProvider>
        </ConfigProvider>
      </WSProvider>
    </ReactKeycloakProvider>
  );
};
