import { useGetOwnerInfo } from "@/entities/owner/libs/use-owner-info";
import { useRef, useMemo } from "react";

const parseFormData = async (forms, deletedIds) => {
  let composedValues = [];
  let hasError = false;
  for (const form of forms) {
    if (form == null) return;
    try {
      await form.validateFields();
      const values = form.getFieldsValue();
      const documents = values.documents?.filter((document) => !document.isDeleted);
      composedValues.push({ ...values, documents });
    } catch {
      hasError = true;
    }
  }

  // Remove deleteIds in array after added id props
  if (deletedIds && deletedIds.current && deletedIds.current.length > 0) {
    composedValues = composedValues.filter((item) => deletedIds.includes(item.id));
  }
  return [hasError, composedValues];
};

const getDeletedDocuments = (owners, composedValues) => {
  const initialDocuments = [];
  for (const owner of owners) {
    if (owner.documents == null) continue;
    initialDocuments.push(
      ...owner.documents.map((document) => ({ ...document, ownerId: owner.id }))
    );
  }
  const currentDocuments = [];
  for (const { documents } of composedValues) {
    if (documents == null) continue;
    for (const document of documents) {
      if (!document.isDeleted) currentDocuments.push(document);
    }
  }
  const deletedDocuments = initialDocuments.filter(
    (document) => !currentDocuments.some(({ id }) => id === document.id)
  );
  return deletedDocuments;
};

export const useOwnerInfo = () => {
  const { owners: allOwners, isLoading } = useGetOwnerInfo();

  const owner = useMemo(() => {
    if (allOwners == null || allOwners.length === 0) return {};
    if (allOwners.length === 1) return { ...allOwners[0], owner: true };
    return allOwners.find((owner) => owner.owner) ?? {};
  }, [allOwners]);
  const partners = useMemo(() => {
    if (allOwners == null || allOwners.length === 1) return [];
    return allOwners.filter((owner) => !owner.owner);
  }, [allOwners]);
  return { owner, partners, isLoading };
};

export const useOwnerInfoController = (owners) => {
  const formsRef = useRef([]);
  const deletedIds = useRef([]);
  const handleDeleteOwner = (id) => {
    deletedIds.current = [...deletedIds.current, id];
  };

  const composeValues = async () => {
    const [hasError, composedValues] = await parseFormData(
      formsRef.current.filter((ref) => ref?.form).map((ref) => ref.form),
      deletedIds.current
    );
    const deletedOwnerIds = deletedIds.current.filter((id) =>
      owners.find((owner) => owner.id === id)
    );
    const deletedDocuments = getDeletedDocuments(owners, composedValues).filter(
      (deletedDocument) => !deletedOwnerIds.includes(deletedDocument.ownerId)
    );
    return [hasError, composedValues, deletedOwnerIds, deletedDocuments];
  };

  return {
    formsRef,
    handleDeleteOwner,
    composeValues
  };
};
