import { CompanyInfoResponse, getCompanyInfo } from "@/services/company.service";
import { useQuery, useQueryClient } from "react-query";

export const useInvalidateGetCompany = () => {
  const queryClient = useQueryClient();
  return () => queryClient.invalidateQueries(["company-info"]);
};

export const useGetCompany = () => {
  const { data, ...others } = useQuery(["company-info"], getCompanyInfo);
  const company: Partial<CompanyInfoResponse> = data ?? {};
  return { company, ...others };
};
