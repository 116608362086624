import { MinusOutlined } from "@ant-design/icons";
import { Button } from "antd";
import { useInOutAnimation } from "hooks/useInOutAnimation";
import React from "react";
import { animated } from "react-spring";
export const MultiFormContext = React.createContext({});
export const MultiForm = ({ children, initialValues, min, max }) => {
  const [formArray, setFormArray] = React.useState(
    initialValues.map((values, index) => ({
      initialValues: values,
      id: values.id || `${name}-${index}`
    }))
  );
  const deleteForm = (id) => {
    setFormArray((formArray) => formArray.filter((item) => item.id !== id));
  };
  const addForm = () => {
    setFormArray((formArray) => [
      ...formArray,
      {
        id: `${name}-${formArray.length}`,
        initialValues: {}
      }
    ]);
  };
  const canAdd = React.useMemo(() => {
    return max == null || formArray.length < max;
  }, [formArray, max]);
  const canRemove = React.useMemo(() => {
    return min == null || formArray.length > min;
  }, [formArray, min]);
  return (
    <MultiFormContext.Provider value={{ formArray, deleteForm, addForm, canAdd, canRemove }}>
      {children}
    </MultiFormContext.Provider>
  );
};
const Content = React.forwardRef(({ onDeleteForm, Form, formProps }, ref) => {
  const { formArray, deleteForm } = React.useContext(MultiFormContext);

  const transitions = useInOutAnimation(formArray, (form) => form.id);
  const handleDeleteForm = (id) => {
    deleteForm(id);
    onDeleteForm && onDeleteForm(id);
  };
  const setRef = (form, index) => {
    if (form == null) {
      delete ref.current[index];
      return;
    }
    ref.current[index] = form;
  };
  return (
    <div>
      {transitions.map(({ item, props, key }, index) => {
        const { initialValues, id } = item;
        return (
          <animated.div key={key} style={props}>
            <div>
              <div className="d-flex justify-content-end">
                <Button
                  type="danger"
                  icon={<MinusOutlined />}
                  onClick={() => handleDeleteForm(id)}
                />
              </div>
              <Form
                {...formProps}
                initialValues={initialValues}
                ref={(formRef) => setRef(formRef, index)}
              />
            </div>
          </animated.div>
        );
      })}
    </div>
  );
});

const AddButton = ({ children, buttonProps }) => {
  const { addForm, canAdd } = React.useContext(MultiFormContext);
  const handleClick = () => {
    addForm();
    buttonProps?.onClick && buttonProps.onClick();
  };
  return (
    <Button type="primary" {...buttonProps} onClick={handleClick} disabled={!canAdd}>
      {children}
    </Button>
  );
};

MultiForm.Forms = Content;
MultiForm.AddButton = AddButton;
