import { DATE_FORMAT } from "@/commons/consts";
import { DASHBOARD_SCHEMA } from "components/widgets/dashboard/dashboard.schema";
import { chain, range } from "lodash";
import moment from "moment-timezone";

const { FIELDS } = DASHBOARD_SCHEMA;

/**
 * Filter data by past date
 */
export const filterByDays = (items, days, fieldName = "soldDate") => {
  let lastDays = moment.utc().subtract(days, "days");
  const result = [
    ...items.filter((item) => {
      return moment(item[fieldName]) >= lastDays;
    })
  ];
  return result;
};

/**
 * Filter data by product condition (type, category, productNames)
 */
export const filterByProductCondition = (items, condition) => {
  const { type, category, productName } = condition;
  let filteredItems = [...items];
  if (Array.isArray(type)) {
    filteredItems = filteredItems.filter((item) => type.includes(item.type));
  }
  if (Array.isArray(category)) {
    filteredItems = filteredItems.filter((item) => category.includes(item.category));
  }
  if (Array.isArray(productName)) {
    filteredItems = filteredItems.filter((item) => productName.includes(item.productName));
  }
  return filteredItems;
};

const isEqualOrInclude = (item, values) => {
  if (Array.isArray(values)) {
    return values.includes(item);
  }
  return item === values;
};

/**
 * Filter data by product on condition (type, category, productNames)
 */
export const filterByProductOneCondition = (items, condition) => {
  const { type, category, productName } = condition;
  let filteredItems = [...items];
  if (type) {
    filteredItems = filteredItems.filter((item) => isEqualOrInclude(item.type, type));
  }
  if (category) {
    filteredItems = filteredItems.filter((item) => isEqualOrInclude(item.category, category));
  }
  if (productName) {
    filteredItems = filteredItems.filter((item) => isEqualOrInclude(item.productName, productName));
  }
  return filteredItems;
};

/**
 * Filter data by year
 */
export const filterByYear = (items, year) => {
  const result = items.filter((item) => moment.utc(item.soldDate).year() === year);
  return result;
};

/**
 * Filter data by month
 */
export const filterByMonth = (items, month) => {
  const result = items.filter((item) => moment.utc(item.soldDate).month() === month - 1);
  return result;
};

/**
 * Group data by product name
 */
export const groupByProduct = (items) => {
  const result = [
    ...items
      .reduce((res, obj) => {
        const key = obj.productName;
        if (res.get(key)) {
          const item = res.get(key);
          item.quantity += obj.quantity;
          return res.set(key, item);
        } else {
          const item = { ...obj };
          return res.set(key, item);
        }
      }, new Map())
      .values()
  ];

  return result;
};

/**
 * Group data by field
 */
export const groupByField = (items, field) => {
  const result = [
    ...items
      .reduce((res, obj) => {
        const key = obj[field];
        if (res.get(key)) {
          const item = res.get(key);
          item.quantity += obj.quantity;
          return res.set(key, item);
        } else {
          const item = { ...obj };
          return res.set(key, item);
        }
      }, new Map())
      .values()
  ];

  return result;
};

/**
 * Sort and get top data by field
 */
export const sortAndGetTopDataByField = (items, top, field) => {
  return items.sort((a, b) => b[field] - a[field]).slice(0, top);
};

/**
 * Sort data by field
 */
export const sortDataByField = (items, field) => {
  return items.sort((a, b) => b[field] - a[field]);
};

export const sortDateTimeDataByField = (items, field) => {
  return items.sort((a, b) => moment(a[field]).valueOf() - moment(b[field]).valueOf());
};

export const filterByCurrentDay = (data, datetimeField) => {
  if (!Array.isArray(data) || data.length === 0) {
    return [];
  }
  return data.filter(
    (item) => moment(item[datetimeField]).format(DATE_FORMAT) === moment.utc().format(DATE_FORMAT)
  );
};

export const filterByCurrentTimeline = (data, datetimeField, timeline) => {
  if (!Array.isArray(data) || data.length === 0) {
    return [];
  }
  const currenDateOfTimeline = moment.utc().valueOf();
  const currentDayOfTimeline = getDayOfTimeline(timeline);
  const firstDateOfTimeline = moment.utc().subtract(currentDayOfTimeline, "days").valueOf();

  return data.filter((item) => {
    const soldDateUnix = moment(item[datetimeField]).valueOf();
    return soldDateUnix <= currenDateOfTimeline && soldDateUnix >= firstDateOfTimeline;
  });
};

const getDayOfTimeline = (timeline) => {
  switch (timeline) {
    case "week":
      return moment.utc().days();
    case "month":
      return moment.utc().date();
    case "year":
      return moment.utc().dayOfYear();
    default:
      return 0;
  }
};

export const groupByDaySteps = (items, step, lastestNumberOfDay) => {
  if (!Array.isArray(items) || items.length === 0) {
    return [];
  }

  const parsedResult = [];
  const arrayOfLatestdays = createArrayOfLatestDays(lastestNumberOfDay);

  const groupByDay = chain(items)
    .groupBy((item) => {
      return moment(item.soldDate).format(DATE_FORMAT);
    })
    .value();

  const result = arrayOfLatestdays.map((day) => {
    const quantity = groupByDay[day]
      ? groupByDay[day].map((item) => item.quantity).reduce((total, quantity) => total + quantity)
      : 0;
    return {
      soldDate: day,
      quantity
    };
  });

  while (result.length > 0) {
    parsedResult.push(result.splice(0, step));
  }

  return parsedResult;
};

export const createArrayOfLatestDays = (lastestNumberOfDay) => {
  const todayDateUnix = moment.utc().valueOf();
  const subtractLatestdaysUnix = moment
    .utc()
    .subtract(lastestNumberOfDay - 1, "days") //count the current day
    .valueOf();
  const oneDayInUnixTime = (todayDateUnix - subtractLatestdaysUnix) / (lastestNumberOfDay - 1);

  const arrayOfLatestdays = range(
    subtractLatestdaysUnix,
    todayDateUnix + oneDayInUnixTime, //count the current day
    oneDayInUnixTime
  ).map((unixTime) => moment(unixTime).format(DATE_FORMAT));

  return arrayOfLatestdays;
};

/**
 * Add total price for list
 */
export const mapTotalPrice = (items) => {
  const result = items.map((item) => {
    const { quantity, unitPrice } = item;
    return { ...item, totalPrice: quantity * unitPrice };
  });
  return result;
};

/**
 * Map product data to data select name
 */
export const mapDataSelect = (items) => {
  const result = items.map((item) => {
    const { name } = item;
    return { name: name, value: name };
  });
  return result;
};

/**
 * Get data (name, value) by field name
 */
export const getDataByFieldName = (items, name) => {
  if (!items || !Array.isArray(items) || items.length === 0) {
    return [];
  }
  let result = [];
  result = [
    ...items
      .reduce((res, obj) => {
        let key = obj[name];
        if (!res.get(key)) {
          return res.set(key, { name: obj[name], value: obj[name] });
        }
        return res;
      }, new Map())
      .values()
  ];
  return result;
};

/**
 * Group data two year by field name
 */
export const groupDataTwoYearByFieldName = (dataCurrentYear, dataPrevYear, name, value) => {
  let dataGroupCurrent, dataGroupPrev;

  switch (value) {
    case FIELDS.name:
      dataGroupCurrent = groupByField(dataCurrentYear, FIELDS.name);
      dataGroupPrev = groupByField(dataPrevYear, FIELDS.name);
      break;

    case FIELDS.type:
      dataGroupCurrent = groupByField(dataCurrentYear, FIELDS.type);
      dataGroupPrev = groupByField(dataPrevYear, FIELDS.type);
      break;

    case FIELDS.category:
      dataGroupCurrent = groupByField(dataCurrentYear, FIELDS.category);
      dataGroupPrev = groupByField(dataPrevYear, FIELDS.category);
      break;

    default:
      dataGroupCurrent = groupByField(dataCurrentYear, FIELDS.name);
      dataGroupPrev = groupByField(dataPrevYear, FIELDS.name);
      break;
  }

  const current = dataGroupCurrent.find((i) => i[name] === value);
  const prev = dataGroupPrev.find((i) => i[name] === value);

  return [current, prev];
};

/**
 * Group data by product condition
 */
export const groupDataByProductCondition = (items, condition) => {
  let result = items;
  switch (condition) {
    case FIELDS.type:
      result = groupByField(items, FIELDS.type);
      break;
    case FIELDS.category:
      result = groupByField(items, FIELDS.category);
      break;
    default:
      break;
  }
  return result;
};

/**
 * Check hiden or show chart
 */
export const isShowChart = (showCharts, chartName) => {
  return showCharts.includes(chartName);
};

/**
 * Group data 12 months
 */
export const groupDataBy12Month = (data) => {
  let jan = [],
    feb = [],
    mar = [],
    apr = [],
    may = [],
    jun = [],
    jul = [],
    aug = [],
    sep = [],
    oct = [],
    nov = [],
    dec = [];

  data.forEach((item) => {
    switch (moment.utc(item.soldDate).month()) {
      case 0:
        jan.push(item);
        break;
      case 1:
        feb.push(item);
        break;
      case 2:
        mar.push(item);
        break;
      case 3:
        apr.push(item);
        break;
      case 4:
        may.push(item);
        break;
      case 5:
        jun.push(item);
        break;
      case 6:
        jul.push(item);
        break;
      case 7:
        aug.push(item);
        break;
      case 8:
        sep.push(item);
        break;
      case 9:
        oct.push(item);
        break;
      case 10:
        nov.push(item);
        break;
      case 11:
        dec.push(item);
        break;
      default:
        break;
    }
  });
  return [jan, feb, mar, apr, may, jun, jul, aug, sep, oct, nov, dec];
};
