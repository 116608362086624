import { usePrefixPath } from "@/utils/location.util";
import { Redirect as RRedirect, RedirectProps } from "react-router-dom";

export const Redirect = ({ to, ...props }: RedirectProps) => {
  const { generatePath } = usePrefixPath();
  const getPath = () => {
    if (typeof to === "string") return generatePath(to);
    return to;
  };
  return <RRedirect {...props} to={getPath()} />;
};
