export const convertStringToMinutes = (datetime: string) => {
  const res = datetime.split(":");
  const hourToMinute = parseInt(res[0]) * 60;
  return parseInt(res[1]) + hourToMinute;
};

export const parsePhoneNumber = (phone) => {
  if (phone === undefined) {
    return [];
  }
  if (!phone.includes("+")) {
    return [undefined, phone];
  }
  const phoneArray = phone.split(" ");
  const phonePrefix = phoneArray[0].substring(1);
  const phoneNumber = phoneArray[1];
  return [phonePrefix, phoneNumber];
};

export const convertMbToBytes = (mb) => {
  return mb * 1024 * 1024;
};

export const toNumber = (value) => {
  return value ? value.toString().replace(/[^\d(\.\d)]/g, "") : "";
};

export const parseProductsFromOrderDetails = (order) => {
  const { productOrderResponses = [] } = order;
  const products = productOrderResponses.map((product) => ({
    id: product.productId,
    url: product.images[0].url,
    name: product.productName,
    quantity: product.quantity,
    totalPrice: product.quantity * product.unitPrice,
    unitPrice: product.unitPrice,
    warranty: product.warranty,
    phoneOperator: product.phoneOperator,
    productCategory: product.productCategory
  }));
  return products;
};

export const ParseDataDetailSpecification = (data) => {
  let obj = {};
  const dataJson = JSON.parse(data);
  if (dataJson) {
    Object.keys(dataJson).forEach(function (key) {
      obj = {
        ...obj,
        [key]: dataJson[key]
      };
    });
  }
  return obj;
};

export const ParseOriginVariantRequest = (data) => {
  let obj = {};
  if (data && data.length > 0) {
    data.forEach((item) => {
      if (item.name === "originVariantRequest") {
        const dataJson = JSON.parse(item.value);
        if (dataJson) {
          Object.keys(dataJson).forEach(function (key) {
            obj = {
              ...obj,
              [key]: dataJson[key]
            };
          });
        }
      }
    });
  }
  return obj;
};

export const parseNameProductDetail = (str) => {
  let text = "";

  if (str && str.length > 0 && str.indexOf("[") > -1) {
    text = JSON.parse(str).join(", ");
  } else {
    text = str;
  }
  return text;
};

export const parseNameTitle = (str) => {
  let textName = "";
  if (str) {
    const dataParseHeader = str.split("-");
    if (dataParseHeader && dataParseHeader.length > 0) {
      dataParseHeader.forEach((item, index) => {
        if (item.indexOf("[") > -1) {
          textName = `${textName} - ${JSON.parse(item).join(", ")}`;
        } else {
          textName = index === 0 ? item : `${textName} - ${item}`;
        }
      });
    }
  }

  return textName;
};
