import React from "react";
import debounce from "lodash/debounce";
import { Loader } from "@/components/commons";

interface Props {
  children: React.ReactNode;
  theme?: {
    name: string;
  };
}
export const AuthThemeLayout = ({ children, theme = { name: "seller" } }: Props) => {
  const [loading, setLoading] = React.useState(false);

  const [defaultTheme] = React.useState(theme);

  React.useEffect(() => {
    let subscription = true;
    setLoading(true);
    const changeTheme = debounce(() => {
      document.getElementsByTagName("body")[0].className = defaultTheme.name;
      setTimeout(() => {
        if (subscription) setLoading(false);
      }, 300);
    }, 300);
    changeTheme();
    return () => {
      subscription = false;
    };
  }, [defaultTheme]);

  if (loading) return <Loader />;

  return <>{children}</>;
};
