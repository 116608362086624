import { ERR_MSG } from "@/commons/consts";
import * as yup from "yup";

export const addressValidationSchema = yup.object({
  addressLine1: yup.string().nullable().required(ERR_MSG.REQUIRED_ERR("Company Address Line 1")),
  country: yup.string().nullable().required(ERR_MSG.REQUIRED_ERR("Country")),
  city: yup.string().nullable().required(ERR_MSG.REQUIRED_ERR("City")),
  state: yup.string(),
  postalCode: yup.string().nullable().required(ERR_MSG.REQUIRED_ERR("Postal Code")),
  email: yup
    .string()
    .nullable()
    .required(ERR_MSG.REQUIRED_ERR("Email"))
    .email(ERR_MSG.REQUIRED_ERR("Email")),
  phone: yup.string().nullable().required(ERR_MSG.REQUIRED_ERR("Phone Number"))
});
