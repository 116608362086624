import React from "react";
import { CloseOutlined } from "@ant-design/icons";

import { KYCProcess } from "./kyc-process/kyc-process.comp";

import styles from "./kyc-modal.module.scss";
import {
  openCompleteKYCModal,
  CompleteKYCModalContent
} from "../complete-kyc-modal/complete-kyc-modal.comp";
import { KYC_STATUSES } from "@/commons/consts";
import {
  useInvalidateUserProfiles,
  useUserProfile
} from "@/entities/user-profile/libs/use-user-profile";
import { Loader } from "@/components/commons";

export const KYCModalContent = ({ onSkip, onSubmitted }) => {
  const { user, isLoading } = useUserProfile();
  const invalidate = useInvalidateUserProfiles();
  const handleSubmitted = async () => {
    await invalidate();
    onSubmitted && onSubmitted();
  };
  if (isLoading) {
    return (
      <div style={{ height: "400px" }}>
        <Loader />
      </div>
    );
  }
  if (user.kycStatus === KYC_STATUSES.PASSED) {
    return <></>;
  }
  if (user.kycStatus === KYC_STATUSES.IN_PROGRESS) {
    return <CompleteKYCModalContent onConfirm={onSkip} />;
  }
  return (
    <div className={styles.content}>
      <div className={styles.header}>
        <h5>Let's start trading</h5>
        <p>
          Please complete the Know Your Customer (KYC) steps to continue trading on the Marketplace.
          <br />
          We verify all traders in order to provide a secure trading experience for all users on the
          Marketplace.
        </p>
      </div>
      <KYCProcess onSkip={onSkip} onSubmitted={handleSubmitted} />
    </div>
  );
};

export const openKYCProcessModal = (modal, onClose) => {
  const instance = modal.info({
    icon: (
      <div
        onClick={() => {
          instance.destroy();
          onClose && onClose();
        }}
        style={{
          position: "absolute",
          right: "-1rem",
          top: "-2rem",
          cursor: "pointer",
          color: "white",
          fontWeight: "bold"
        }}
      >
        <CloseOutlined />
      </div>
    ),
    maskClosable: false,
    keyboard: true,
    footer: null,
    width: "80%",
    okButtonProps: { style: { display: "none" } },
    className: styles["modal-container"],
    content: (
      <KYCModalContent
        onSkip={() => {
          instance && instance.destroy();
          onClose && onClose();
        }}
        onSubmitted={() => {
          instance && instance.destroy();
          onClose && onClose();
          openCompleteKYCModal(modal, onClose);
        }}
      />
    )
  });
};
