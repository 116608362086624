import React from "react";
export const useCompanyInfoController = () => {
  const refCompanyInfoForm = React.useRef();
  const composeValues = () => {
    const companyForm = refCompanyInfoForm.current.form;
    let hasError = false;
    let composedValues;
    companyForm &&
      companyForm.validateFields((error, values) => {
        if (error) {
          hasError = true;
        } else {
          composedValues = values;
        }
      });

    return [hasError, composedValues];
  };

  return {
    refCompanyInfoForm,
    composeValues
  };
};
