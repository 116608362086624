import React from "react";
import { SubLayout } from "@/components/layout/sub-layout.comp";
import { getMenuData } from "./menu-data";
import { usePrefixPath } from "@/utils/location.util";

export const Layout = ({ children }: { children: React.ReactNode }) => {
  const { prefix } = usePrefixPath();
  return (
    <SubLayout menuData={getMenuData(prefix)} header="Profile">
      {children}
    </SubLayout>
  );
};
