import range from "lodash/range";

export const BADGE_TYPES = Object.freeze({
  MANUFACTURE: "MANUFACTURE",
  DISTRIBUTOR: "DISTRIBUTOR",
  NUMBER_BADGE: "NUMBER_BADGE",
  VALUE_BADGE: "VALUE_BADGE"
});

export const BADGE_LEVELS = Object.freeze({
  [BADGE_TYPES.STATUS_BADGE]: {
    0: "Distributor",
    1: "Manufactor"
  },

  [BADGE_TYPES.NUMBER_BADGE]: {
    0: "blue",
    1: "bronze",
    2: "silver",
    3: "gold",
    4: "platinum"
  },
  [BADGE_TYPES.VALUE_BADGE]: {
    0: "blue",
    1: "bronze",
    2: "silver",
    3: "gold",
    4: "platinum"
  }
});

export const DATE_FORMAT = "YYYY-MM-DD";
export const DATETIME_FORMAT = "YYYY-MM-DD HH:mm A";

export const TIME_FIELDS = Object.freeze({
  week: "7",
  month: "30",
  year: "365"
});

export const TIME_LABELS = Object.freeze({
  [TIME_FIELDS.week]: "Week",
  [TIME_FIELDS.month]: "Month",
  [TIME_FIELDS.year]: "Year"
});

export const SORT_ORDERS = Object.freeze({
  DESC: "desc",
  ASC: "asc"
});

export const SALE_CHANNELS = Object.freeze({
  OWN_BRAND: "Own Brand​",
  NICHE_BRANDS: "Niche Brands",
  WHOLESALE_BRANDS: "Wholesale Brands​",
  WHOLESALE_MASS: "Wholesale Mass"
});

export const DOCUMENT_RULE_ACTOR = Object.freeze({
  SELLER: "Seller",
  BUYER: "Buyer",
  LOGISTIC_SERVICE_PROVIDER: "Logistic Service Provider",
  INSPECTION_SERVICE_PROVIDER: "Inspection Provider"
});

export const ACTORS = {
  Seller: "SELLER",
  Buyer: "BUYER",
  "Logistic Service Provider": "LOGISTIC_SERVICE_PROVIDER",
  "Inspection Provider": "INSPECTION_SERVICE_PROVIDER"
};

export const ACTORS_REVERSE = {
  SELLER: "Seller",
  BUYER: "Buyer",
  LOGISTIC_SERVICE_PROVIDER: "Logistic Service Provider",
  INSPECTION_SERVICE_PROVIDER: "Inspection Provider"
};
export const WEEK_DAYS = Object.freeze({
  1: "Mon",
  2: "Tue",
  3: "Wed",
  4: "Thu",
  5: "Fri",
  6: "Sat",
  7: "Sun"
});

export const NOTIFICATION_TYPE = Object.freeze({
  ADMIN_ROUTE_ADD_NEW_USER: "ADMIN_ROUTE_ADD_NEW_USER",
  ADMIN_ROUTE_ADD_NEW_ADDRESS: "ADMIN_ROUTE_ADD_NEW_ADDRESS",
  ADMIN_PCC_CREDIT_COMMISSION_DEBIT: "ADMIN_PCC_CREDIT_COMMISSION_DEBIT",
  ADMIN_PROMPT_PCC_PAYMENT_FOR_BUYER: "ADMIN_PROMPT_PCC_PAYMENT_FOR_BUYER",
  ADMIN_MARKETPLACE_CREDIT_REQUEST_PCC: "ADMIN_MARKETPLACE_CREDIT_REQUEST_PCC",
  ADMIN_REBATE_CREDIT_ADD_NEW_USER: "ADMIN_REBATE_CREDIT_ADD_NEW_USER",
  ADMIN_ROUTE_ADD_ORDER: "ADMIN_ROUTE_ADD_ORDER",
  ADMIN_CONFIRM_EXTERNAL_ORDER: "ADMIN_CONFIRM_EXTERNAL_ORDER",
  // Create a product
  CCC_ENABLE_CREATE_PRODUCT: "CCC_ENABLE_CREATE_PRODUCT",
  CCC_DISABLE_CREATE_PRODUCT: "CCC_DISABLE_CREATE_PRODUCT",
  // Subscribe a Product
  BUYER_SUBSCRIBE: "BUYER_SUBSCRIBE",
  // Sale
  BUYER_SALE: "BUYER_SALE",
  SELLER_SALE: "SELLER_SALE_CREATED",
  SELLER_SALE_CREATED_COMBO: "SELLER_SALE_CREATED_COMBO",
  // Bid
  // Buyer
  BUYER_BID_COUNTER: "BUYER_BID_COUNTER",
  BUYER_BID_COUNTER_COMBO: "BUYER_BID_COUNTER_COMBO",
  BUYER_BID_ACCEPTED: "BUYER_BID_ACCEPTED",
  BUYER_BID_ACCEPTED_COMBO: "BUYER_BID_ACCEPTED_COMBO",
  BUYER_BID_REJECTED: "BUYER_BID_REJECTED",
  BUYER_BID_REJECTED_COMBO: "BUYER_BID_REJECTED_COMBO",
  BUYER_BID_DELETED: "BUYER_BID_DELETED",
  BUYER_BID_DELETED_COMBO: "BUYER_BID_DELETED_COMBO",

  // Seller
  SELLER_BID_COUNTER: "SELLER_BID_COUNTER",
  SELLER_BID_COUNTER_COMBO: "SELLER_BID_COUNTER_COMBO",
  SELLER_BID_ACCEPTED: "SELLER_BID_ACCEPTED",
  SELLER_BID_ACCEPTED_COMBO: "SELLER_BID_ACCEPTED_COMBO",
  SELLER_BID_REJECTED: "SELLER_BID_REJECTED",
  SELLER_BID_REJECTED_COMBO: "SELLER_BID_REJECTED_COMBO",
  SELLER_BID_DELETED: "SELLER_BID_DELETED",
  SELLER_BID_DELETED_COMBO: "SELLER_BID_DELETED_COMBO",
  SELLER_BID_FIRST: "SELLER_BID_FIRST",
  SELLER_BID_FIRST_COMBO: "SELLER_BID_FIRST_COMBO",

  // Order
  SELLER_SERIAL_NUMBER: "SELLER_SERIAL_NUMBER",
  SELLER_CONFIRM_ADDITIONAL_DOCUMENT: "SELLER_CONFIRM_ADDITIONAL_DOCUMENT",
  SELLER_SHIPPING_DETAILS: "SELLER_SHIPPING_DETAILS",
  SELLER_PREPARE_PICKUP: "SELLER_PREPARE_PICKUP",
  SELLER_FIRST_REMINDER_PICKUP: "SELLER_FIRST_REMINDER_PICKUP",
  SELLER_SECOND_REMINDER_PICKUP: "SELLER_SECOND_REMINDER_PICKUP",
  SELLER_PICKUP_SUCCESS: "SELLER_PICKUP_SUCCESS",
  SELLER_DELIVERY_SUCCESS: "SELLER_DELIVERY_SUCCESS",
  SELLER_INSPECTION: "SELLER_INSPECTION",
  SELLER_INSPECTION_PASS: "SELLER_INSPECTION_PASS",
  SELLER_INSPECTION_FAIL: "SELLER_INSPECTION_FAIL",
  SELLER_REVIEW: "SELLER_REVIEW",
  SELLER_DOCUMENT_COMBO: "SELLER_DOCUMENT_COMBO",
  SELLER_DOCUMENT: "SELLER_DOCUMENT",
  SELLER_CONFIRM_EXTERNAL_ORDER: "SELLER_CONFIRM_EXTERNAL_ORDER",

  BUYER_SERIAL_NUMBER: "BUYER_SERIAL_NUMBER",
  BUYER_LOGISTIC_PROVIDER: "BUYER_LOGISTIC_PROVIDER",
  BUYER_SHIPMENT: "BUYER_SHIPMENT",
  BUYER_SHIPMENT_DOCUMENT: "BUYER_SHIPMENT_DOCUMENT",
  BUYER_PREPARE_PICKUP: "BUYER_PREPARE_PICKUP",
  BUYER_PICKUP_SUCCESS: "BUYER_PICKUP_SUCCESS",
  BUYER_INSPECTION: "BUYER_INSPECTION",
  BUYER_REVIEW: "BUYER_REVIEW",
  BUYER_DOCUMENT_COMBO: "BUYER_DOCUMENT_COMBO",
  BUYER_DOCUMENT: "BUYER_DOCUMENT",
  PROMPT_PCC_CREATE_ROUTE: "PROMPT_PCC_CREATE_ROUTE",
  BUYER_CONFIRM_EXTERNAL_ORDER: "BUYER_CONFIRM_EXTERNAL_ORDER",

  // Approval
  SELLER_SALE_PENDING: "SELLER_SALE_PENDING",
  SELLER_SALE_APPROVED: "SELLER_SALE_APPROVED",
  SELLER_SALE_REJECTED: "SELLER_SALE_REJECTED",
  SELLER_SALE_REVIEW: "SELLER_SALE_REVIEW",

  // Blocked fund
  BUYER_BLOCK_FUND_IVS: "BUYER_BLOCK_FUND_IVS",
  BUYER_BLOCK_FUND_TSF: "BUYER_BLOCK_FUND_TSF",
  // Load fund
  BUYER_FIRST_LOAD_FUND: "BUYER_FIRST_LOAD_FUND",
  BUYER_SECOND_LOAD_FUND: "BUYER_SECOND_LOAD_FUND",
  // cancel order
  BUYER_CANCEL_SHIPMENT: "BUYER_CANCEL_SHIPMENT",
  SELLER_CANCEL_SHIPMENT: "SELLER_CANCEL_SHIPMENT",
  // provide pickup
  SELLER_PROVIDE_PICKUP_DATE: "SELLER_PROVIDE_PICKUP_DATE",

  //GMEX
  //seller
  SELLER_RECEIPT_WITHDRAWAL: "SELLER_RECEIPT_WITHDRAWAL",
  COMPLETION_WITHDRAWAL: "COMPLETION_WITHDRAWAL",
  SELLER_BALANCE_CREDIT: "SELLER_BALANCE_CREDIT",
  SELLER_BALANCE_DEBIT: "SELLER_BALANCE_DEBIT",
  SELLER_BALANCE_CREDIT_IVS: "SELLER_BALANCE_CREDIT_IVS",
  //buyer
  BUYER_RECEIPT_WITHDRAWAL: "BUYER_RECEIPT_WITHDRAWAL",
  BUYER_BALANCE_UPDATE_CREDIT: "BUYER_BALANCE_UPDATE_CREDIT",
  BUYER_BALANCE_UPDATE_DEBIT_IVS: "BUYER_BALANCE_UPDATE_DEBIT_IVS",
  BUYER_BALANCE_UPDATE_DEBIT_TSF: "BUYER_BALANCE_UPDATE_DEBIT_TSF",

  // Marketplace credit
  MARKETPLACE_CREDIT_ACCEPTED: "MARKETPLACE_CREDIT_ACCEPTED",
  MARKETPLACE_CREDIT_REJECTED: "MARKETPLACE_CREDIT_REJECTED",
  BUYER_CREDIT_LOAD_FUND: "BUYER_CREDIT_LOAD_FUND",
  PROMPT_USER_ADD_FUND: "PROMPT_USER_ADD_FUND",

  // Rebate
  REBATE_CREATED: "REBATE_CREATED",
  REBATE_UPDATED: "REBATE_UPDATED",
  REBATE_DELETED: "REBATE_DELETED",

  // Introducer
  TRADER_INTRODUCER_ASSGIN: "TRADER_INTRODUCER_ASSGIN",
  TRADER_INTRODUCER_REGISTER: "TRADER_INTRODUCER_REGISTER",
  INTRODUCER_COMMISSION: "INTRODUCER_COMMISSION",
  INTRODUCER_EXPIRED: "INTRODUCER_EXPIRED",

  // Coop
  COOP_CREATE_TRADER_COMPLETED: "COOP_CREATE_TRADER_COMPLETED",
  COOP_PROVIDE_SHIPPING_DETAILS: "COOP_PROVIDE_SHIPPING_DETAILS",
  COOP_UPLOAD_DOCUMENT: "COOP_UPLOAD_DOCUMENT",
  COOP_LOAD_FUND: "COOP_LOAD_FUND",

  //PROFORMA_INVOICE
  SELLER_CONFIRM_PROFORMA_INVOICE: "SELLER_CONFIRM_PROFORMA_INVOICE",
  SELLER_VIEW_PROFORMA_INVOICE: "SELLER_VIEW_PROFORMA_INVOICE",
  SELLER_PROFORMA_INVOICE_ACCEPTED: "SELLER_PROFORMA_INVOICE_ACCEPTED",
  SELLER_PROFORMA_INVOICE_REJECTED: "SELLER_PROFORMA_INVOICE_REJECTED",
  VIEW_PROFORMA_INVOICE_ACCEPTED_AS_SELLER: "VIEW_PROFORMA_INVOICE_ACCEPTED_AS_SELLER",
  SELLER_REJECT_PROFORMA_INVOICE: "SELLER_REJECT_PROFORMA_INVOICE",
  SELLER_BUYER_NOT_CONFIRM_PROFORMA_INVOICE: "SELLER_BUYER_NOT_CONFIRM_PROFORMA_INVOICE",
  SELLER_NOT_CONFIRM_PROFORMA_INVOICE: "SELLER_NOT_CONFIRM_PROFORMA_INVOICE",

  BUYER_VIEW_PROFORMA_INVOICE: "BUYER_VIEW_PROFORMA_INVOICE",
  BUYER_CONFIRM_PROFORMA_INVOICE: "BUYER_CONFIRM_PROFORMA_INVOICE",
  BUYER_PROFORMA_INVOICE_REJECTED: "BUYER_PROFORMA_INVOICE_REJECTED",
  BUYER_SELLER_NOT_CONFIRM_PROFORMA_INVOICE: "BUYER_SELLER_NOT_CONFIRM_PROFORMA_INVOICE",
  BUYER_NOT_CONFIRM_PROFORMA_INVOICE: "BUYER_NOT_CONFIRM_PROFORMA_INVOICE",

  SELLER_NEGOTIATION_COMMENT: "SELLER_NEGOTIATION_COMMENT",
  BUYER_NEGOTIATION_COMMENT: "BUYER_NEGOTIATION_COMMENT"
});

export const PHONE_CREDIT_TYPES = Object.freeze({
  SEVEN_DAYS: "A_SEVEN_DAYS",
  FOURTEEN_DAYS: "B_FOURTEEN_DAYS",
  TWENTY_ONE_DAYS: "C_TWENTY_ONE_DAYS",
  TWENTY_EIGHT_DAYS: "D_TWENTY_EIGHT_DAYS",
  THIRTY_FIVE_DAYS: "E_THIRTY_FIVE_DAYS",
  ESCROW_ON_DELIVERY: "ESCROW_ON_DELIVERY"
});

export const PHONE_CREDIT_TYPE_LABELS = Object.freeze({
  [PHONE_CREDIT_TYPES.SEVEN_DAYS]: "7 days",
  [PHONE_CREDIT_TYPES.FOURTEEN_DAYS]: "14 days",
  [PHONE_CREDIT_TYPES.TWENTY_ONE_DAYS]: "21 days",
  [PHONE_CREDIT_TYPES.TWENTY_EIGHT_DAYS]: "28 days",
  [PHONE_CREDIT_TYPES.THIRTY_FIVE_DAYS]: "35 days",
  [PHONE_CREDIT_TYPES.ESCROW_ON_DELIVERY]: "Escrow on Delivery"
});

export const PRODUCT_DETAILS_LABELS = Object.freeze({
  productCategory: "Product Category",
  productType: "Product Type",
  productName: "Product Name",
  hsCode: "HS Code",
  chapterLabel: "Chapter Label",
  headingLabel: "Heading Label",
  hsCodeDescription: "HS Code Description",
  ahecc: "AHECC",
  aheccDescription: "AHECC Full Description",
  aheccFullDescription: "AHECC Full Description",
  unitOfQuantity: "Unit of Quantity",
  minimumOrderQuantity: "Minimum Order Quantity",
  minimumQuantity: "Minimum Order Quantity",
  allowedMutipleQuantity: "Allowed Multiple Quantity",
  allowedMultiplesQuantity: "Allowed Multiple Quantity",
  keyword: "Key Word",
  quantity: "Quantity"
});

export const USER_TABS_NAME = Object.freeze({
  profileInfo: "profile-info",
  companyInfo: "company-info",
  ownerInfo: "owner-info",
  bankDetails: "bank-details",
  documents: "uploaded-documents",
  security: "add-credit-security",
  settings: "settings",
  rebateDetails: "rebate-details",
  introducer: "introducer-info",
  businessDetails: "business-details"
});
export const PREFERENCE_TABS_NAME = Object.freeze({
  general: "general",
  branding: "branding"
});

export const MESSAGES = Object.freeze({
  LOGIN_SUCCESS_TITLE: "Logged In",
  CHANGE_PASSWORD_SUCCESS: "Your password has been changed successfully.",
  RESET_PASSWORD_SUCCESS: "Reset Password Success",
  PHONE_VERIFICATION_CODE_SENT: "We've sent you a verification code to the above phone number.",
  SEND_VERIFICATION_CODE: "Send Verification Code",
  PHONE_VERIFY_CODE: "Verify",
  VERIFY_PHONE_NOW: "Please verify your phone number",
  VERIFY_PHONE_SUCCESSFUL: "Verify successful",
  UPDATE_SUCCESSFUL: "Update Successful",
  VERIFY_PHONE_TO_USE_THIS_FEATURE: "Please verify your phone number to use this feature"
});

export const NOTIFICATION_CHANNELS = Object.freeze({
  EMAIL: "EMAIL",
  WHATSAPP: "WHATSAPP",
  WEB: "WEB",
  SMS: "SMS"
});

export const TWO_FACTOR_AUTH_TYPES = Object.freeze({
  DISABLED: "TWOFA_DISABLED",
  PER_30_DAYS: "PER_30_DAYS",
  EVERY_LOGIN: "EVERY_LOGIN",
  WHATSAPP_PER_30_DAYS: "TWOFA_WHATSAPP_EVERY_30_DAYS",
  WHATSAPP_EVERY_LOGIN: "TWOFA_WHATSAPP_EVERY_LOGIN",
  EMAIL_PER_30_DAYS: "TWOFA_EMAIL_EVERY_30_DAYS",
  EMAIL_EVERY_LOGIN: "TWOFA_EMAIL_EVERY_LOGIN",
  SMS_PER_30_DAYS: "TWOFA_SMS_EVERY_30_DAYS",
  SMS_EVERY_LOGIN: "TWOFA_SMS_EVERY_LOGIN",
  GA_PER_30_DAYS: "TWOFA_GA_EVERY_30_DAYS",
  GA_EVERY_LOGIN: "TWOFA_GA_EVERY_LOGIN"
});

export const THREE_STEPS_SECURITY_STATUS = Object.freeze({
  SUCCESS: "SUCCESS",
  INVALID: "INVALID",
  OTP_EXPIRED: "OTP_EXPIRED",
  OTP_LOCKED: "OTP_LOCKED",
  PASSCODE_LOCKED: "PASSCODE_LOCKED"
});

export const KYC_STATUSES = Object.freeze({
  NOT_COMPLETED: "NOT_COMPLETED",
  IN_PROGRESS: "IN_PROGRESS",
  PASSED: "PASSED",
  FAILED: "FAILED"
});

export const COMPANY_TYPE = Object.freeze({
  CCC_SELLER: "CCC_SELLER",
  CCC_BUYER: "CCC_BUYER",
  CCC_DUAL: "CCC",
  PCC_DUAL: "PCC"
});

export const USER_STATUSES = Object.freeze({
  ACTIVE: "ACTIVE",
  DISABLED: "DISABLED",
  INACTIVE: "INACTIVE",
  DELETED: "DELETED"
});

export const USER_STATUS_LABELS = Object.freeze({
  ACTIVE: "Active",
  DISABLED: "Inactive",
  INACTIVE: "Pending",
  DELETED: "Deleted"
});

export const COMBO_TEXT = "Combo";
export const COMBO_VALUE_NAME = "combo";

export const USER_ROLE = Object.freeze({
  seller: "seller",
  buyer: "buyer",
  seller_buyer: "seller_buyer"
});

export const USER_STATUSES_LABEL = Object.freeze({
  ACTIVE: "Active",
  INACTIVE: "Inactive",
  DELETED: "Deleted"
});

export const COMPANY_STATUSES = Object.freeze({
  ACTIVE: "ACTIVE",
  IN_PROGRESS: "IN_PROGRESS",
  INACTIVE: "INACTIVE",
  PENDING_APPROVAL: "PENDING_APPROVAL"
});

export const HH_MM_FORMAT = "HH:mm";

export const PRODUCT_WARRANTY_TYPES = Object.freeze({
  WARRANTY: "Warranty",
  NO_WARRANTY: "No Warranty"
});

export const PHONE_OPERATOR_LIMIT_TYPES = Object.freeze({
  NEVER_LOCKED: "Phone Never Locked",
  LOCKED_BY_OPERATOR: "Phone Locked by Operator",
  OPERATOR_UNLOCKED: "Operator Phone Unlocked"
});

export const CREDITS = Object.freeze({
  EXTERNAL_PAYMENT: {
    type: "EXTERNAL_PAYMENT",
    label: "External Payment"
  },
  A_SEVEN_DAYS: {
    type: "A_SEVEN_DAYS",
    label: "7 days"
  },
  B_FOURTEEN_DAYS: {
    type: "B_FOURTEEN_DAYS",
    label: "14 days"
  },
  C_TWENTY_ONE_DAYS: {
    type: "C_TWENTY_ONE_DAYS",
    label: "21 days"
  },
  D_TWENTY_EIGHT_DAYS: {
    type: "D_TWENTY_EIGHT_DAYS",
    label: "28 days"
  },
  E_THIRTY_FIVE_DAYS: {
    type: "E_THIRTY_FIVE_DAYS",
    label: "35 days"
  },
  ESCROW_ON_DELIVERY: {
    type: "ESCROW_ON_DELIVERY",
    label: "Escrow on Delivery"
  }
});

export const PHONE_CREDIT_TYPES_VALUES = Object.freeze({
  "7 days": "A_SEVEN_DAYS",
  "14 days": "B_FOURTEEN_DAYS",
  "21 days": "C_TWENTY_ONE_DAYS",
  "28 days": "D_TWENTY_EIGHT_DAYS",
  "35 days": "E_THIRTY_FIVE_DAYS",
  "Escrow on Delivery": "ESCROW_ON_DELIVERY"
});

export const FINANCIAL_TURNOVER = [
  {
    name: "",
    text: "10-25 Million USD",
    value: ""
  },
  {
    name: "",
    text: "25-50 Million USD",
    value: ""
  },
  {
    name: "",
    text: "50-100 Million USD",
    value: ""
  },
  {
    name: "",
    text: "more than 100 Million USD",
    value: ""
  }
];

export const GENERAL_YEARS = range(1970, 2020);

export const FINANCIAL_YEARS = range(2000, 2020);

export const BID_TYPE = Object.freeze({
  NEW_CREATED: "NEW_CREATED",
  FIRST: "FIRST",
  COUNTER: "COUNTER",
  COUNTER_APPROVING: "COUNTER_APPROVING",
  FINAL: "FINAL"
});

export const BID_STATUS = Object.freeze({
  ACTIVE: "ACTIVE",
  ACCEPTED: "ACCEPTED",
  REJECTED: "REJECTED",
  CANCEL: "CANCELLED",
  EXPIRED: "EXPIRED",
  DELETED: "DELETED",
  APPROVED: "APPROVED",
  INPROGRESS: "IN PROGRESS"
});

export const PAYMENT_METHODS = Object.freeze({
  MARKETPLACE_SERVICES: "MARKETPLACE_SERVICES",
  EXTERNAL_SERVICES: "EXTERNAL_PAYMENT"
});

export const PAYMENT_METHODS_LABELS = Object.freeze({
  [PAYMENT_METHODS.MARKETPLACE_SERVICES]: "From my wallet",
  [PAYMENT_METHODS.EXTERNAL_SERVICES]: "External Services"
});

export const CREDIT_TYPES_BY_PAYMENT_METHODS = Object.freeze({
  [PAYMENT_METHODS.MARKETPLACE_SERVICES]: {
    ESCROW_ON_DELIVERY: CREDITS.ESCROW_ON_DELIVERY.type
  }
});

export const ORDER_STATUS = Object.freeze({
  ACTIVE: "ACTIVE",
  ACCEPTED: "ACCEPTED",
  SHIPPING: "SHIPPING",
  INSPECTED: "INSPECTED",
  PENDING_PAYMENT: "PENDING_PAYMENT",
  PAID: "PAID",
  COMPLETED: "COMPLETED",
  REJECTED: "REJECTED",
  FAILED_INSPECTION: "FAILED_INSPECTION",
  FAILED_PAYMENT: "FAILED_PAYMENT",
  CANCEL: "CANCEL",
  ACTION_REQUIRED: "ACTION_REQUIRED",
  IN_PROGRESS: "IN_PROGRESS",
  BLOCKED: "BLOCKED"
});

export const ORDER_STATUS_LABEL = Object.freeze({
  [ORDER_STATUS.ACTIVE]: "ACTIVE",
  [ORDER_STATUS.ACCEPTED]: "ACCEPTED",
  [ORDER_STATUS.SHIPPING]: "SHIPPING",
  [ORDER_STATUS.INSPECTED]: "INSPECTED",
  [ORDER_STATUS.PENDING_PAYMENT]: "PENDING PAYMENT",
  [ORDER_STATUS.PAID]: "PAID",
  [ORDER_STATUS.COMPLETED]: "COMPLETED",
  [ORDER_STATUS.REJECTED]: "REJECTED",
  [ORDER_STATUS.FAILED_INSPECTION]: "FAILED INSPECTION",
  [ORDER_STATUS.FAILED_PAYMENT]: "FAILED PAYMENT",
  [ORDER_STATUS.CANCEL]: "CANCEL",
  [ORDER_STATUS.ACTION_REQUIRED]: "ACTION REQUIRED",
  [ORDER_STATUS.IN_PROGRESS]: "IN PROGRESS",
  [ORDER_STATUS.BLOCKED]: "BLOCKED"
});

export const ORDER_PROCESS = Object.freeze({
  PCC_CREATE_TAX: "PCC_CREATE_TAX",
  OFFER_APPROVED: "OFFER_APPROVED",
  PROVIDE_SHIPPING_DETAILS: "PROVIDE_SHIPPING_DETAILS",
  UPLOAD_SHIPPING_DOCUMENTS: "UPLOAD_SHIPPING_DOCUMENTS",
  WAIT_FOR_BUYER_UPLOAD_DOCUMENTS: "WAIT_FOR_BUYER_UPLOAD_DOCUMENTS",
  WAIT_FOR_SELLER_UPLOAD_DOCUMENTS: "WAIT_FOR_SELLER_UPLOAD_DOCUMENTS",
  CHOOSE_LOGISTICS_PROVIDER: "CHOOSE_LOGISTICS_PROVIDER",
  SHIPMENT_PICKUP: "SHIPMENT_PICKUP",
  TRACK_SHIPMENT: "TRACK_SHIPMENT",
  INSPECTION: "INSPECTION",
  SHIPMENT_REACH_BUYER_WAREHOUSE: "SHIPMENT_REACH_BUYER_WAREHOUSE",
  LOAD_FUND: "LOAD_FUND",
  PROVIDE_PICKUP_DATE: "PROVIDE_PICKUP_DATE",
  DONE: "DONE",
  CANCEL: "CANCEL",
  REVIEW: "REVIEW",
  GENERATE_TAX_INVOICE: "GENERATE_TAX_INVOICE",
  CHOOSE_THIRD_PARTY_PROVIDER: "CHOOSE_THIRD_PARTY_PROVIDER",
  CONFIRM_PICKUP_DATE: "CONFIRM_PICKUP_DATE",
  PAYMENT: "PAYMENT",
  PAYMENT_OVERDUE: "PAYMENT_OVERDUE",
  CONFIRM_ADDITIONAL_DOCUMENT: "CONFIRM_ADDITIONAL_DOCUMENT",
  UPLOAD_ADDITIONAL_DOCUMENT: "UPLOAD_ADDITIONAL_DOCUMENT",
  CONFIRM_PROFORMA_INVOICE: "CONFIRM_PROFORMA_INVOICE",
  BUYER_CONFIRM_PROFORMA_INVOICE: "BUYER_CONFIRM_PROFORMA_INVOICE"
});

export const ORDER_PROCESS_LABEL = Object.freeze({
  [ORDER_PROCESS.OFFER_APPROVED]: "OFFER APPROVED",
  [ORDER_PROCESS.PCC_CREATE_TAX]: "PCC CREATE TAX",
  [ORDER_PROCESS.CONFIRM_PROFORMA_INVOICE]: "CONFIRM PRO FORMA INVOICE",
  [ORDER_PROCESS.BUYER_CONFIRM_PROFORMA_INVOICE]: "BUYER CONFIRM PRO FORMA INVOICE",
  [ORDER_PROCESS.UPLOAD_SHIPPING_DOCUMENTS]: "UPLOAD SHIPPING DOCUMENTS",
  [ORDER_PROCESS.INSPECTION]: "INSPECTION",
  [ORDER_PROCESS.DONE]: "DONE",
  [ORDER_PROCESS.CANCEL]: "CANCEL",
  [ORDER_PROCESS.REVIEW]: "REVIEW",
  [ORDER_PROCESS.GENERATE_TAX_INVOICE]: "GENERATE TAX INVOICE",
  [ORDER_PROCESS.CHOOSE_THIRD_PARTY_PROVIDER]: "CHOOSE THIRD PARTY PROVIDER"
  // [ORDER_PROCESS.FIRST_BID_AS_BUYER]: "First bid as buyer",
  // [ORDER_PROCESS.FINAL_BID_AS_BUYER]: "Final bid as buyer",
  // [ORDER_PROCESS.COUNTER_BID_AS_SELLER]: "Counter bid as seller",
  // [ORDER_PROCESS.REJECTED_BID_AS_SELLER]: "Rejected bid as seller",
  // [ORDER_PROCESS.PRODUCT_DELETED_AS_SELLER]: "product deleted as seller"
});

export const SELLER_ORDER_PROCESS_LABEL = Object.freeze({
  [ORDER_PROCESS.PCC_CREATE_TAX]: "Marketplace to Generate PI",
  [ORDER_PROCESS.OFFER_APPROVED]: "Offer Approved",
  [ORDER_PROCESS.UPLOAD_SHIPPING_DOCUMENTS]: "Upload Documents",
  [ORDER_PROCESS.INSPECTION]: "Buyer Inspection Report",
  [ORDER_PROCESS.DONE]: "Done",
  [ORDER_PROCESS.CANCEL]: "Cancel",
  [ORDER_PROCESS.REVIEW]: "Review",
  [ORDER_PROCESS.GENERATE_TAX_INVOICE]: "Generate Tax Invoice",
  [ORDER_PROCESS.CHOOSE_THIRD_PARTY_PROVIDER]: "Choose Third Party Provider",
  [ORDER_PROCESS.WAIT_FOR_BUYER_UPLOAD_DOCUMENTS]: "Buyer to Upload Documents",
  [ORDER_PROCESS.PROVIDE_SHIPPING_DETAILS]: "Provide Shipping Details",
  [ORDER_PROCESS.CHOOSE_LOGISTICS_PROVIDER]: "Buyer to choose logistics provider",
  [ORDER_PROCESS.CONFIRM_PICKUP_DATE]: "Pickup time and date confirmed",
  [ORDER_PROCESS.TRACK_SHIPMENT]: "In Transit",
  [ORDER_PROCESS.SHIPMENT_PICKUP]: "Shipment Pickup",
  [ORDER_PROCESS.SHIPMENT_REACH_BUYER_WAREHOUSE]: "Shipment reached Buyer Warehouse",
  [ORDER_PROCESS.LOAD_FUND]: "Buyer to load Funds in wallet",
  [ORDER_PROCESS.PROVIDE_PICKUP_DATE]: "Provide pickup date",
  [ORDER_PROCESS.PAYMENT]: "Payment",
  [ORDER_PROCESS.PAYMENT_OVERDUE]: "Payment overdue",
  [ORDER_PROCESS.CONFIRM_ADDITIONAL_DOCUMENT]: "Confirm additional document",
  [ORDER_PROCESS.UPLOAD_ADDITIONAL_DOCUMENT]: "Upload additional document",
  [ORDER_PROCESS.CONFIRM_PROFORMA_INVOICE]: "Confirm Pro Forma Invoice",
  [ORDER_PROCESS.BUYER_CONFIRM_PROFORMA_INVOICE]: "Buyer to confirm Pro Forma Invoice"
});

export const BUYER_ORDER_PROCESS_LABEL = Object.freeze({
  [ORDER_PROCESS.PCC_CREATE_TAX]: "Marketplace to Generate PI",
  [ORDER_PROCESS.OFFER_APPROVED]: "Offer Approved",
  [ORDER_PROCESS.UPLOAD_SHIPPING_DOCUMENTS]: "Upload Documents",
  [ORDER_PROCESS.INSPECTION]: "Buyer Inspection Report",
  [ORDER_PROCESS.DONE]: "Done",
  [ORDER_PROCESS.CANCEL]: "Cancel",
  [ORDER_PROCESS.REVIEW]: "Review",
  [ORDER_PROCESS.GENERATE_TAX_INVOICE]: "Generate Tax Invoice",
  [ORDER_PROCESS.CHOOSE_THIRD_PARTY_PROVIDER]: "Choose Third Party Provider",
  [ORDER_PROCESS.WAIT_FOR_SELLER_UPLOAD_DOCUMENTS]: "Seller to Upload Documents",
  // [ORDER_PROCESS.FIRST_BID_AS_BUYER]: "First bid as buyer",
  // [ORDER_PROCESS.FINAL_BID_AS_BUYER]: "Final bid as buyer",
  // [ORDER_PROCESS.COUNTER_BID_AS_SELLER]: "Counter bid as seller",
  // [ORDER_PROCESS.REJECTED_BID_AS_SELLER]: "Rejected bid as seller",
  // [ORDER_PROCESS.PRODUCT_DELETED_AS_SELLER]: "product deleted as seller",
  [ORDER_PROCESS.CHOOSE_LOGISTICS_PROVIDER]: "Choose Logistics Provider",
  [ORDER_PROCESS.PROVIDE_SHIPPING_DETAILS]: "Seller to Provide Shipping Details",
  [ORDER_PROCESS.CONFIRM_PICKUP_DATE]: "Seller to Confirm Pickup time and date",
  [ORDER_PROCESS.TRACK_SHIPMENT]: "Track shipment",
  [ORDER_PROCESS.SHIPMENT_PICKUP]: "Shipment Pickup",
  [ORDER_PROCESS.LOAD_FUND]: "Load Funds in wallet",
  [ORDER_PROCESS.PAYMENT]: "Payment",
  [ORDER_PROCESS.PAYMENT_OVERDUE]: "Payment overdue",
  [ORDER_PROCESS.UPLOAD_ADDITIONAL_DOCUMENT]: "Upload additional document",
  [ORDER_PROCESS.CONFIRM_PROFORMA_INVOICE]: "Confirm Pro Forma Invoice",
  [ORDER_PROCESS.UPLOAD_ADDITIONAL_DOCUMENT]: "Upload additional document",
  [ORDER_PROCESS.CONFIRM_PROFORMA_INVOICE]: "Confirm Pro Forma Invoice",
  [ORDER_PROCESS.BUYER_CONFIRM_PROFORMA_INVOICE]: "Buyer to confirm Pro Forma Invoice"
});

export const INSPECTION_IMAGE_TYPE = Object.freeze({
  INSPECTION_REJECT: "INSPECTION_REJECT",
  INSPECTION_ACCEPT: "INSPECTION_ACCEPT"
});

export const INSPECTION_REASON = Object.freeze({
  PRODUCT_TAMPERED: "The box has been Tampered",
  PRODUCT_DAMAGED_IN_TRANSIT: "The Goods were Damaged in transit",
  PI_REJECTED_BY_SELLER: "Seller cancelled the Order",
  PI_REJECTED_BY_BUYER: "Buyer cancelled the Order",
  CANCELLED_BY_MARKETPLACE_DUE_TO_BUYER:
    "The order was cancelled as the buyer did not take action on time.",
  CANCELLED_BY_MARKETPLACE_DUE_TO_SELLER:
    "The order was cancelled as the seller did not take action on time."
});

export const DAYS_IN_MINUTES = range(0, 1440, 30);

export const TEXT_HOVER_PENDING = Object.freeze({
  SELLER: "The response from your buyer will be delayed due to the time zone different",
  BUYER: "The response from your seller will be delayed due to the time zone different"
});

export const ACTION_LIST_TYPE = Object.freeze({
  // Subscribed product available
  PRODUCT_SUBSCRIPTION_AS_BUYER: "PRODUCT_SUBSCRIPTION_AS_BUYER",
  // Order
  UPLOAD_SERIAL_NUMBER_AS_SELLER: "UPLOAD_SERIAL_NUMBER_AS_SELLER",
  CONFIRM_UPLOAD_ADDITIONAL_DOCUMENT_AS_SELLER: "CONFIRM_UPLOAD_ADDITIONAL_DOCUMENT_AS_SELLER",
  REVIEW_AS_SELLER: "REVIEW_AS_SELLER",
  REVIEW_AS_BUYER: "REVIEW_AS_BUYER",
  SHIPPING_DETAILS_AS_SELLER: "SHIPPING_DETAILS_AS_SELLER",
  SELECT_LOGISTIC_PROVIDER_AS_BUYER: "SELECT_LOGISTIC_PROVIDER_AS_BUYER",
  INSPECTION_REPORT_AS_BUYER: "INSPECTION_REPORT_AS_BUYER",
  INSPECTION_REPORT_FAIL_AS_SELLER: "INSPECTION_REPORT_FAIL_AS_SELLER",
  UPLOAD_DOCUMENT_AS_SELLER: "UPLOAD_DOCUMENT_AS_SELLER",
  UPLOAD_DOCUMENT_AS_BUYER: "UPLOAD_DOCUMENT_AS_BUYER",
  // Bid
  FIRST_BID_AS_BUYER: "FIRST_BID_AS_BUYER",
  FINAL_BID_AS_BUYER: "FINAL_BID_AS_BUYER",
  COUNTER_BID_AS_SELLER: "COUNTER_BID_AS_SELLER",
  REJECTED_BID_AS_SELLER: "REJECTED_BID_AS_SELLER",
  PRODUCT_DELETED_AS_SELLER: "PRODUCT_DELETED_AS_SELLER",
  // Approval
  REVIEW_SALE_AS_SELLER: "REVIEW_SALE_AS_SELLER",

  // Blocked Fund
  BUYER_FIRST_LOAD_FUND: "BUYER_FIRST_LOAD_FUND",
  BUYER_SECOND_LOAD_FUND: "BUYER_SECOND_LOAD_FUND",

  PROVIDE_PICKUP_DATE: "PROVIDE_PICKUP_DATE",

  // Coop
  COOP_PROVIDE_SHIPPING_DETAILS: "COOP_PROVIDE_SHIPPING_DETAILS",
  COOP_UPLOAD_DOCUMENT: "COOP_UPLOAD_DOCUMENT",
  COOP_LOAD_FUND: "COOP_LOAD_FUND",

  // Proforma Invoice
  VIEW_PROFORMA_INVOICE_AS_SELLER: "VIEW_PROFORMA_INVOICE_AS_SELLER",
  VIEW_PROFORMA_INVOICE_AS_BUYER: "VIEW_PROFORMA_INVOICE_AS_BUYER",
  CONFIRM_PROFORMA_INVOICE_AS_BUYER: "CONFIRM_PROFORMA_INVOICE_AS_BUYER",
  VIEW_PROFORMA_INVOICE_REJECTED_AS_BUYER: "VIEW_PROFORMA_INVOICE_REJECTED_AS_BUYER",
  CONFIRM_PROFORMA_INVOICE_AS_SELLER: "CONFIRM_PROFORMA_INVOICE_AS_SELLER",
  VIEW_PROFORMA_INVOICE_ACCEPTED_AS_SELLER: "VIEW_PROFORMA_INVOICE_ACCEPTED_AS_SELLER",

  // KYC Process
  KYC_REMINDER: "KYC_REMINDER"
});

export const ACTION_LIST_TYPE_LABEL = Object.freeze({
  [ACTION_LIST_TYPE.UPLOAD_SERIAL_NUMBER_AS_SELLER]: "Upload Shipping Document",
  [ACTION_LIST_TYPE.CONFIRM_UPLOAD_ADDITIONAL_DOCUMENT_AS_SELLER]: "Confirm Document Upload",
  [ACTION_LIST_TYPE.REVIEW_AS_SELLER]: "Rate user",
  [ACTION_LIST_TYPE.REVIEW_AS_BUYER]: "Rate user",
  [ACTION_LIST_TYPE.SHIPPING_DETAILS_AS_SELLER]: "Provide Shipping Information",
  [ACTION_LIST_TYPE.SELECT_LOGISTIC_PROVIDER_AS_BUYER]: "Select Logistics Provider",
  [ACTION_LIST_TYPE.INSPECTION_REPORT_AS_BUYER]: "Shipment Arrived",
  [ACTION_LIST_TYPE.INSPECTION_REPORT_FAIL_AS_SELLER]: "Shipment challenged",

  [ACTION_LIST_TYPE.FIRST_BID_AS_BUYER]: "Respond to Bid Offer",
  [ACTION_LIST_TYPE.FINAL_BID_AS_BUYER]: "Respond to Final Bid Offer",
  [ACTION_LIST_TYPE.COUNTER_BID_AS_SELLER]: "Respond to Bid Offer",
  [ACTION_LIST_TYPE.REJECTED_BID_AS_SELLER]: "Bid rejected",
  [ACTION_LIST_TYPE.PRODUCT_DELETED_AS_SELLER]: "Sale Offer removed",
  [ACTION_LIST_TYPE.PRODUCT_SUBSCRIPTION_AS_BUYER]: "Subscribe Product Available",

  [ACTION_LIST_TYPE.REVIEW_SALE_AS_SELLER]: "Sale Offer Review",
  [ACTION_LIST_TYPE.UPLOAD_DOCUMENT_AS_SELLER]: "Upload Documents",
  [ACTION_LIST_TYPE.UPLOAD_DOCUMENT_AS_BUYER]: "Upload Documents",

  //Blocked Fund
  [ACTION_LIST_TYPE.BUYER_FIRST_LOAD_FUND]: "Add Funds",
  [ACTION_LIST_TYPE.BUYER_SECOND_LOAD_FUND]: "Add Funds",

  //Proforma Invoice
  [ACTION_LIST_TYPE.VIEW_PROFORMA_INVOICE_AS_SELLER]: "Pro Forma Invoice",
  [ACTION_LIST_TYPE.VIEW_PROFORMA_INVOICE_ACCEPTED_AS_SELLER]: "Pro Forma Invoice",
  [ACTION_LIST_TYPE.CONFIRM_PROFORMA_INVOICE_AS_SELLER]: "Pro Forma Invoice",

  [ACTION_LIST_TYPE.VIEW_PROFORMA_INVOICE_AS_BUYER]: "Pro Forma Invoice",
  [ACTION_LIST_TYPE.CONFIRM_PROFORMA_INVOICE_AS_BUYER]: "Pro Forma Invoice",
  [ACTION_LIST_TYPE.VIEW_PROFORMA_INVOICE_REJECTED_AS_BUYER]: "Unconfirmed pro forma invoice",

  [ACTION_LIST_TYPE.KYC_REMINDER]: "KYC Verification"
});

export const MESUREMENT_UNITS = {
  SI: "SI",
  SU: "SU"
};

export const MESUREMENT_UNIT_LABELS = {
  [MESUREMENT_UNITS.SI]: "Imperial – Lb/inch",
  [MESUREMENT_UNITS.SU]: "Metric – Kg/cm"
};

export const WEIGHT_UNITS = {
  KG: "kg",
  LB: "lb"
};

export const DIMENSION_UNITS = {
  CM: "cm",
  IN: "in"
};

export const FILE_TYPES = {
  EXCEL: "Excel",
  PDF: "PDF"
};

export const FILE_EXTENSIONS = Object.freeze({
  [FILE_TYPES.EXCEL]: ".xlsx,.xls",
  [FILE_TYPES.PDF]: ".pdf"
});

export const DOC_NAME_TYPES = Object.freeze({
  SERIAL_NUMBER: "SERIAL_NUMBER",
  COMMERCIAL_INVOICE: "COMMERCIAL_INVOICE",
  PURCHASE_ORDER: "PURCHASE_ORDER",
  LABEL_IMAGE: "LABEL_IMAGE",
  SHIPMENT_RECEIPT: "SHIPMENT_RECEIPT",
  PROFORMA_INVOICE: "PROFORMA_INVOICE"
});

export const DOC_CODE_LABELS = Object.freeze({
  [DOC_NAME_TYPES.PURCHASE_ORDER]: "purchase-order",
  [DOC_NAME_TYPES.LABEL_IMAGE]: "label-image",
  [DOC_NAME_TYPES.COMMERCIAL_INVOICE]: "commercial-invoice",
  [DOC_NAME_TYPES.SHIPMENT_RECEIPT]: "shipment-receipt",
  [DOC_NAME_TYPES.SERIAL_NUMBER]: "serial-number",
  [DOC_NAME_TYPES.PROFORMA_INVOICE]: "pro-forma-invoice"
});

export const DOC_NAME_LABELS = Object.freeze({
  [DOC_NAME_TYPES.SERIAL_NUMBER]: "Serial Number",
  [DOC_NAME_TYPES.PURCHASE_ORDER]: "Purchase Order",
  [DOC_NAME_TYPES.LABEL_IMAGE]: "Label Image",
  [DOC_NAME_TYPES.COMMERCIAL_INVOICE]: "Commercial Invoice",
  [DOC_NAME_TYPES.SHIPMENT_RECEIPT]: "Shipment Receipt",
  [DOC_NAME_TYPES.PROFORMA_INVOICE]: "pro forma invoice"
});

export const COMMON_STATUSES = Object.freeze({
  ACTIVE: "ACTIVE",
  INACTIVE: "INACTIVE"
});

export const COMMON_STATUS_LABEL = Object.freeze({
  [COMMON_STATUSES.ACTIVE]: "Active",
  [COMMON_STATUSES.INACTIVE]: "Inactive"
});

export const WALLET_STATUS = Object.freeze({
  ACTIVE: "ACTIVE",
  INACTIVE: "INACTIVE",
  PENDING: "IN_PROGRESS",
  ERROR: "CANCELLED"
});

export const DOCUMENT_SOURCE = Object.freeze({
  SELLER: "Seller",
  BUYER: "Buyer",
  LOGISTIC_SERVICE_PROVIDER: "Logistics Service Provider",
  INSPECTION_SERVICE_PROVIDER: "Inspection Service Provider"
});

export const LOGISTIC_TYPE = Object.freeze({
  FEDEX: "FEDEX",
  DHL: "DHL",
  SHIPCHAIN: "SHIPCHAIN"
});

export const TRADER_TYPE = [
  { id: "BUYER", name: "Buyer" },
  { id: "SELLER", name: "Seller" },
  { id: "ALL", name: "All" }
];

export const PROFORMA_INVOICE_TABLE_LABELS = Object.freeze({
  slno: "SL NO",
  uom: "UOM",
  hscode: "HSCODE",
  productDescription: "Description",
  quantity: "Qty",
  unitPrice: "Unit Price",
  taxPercentage: "(%)",
  taxValue: "(value)",
  lineTotalExcTax: "Line Total (Excl. Tax)",
  lineTotalIncTax: "Line Total (Incl. Tax)"
});
