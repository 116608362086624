import React from "react";
import { Input, Select } from "antd";
import countryList from "assets/country.json";
const DEFAULT_PLACEHOLDER = "+1 23456789";
const NO_PREFIX_PLACEHOLDER = "23456789";

const allPhonePrefixes = [
  ...new Set(
    countryList.reduce((codes, country) => {
      return [...codes, ...country.callingCodes.map((code) => `+${code}`)];
    }, [])
  )
];

const getPrefix = (value) => {
  if (value == null) return { prefix: undefined, phone: undefined };
  const parts = value.split(" ", 2);
  if (parts.length === 1) return { prefix: undefined, phone: parts[0] };
  return { prefix: parts[0], phone: parts[1] };
};

const parsePhone = ({ prefix, phone }) => {
  if (prefix == null) return phone;
  return `${prefix} ${phone ?? ""}`;
};

const findPhonePrefixes = (country) => {
  const foundCountry = countryList.find((current) => current.alpha2Code === country);
  if (foundCountry == null) return [];
  return foundCountry.callingCodes.map((code) => `+${code}`);
};

export const PhoneInput = React.forwardRef(
  (
    { value, onChange, onInputChange, dataSource, country, disabled, placeholder, ...props },
    ref
  ) => {
    const [fullPhoneNumber, setFullPhoneNumber] = React.useState(getPrefix(value));
    const [internalCountry, setInternalCountry] = React.useState(country);
    const handlePrefixChange = React.useCallback(
      (changedPrefix) => {
        const { phone } = fullPhoneNumber;
        const newPhoneNumber = { prefix: changedPrefix, phone };
        const changedValue = parsePhone(newPhoneNumber);
        onChange && onChange(changedValue);
        onInputChange && onInputChange(changedValue);
      },
      [fullPhoneNumber, onChange, onInputChange]
    );
    const handlePhoneChange = React.useCallback(
      (e) => {
        const { prefix } = fullPhoneNumber;
        const newPhoneNumber = { prefix, phone: e.target.value };
        setFullPhoneNumber(newPhoneNumber);
        const changedValue = parsePhone(newPhoneNumber);
        onChange(changedValue);
        onInputChange && onInputChange(changedValue);
      },
      [fullPhoneNumber, onChange, onInputChange]
    );

    const phonePrefixes = React.useMemo(() => {
      if (dataSource != null) return dataSource;
      if (country) {
        return findPhonePrefixes(country);
      }
      return allPhonePrefixes;
    }, [dataSource, country]);

    const actualPlaceholder = React.useMemo(() => {
      if (placeholder) return placeholder;
      if (fullPhoneNumber.prefix == null || fullPhoneNumber.prefix === "") {
        return DEFAULT_PLACEHOLDER;
      }
      return NO_PREFIX_PLACEHOLDER;
    }, [placeholder, fullPhoneNumber]);

    React.useEffect(() => {
      if (disabled) return;
      if (country === internalCountry) return;
      setInternalCountry(country);
      const nextPrefixes = findPhonePrefixes(country);
      if (
        phonePrefixes.includes(fullPhoneNumber.prefix) &&
        nextPrefixes.includes(fullPhoneNumber.prefix)
      ) {
        return;
      }
      if (nextPrefixes.length >= 1 && country != null) {
        onChange(
          parsePhone({
            phone: fullPhoneNumber.phone,
            prefix: nextPrefixes[0]
          })
        );
      }
    }, [phonePrefixes, disabled, country, onChange, internalCountry, fullPhoneNumber]);

    React.useEffect(() => {
      setFullPhoneNumber(getPrefix(value));
    }, [value]);

    return (
      <Input
        ref={ref}
        value={fullPhoneNumber.phone}
        onChange={handlePhoneChange}
        disabled={disabled}
        placeholder={actualPlaceholder}
        addonBefore={
          <Select
            showSearch
            allowClear
            filterOption={(searchValue, option) => {
              return option.label.includes(searchValue);
            }}
            value={fullPhoneNumber.prefix}
            onChange={handlePrefixChange}
            disabled={phonePrefixes.length === 0 || disabled}
            style={{ width: "96px" }}
          >
            {phonePrefixes.map((option) => (
              <Select.Option key={option} value={option} label={option}>
                {option}
              </Select.Option>
            ))}
          </Select>
        }
        {...props}
      />
    );
  }
);
