import React from "react";
import styles from "./styles.module.scss";
import classnames from "classnames";
import PropTypes from "prop-types";

export const SmallAmountCard = ({ title, amount, currency = "", icon, className }) => {
  return (
    <div
      className={classnames(
        "d-flex align-items-center p-3 dtc-br-10 air__utils__shadow",
        styles["small-balance-card-gradient"],
        className
      )}
    >
      <div className="my-1 mr-4 font-size-36 flex-shrink-0 ml-2 pr-3 border-right">{icon}</div>
      <div className="text-center text-xl-left text-uppercase flex-grow-1 text-truncate">
        <div className="font-size-14 text-truncate">{title}</div>
        <div className="font-size-24 text-truncate">
          {`${currency} `} {amount}
        </div>
      </div>
    </div>
  );
};

SmallAmountCard.propTypes = {
  title: PropTypes.string.isRequired,
  amount: PropTypes.oneOfType([PropTypes.number, PropTypes.object]).isRequired,
  currency: PropTypes.string,
  icon: PropTypes.element.isRequired
};
