import { datetimeUtil } from "@/utils";
import { COMMON_FIELDS } from "@/commons/schemas";

const { sortByTime } = datetimeUtil;

export const mapProcessStatusData = (negotiations) => {
  if (Array.isArray(negotiations) && negotiations.length) {
    return negotiations.map((item) => {
      if (item.bidResponseList && Array(item.bidResponseList) && item.bidResponseList.length) {
        let mappedBidResponseList = [...item.bidResponseList];
        mappedBidResponseList = mappedBidResponseList.sort(sortByTime(COMMON_FIELDS.createdDate));
        return {
          ...item,
          bidResponseList: mappedBidResponseList,
          createdDate: mappedBidResponseList[0].createdDate
        };
      }
      return item;
    });
  }
  return negotiations;
};
