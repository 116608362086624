import React from "react";
import { usePrefixPath } from "@/utils/location.util";
import { useHistory as useRHistory } from "react-router-dom";

export const useHistory = () => {
  const { generatePath } = usePrefixPath();
  const { push: _push, ...other } = useRHistory();
  const push: typeof _push = React.useCallback(
    (params) => {
      if (typeof params === "string") {
        _push(generatePath(params));
        return;
      }

      if ("pathname" in params) {
        const pathname = params.pathname;
        _push({
          ...params,
          pathname: generatePath(pathname)
        });
        return;
      }

      _push(params);
    },
    [_push, generatePath]
  );

  return { push, ...other };
};
