import React from "react";
import { useLocation } from "@/components/commons/navigator";
import { generatePath, matchPath } from "@/components/commons/navigator";

export const useGetSearchParams = (keys) => {
  const location = useLocation();
  return React.useMemo(() => {
    const params = new URLSearchParams(location.search);
    if (Array.isArray(keys)) {
      const values = [];
      for (const key of keys) {
        values.push(params.get(key));
      }
      return values;
    }
    return params.get(keys);
  }, [location, keys]);
};

const rolePath = "/:prefix";
export const usePrefixPath = () => {
  const location = useLocation();
  const match = matchPath<{ prefix: string }>(location.pathname, rolePath);

  const _generatePath = React.useCallback(
    (path: string) => {
      const match = matchPath<{ prefix: string }>(location.pathname, rolePath);
      if (!match?.params?.prefix) return path;
      return generatePath(path, { prefix: match?.params?.prefix });
    },
    [location.pathname]
  );

  return { prefix: match?.params?.prefix, generatePath: _generatePath };
};
