import React from "react";
import { datetimeUtil } from "@/utils";
import { WEEK_DAYS } from "@/commons/consts";
import { Button } from "antd";
import { FIELDS, LABELS } from "./company-info.schema";
import { KVList } from "components/utils";
import { Country } from "components/utils/country/country.comp";

const { minutesToTime } = datetimeUtil;
const formatWorkingDay = (workingDays) =>
  workingDays
    ?.sort()
    .map((workDay) => WEEK_DAYS[workDay])
    ?.join(", ");
const formatWorkingTime = (value) => minutesToTime(value, true);
const pushField = (values, companyInfo, field, formatFn) => {
  const key = LABELS[field];
  let value = companyInfo[field];
  if (value == null || key == null) {
    return;
  }
  value = formatFn ? formatFn(companyInfo[field]) : companyInfo[field];
  values.push({ value, key });
};
const parseGeneralData = (companyInfo) => {
  const values = [];
  pushField(values, companyInfo, FIELDS.name);
  pushField(values, companyInfo, FIELDS.registrationNumber);
  pushField(values, companyInfo, FIELDS.country, (code) => <Country code={code} />);
  pushField(values, companyInfo, FIELDS.officeNumber);
  pushField(values, companyInfo, FIELDS.incorporationDate, datetimeUtil.formatDate);
  pushField(values, companyInfo, FIELDS.registrationExpiryDate, datetimeUtil.formatDate);
  pushField(values, companyInfo, FIELDS.jurisdictionOfIncorporation);
  return values;
};
const parseWorkingData = (companyInfo) => {
  const values = [];
  pushField(values, companyInfo, FIELDS.workingStartTime, formatWorkingTime);
  pushField(values, companyInfo, FIELDS.workingEndTime, formatWorkingTime);
  pushField(values, companyInfo, FIELDS.workingDays, formatWorkingDay);
  return values;
};
const DocumentInfo = ({ document }) => {
  if (document == null) return <></>;
  return (
    <Button href={document?.url} target="_blank" size="small" type="primary" className="ml-2">
      Registration of the Company
    </Button>
  );
};
export const CompanyInfoReadonly = ({ companyInfo, showHeader = true, isProfileTab = false }) => {
  const arrayData = React.useMemo(() => {
    const data = parseGeneralData(companyInfo);
    if (isProfileTab) {
      const workingData = parseWorkingData(companyInfo);
      data.push(...workingData);
    }
    const documentItem = {
      key: "Certification of Registration of the Company",
      value: <DocumentInfo document={companyInfo?.certificationDocument} />,
      props: {
        span: 24,
        lg: 24
      }
    };
    data.push(documentItem);
    return data;
  }, [companyInfo, isProfileTab]);

  return (
    <>
      {showHeader && (
        <>
          <h5 className="font-weight-bold">COMPANY INFORMATION</h5>
          <hr />
        </>
      )}
      <KVList items={arrayData} colProps={{ span: 24, lg: 12 }} />
    </>
  );
};
