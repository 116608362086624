import "c3/c3.min.css";
import "lightgallery.js/dist/css/lightgallery.min.css";
// app styles

import React from "react";
import ReactDOM from "react-dom";
import { LightgalleryProvider } from "react-lightgallery";

import "@/utils/monkey-patch.util";
import * as serviceWorker from "./serviceWorker";
import { history } from "./_history";
import "./global.scss";
import { AppProviders } from "@/providers/app-providers";
import { keycloakProviderInitConfig } from "@/services/keycloak.service";
import { Router } from "react-router";
import { MarketApp } from "@/apps/market";

ReactDOM.render(
  <AppProviders initOptions={keycloakProviderInitConfig}>
    <LightgalleryProvider>
      <Router history={history}>
        <MarketApp />
      </Router>
    </LightgalleryProvider>
  </AppProviders>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
