import { backendAPI } from "@/utils/httpAPI.util";
import { API_PATHS } from "@/commons/consts/system/api-paths";

/*
 * OWNER INFO
 */

export const getOwnerInfo = async () => {
  const addresses = await backendAPI.get(API_PATHS.OWNER_INFO);
  return addresses;
};

export const createOwnerInfo = async (values) => {
  const result = await backendAPI.post(API_PATHS.OWNER_INFO, values);
  return result;
};

export const updateOwnerInfo = async (id, values) => {
  await backendAPI.put(`${API_PATHS.OWNER_INFO}/${id}`, values);
};

export const deleteOwnerInfo = async (id) => {
  await backendAPI.delete(`${API_PATHS.OWNER_INFO}/${id}`);
};

export const uploadOwnerDocuments = async (ownerId, fileList) => {
  const uploadPromises = fileList.map(({ file, type }) => {
    if (file == null) {
      return null;
    }
    const promise = backendAPI.postFile(`${API_PATHS.OWNER_INFO}/${ownerId}/documents`, file, {
      type
    });
    return promise;
  });
  const result = await Promise.all(uploadPromises);
  return result;
};

export const deleteOwnerDocuments = async (id, ownerId) => {
  await backendAPI.delete(`${API_PATHS.OWNER_INFO}/${ownerId}/documents/${id}`);
};
