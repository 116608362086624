export class ApiPathConsts {
  static #PUBLIC_PREFIX = "/public";
  static #ADMIN_PREFIX = "/admin";
  static #USER_PREFIX = "/me";
  static #USER_MANAGEMENT_PREFIX = "/admin/users";
  static #ORDER_PREFIX = "/orders";
  static #WALLET_PREFIX = "/wallet";
  static #COMPANY_PREFIX = "/companies";
  static #ADMIN_PRODUCT_PREFIX = "/admin/products";
  static #PRODUCT_PREFIX = "/products";
  static #BRANDS_PREFIX = "/brands";
  static #REBATES_PREFIX = "/rebates";
  static #PRODUCT_REQUEST_PREFIX = "/product-requests";
  static #TRADE_RULE_PREFIX = "/product-rules";
  static #BADGE = "/badges";
  static #SETTING_PREFIX = "/me/notifications/settings";
  static #TWO_FACTOR_AUTH_PREFIX = "/tfa";
  static #BUSSINESS_DETAIL = "/company-product-rules";
  static MARKETPLACE = "/admin/marketplace";

  static get GET_ADMIN_USER() {
    return `${this.#USER_MANAGEMENT_PREFIX}`;
  }

  static get GET_CURRENT_USER() {
    return `${this.USER_PREFIX}`;
  }
  static get POST_MARKETPLACE_BRANDING_ASSET() {
    return `${this.MARKETPLACE}/branding/assets`;
  }
  static get GET_MARKETPLACE_BRANDING_ASSET() {
    return `/public/marketplace/branding/assets`;
  }
  static get GET_ORGANIZATION() {
    return `${this.#PUBLIC_PREFIX}/organizations`;
  }
  static get UPDATE_ORGANIZATION() {
    return `${this.#ADMIN_PREFIX}/organizations`;
  }
  static get UPDATE_USER_INFO() {
    return `${this.#USER_PREFIX}`;
  }
  static get GET_ALL_USERS() {
    return `${this.#USER_MANAGEMENT_PREFIX}/all`;
  }
  static get GET_ALL_BUYERS() {
    return `${this.#USER_MANAGEMENT_PREFIX}/buyer`;
  }
  static get GET_ALL_SELLERS() {
    return `${this.#USER_MANAGEMENT_PREFIX}/seller`;
  }
  static get SUSPEND_USER() {
    return `${this.#USER_MANAGEMENT_PREFIX}/:companyId/suspend`;
  }
  static get UNSUSPEND_USER() {
    return `${this.#USER_MANAGEMENT_PREFIX}/:companyId/unlockSuspend`;
  }
  static get ASSIGN_BADGE_TO_USER() {
    return `${this.#USER_MANAGEMENT_PREFIX}/:companyId/assign/badge/type`;
  }
  static get GET_AVAILABLE_BADGES() {
    return `${this.#USER_MANAGEMENT_PREFIX}/:companyId/badge/type`;
  }
  static get GET_ALL_BADGES() {
    return `${this.#BADGE}`;
  }
  static get LOGIN() {
    return `/admin${this.#PUBLIC_PREFIX}/login`;
  }

  static get GET_ALL_ACTIVE_ORDERS() {
    return `${this.#ORDER_PREFIX}/active`;
  }

  static get GET_ALL_FEATURE_FLAGS() {
    return `${this.#ADMIN_PREFIX}/feature-flag`;
  }

  static get UPDATE_FEATURE_FLAG() {
    return `${this.#ADMIN_PREFIX}/feature-flag/:featureId`;
  }

  static get GET_ALL_ORDERS_HISTORY() {
    return `${this.#ORDER_PREFIX}/history`;
  }

  static get GET_ORDER_PREFIX() {
    return this.#ORDER_PREFIX;
  }

  static get GET_COMPLETE_ORDERS() {
    return `${this.MARKETPLACE}${this.#ORDER_PREFIX}/completed`;
  }

  static get GET_ALL_ORDERS() {
    return `${this.MARKETPLACE}${this.#ORDER_PREFIX}`;
  }

  static get GET_WALLET_DASHBOARD() {
    return `${this.#WALLET_PREFIX}`;
  }

  static get GET_WALLET_TRANSACTION_DETAILS() {
    return `${this.#WALLET_PREFIX}/transactions`;
  }

  static get GET_ACCOUNT_SUMMARY() {
    return `account/summary`;
  }

  static get GET_WITHDRAWAL() {
    return `${this.#WALLET_PREFIX}/withdrawal`;
  }

  static get BANK_DETAILS() {
    return `${this.#COMPANY_PREFIX}/bankdetails`;
  }

  static get WALLET_WITHDRAWAL() {
    return `${this.#WALLET_PREFIX}/withdrawal`;
  }

  static get ADD_PRODUCT() {
    return `${this.#ADMIN_PRODUCT_PREFIX}`;
  }

  static get CHECK_DUPLICATE_PRODUCT() {
    return `${this.#ADMIN_PRODUCT_PREFIX}/check`;
  }

  static get EDIT_PRODUCT() {
    return `${this.#ADMIN_PRODUCT_PREFIX}/:id`;
  }

  static get GET_PRODUCTS() {
    return `${this.#PRODUCT_PREFIX}/global`;
  }

  static get GET_ALL_PRODUCTS() {
    return `${this.MARKETPLACE}${this.#PRODUCT_PREFIX}`;
  }

  static get GET_TOTAL_OFFER_PRODUCTS() {
    return `/admin${this.#PRODUCT_PREFIX}/offers/total`;
  }

  static get GET_PRODUCT_CATEGORIES() {
    return `/products/category`;
  }

  static get GET_PRODUCT_SALE_CHANNEL() {
    return `${this.#ADMIN_PRODUCT_PREFIX}/salesChannel`;
  }

  static get DELETE_PRODUCT() {
    return `${this.#ADMIN_PRODUCT_PREFIX}/:id`;
  }

  static get GET_PRODUCT_DETAILS() {
    return `${this.#ADMIN_PRODUCT_PREFIX}/:id`;
  }

  static get UPLOAD_PRODUCT_IMAGE() {
    return `${this.#PRODUCT_PREFIX}/images`;
  }

  static get DELETE_PRODUCT_IMAGE() {
    return `${this.#PRODUCT_PREFIX}/images`;
  }

  static get GET_PRODUCT_NAMES_BY_TYPE_ID() {
    return `${this.#ADMIN_PRODUCT_PREFIX}/types/:id`;
  }
  static get ADD_AVAILABLE_PRODUCT() {
    return `${this.#ADMIN_PRODUCT_PREFIX}/map/:sourceId/:targetId`;
  }
  static get GET_BUYER_COMPANY() {
    return `${this.#COMPANY_PREFIX}`;
  }

  static get GET_BRANDS() {
    return `${this.#BRANDS_PREFIX}/:id`;
  }

  static get REBATES() {
    return `${this.#REBATES_PREFIX}`;
  }

  static get GET_NEW_COMPANY_LIST() {
    return `${this.#COMPANY_PREFIX}/new`;
  }

  static get APPROVE_NEW_COMPANY() {
    return `${this.#COMPANY_PREFIX}/:companyId/approve`;
  }

  static get UPDATE_PRODUCT_CREATION_PERMISSION() {
    return `${this.#COMPANY_PREFIX}/:companyId/productCreation?isEnable=`;
  }

  static get GET_PRODUCTS_REQUESTS() {
    return this.#PRODUCT_REQUEST_PREFIX;
  }

  static get DELETE_PRODUCTS_REQUESTS() {
    return `${this.#PRODUCT_REQUEST_PREFIX}/:id`;
  }

  static get GET_PRODUCT_TRADE_RULES_STATUS() {
    return `${this.#TRADE_RULE_PREFIX}/rule-status/:id`;
  }

  static get GET_PRODUCT_TRADE_RULES() {
    return this.#TRADE_RULE_PREFIX;
  }
  static get GET_PRODUCT_TRADE_RULE_DETAIL() {
    return `${this.#TRADE_RULE_PREFIX}/:id`;
  }
  static get PUT_PRODUCT_TRADE_RULE_DETAIL() {
    return `${this.#TRADE_RULE_PREFIX}/:id`;
  }
  static get POST_PRODUCT_TRADE_RULE_DETAIL() {
    return `${this.#TRADE_RULE_PREFIX}/:id`;
  }

  static get PHONE_VERIFICATION() {
    return `${this.#SETTING_PREFIX}/phone/code`;
  }

  static get SECURITY_QUESTIONS() {
    return `${this.#USER_PREFIX}/threeSteps/questions`;
  }
  static get SECURITY_ANSWER() {
    return `${this.#USER_PREFIX}/threeSteps/answers`;
  }
  static get THREE_STEPS_PASSCODE() {
    return `${this.#USER_PREFIX}/threeSteps/passcode`;
  }
  static get THREE_STEPS_OTP() {
    return `${this.#USER_PREFIX}/threeSteps/otp`;
  }
  static get NOTIFICATION_SETTING() {
    return `${this.#SETTING_PREFIX}`;
  }
  static get CHANGE_PASSWORD() {
    return `${this.#USER_PREFIX}/password`;
  }
  static get FORGET_BROWSER() {
    return `${this.#TWO_FACTOR_AUTH_PREFIX}/browser/forgetting`;
  }
  static get BUSSINESS_DETAIL() {
    return `${this.#BUSSINESS_DETAIL}`;
  }
  static get COMPANY_SEND_VERIFICATION_CODE() {
    return `${this.#COMPANY_PREFIX}/bankdetails/code`;
  }
  static get GET_COMPANY_ME() {
    return `${this.#COMPANY_PREFIX}/me`;
  }
  static get GET_GOOGLE_AUTHENTICATOR_QRCODE() {
    return `${this.#TWO_FACTOR_AUTH_PREFIX}/ga`;
  }
  static get GET_EMAIL_VERIFICATION() {
    return `${this.#PUBLIC_PREFIX}/tokens/registration`;
  }
  static get SEND_RESET_PW_LINK() {
    return `${this.#PUBLIC_PREFIX}/sendResetPasswordLink`;
  }
  static get RESET_PW() {
    return `${this.#PUBLIC_PREFIX}/resetPassword`;
  }
  static get CHECK_RESET_PW_TOKEN() {
    return `${this.#PUBLIC_PREFIX}/tokens/password`;
  }

  static get MARKETPLACE_FEATURES() {
    return `${this.#PUBLIC_PREFIX}/marketplace/settings`;
  }

  static get ADMIN_MARKETPLACE_FEATURES() {
    return `${this.#ADMIN_PREFIX}/marketplace/settings`;
  }

  static get CREATE_WITHDRAW_FUND() {
    return `${this.#WALLET_PREFIX}/withdrawal`;
  }
  static get CHECK_VALIDATION_CODE() {
    return `/tfa/code/validation`;
  }
  static get COMPANY_ADDRESSES() {
    return `${COMPANY_PREFIX}/addresses`;
  }
}

const PUBLIC_PREFIX = "/public";
const USER_PREFIX = "/me";
const USER_MANAGEMENT_PREFIX = "/users";
const SETTING_PREFIX = "/me/notifications/settings";
const KYC_PREFIX = "/kyc";
const COMPANY_PREFIX = "/companies";
const OWNER_PREFIX = "/owners";
const PRODUCT_PREFIX = "/products";
const SALES_PREFIX = "/sales";
const SELLER_PREFIX = "/sellers";
const BUYER_PREFIX = "/buyers";
const ORDER_PREFIX = "/orders";
const AUDIT_LOGS_PREFIX = "/audit-logs";
const WALLET_PREFIX = "/wallet";
const TWO_FACTOR_AUTH_PREFIX = "/tfa";
const REBATES_PREFIX = "/rebates";
const INTRODUCER_PREFIX = "/introducers";
const COOP_PREFIX = "coops";
const COMMENT_PREFIX = "/comments";

export const GET_CURRENT_USER = `${USER_PREFIX}`;
export const UPDATE_USER_INFO = `${USER_PREFIX}`;
export const CHANGE_PASSWORD = `${USER_PREFIX}/password`;
export const USER_REBATES = `${REBATES_PREFIX}`;

export const GET_AUDIT_LOGS = `${AUDIT_LOGS_PREFIX}`;

export const GET_ALL_USERS = `${USER_MANAGEMENT_PREFIX}`;
export const CREATE_USER = `${USER_MANAGEMENT_PREFIX}`;
export const DELETE_USER = `${USER_MANAGEMENT_PREFIX}`;
export const UPDATE_USER = `${USER_MANAGEMENT_PREFIX}`;

export const LOGIN = `${PUBLIC_PREFIX}/login`;
export const REGISTER = `${PUBLIC_PREFIX}/register`;
export const RESET_PW = `${PUBLIC_PREFIX}/resetPassword`;
export const UNBLOCK_USER = `${PUBLIC_PREFIX}/tokens/unlock`;
export const SUGGEST_USERNAME = `${PUBLIC_PREFIX}/username`;
export const CHECK_RESET_PW_TOKEN = `${PUBLIC_PREFIX}/tokens/password`;
export const CHECK_EMAIL_CONFIRM_TOKEN = `${PUBLIC_PREFIX}/tokens/registration`;
export const SEND_RESET_PW_LINK = `${PUBLIC_PREFIX}/sendResetPasswordLink`;
export const PHONE_VERIFICATION = `${SETTING_PREFIX}/phone/code`;
export const NOTIFICATION_SETTING = SETTING_PREFIX;
export const NOTIFICATIONS = `${USER_PREFIX}/notifications`;
export const NOTIFICATIONS_READ = `${USER_PREFIX}/notifications/read`;
export const SUBSCRIPTION = `${USER_PREFIX}/notifications/subscriptions`;
export const SECURITY_QUESTIONS = `${USER_PREFIX}/threeSteps/questions`;
export const SECURITY_ANSWER = `${USER_PREFIX}/threeSteps/answers`;
export const DATA_3_STEPS = `${USER_PREFIX}/threeSteps`;
export const THREE_STEPS_PASSCODE = `${USER_PREFIX}/threeSteps/passcode`;
export const THREE_STEPS_OTP = `${USER_PREFIX}/threeSteps/otp`;
export const SELLER_DASHBOARD = `${SELLER_PREFIX}/dashboard`;
export const SELLER_ACTIONLIST = `${SELLER_PREFIX}/actionList`;
export const BUYER_DASHBOARD = `${BUYER_PREFIX}/dashboard`;
export const BUYER_ACTIONLIST = `${BUYER_PREFIX}/actionList`;

export const KYC_STEP1 = `${KYC_PREFIX}/step-1`;
export const KYC_STEP2 = `${KYC_PREFIX}/step-2`;
export const KYC_STEP3 = `${KYC_PREFIX}/step-3`;
export const KYC_STEP4 = `${KYC_PREFIX}/step-4`;
export const KYC_SUBMIT = `${KYC_PREFIX}/submit`;

export const COMPANY_INFO = `${COMPANY_PREFIX}`;
export const COMPANY_SEND_VERIFICATION_CODE = `${COMPANY_PREFIX}/bankdetails/code`;
export const KYC_SUBMISSION = `${COMPANY_PREFIX}/kyc`;
export const COMPANY_ADDRESSES = `${COMPANY_PREFIX}/addresses`;
export const BANK_DETAILS = `${COMPANY_PREFIX}/bankdetails`;
export const DOCUMENTS = `${COMPANY_PREFIX}/documents`;
export const FINANCIAL_DETAILS = `${COMPANY_PREFIX}/financialdetails`;
export const COMPANY_LOGO = `${COMPANY_PREFIX}/logos`;
export const OWNER_INFO = `${OWNER_PREFIX}`;

export const PRODUCTS = `${PRODUCT_PREFIX}`;
export const PRODUCTS_INVENTORY = `/inventories`;
export const PRODUCTS_CATEGORIES = `${PRODUCT_PREFIX}/category`;
export const PRODUCTS_IMAGE = `${PRODUCT_PREFIX}/images`;
export const PRODUCTS_ITEM_LISTING = `${PRODUCT_PREFIX}/global`;
export const PRODUCTS_MARKETPLACE = `${PRODUCT_PREFIX}/sales`;
export const PRODUCTS_BID_MORE = `${PRODUCT_PREFIX}/sellers/:sellerId/sales/:saleId`;

export const SALES_PRODUCT = `${SALES_PREFIX}`;
export const SALES_IMAGE = `${SALES_PREFIX}/images`;
export const BID_PRODUCT = `${BUYER_PREFIX}/bids/first`;
export const SELLER_SALES = `${SELLER_PREFIX}/sales`;
export const SELLER_BID_NEGOTIATION = `${SELLER_PREFIX}/negotiations`;
export const BUYER_BID_NEGOTIATION = `${BUYER_PREFIX}/negotiations`;
export const SELLER_BID_NEGOTIATION_BID = `${SELLER_PREFIX}/bids`;
export const BUYER_BID_NEGOTIATION_BID = `${BUYER_PREFIX}/bids`;
export const BUYER_BIDS = `${BUYER_PREFIX}/negotiations`;
export const ORDER_SELLER = `${SELLER_PREFIX}${ORDER_PREFIX}`;
export const ORDER_BUYER = `${BUYER_PREFIX}${ORDER_PREFIX}`;
export const SHIPCHAIN_ORDER_SELLER = `${SELLER_PREFIX}/shipchain`;
export const SHIPCHAIN_ORDER_BUYER = `${BUYER_PREFIX}/shipchain`;
export const FINANCIAL_SELLER = `${SELLER_PREFIX}/financial`;
export const FINANCIAL_BUYER = `${BUYER_PREFIX}/financial`;

export const WALLET_SELLER = `${SELLER_PREFIX}/wallet`;
export const WALLET_BUYER = `${BUYER_PREFIX}/wallet`;
export const WALLET_WITHDRAWAL = `${WALLET_PREFIX}/withdrawal`;

export const SELLER_BID = `${SELLER_PREFIX}/bids`;
export const BUYER_BID = `${BUYER_PREFIX}/bids`;

export const CHECK_2FA_STATUS = `${PUBLIC_PREFIX}/tfa/checking`;
export const CHECK_EXPIRE_BROWSER = `${TWO_FACTOR_AUTH_PREFIX}/check-expired-browser`;
export const CHECK_VALIDATION_CODE = `${TWO_FACTOR_AUTH_PREFIX}/code/validation`;
export const FORGET_BROWSER = `${TWO_FACTOR_AUTH_PREFIX}/browser/forgetting`;
export const SEND_VALIDATION_CODE = `${TWO_FACTOR_AUTH_PREFIX}/code`;
export const GET_GOOGLE_AUTHENTICATOR_QRCODE = `${TWO_FACTOR_AUTH_PREFIX}/ga`;

//INTRODUCER
export const INTRODUCER_INFO_TAB_PATH = `${INTRODUCER_PREFIX}`;
// COOP
export const COOP_TRADERS = `${COOP_PREFIX}/traders`;

//Business detail
export const UPLOAD_FILE = `companies/documents`;
export const GET_PRODUCT_CATEGORIES = `products/category`;
export const GET_PRODUCT_TYPE = `products/category`;
export const GET_PRODUCT_NAME = `/products/types`;
export const GET_TEMPLATES = `products/template`;
export const GET_PRODUCT_SUPPORTING_DOC = `product-rules`;
export const ADD_NEW_PRODUCT = `product-requests`;
export const BUSINESS_DETAIL = `/company-product-rules`;
export const PROFILE_BUSINESS_DETAIL = `/company-product-rule/profile`;

// Comment
export const COMMENT = COMMENT_PREFIX;

// Feature flag
export const FEATURE_FLAG = `${PUBLIC_PREFIX}/marketplace/settings`;

// Resource Config
export const MARKETPLACE_BRANDING_ASSET = `${PUBLIC_PREFIX}/marketplace/branding/assets`;
export const MARKETPLACE_NAME = `${PUBLIC_PREFIX}/organizations`;
