import { useMutation } from "react-query";
import { saveStep2 } from "@/services/kyc.service";
import { deleteOwnerDocuments } from "@/services/owner.service";
import { useInvalidateOwnerInfo } from "./use-owner-info";

const deleteAllOwnerDocuments = async (deletedFiles) => {
  await Promise.all(
    deletedFiles.map(({ fileIds, ownerId }) => deleteOwnerDocuments(fileIds, ownerId))
  );
};

const deleteDocumentsAndSave = async ({ ownerInfo, deletedIds, deletedFiles }) => {
  await deleteAllOwnerDocuments(deletedFiles);
  await saveStep2({ ownerInfo, deletedIds });
};

export const useUpdateOwner = () => {
  const invalidate = useInvalidateOwnerInfo();
  return useMutation(deleteDocumentsAndSave, {
    onSuccess: async () => {
      await invalidate();
    }
  });
};
