import { Loader } from "@/components/commons";
import { Box } from "@mui/material";
import { Suspense } from "react";
import { Route, Switch } from "react-router-dom";

interface NestedRoute {
  key: string;
  path: string | string[];
  Component: React.ElementType;
  children?: NestedRoute[];
  exact?: boolean;
}
const getChildrenPaths = (route: NestedRoute) => {
  if (route.path) {
    if (Array.isArray(route.path)) return route.path;
    return [route.path];
  }
  if (route.children == null || route.children.length === 0) {
    if (Array.isArray(route.path)) return route.path;
    return [route.path];
  }
  const paths: string[] = [];
  for (const child of route.children) {
    paths.push(...getChildrenPaths(child));
  }
  return paths;
};

export const Routes = ({ routes }: { routes: NestedRoute[] }) => {
  const getKey = (route: NestedRoute) => {
    if (route.key) return route.key;
    if (typeof route.path === "string") return route.path;
    if (Array.isArray(route.path)) return route.path.join();
    return "";
  };
  const renderRoutes = (routes: NestedRoute[]) => {
    return (
      <Suspense
        fallback={
          <Box height={200}>
            <Loader />
          </Box>
        }
      >
        <Switch>
          {routes.map((route) => {
            if (route.children == null || route.children.length === 0) {
              return (
                <Route
                  key={getKey(route)}
                  path={route.path}
                  component={route.Component as never}
                  exact={route?.exact ?? true}
                />
              );
            }
            const Layout = route.Component;
            const childrenPaths = getChildrenPaths(route);
            return (
              <Route key={route.key} path={childrenPaths} exact={route.exact ?? true}>
                <Layout>{renderRoutes(route.children)}</Layout>
              </Route>
            );
          })}
        </Switch>
      </Suspense>
    );
  };
  return <>{renderRoutes(routes)}</>;
};
