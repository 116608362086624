import React from "react";
import { Carousel } from "antd";
import styles from "./styles.module.scss";
import { BulletIcon, ArrowLeftIcon, ArrowRightIcon } from "components/utils/image-slider/icons";

export const ImageSlider = ({ images }) => {
  const [index, setIndex] = React.useState(0);
  const ref = React.useRef();
  const renderedBullets = React.useMemo(() => {
    const bullets = [];
    for (let i = 0; i < images.length; i++) {
      bullets.push(<BulletIcon key={i} active={i === index} />);
    }
    return <>{bullets}</>;
  }, [images, index]);
  const hasMany = images.length > 1;
  if (!images || images.length === 0) return <></>;
  return (
    <>
      <div className={styles.carousel}>
        <Carousel
          ref={ref}
          afterChange={setIndex}
          effect="fade"
          className="custom-pagination-slider"
          dots={false}
        >
          {images.map((item) => (
            <img alt={item.url} key={`img${item.id}`} src={item.url} />
          ))}
        </Carousel>
        {hasMany && (
          <div className={styles.arrowLeft}>
            <ArrowLeftIcon onClick={() => ref.current.prev()} />
          </div>
        )}
        {hasMany && (
          <div className={styles.arrowRight}>
            <ArrowRightIcon onClick={() => ref.current.next()} />
          </div>
        )}
      </div>
      {hasMany && <div className={styles.bullets}>{renderedBullets}</div>}
    </>
  );
};
