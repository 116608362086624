import moment from "moment-timezone";
import { DATETIME_FORMAT } from "@/commons/consts/general.const";
import { datetimeUtil, setTimeForDayByMinute } from "./date-time.util";
import { convertStringToMinutes } from "./convert.util";

const { minutesToTime, isBetweenTwoDate } = datetimeUtil;

export const calBidTimer = (bid) => {
  const sellerTimezone = bid.sellerTimezone;
  const buyerTimezone = bid.buyerTimezone;
  const expiredDate = bid.expiredDate;
  const isSeller = bid.isSeller;
  const startWorkingHour = isSeller ? bid.sellerStartWorkingHour : bid.buyerStartWorkingHour;
  const endWorkingHour = isSeller ? bid.sellerEndWorkingHour : bid.buyerEndWorkingHour;
  const workingDays = isSeller ? bid.sellerWorkingDays : bid.buyerWorkingDays;

  let countdownMinutes;
  let totalMinutes = 0;

  // Get seller and buyer time
  let sellerTime = moment.tz(moment.utc(expiredDate, DATETIME_FORMAT), sellerTimezone);
  let buyerTime = moment.tz(moment.utc(expiredDate, DATETIME_FORMAT), buyerTimezone);
  // Compare time zone
  const compareTimezone = Math.abs(sellerTime.utcOffset() / 60 - buyerTime.utcOffset() / 60);
  // Get total minutes countdown
  if (compareTimezone < 4) {
    totalMinutes = 4 * 60;
  } else if (compareTimezone >= 8) {
    totalMinutes = 12 * 60;
  } else {
    totalMinutes = 8 * 60;
  }
  // Set defaut timezone and expired time by timezone
  const defaultTz = isSeller ? sellerTimezone : buyerTimezone;
  const expiredTimeByTz = isSeller ? sellerTime : buyerTime;

  // Convert current time utc to timezone
  const startTimeByTz = moment.tz(moment.utc(bid.countDownDate), defaultTz);
  const now = new Date().toUTCString();
  let currentTimeByTz = moment.tz(moment.utc(now), defaultTz);

  const dayOfStartTime = startTimeByTz.toDate().getDate();
  const dayOfExpiredTime = expiredTimeByTz.toDate().getDate();
  const dayOfCurrentTime = currentTimeByTz.toDate().getDate();

  const startCDMinutes = convertStringToMinutes(startTimeByTz.format("HH:mm"));
  const currentMinutes = convertStringToMinutes(currentTimeByTz.format("HH:mm"));
  const expiredCDMinutes = convertStringToMinutes(expiredTimeByTz.format("HH:mm"));
  const endWorkingMinutes = endWorkingHour;
  const startWorkingMinutes = startWorkingHour;
  let minutesToday;

  const setExpiredCountdown = (minutes, isRuning = false, minutesToday = 0) => {
    if (minutes) {
      return {
        time: minutesToTime(minutes),
        minutesRemaining: minutes,
        isRuning: isRuning,
        minutesToday: minutesToday,
        totalMinutes: totalMinutes
      };
    } else {
      //expired
      return undefined;
    }
  };

  // I
  if (moment(currentTimeByTz).isBefore(startTimeByTz)) {
    minutesToday = endWorkingMinutes - startCDMinutes;
    return setExpiredCountdown(totalMinutes, false, minutesToday);
  }
  // II
  else if (moment(expiredTimeByTz).isSameOrBefore(currentTimeByTz)) {
    return setExpiredCountdown(null, false, 0);
  }
  // III
  else {
    // 1
    if (dayOfStartTime === dayOfExpiredTime) {
      countdownMinutes = expiredCDMinutes - currentMinutes;
      return setExpiredCountdown(countdownMinutes, true, countdownMinutes);
    }
    // 2
    else {
      // a
      const endWKStart = setTimeForDayByMinute(endWorkingMinutes, startTimeByTz);
      const startCDAndEndWK = isBetweenTwoDate(currentTimeByTz, startTimeByTz, endWKStart);
      if (startCDAndEndWK) {
        countdownMinutes = totalMinutes - (currentMinutes - startCDMinutes);
        minutesToday = endWorkingMinutes - currentMinutes;
        return setExpiredCountdown(countdownMinutes, true, minutesToday);
      }
      // b.
      const startWKEnd = setTimeForDayByMinute(startWorkingMinutes, expiredTimeByTz);
      const expiredCDAndStartWK = isBetweenTwoDate(currentTimeByTz, startWKEnd, expiredTimeByTz);
      if (expiredCDAndStartWK) {
        countdownMinutes = expiredCDMinutes - currentMinutes;
        return setExpiredCountdown(countdownMinutes, true, countdownMinutes);
      }
      // c
      const currentDayOfWeek = currentTimeByTz.isoWeekday();
      let i = 0;
      let isRuning = false;
      countdownMinutes = totalMinutes - (endWorkingMinutes - startCDMinutes);

      if (workingDays.includes(currentDayOfWeek) && dayOfCurrentTime !== dayOfStartTime) {
        if (currentMinutes < startWorkingMinutes) {
          minutesToday = 0;
        } else if (currentMinutes >= endWorkingMinutes) {
          minutesToday = 0;
          i += 1;
        } else {
          isRuning = true;
          minutesToday = endWorkingMinutes - currentMinutes;
          countdownMinutes = countdownMinutes - (currentMinutes - startWorkingMinutes);
        }
      }

      if (dayOfCurrentTime !== dayOfStartTime) {
        let temp = currentTimeByTz;
        let yesterday = moment(temp).subtract(1, "days");
        let dayOfYesterday = yesterday.toDate().getDate();
        let day = yesterday.isoWeekday();

        while (dayOfYesterday !== dayOfStartTime) {
          if (workingDays.includes(day)) {
            i += 1;
          }
          temp = yesterday;
          yesterday = moment(temp).subtract(1, "days");
          dayOfYesterday = yesterday.toDate().getDate();
          day = yesterday.isoWeekday();
        }
      }

      countdownMinutes = countdownMinutes - (endWorkingMinutes - startWorkingMinutes) * i;
      return setExpiredCountdown(countdownMinutes, isRuning, minutesToday);
    }
  }
};

export const calDueTimeAddFund = (bid) => {
  const {
    sellerTimezone,
    expiredDate,
    sellerStartWorkingHour,
    sellerEndWorkingHour,
    sellerWorkingDays
  } = bid;

  let timeRemaining = 0;
  const hoursInDay = sellerEndWorkingHour - sellerStartWorkingHour;

  const setExpiredCountdown = (minutes) => {
    if (minutes) {
      return {
        minutesRemaining: minutes,
        hoursInDay: hoursInDay / 60
      };
    } else {
      //expired
      return undefined;
    }
  };

  const expiredDateSeller = moment.tz(moment.utc(expiredDate, DATETIME_FORMAT), sellerTimezone);

  const now = new Date().toUTCString();
  const currentTimeSeller = moment.tz(moment.utc(now), sellerTimezone);

  //// I) Expired
  if (moment(currentTimeSeller).isSameOrAfter(expiredDateSeller)) {
    return setExpiredCountdown(timeRemaining);
  }

  //// II) Not Expire
  const dayOfExpiredTime = expiredDateSeller.toDate().getDate();
  const dayOfCurrentTime = currentTimeSeller.toDate().getDate();

  const currentMinutes = convertStringToMinutes(currentTimeSeller.format("HH:mm"));
  const expiredMinutes = convertStringToMinutes(expiredDateSeller.format("HH:mm"));
  const startWorkingMinutes = sellerStartWorkingHour;
  const endWorkingMinutes = sellerEndWorkingHour;

  /// 1) Current Date === Expired Date
  if (dayOfCurrentTime === dayOfExpiredTime) {
    timeRemaining = expiredMinutes - currentMinutes;
    return setExpiredCountdown(timeRemaining);
  }

  /// 2) Current Date in workingDays and !== Expired Date
  const currentDayOfWeek = currentTimeSeller.isoWeekday();
  let i = 0;

  // Cal time in Expired Date
  timeRemaining = endWorkingMinutes - expiredMinutes;

  // Cal time in Current Date
  if (sellerWorkingDays.includes(currentDayOfWeek)) {
    if (currentMinutes < startWorkingMinutes) {
      i = 0;
    } else if (currentMinutes >= endWorkingMinutes) {
      i += 1;
    } else {
      timeRemaining += endWorkingMinutes - currentMinutes;
    }
  }

  // Cal time between  Current Date and Expired Date
  let temp = currentTimeSeller;
  let nextDay = moment(temp).add(1, "days");
  let dayOfNextDay = nextDay.toDate().getDate();
  let day = nextDay.isoWeekday();
  while (dayOfNextDay !== dayOfExpiredTime) {
    if (sellerWorkingDays.includes(day)) {
      i += 1;
    }
    temp = nextDay;
    nextDay = moment(temp).add(1, "days");
    dayOfNextDay = nextDay.toDate().getDate();
    day = nextDay.isoWeekday();
  }

  timeRemaining += (endWorkingMinutes - startWorkingMinutes) * i;
  return setExpiredCountdown(timeRemaining);
};
