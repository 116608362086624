import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useParams } from "@/components/commons/navigator";
import Typography from "@mui/material/Typography";
import { SubLayout } from "@/components/layout/sub-layout.comp";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Divider from "@mui/material/Divider";
import { UserService } from "@/services";
import { useAsyncErrorHandler } from "@/utils/error-handler.util";
import { Loader } from "../commons";
import { TablePagination } from "@mui/material";
import { ROUTES } from "@/commons/consts";
import { UserDetailsContext } from "./context.comp";
import { getAllRecordsFromAPI } from "@/utils/general.util";

const PAGE_SIZE = 10;

const Header = () => {
  return (
    <Stack spacing={3} alignItems="flex-start">
      <Typography variant="h5">Traders</Typography>
    </Stack>
  );
};

const Container = ({ children }) => {
  const asyncErrorHandler = useAsyncErrorHandler();
  const { id } = useParams();
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [pageIndex, setPageIndex] = useState(0);

  const getTraders = useCallback(async () => {
    try {
      await asyncErrorHandler(async () => {
        const traders = await getAllRecordsFromAPI(UserService.getAllUsers);
        setData(traders);
      });
    } finally {
      setLoading(false);
    }
  }, [asyncErrorHandler]);

  const filteredTraderList = useMemo(() => {
    const firstSize = pageIndex * PAGE_SIZE;
    const lastSize = PAGE_SIZE + firstSize;
    return data?.slice(firstSize, lastSize);
  }, [data, pageIndex]);

  const filteredPageIndex = useMemo(() => {
    const index = data?.findIndex((trader) => trader.id === id);
    return Math.trunc(index / PAGE_SIZE);
  }, [data, id]);

  const handlePageChange = useCallback((_, page) => {
    setPageIndex(page);
  }, []);

  const menuData = React.useMemo(() => {
    if (data == null) return;
    const menuItems = filteredTraderList?.map((user) => ({
      title: (
        <Stack direction="column" spacing={1}>
          <Typography variant="body2" color="text.primary">
            {user.firstName} {user.lastName}
          </Typography>
          <Typography fontSize="14px" color="grey.500">
            {user.email}
          </Typography>
        </Stack>
      ),
      key: user.id,
      url: `${ROUTES.TRADER_DETAILS}/${user.id}`
    }));

    return [...menuItems];
  }, [data, filteredTraderList]);

  useEffect(() => {
    getTraders();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setPageIndex(filteredPageIndex);
  }, [filteredPageIndex]);

  if (loading) {
    return (
      <Stack height={200}>
        <Loader />
      </Stack>
    );
  }

  return (
    <SubLayout
      header={<Header />}
      menuData={menuData}
      divider={<Divider sx={{ borderColor: "grey.300" }} />}
      widthSubSidebar={307}
      lastItemSubSidebar={
        <Stack direction="row" justifyContent="flex-end" width="100%" mt={-1}>
          <TablePagination
            count={data?.length}
            page={pageIndex}
            rowsPerPageOptions={[]}
            rowsPerPage={PAGE_SIZE}
            onPageChange={handlePageChange}
            sx={{
              border: 0
            }}
          />
        </Stack>
      }
    >
      <UserDetailsContext.Provider value={data}>{children}</UserDetailsContext.Provider>
    </SubLayout>
  );
};

export const Layout = ({ children }) => {
  return (
    <Box height="100%">
      <Container>{children}</Container>
    </Box>
  );
};
