import React from "react";
import { ERR_MSG } from "@/commons/consts";
import { createFormErrorComp } from "@/utils";
import { pdfUploadRules, validPhoneNumber, FormLabel } from "components/utils";
import { TEXTS } from "@/commons/consts/texts.const";

export const FIELDS = {
  name: "name",
  registrationNumber: "registrationNumber",
  timezone: "timezone",
  workingStartTime: "workingStartTime",
  workingEndTime: "workingEndTime",
  workingDays: "workingDays",
  officeNumber: "officeNumber",
  officeNumberPrefix: "officeNumberPrefix",
  country: "country",
  incorporationDate: "incorporationDate",
  registrationExpiryDate: "registrationExpiryDate",
  jurisdictionOfIncorporation: "jurisdictionOfIncorporation",
  certificationDocument: "certificationDocument"
};
export const LABELS = {
  [FIELDS.name]: "Company Name",
  [FIELDS.registrationNumber]: "Company Registration Number",
  [FIELDS.country]: "Country",
  [FIELDS.certificateRegistration]: "Certificate Registration",
  [FIELDS.workingDays]: "Working Days",
  [FIELDS.workingEndTime]: "Office Hours (End Time)",
  [FIELDS.workingStartTime]: "Office Hours (Start Time)",
  [FIELDS.timezone]: "Time zone",
  [FIELDS.officeNumber]: "Office Number",
  [FIELDS.incorporationDate]: "Date of Incorporation",
  [FIELDS.registrationExpiryDate]: "Expiry Date of Company Registration",
  [FIELDS.jurisdictionOfIncorporation]: "Jurisdiction Of Incorporation",
  [FIELDS.certificationDocument]: "Please upload the Certification of Registration of the Company"
};
const HOVER_TEXT = {
  companyInfo: TEXTS.companyInfoHoverText,
  name: TEXTS.companyNameHoverText,
  registrationNumber: TEXTS.companyRegistrationNumberHoverText,
  country: TEXTS.companyCountryHoverText,
  officeNumber: TEXTS.companyOfficeNumberHoverText,
  incorporationDate: TEXTS.companyIncorporationDateHoverText,
  registrationExpiryDate: TEXTS.companyRegistrationExpiryDateHoverText
};

export const INCLUDED_JOI_COUNTRIES = ["CA", "US"];
export const hasJOI = (country) => INCLUDED_JOI_COUNTRIES.includes(country);

const Label = React.memo(({ name }) => (
  <FormLabel label={LABELS[name]} tooltip={HOVER_TEXT[name]} />
));

export const companyInfoSchema = {
  [FIELDS.name]: {
    name: FIELDS.name,
    label: <Label name={FIELDS.name} />,
    placeholder: "ABC Trading Ltd",
    options: {
      rules: [
        {
          required: true,
          message: createFormErrorComp(ERR_MSG.REQUIRED_ERR(LABELS[FIELDS.name]))
        }
      ]
    }
  },
  [FIELDS.registrationNumber]: {
    name: FIELDS.registrationNumber,
    label: <Label name={FIELDS.registrationNumber} />,
    placeholder: "01234567, ABC12344 etc.",
    options: {
      rules: [
        {
          required: true,
          message: createFormErrorComp(ERR_MSG.REQUIRED_ERR(LABELS[FIELDS.registrationNumber]))
        }
      ]
    }
  },
  [FIELDS.country]: {
    name: FIELDS.country,
    label: <Label name={FIELDS.country} />,
    type: "country",
    placeholder: "USA, Australia etc.",
    options: {
      rules: [
        {
          required: true,
          message: createFormErrorComp(ERR_MSG.REQUIRED_ERR(FIELDS.country))
        }
      ]
    }
  },
  [FIELDS.officeNumber]: {
    name: FIELDS.officeNumber,
    label: <Label name={FIELDS.officeNumber} />,
    type: "phone",
    options: {
      rules: [
        {
          required: true,
          message: createFormErrorComp(ERR_MSG.REQUIRED_ERR("Phone Number"))
        },
        validPhoneNumber
      ]
    }
  },
  [FIELDS.incorporationDate]: {
    name: FIELDS.incorporationDate,
    label: <Label name={FIELDS.incorporationDate} />,
    type: "date",
    placeholder: LABELS[FIELDS.incorporationDate]
  },
  [FIELDS.registrationExpiryDate]: {
    name: FIELDS.registrationExpiryDate,
    label: <Label name={FIELDS.registrationExpiryDate} />,
    placeholder: LABELS[FIELDS.registrationExpiryDate],
    type: "date",
    options: {
      rules: [
        {
          required: true,
          message: createFormErrorComp(ERR_MSG.REQUIRED_ERR(LABELS[FIELDS.registrationExpiryDate]))
        }
      ]
    }
  },
  [FIELDS.jurisdictionOfIncorporation]: {
    name: FIELDS.jurisdictionOfIncorporation,
    label: LABELS[FIELDS.jurisdictionOfIncorporation],
    placeholder: LABELS[FIELDS.jurisdictionOfIncorporation],
    options: {
      rules: [
        {
          required: true,
          message: createFormErrorComp(
            ERR_MSG.REQUIRED_ERR(LABELS[FIELDS.jurisdictionOfIncorporation])
          )
        }
      ]
    }
  },
  [FIELDS.certificationDocument]: {
    name: FIELDS.certificationDocument,
    label: LABELS[FIELDS.certificationDocument],
    type: "file",
    options: {
      rules: pdfUploadRules
    }
  }
};
