import Box from "@mui/material/Box";
import { Helmet } from "react-helmet";

export const Layout = ({ children }) => {
  return (
    <Box px={3} pt={3} height="100%">
      <Helmet title="Inventory" />
      {children}
    </Box>
  );
};
