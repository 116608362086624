import React, { useImperativeHandle } from "react";
import { OwnerInfoFormView } from "./owner-info-form-view.comp";
import { FIELDS, hasNationalIdNumber } from "./owner-info-form.schema";

import { Form } from "antd";
import { DOCUMENT_KEYS, DOCUMENT_TYPES } from "@/commons/consts/document.const";

export const OwnerInfoForm = React.forwardRef(({ initialValues, onSubmit, disabled }, ref) => {
  const [form] = Form.useForm();
  useImperativeHandle(ref, () => ({
    form
  }));
  const getDocumentNumberValue = React.useCallback(
    (value) => {
      const { getFieldValue } = form;
      const showNationId = hasNationalIdNumber(getFieldValue(FIELDS.nationality));
      if (showNationId && value === DOCUMENT_KEYS.NATIONAL_ID) {
        return getFieldValue(FIELDS.nationalIdNumber);
      }
      if (initialValues && value === initialValues[FIELDS.documentType]) {
        return initialValues[FIELDS.documentNumber];
      }
      return undefined;
    },
    [form, initialValues]
  );

  const updateNationalId = React.useCallback(() => {
    const values = form.getFieldsValue();
    if (
      hasNationalIdNumber(values.nationality) &&
      values.documentType === DOCUMENT_KEYS.NATIONAL_ID
    ) {
      form.setFieldsValue({
        ...values,
        nationalIdNumber: values.documentNumber
      });
    }
  }, [form]);

  const handleDocumentTypeChange = React.useCallback(
    (value) => {
      const { setFieldsValue } = form;
      const issueDate =
        initialValues &&
        initialValues[FIELDS.documentType] === value &&
        initialValues[FIELDS.issueDate]
          ? initialValues[FIELDS.issueDate]
          : undefined;
      const expiryDate =
        initialValues &&
        initialValues[FIELDS.documentType] === value &&
        initialValues[FIELDS.expiryDate]
          ? initialValues[FIELDS.expiryDate]
          : undefined;
      const documentNumberValue = getDocumentNumberValue(value);
      setFieldsValue({
        [FIELDS.documentNumber]: documentNumberValue,
        [FIELDS.issueDate]: issueDate,
        [FIELDS.expiryDate]: expiryDate
      });
      const showNationId = hasNationalIdNumber(form.getFieldValue(FIELDS.nationality));
      if (showNationId && value === DOCUMENT_TYPES.NATIONAL_ID) {
        form.setFieldsValue({
          [FIELDS.nationalIdNumber]: documentNumberValue
        });
      }
    },
    [form, initialValues, getDocumentNumberValue]
  );

  const handleDocumentNumberChange = React.useCallback(() => {
    updateNationalId();
  }, [updateNationalId]);

  const handleNationalityChange = React.useCallback(() => {
    updateNationalId();
  }, [updateNationalId]);

  return (
    <Form form={form} layout="vertical" onSubmit={onSubmit} initialValues={initialValues}>
      <OwnerInfoFormView
        form={form}
        initialValues={initialValues}
        onSubmit={onSubmit}
        disabled={disabled}
        onDocumentTypeChange={handleDocumentTypeChange}
        onDocumentNumberChange={handleDocumentNumberChange}
        onNationalityChange={handleNationalityChange}
      />
    </Form>
  );
});
