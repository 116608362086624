import React from "react";
import { Link, useLocation } from "@/components/commons/navigator";
import { EllipsisOutlined } from "@ant-design/icons";
import { Button, Menu, Dropdown, Layout } from "antd";
import { ROUTES } from "@/commons/consts";
import { isScreensize } from "@/utils";
import { AuthThemeLayout } from "@/processes/antd-theme-layout/ant-theme.layout";
import { LegacyContainer } from "@/processes/legacy-container";
import "./index.scss";
import { Logo } from "@/components/logo/logo.comp";

const { SubMenu } = Menu;
const { Header, Content } = Layout;
const routes = [
  ROUTES.POLICIES,
  ROUTES.FEE_SCHEDULE,
  ROUTES.PRIVACY_POLICY,
  ROUTES.TERMS_AND_CONDITIONS
];
const PoliciesPage = ({ children }) => {
  const location = useLocation();

  const activeKeys = routes.filter((route) => route.startsWith(location.pathname));

  const menu = (
    <Menu
      selectedKeys={activeKeys}
      mode="horizontal"
      className="menu"
      style={{ lineHeight: "45px" }}
    >
      <Menu.Item key={ROUTES.TERMS_AND_CONDITIONS}>
        <Link to={ROUTES.TERMS_AND_CONDITIONS} replace>
          Terms & Conditions
        </Link>
      </Menu.Item>
      <SubMenu key="policies" popupClassName="popup-policies-text-center" title="Policies">
        <Menu.Item key={ROUTES.FEE_SCHEDULE}>
          <Link to={ROUTES.FEE_SCHEDULE} replace>
            Fee Schedule
          </Link>
        </Menu.Item>
        <Menu.Divider />
        <Menu.Item key={ROUTES.PRIVACY_POLICY}>
          <Link to={ROUTES.PRIVACY_POLICY} replace>
            Privacy Policy
          </Link>
        </Menu.Item>
      </SubMenu>
    </Menu>
  );

  const mobileMenu = (
    <Dropdown overlay={menu}>
      <Button className="menu-button" size="large">
        <EllipsisOutlined />
      </Button>
    </Dropdown>
  );

  return (
    <AuthThemeLayout theme={{ name: "buyer" }}>
      <LegacyContainer>
        <Layout className="layout">
          <Header className="term__header policy">
            <div
              style={{
                height: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center"
              }}
            >
              <a href="/">
                <Logo height={70} width="auto" />
              </a>
            </div>
            {isScreensize("sm") ? mobileMenu : menu}
          </Header>
          <Content className="content">{children}</Content>
        </Layout>
      </LegacyContainer>
    </AuthThemeLayout>
  );
};

export default PoliciesPage;
