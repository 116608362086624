import { DATETIME_FORMAT } from "@/commons/consts/general.const";
import { toNumber } from "./general.util";
import moment from "moment";

//Takes into account Unicode and non-English alphabets
export const sortAlphabetically = (stringA, stringB) => {
  return stringA && stringA.localeCompare(stringB, "en", { sensitivity: "base" });
};

export const sortPrice = (priceA, priceB) => {
  return toNumber(priceA) - toNumber(priceB);
};

export const sortDateTime = (firstDate, lastDate) =>
  moment(firstDate, DATETIME_FORMAT).valueOf() - moment(lastDate, DATETIME_FORMAT).valueOf();
