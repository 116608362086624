import React from "react";
import { Loader } from "@/components/commons";
import { useUserProfile } from "@/entities/user-profile/libs/use-user-profile";
import { useKeycloak } from "@react-keycloak/web";
import { usePrefixPath } from "@/utils/location.util";
import { COMPANY_TYPE } from "@/commons/consts/general.const";
import { AccessDenied } from "../access-denied/access-denied.page";

const getRoles = (companyType: string) => {
  if (companyType === COMPANY_TYPE.CCC_DUAL)
    return [COMPANY_TYPE.CCC_BUYER, COMPANY_TYPE.CCC_SELLER];
  if (companyType === COMPANY_TYPE.PCC_DUAL) {
    return [COMPANY_TYPE.CCC_BUYER, COMPANY_TYPE.CCC_SELLER, COMPANY_TYPE.PCC_DUAL];
  }
  if (companyType === COMPANY_TYPE.CCC_BUYER || companyType === COMPANY_TYPE.CCC_SELLER)
    return [companyType];
  return [];
};

const roleToPrefix = {
  [COMPANY_TYPE.CCC_BUYER]: "/buyer",
  [COMPANY_TYPE.CCC_SELLER]: "/seller",
  [COMPANY_TYPE.PCC_DUAL]: "/admin"
};

const useCheckPrefixPath = () => {
  const { prefix: prefixValue } = usePrefixPath();
  const prefix = "/" + prefixValue;
  const { user } = useUserProfile();

  const getAvailablePrefixes = () => {
    const availableRoles = getRoles(user?.companyType);
    const prefixes: string[] = [];
    for (const role of availableRoles) {
      if (role in roleToPrefix) {
        prefixes.push(roleToPrefix[role]);
      }
    }
    return prefixes;
  };
  const availablePrefixes = getAvailablePrefixes();
  const isValidPrefix = () => {
    return Boolean(prefix && availablePrefixes.includes(prefix));
  };
  return { prefix, isValidPrefix, availablePrefixes };
};

export const UserGuard = ({ children }: { children: React.ReactNode }) => {
  const { keycloak, initialized } = useKeycloak();
  const { isLoading } = useUserProfile();
  const { isValidPrefix } = useCheckPrefixPath();

  React.useEffect(() => {
    if (!keycloak.authenticated) {
      keycloak.login();
    }
  }, [keycloak]);

  if (!initialized || !keycloak.authenticated) return <Loader />;
  if (isLoading) return <Loader />;
  if (isValidPrefix()) return <>{children}</>;
  return <AccessDenied />;
};
